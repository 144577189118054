import { DeleteOutlined, ExpandOutlined } from '@ant-design/icons';

export const figure = [
  {
    key: 'edit-responsive-figure',
    label: 'Configurer le responsive',
    icon: <ExpandOutlined />,
    onClick: (_, editor) => {
      editor.context.toggleFigureResponsiveModal(true);
    },
  },
  {
    key: 'delete-figure',
    label: 'Supprimer',
    icon: <DeleteOutlined />,
    onClick: (_, editor) => {
      editor.commands.deleteNode('figure');
    },
  },
];
