export const getSelectedText = (editor) => {
  const { from, to } = editor.view.state.selection;

  if (!from || !to) {
    return '';
  }

  if (from === to) {
    return '';
  }

  return editor.view.state.doc.textBetween(from, to);
};
