import { useEffect, useRef, memo } from 'react';
import { Button, Collapse, Popconfirm, Space, Switch, Tag } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';
import EditableTitle from './EditableTitle';
import EditableAnswer from './EditableAnswer';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const FAQQuestion = memo(
  ({
    data,
    questionIndex,
    sectionIndex,
    sectionId,
    isEditing,
    removeQuestion,
    onMove,
    onSwap,
    whileDragging,
    disabled,
    sectionState,
    ...props
  }) => {
    const { control } = useFormContext();
    const { user } = useAuth();
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [, drop] = useDrop({
      accept: 'QUESTION',
      drop: (item, monitor) => {
        if (item.sectionIndex === sectionIndex) {
          if (item.index === questionIndex) return;

          onSwap(
            { section: item.sectionIndex, question: item.index },
            { section: sectionIndex, question: questionIndex },
          );
        } else {
          onMove(
            { section: item.sectionIndex, question: item.index },
            { section: sectionIndex, question: questionIndex },
          );
        }
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'QUESTION',
      item: { index: questionIndex, sectionIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    useEffect(() => whileDragging(), [isDragging]);

    const popconfirmProps = {
      title: 'Êtes-vous sûr ?',
      cancelText: 'Non',
      okText: 'Oui',
      placement: 'left',
    };

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <div ref={previewRef} style={{ opacity, borderTop: questionIndex !== 0 && '1px solid #d9d9d9' }}>
        <Collapse.Panel
          {...props}
          className="arinfo-faq-question"
          header={
            <div
              onClick={(e) => isEditing && e.stopPropagation()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <EditableTitle
                fieldName={`sections.${sectionIndex}.questions[${questionIndex}].question`}
                isEditing={isEditing}
                disabled={disabled}
                authorized={checkAuthorization(user, 'arinfo', 'faq', 'update-faq')}
              />
            </div>
          }
          extra={
            <>
              {isEditing ? (
                <Space onClick={(e) => isEditing && e.stopPropagation()}>
                  {(checkAuthorization(user, 'arinfo', 'faq', 'enable-question') ||
                    checkAuthorization(user, 'arinfo', 'faq', 'disable-question')) && (
                    <Controller
                      control={control}
                      name={`sections.${sectionIndex}.questions[${questionIndex}].disabled`}
                      render={({ field }) => (
                        <Switch
                          onChange={(value) => {
                            const canAccess = field.value
                              ? checkAuthorization(user, 'arinfo', 'faq', 'enable-question')
                              : checkAuthorization(user, 'arinfo', 'faq', 'disable-question');

                            if (canAccess) {
                              field.onChange(!value);
                            }
                          }}
                          checked={!field.value}
                          checkedChildren="Activé"
                          unCheckedChildren="Désactivé"
                          disabled={
                            disabled ||
                            (field.value
                              ? !checkAuthorization(user, 'arinfo', 'faq', 'enable-question')
                              : !checkAuthorization(user, 'arinfo', 'faq', 'disable-question'))
                          }
                        />
                      )}
                    />
                  )}
                  {checkAuthorization(user, 'arinfo', 'faq', 'update-faq') && (
                    <>
                      <Button
                        ref={ref}
                        icon={<DragOutlined />}
                        onClick={(e) => e.stopPropagation()}
                        disabled={disabled}
                      />
                      <Popconfirm
                        {...popconfirmProps}
                        onConfirm={() => removeQuestion(questionIndex)}
                        disabled={disabled}
                      >
                        <Button icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} disabled={disabled} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              ) : (
                <Tag color={data.disabled ? 'red' : !sectionState && 'green'}>
                  {data.disabled ? 'Désactivé' : !sectionState ? 'Activé' : 'Activé mais section invisible'}
                </Tag>
              )}
            </>
          }
        >
          <Controller
            control={control}
            name={`sections.${sectionIndex}.questions[${questionIndex}].answer`}
            render={({ field }) => (
              <EditableAnswer isEditing={isEditing} fieldProps={field} disabled={disabled} isDragging={isDragging} />
            )}
          />
        </Collapse.Panel>
      </div>
    );
  },
);

FAQQuestion.displayName = 'FAQQuestion';
export default FAQQuestion;
