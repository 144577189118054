import { Input, Select, Space, TreeSelect } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import useSWR from 'swr';
import { agencyRoutes, formationRoutes, groupRoutes, userRoutes } from '../../../../lib/routes';
import { searchInString } from '../../../../lib/helpers';
import { sort } from '../../../../shared/utils';

const CompanyFormationListFilters = ({ values, setValues, steps }) => {
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: groups, isValidating: isGroupsValidating } = useSWR(groupRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: users, isValidating: isUsersValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const formattedAgencies = (agencies?.data || [])
    .sort((a, b) => sort(a, b, 'city'))
    .map((item) => {
      return { label: item.city, value: item._id };
    });

  const formattedFormations = (formations?.data || [])
    .sort((a, b) => sort(a, b, 'title'))
    .map((item) => {
      return { label: item.title, value: item._id };
    });

  const formattedGroups = (groups?.data || [])
    .sort((a, b) => sort(a, b, 'name'))
    .map((item) => {
      return { label: item.name, value: item._id };
    });

  const formattedUsers = (users?.data || [])
    .sort((a, b) => sort(a, b, 'last_name'))
    .map((item) => {
      return { label: `${item.last_name.toUpperCase()} ${capitalize(item.first_name)}`, value: item._id };
    });

  return (
    <div className="reports-search-container">
      <Input
        placeholder="Rechercher"
        style={{ width: 200 }}
        value={values?.query}
        onChange={(e) => setValues({ ...values, query: e.target.value })}
        suffix={<SearchOutlined />}
        allowClear={true}
      />
      <Space>
        {steps && (
          <TreeSelect
            placeholder="Étape"
            showCheckedStrategy="SHOW_PARENT"
            treeCheckable={true}
            style={{ width: 250 }}
            value={values?.step}
            filterTreeNode={(input, option) => searchInString(input, option.label)}
            onChange={(e) => setValues({ ...values, step: e })}
            treeData={steps}
            allowClear
          />
        )}
        <Select
          placeholder="Responsable du suivi"
          mode="multiple"
          style={{ width: 200 }}
          value={values?.referents}
          filterOption={(input, option) => searchInString(input, option.label)}
          onChange={(e) => setValues({ ...values, referents: e })}
          options={formattedUsers || []}
          allowClear
          disabled={!users || isUsersValidating}
        />
        <Select
          placeholder="Groupe"
          mode="multiple"
          style={{ width: 150 }}
          value={values?.groups}
          filterOption={(input, option) => searchInString(input, option.label)}
          onChange={(e) => setValues({ ...values, groups: e })}
          options={formattedGroups || []}
          allowClear
          disabled={!groups || isGroupsValidating}
        />
        <Select
          placeholder="Formation"
          mode="multiple"
          style={{ width: 150 }}
          value={values?.trainings}
          filterOption={(input, option) => searchInString(input, option.label)}
          onChange={(e) => setValues({ ...values, trainings: e })}
          options={formattedFormations || []}
          allowClear
          disabled={!formations || isFormationsValidating}
        />
        <Select
          placeholder="Centre"
          mode="multiple"
          style={{ width: 150 }}
          value={values?.agencies}
          filterOption={(input, option) => searchInString(input, option.label)}
          onChange={(e) => setValues({ ...values, agencies: e })}
          options={formattedAgencies || []}
          allowClear
          disabled={!agencies || isAgenciesValidating}
        />
      </Space>
    </div>
  );
};

export default CompanyFormationListFilters;
