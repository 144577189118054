import { Button, Popover, Space, Form, Tooltip, Upload, Typography, message, Alert } from 'antd';
import { useState } from 'react';
import { VideoCameraOutlined, UploadOutlined } from '@ant-design/icons';
import useFetch from '../../hooks/useFetch';
import chunkUpload from '../../lib/chunk-upload';
import { useCloudinaryContext } from '../../cloudinaryContext';

const CloudinaryVideoPopover = ({ editor, disabled }) => {
  const { status, showUsageModal, refreshCache } = useCloudinaryContext();
  const [isOpen, setIsOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { post } = useFetch();

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={(visible) => {
        if (disabled || [null, 'danger'].includes(status)) {
          showUsageModal();
          return setIsOpen(false);
        }

        if (!uploading) {
          setIsOpen(!isOpen);
        }
      }}
      trigger="click"
      title="Téléverser une vidéo"
      destroyTooltipOnHide
      placement="bottom"
      className="editor-upload-video"
      content={
        <Space direction="vertical" style={{ width: 450 }}>
          {status === 'warning' && (
            <Alert
              type="warning"
              showIcon
              description="Le quota d'utilisation mensuel à dépassé les 50%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos sera désactivé à partir de 75% et ce jusqu'a la prochaine mensualité."
            />
          )}
          <Form.Item style={{ margin: 0 }}>
            <Upload
              action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/video/upload`}
              customRequest={chunkUpload}
              listType="picture"
              data={async (file) => {
                // Récupère une signature d'upload
                const url = `${process.env.REACT_APP_BASE_URL_API_URL}/v2/medias/videos/signature`;
                const response = await post(url);

                if (response.status === 200) {
                  const { timestamp, signature } = response.data;

                  return {
                    file: file,
                    api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
                    timestamp,
                    signature,
                  };
                }
              }}
              beforeUpload={(file) => {
                if (!file.type.startsWith('video/')) {
                  return Upload.LIST_IGNORE;
                }

                const fileSize = file.size / 1024 / 1024;

                if (fileSize > 2000) {
                  message.error('Téléversement limité à 2 Go');
                  return Upload.LIST_IGNORE;
                }
              }}
              onChange={({ fileList }) => {
                const file = fileList[0];
                const { status } = file;

                if (status === 'uploading') {
                  setUploading(true);
                } else if (status === 'error') {
                  setUploading(false);
                } else if (status === 'done') {
                  const url = `https://player.cloudinary.com/embed/?cloud_name=${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}&public_id=${file.response.public_id}&fluid=true&controls=true`;
                  editor.commands.setVideo({ src: url });
                  refreshCache();
                  setUploading(false);
                  setIsOpen(false);
                } else {
                  setUploading(false);
                }
              }}
              showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
              className="upload-input"
              multiple={false}
              maxCount={1}
              accept="video/*"
            >
              <Button block icon={<UploadOutlined />} disabled={uploading}>
                <Typography.Text ellipsis style={{ paddingRight: 14 }}>
                  Sélectionner un fichier
                </Typography.Text>
              </Button>
            </Upload>
          </Form.Item>
          <Alert
            type="info"
            showIcon
            message="Video cannot be played - Resource is too large to process synchronously, processing in background"
            description="Si ce message s'affiche cela signifie que la vidéo est en cours de traitement sur le serveur et qu'elle sera bientôt disponible, vous pouvez sauvegarder et quitter le cours le traitement continuera."
          />
        </Space>
      }
    >
      <Tooltip key="cloudinaryVideo" title="Téléverser une vidéo">
        <Button
          icon={<VideoCameraOutlined />}
          disabled={disabled || [null, 'danger'].includes(status)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        />
      </Tooltip>
    </Popover>
  );
};

export default CloudinaryVideoPopover;
