import { Button, Drawer } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { SaveOutlined } from '@ant-design/icons';
import { memo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import Editor from '../../../../ui/Editor';
import './editor.css';

const ModuleEditModal = memo(({ disabled, title, fieldName, onClose, loading, onSave }) => {
  const { control } = useFormContext();

  useHotkeys('ctrl+s', onSave, { enabled: !!fieldName });

  return (
    <Drawer
      title={title}
      height="100vh"
      visible={fieldName}
      placement="top"
      onClose={onClose}
      bodyStyle={{ padding: 0 }}
      extra={[
        <Button type="primary" icon={<SaveOutlined />} onClick={onSave} loading={loading}>
          Sauvegarder
        </Button>,
      ]}
    >
      {fieldName && (
        <Controller
          control={control}
          name={fieldName}
          render={({ field }) => (
            <Editor
              {...field}
              disabled={disabled}
              className="editor-formao-modules"
              borderless
              uploadWorkspace="formao"
              options={{
                save: {
                  callback: onSave,
                },
                heading: {
                  levels: [2, 3, 4],
                  labels: {
                    2: 'Titre principal',
                    3: 'Titre secondaire',
                    4: 'Sous-titre',
                  },
                },
                strike: false,
                underline: false,
                color: false,
                important: {
                  tooltip: 'Bloc à retenir',
                },
                highlight: {
                  multicolor: true,
                  colors: ['#B3C0D8'],
                },
                details: false,
                textAlign: false,
                grid: false,
              }}
            />
          )}
        />
      )}
    </Drawer>
  );
});

ModuleEditModal.displayName = 'ModuleEditModal';
export default ModuleEditModal;
