import { Card, message } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import FullCalendar from '@fullcalendar/react';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import CreateDrawer from './CreateDrawer';
import EditDrawer from './EditDrawer';
import { eventRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import { addDays, isSameDay } from 'date-fns';

const fetcher = (url) => fetch(url).then((res) => res.json());

const formatDates = (data) => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map((item) => {
    const start = new Date(item.start);
    const end = isSameDay(new Date(item.start), new Date(item.end))
      ? new Date(item.end)
      : addDays(new Date(item.end), 1);

    return { ...item, start, end };
  });
};

const EventsList = () => {
  const [createEventDrawerVisible, setCreateEventDrawerVisible] = useState(false);
  const [editEventDrawerVisible, setEditEventDrawerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [lockedDays, setLockedDays] = useState([]);
  const { user } = useAuth();
  const { data: events, mutate } = useSWR(eventRoutes.referent);
  const formattedEvents = useMemo(() => formatDates(events?.data), [events?.data]);
  const { data: holidaysData } = useSWR('https://calendrier.api.gouv.fr/jours-feries/metropole.json', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (typeof holidaysData !== 'undefined') {
      setLockedDays(Object.keys(holidaysData).map((key) => key));
      setHolidays(
        Object.entries(holidaysData).map(([key, value]) => ({
          title: `${value} - Férié`,
          start: key,
          description: 'Jour férié',
          display: 'background',
          groupId: 'holidays',
        })),
      );
    }
  }, [holidaysData]);

  const handleDateSelect = (info) => {
    if (!lockedDays.includes(info.dateStr)) {
      if (checkAuthorization(user, 'formao', 'events', 'create-event')) {
        setSelectedDate(info.date);
        setCreateEventDrawerVisible(true);
      } else {
        message.error("Vous ne disposez pas de l'autorisation requise pour créer un évènement");
      }
    }
  };

  const handleEventSelect = (info) => {
    if (info.event._def.groupId !== 'holidays') {
      if (checkAuthorization(user, 'formao', 'events', 'update-event')) {
        setSelectedEvent(info.event._def.extendedProps._id);
        setEditEventDrawerVisible(true);
      } else {
        message.error("Vous ne disposez pas de l'autorisation requise pour modifier un évènement");
      }
    }
  };

  return (
    <Card>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        locale={frLocale}
        initialView="dayGridMonth"
        // timeZone="Europe/Paris"
        events={[...holidays, ...formattedEvents]}
        displayEventTime={false}
        dateClick={handleDateSelect}
        eventClick={handleEventSelect}
      />
      {checkAuthorization(user, 'formao', 'events', 'create-event') && (
        <CreateDrawer
          visible={createEventDrawerVisible}
          date={selectedDate}
          onClose={() => setCreateEventDrawerVisible(false)}
          onCreate={mutate}
        />
      )}
      {checkAuthorization(user, 'formao', 'events', 'update-event') && (
        <EditDrawer
          visible={editEventDrawerVisible}
          eventId={selectedEvent}
          onClose={() => setEditEventDrawerVisible(false)}
          onFinish={mutate}
        />
      )}
    </Card>
  );
};

export default EventsList;
