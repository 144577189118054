import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// prettier-ignore
import { closestCenter, DndContext, DragOverlay, getFirstCollision, MeasuringStrategy, pointerWithin, rectIntersection, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PointerSensor } from '../../../../lib/dnd-kit-no-drag';
import Cell from './Cell';
import Row from './Row';

const createRows = (array, mode) => {
  let newArray = [...array];
  let hiddenCells = [];

  newArray = newArray
    .map((item, index) => ({ ...item, id: uniqueId(), originIndex: index }))
    .sort((a, b) => a[mode].order - b[mode].order)
    .reduce(
      (prev, curr) => {
        // Si la cellule est cachée, elle n'est pas ajouté dans une rangée
        if (!!curr[mode].hidden) {
          hiddenCells.push(curr);
          return prev;
        }

        // Calcule la taille totale de la dernière ligne
        const lastRowTotal = prev[prev.length - 1].reduce((prev, curr) => prev + curr[mode].size, 0);

        // Si le total de la dernière ligne + de la nouvelle cellule est inférieur ou égal à 24
        if (lastRowTotal + curr[mode].size <= 24) {
          // La cellule est ajouté
          prev[prev.length - 1].push(curr);
        } else {
          // Sinon une nouvelle ligne est créée et la ligne précédente est triée
          prev.push([curr]);
        }

        return prev;
      },
      [[]],
    );

  // Ajoute les cellules cachées à la fin des rangées
  if (hiddenCells.length !== 0) {
    newArray.push(hiddenCells);
  }

  return newArray;
};

const MobileAndTabletGrid = memo(({ mode, onUpdate, useColors }) => {
  const [activeId, setActiveId] = useState(null);
  const [clonedItems, setClonedItems] = useState(null);
  const [isResizing, setIsResizing] = useState(false);
  const { control, watch } = useFormContext();
  const { replace } = useFieldArray({ control: control, name: 'cells' });
  const cells = watch('cells');
  const [rows, setRows] = useState(createRows(cells, mode));
  const containers = useMemo(() => Array.from({ length: rows.length }, (_, i) => `row_${i}`), [rows]);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    const totalItems = rows.flat().length;

    if (cells.length !== totalItems) {
      setRows(createRows(cells, mode));
    }
  }, [cells]);

  useEffect(() => {
    if (activeId === null && isResizing === false) {
      const newColumns = [...cells];
      const updatedRows = [...rows].flat();

      for (let i = 0; i < updatedRows.length; i++) {
        newColumns[updatedRows[i].originIndex][mode] = {
          ...updatedRows[i][mode],
          order: i + 1,
        };
      }

      replace(newColumns);
    }
  }, [activeId, isResizing, rows]);

  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && containers.includes(activeId)) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => containers.includes(container.id)),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections = pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);

      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (containers.includes(overId)) {
          const containerItems = rows[Number(overId.split('row_').pop())];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId && containerItems.findIndex((item) => item.id === container.id) !== -1,
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, rows, containers],
  );

  const findActiveCell = (id) => {
    if (!id) {
      return null;
    }

    const match = rows.flat().find((item) => item.id === id);

    if (!match) {
      return null;
    }

    return match;
  };

  const findContainer = (id) => {
    if (containers.includes(id)) {
      return Number(id.split('row_').pop());
    }

    let container = null;

    for (let i = 0; i < rows.length; i++) {
      const match = rows[i].findIndex((item) => Number(item.id) === Number(id));

      if (match !== -1) {
        container = i;
        break;
      }
    }

    return container;
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [rows]);

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
    setClonedItems(rows);
  };

  const handleDragCancel = () => {
    if (clonedItems) {
      setRows(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  const handleDragEnd = ({ active, over }) => {
    // Change la vue de la prévisualisation
    onUpdate();

    // Récupère le parent de la cellule à déplacer
    const activeContainer = findContainer(active.id);

    // Si aucun parent ne correspond ou qu'aucune cible n'est présente, l'action est annulée
    if (activeContainer === null || over?.id == null) {
      setActiveId(null);
      return;
    }

    // Récupère la cellule à déplacer
    const activeIndex = rows[activeContainer].findIndex((item) => item.id === active.id);

    // Si la cellule est déposé dans une nouvelle ligne
    if (over.id.startsWith('placeholderRow_')) {
      // Récupère la position de la nouvelle ligne
      const placeholderIndex = Number(over.id.split('placeholderRow_').pop());

      // met à jour les lignes
      setRows((rows) => {
        let newRows = [...rows];

        const indexToRemove = rows[activeContainer].findIndex((item) => item.id === active.id);
        const itemToRemove = rows[activeContainer][indexToRemove];

        if (indexToRemove === 0) {
          if (newRows[activeContainer].length > 1) {
            newRows[activeContainer][indexToRemove + 1][mode].size =
              newRows[activeContainer][indexToRemove + 1][mode].size + itemToRemove[mode].size;
          }
        } else {
          newRows[activeContainer][indexToRemove - 1][mode].size =
            newRows[activeContainer][indexToRemove - 1][mode].size + itemToRemove[mode].size;
        }

        // Retire la cellule de son parent
        newRows[activeContainer] = rows[activeContainer].filter((item) => item.id !== active.id);
        // Ajout la cellule dans une nouvelle ligne
        newRows.push([
          {
            ...rows[activeContainer][activeIndex],
            [mode]: { ...rows[activeContainer][activeIndex][mode], size: 24, hidden: false },
          },
        ]);

        if (placeholderIndex !== rows.length) {
          // Déplace la nouvelle ligne à la position initiale
          newRows = arrayMove(newRows, newRows.length - 1, placeholderIndex);
        }

        // Supprime les lignes vides
        newRows = newRows.filter((item) => item.length !== 0);

        return newRows;
      });

      setActiveId(null);
      return;
    }

    // Récupère la ligne dans laquelle la cellule est déplacée
    const overContainer = findContainer(over.id);
    // Récupère la position à laquelle insérer la cellule
    const overIndex = rows[overContainer].findIndex((item) => item.id === over.id);
    // Vérifie si la cible est la rangée des cellules invisibles
    const hiddenRow = rows[overContainer].filter((item) => !!item[mode].hidden).length !== 0;

    // Si la cible est la rangée des cellules invisibles, l'action est annulée
    if (hiddenRow) {
      setActiveId(null);
      return;
    }

    if (activeIndex !== overIndex) {
      // met à jour les lignes
      setRows((rows) => {
        const newRows = [...rows];

        // Déplace la cellule à la position choisie dans la ligne
        newRows[overContainer] = arrayMove(newRows[overContainer], activeIndex, overIndex);

        return newRows;
      });
    }

    setActiveId(null);
  };

  const handleDragOver = ({ active, over }) => {
    // Change la vue de la prévisualisation
    onUpdate();

    const activeContainer = findContainer(active.id);
    const overContainer = findContainer(over?.id);

    // Si aucun parent n'est trouvé ou qu'aucune cible n'est trouvée ou que le parent et la cible sont identiques, l'action est annulée
    if (activeContainer === null || overContainer === null || activeContainer === overContainer) {
      return;
    }

    // Vérifie si la cible est la rangée des cellules invisibles
    const hiddenRow = rows[overContainer].filter((item) => !!item[mode].hidden).length !== 0;

    // Si la cible est la rangée des cellules invisibles, l'action est annulée
    if (hiddenRow) {
      return;
    }

    const activeItems = rows[activeContainer];
    const overItems = rows[overContainer];
    const activeIndex = activeItems.findIndex((item) => item.id === active.id);
    const overIndex = overItems.findIndex((item) => item.id === over.id);

    // Si le total des cellules de la ligne ciblée + la la cellule à déplacer est supérieur à 24, l'action est annulée
    if (overItems.length + 1 > 24) {
      return;
    }

    // Met à jour les lignes
    setRows((rows) => {
      const newRows = [...rows];

      let newIndex;

      if (containers.includes(over.id)) {
        newIndex = overItems.length + 1;
      } else {
        const isBeforeOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.left > over.rect.left + over.rect.width;
        const modifier = isBeforeOverItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      recentlyMovedToNewContainer.current = true;

      const indexToRemove = rows[activeContainer].findIndex((item) => item.id === active.id);
      const itemToRemove = rows[activeContainer][indexToRemove];

      // Ajoute la taille de la cellule à la déplacer à son voisin le plus proche dans le parent d'origine
      if (indexToRemove === 0) {
        if (newRows[activeContainer].length > 1) {
          newRows[activeContainer][indexToRemove + 1][mode].size =
            newRows[activeContainer][indexToRemove + 1][mode].size + itemToRemove[mode].size;
        }
      } else {
        newRows[activeContainer][indexToRemove - 1][mode].size =
          newRows[activeContainer][indexToRemove - 1][mode].size + itemToRemove[mode].size;
      }

      // Retire la cellule du mode caché
      newRows[activeContainer][activeIndex][mode].hidden = false;
      // Retire la cellule de son parent
      newRows[activeContainer] = rows[activeContainer].filter((item) => item.id !== active.id);

      // Ajoute la cellule dans la ligne ciblée
      newRows[overContainer] = [
        ...rows[overContainer].slice(0, newIndex),
        rows[activeContainer][activeIndex],
        ...rows[overContainer].slice(newIndex, rows[overContainer].length),
      ];

      // Si la taille totale des éléments de la ligne ciblée + la taille de la cellule à déplacer est supérieure à 24,
      // la taille des cellules est recalculée pour répartir l'espace de façon égal
      if (newRows[overContainer].reduce((prev, curr) => prev + curr[mode].size, 0) > 24) {
        // Change la taille des cellules à 0 avant de commencer la répartition
        newRows[overContainer] = newRows[overContainer].map((item) => ({
          ...item,
          [mode]: { ...item[mode], size: 0 },
        }));
        // Commence la répartition à la première cellule
        let nextIndex = 0;

        for (let i = 24; i > 0; i--) {
          newRows[overContainer][nextIndex][mode].size += 1;

          // Si la cellule est la dernière, la boucle recommence au début
          if (nextIndex === newRows[overContainer].length - 1) {
            nextIndex = 0;
          } else {
            nextIndex++;
          }
        }
      }

      // Si la parent est vide, il est supprimé
      if (newRows[activeContainer].length === 0) {
        newRows.splice(activeContainer, 1);
      }

      return newRows;
    });
  };

  const handleResize = (updatedRow, rowIndex) => {
    setRows((rows) => {
      const newRows = [...rows];

      newRows[rowIndex] = updatedRow;

      return newRows;
    });
  };

  const handleCellVisibleChange = (cellId, newValue) => {
    // Change la vue de la prévisualisation
    onUpdate();

    // Récupère le parent de la cellule à déplacer
    const container = findContainer(cellId);
    // Récupère la cellule à déplacer
    const cellIndex = rows[container].findIndex((item) => item.id === cellId);

    setRows((rows) => {
      let newRows = [...rows];

      if (!!newValue) {
        if (cellIndex === 0) {
          if (newRows[container].length > 1) {
            newRows[container][cellIndex + 1][mode].size =
              newRows[container][cellIndex + 1][mode].size + newRows[container][cellIndex][mode].size;
          }
        } else {
          newRows[container][cellIndex - 1][mode].size =
            newRows[container][cellIndex - 1][mode].size + newRows[container][cellIndex][mode].size;
        }

        // Recherche si une rangée de cellule cachées existe
        const hiddenRow = newRows[newRows.length - 1].filter((item) => !!item[mode].hidden).length !== 0;

        // Si aucune rangée de cellule cachée n'est trouvée, elle est créée
        if (!hiddenRow) {
          newRows.push([{ ...rows[container][cellIndex], [mode]: { size: 24, hidden: true } }]);
        } else {
          // Ajoute la cellule à cacher à la fin de la rangée des cellules cachées
          newRows[newRows.length - 1].push({ ...rows[container][cellIndex], [mode]: { size: 24, hidden: true } });
          // Change la taille des cellules à 0 avant de commencer la répartition
          newRows[newRows.length - 1] = newRows[newRows.length - 1].map((item) => ({
            ...item,
            [mode]: { ...item[mode], size: 0 },
          }));
          // Commence la répartition à la première cellule
          let nextIndex = 0;

          for (let i = 24; i > 0; i--) {
            newRows[newRows.length - 1][nextIndex][mode].size += 1;

            // Si la cellule est la dernière, la boucle recommence au début
            if (nextIndex === newRows[newRows.length - 1].length - 1) {
              nextIndex = 0;
            } else {
              nextIndex++;
            }
          }
        }

        // Retire la cellule de son parent
        newRows[container] = rows[container].filter((item) => item.id !== cellId);

        if (newRows[container].length === 0) {
          newRows.splice(container, 1);
        }

        return newRows;
      }

      return newRows;
    });
  };

  return (
    <div
      className={classnames('editor-grid-container editor-grid-container-responsive', {
        'editor-grid-container-dragging': isResizing,
      })}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{ droppable: MeasuringStrategy.Always }}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragCancel={handleDragCancel}
      >
        {rows.map((items, index) => {
          if (items.filter((item) => !!item[mode].hidden).length !== 0) {
            return (
              <Fragment key={`${mode}_row_${index}`}>
                <Row {...{ index }} first={index === 0} placeholder />
                <Row
                  {...{ mode, index, items, useColors }}
                  onResize={setIsResizing}
                  onResizeStart={onUpdate}
                  onResizeEnd={(updatedRow) => handleResize(updatedRow, index)}
                  onCellVisibleChange={handleCellVisibleChange}
                />
              </Fragment>
            );
          } else {
            return (
              <Fragment key={`${mode}_row_${index}`}>
                <Row {...{ index }} first={index === 0} placeholder />
                <Row
                  {...{ mode, index, items, useColors }}
                  onResize={setIsResizing}
                  onResizeStart={onUpdate}
                  onResizeEnd={(updatedRow) => handleResize(updatedRow, index)}
                  onCellVisibleChange={handleCellVisibleChange}
                />
                {index === rows.length - 1 && <Row index={index + 1} last placeholder />}
              </Fragment>
            );
          }
        })}
        <DragOverlay>
          {activeId ? <Cell {...{ mode, useColors }} placeholder item={findActiveCell(activeId)} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
});

MobileAndTabletGrid.displayName = 'MobileAndTabletGrid';
export default MobileAndTabletGrid;
