import { Button, Popover, Input, Space, Form, Tooltip } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import { LinkOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getSelectedText } from './helpers';

const Link = ({ editor, disabled, isActive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');
  const selectedText = getSelectedText(editor);

  useEffect(() => {
    if (isOpen) {
      const prevUrl = editor.getAttributes('link').href;

      setUrl(prevUrl || '');
    }
  }, [editor, isOpen]);

  const handleSubmit = useCallback(() => {
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      setIsOpen(false);
      return;
    }

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({
        href: url,
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
      })
      .run();
    setIsOpen(false);
    return;
  }, [editor, url]);

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={setIsOpen}
      trigger="click"
      title="Insérer un lien"
      overlayStyle={{ width: 350 }}
      placement="bottom"
      content={
        <Space direction="vertical">
          <Form.Item extra="Veillez à toujours débuter vos liens par http:// ou https://" style={{ margin: 0 }}>
            <Input placeholder="Entrez votre lien" value={url} onChange={(e) => setUrl(e.target.value)} />
          </Form.Item>
          <Space>
            <Button icon={<CheckOutlined />} type="primary" disabled={disabled} onClick={handleSubmit} />
            <Button icon={<CloseOutlined />} onClick={() => setIsOpen(false)} disabled={disabled} />
          </Space>
        </Space>
      }
    >
      <Tooltip key="link" title="Lien">
        <Button icon={<LinkOutlined />} type={isActive && 'primary'} disabled={disabled || selectedText === ''} />
      </Tooltip>
    </Popover>
  );
};

export default Link;
