import React, { useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Form, Button, Typography, message, Input, Row, Col, Select, Card, Divider } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { statisticRoutes, agencyRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';
import './StatisticsCreate.css';

const StatisticsCreate = () => {
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { handleSubmit, setError, control } = useForm({
    defaultValues: {
      values: [
        { training: 'TEBDP' },
        { training: 'IMEP' },
        { training: 'CDUI' },
        { training: 'DWWM' },
        { training: 'CDA' },
        { training: 'TAI' },
        { training: 'Outils' },
        { training: 'COBIM' },
        { training: 'BIMOD' },
      ],
      learning: [
        { training: 'TEBDP' },
        { training: 'IMEP' },
        { training: 'CDUI' },
        { training: 'DWWM' },
        { training: 'CDA' },
        { training: 'TAI' },
        { training: 'COBIM' },
        { training: 'BIMOD' },
      ],
    },
  });
  const { errors } = useFormState({ control });
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const { post } = useFetch();

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(statisticRoutes.create, JSON.stringify(form));

    if (results.status === 201) {
      history.push('/arinfo/statistiques');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="create-statistics">
      <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Form.Item
          label="Centre"
          required
          validateStatus={errors?.agency?.message && 'error'}
          help={errors?.agency?.message}
        >
          <Controller
            name="agency"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                disabled={!agencies || isProcessing}
                placeholder="Sélectionnez dans la liste"
                allowClear
                options={[{ label: 'Global', value: 'Global' }].concat(
                  agencies?.data.map((agency) => ({
                    label: agency.city,
                    value: agency._id,
                  })) || [],
                )}
              />
            )}
          />
        </Form.Item>
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>N'ajoutez pas le signe des pourcentages (%) sur les taux, il sera automatiquement ajouté</li>
              <li>
                Laisser les champs vides si vous n'avez aucune valeur à rentrer, un slash (/) sera automatiquement
                ajouté en remplacement
              </li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Typography.Title level={5}>
          Indicateurs pour la Formation Professionnelle Continue (OF) et l'Apprentissage (CFA)
        </Typography.Title>
        <Form.Item>
          <Row className="bordered" wrap={false}>
            <Col className="header header-main">OF + CFA</Col>
            <Col className="header">Taux de satisfaction</Col>
            <Col className="header">Effectif formé</Col>
            <Col className="header">Taux de présentation</Col>
            <Col className="header">Taux de réussite</Col>
            <Col className="header">Taux de rupture</Col>
            <Col className="header">Insertion pro. à 6 mois</Col>
            <Col className="header">Poursuite d'études</Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">TEBDP</Col>
            <Col>
              <Controller
                control={control}
                name="values.0.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.0.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.0.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.0.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.0.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.0.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.0.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">IMEP</Col>
            <Col>
              <Controller
                control={control}
                name="values.1.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.1.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.1.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.1.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.1.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.1.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.1.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">CDUI</Col>
            <Col>
              <Controller
                control={control}
                name="values.2.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.2.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.2.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.2.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.2.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.2.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.2.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">DWWM</Col>
            <Col>
              <Controller
                control={control}
                name="values.3.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.3.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.3.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.3.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.3.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.3.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.3.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">CDA</Col>
            <Col>
              <Controller
                control={control}
                name="values.4.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.4.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.4.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.4.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.4.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.4.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.4.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">TAI</Col>
            <Col>
              <Controller
                control={control}
                name="values.5.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.5.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.5.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.5.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.5.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.5.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.5.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">COBIM</Col>
            <Col>
              <Controller
                control={control}
                name="values.7.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.7.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.7.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.7.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.7.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.7.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.7.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">BIMOD</Col>
            <Col>
              <Controller
                control={control}
                name="values.8.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.8.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.8.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.8.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.8.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.8.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.8.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">Outils</Col>
            <Col>
              <Controller
                control={control}
                name="values.6.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.6.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.6.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.6.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.6.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.6.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="values.6.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
        </Form.Item>
        <Typography.Title level={5}>Indicateurs pour l'Apprentissage (CFA) uniquement</Typography.Title>
        <Form.Item>
          <Row className="bordered" wrap={false}>
            <Col className="header header-main">CFA</Col>
            <Col className="header">Taux de satisfaction</Col>
            <Col className="header">Effectif formé</Col>
            <Col className="header">Taux de présentation</Col>
            <Col className="header">Taux de réussite</Col>
            <Col className="header">Taux de rupture</Col>
            <Col className="header">Insertion pro. à 6 mois</Col>
            <Col className="header">Poursuite d'études</Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">TEBDP</Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.0.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">IMEP</Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.1.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">CDUI</Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.2.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">DWWM</Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.3.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">CDA</Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.4.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">TAI</Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.5.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">COBIM</Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.6.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
          <Row className="bordered" wrap={false}>
            <Col className="header">BIMOD</Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.satisfaction_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.real"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.presentation"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.success_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.break_rate"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.insertion"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="learning.7.after_training"
                render={({ field }) => <Input {...field} disabled={isProcessing} suffix="%" />}
              />
            </Col>
          </Row>
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={isProcessing}>
            <Link to="/arinfo/statistiques">Annuler</Link>
          </Button>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default StatisticsCreate;
