import { memo, useCallback, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';

const ResizeHandle = memo(({ onResize, onResizeStart, onDrag }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [id] = useState(uniqueId('desktop_resize_handle_'));

  useEffect(() => onDrag(isDragging), [isDragging]);

  // Active le redimensionnement
  const startDrag = useCallback((event) => {
    // Si l'élément cliqué est la cellule ou son contenu, le redimensionnement est activé
    if (event.target.id === id || event.target.closest(`#${id}`) !== null) {
      onResizeStart();
      return setIsDragging(true);
    }
  }, []);

  // Désactive le redimensionnement
  const endDrag = useCallback((event) => {
    return setIsDragging(false);
  }, []);

  // Redimensionne les cellules
  const move = useCallback(
    (event) => {
      if (isDragging) {
        return onResize(event);
      }
    },
    [isDragging],
  );

  useEffect(() => {
    document.addEventListener('mousedown', startDrag);
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('mousemove', move);

    return () => {
      document.removeEventListener('mousedown', startDrag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('mousemove', move);
    };
  }, [startDrag, endDrag, move]);

  return <div id={id} className="editor-grid-resize-handle" />;
});

ResizeHandle.displayName = 'ResizeHandle';
export default ResizeHandle;
