import { Form, Input, Row, Col, Space, Tabs, Typography, Alert, Badge, Radio, Collapse, Select } from 'antd';
import { memo, useMemo } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import useSWR from 'swr';
import { sort } from '../../../../shared/utils';
import { agencyRoutes, trainingRoutes } from '../../../../lib/routes';

const { Item } = Form;
const { TabPane } = Tabs;
const { Text } = Typography;
const { Group } = Radio;

const TabPaneTitle = memo(({ children, index, errors }) => {
  const costPerDayTotal = Object.keys(errors[index]?.costPerDay || {}).length;
  const costPerHourTotal = Object.keys(errors[index]?.costPerHour || {}).length;
  const daysTotal = Object.keys(errors[index]?.days || {}).length;
  const hoursTotal = Object.keys(errors[index]?.hours || {}).length;
  const pricesWithoutTaxesTotal = Object.keys(errors[index]?.pricesWithoutTaxes || {}).length;
  const pricesWithTaxesTotal = Object.keys(errors[index]?.pricesWithTaxes || {}).length;
  const title = errors[index]?.title ? 1 : 0;
  const description = errors[index]?.description ? 1 : 0;
  const total =
    costPerDayTotal +
    costPerHourTotal +
    daysTotal +
    hoursTotal +
    pricesWithTaxesTotal +
    pricesWithoutTaxesTotal +
    title +
    description;

  return (
    <Badge count={total} status="error" offset={[0, -10]}>
      {children}
    </Badge>
  );
});

const getStatus = (errors, fields) => {
  let status = '';

  if (errors) {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i]]) {
        status = 'error';
      }
    }
  }

  return status;
};

const CPFImportPrices = ({ disabled, errors, onDelete }) => {
  const { control } = useFormContext();
  const { fields: grids, remove, append } = useFieldArray({ control, name: 'grids' });
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: trainings } = useSWR(trainingRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const trainingsGroups = useMemo(
    () => [
      {
        label: 'Formations outils',
        options:
          trainings?.data
            .sort((a, b) => sort(a, b, 'title'))
            .filter((item) => item.type === 'outil' && !!item.cpf_url)
            .map((training) => ({
              label: training.title,
              value: training._id,
            })) || [],
      },
      {
        label: 'Formations métiers',
        options:
          trainings?.data
            .sort((a, b) => sort(a, b, 'title'))
            .filter((item) => item.type === 'metier' && !!item.cpf_url)
            .map((training) => ({
              label: training.title,
              value: training._id,
            })) || [],
      },
    ],
    [trainings],
  );

  const handleEdit = (key, action) => {
    if (action === 'add') {
      append({ title: 'Nouvelle grille' });
    } else {
      const index = grids.findIndex((item) => item.id === key);

      onDelete(index);
      remove(index);
    }
  };

  return (
    <Tabs className="cpf-tabs" type="editable-card" onEdit={handleEdit} hideAdd={disabled}>
      {grids.map((item, index) => (
        <TabPane
          key={item.id}
          disabled={disabled}
          closable={grids.length > 1}
          tab={
            <Controller
              name={`grids.${index}.title`}
              control={control}
              render={({ field }) => (
                <TabPaneTitle index={index} errors={errors}>
                  <Text editable={!disabled && { onChange: field.onChange, tooltip: 'Renommer' }}>{field.value}</Text>
                </TabPaneTitle>
              )}
            />
          }
        >
          <Item
            label="Description"
            required
            validateStatus={errors[index]?.description && 'error'}
            help={errors[index]?.description}
          >
            <Controller
              name={`grids.${index}.description`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Exemple : Prestation (logiciel + lieu) : classique"
                  disabled={disabled}
                />
              )}
            />
          </Item>
          <Item label="Format" required validateStatus={errors[index]?.format && 'error'} help={errors[index]?.format}>
            <Controller
              name={`grids.${index}.format`}
              control={control}
              render={({ field }) => (
                <Group {...field} disabled={disabled}>
                  <Space direction="vertical">
                    <Radio value="shared">Formateur partagé (En groupe)</Radio>
                    <Radio value="dedicated">Formateur dédié (100% sur mesure)</Radio>
                  </Space>
                </Group>
              )}
            />
          </Item>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Item label="Coût/horaire" validateStatus={getStatus(errors[index]?.costPerHour, ['column', 'number'])}>
                <Space direction="vertical">
                  <Controller
                    name={`grids.${index}.costPerHour.startCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.costPerHour, [
                          'startMissing',
                          'startColumnMissing',
                          'startNumberMissing',
                        ])}
                        placeholder="Cellule de départ"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`grids.${index}.costPerHour.endCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.costPerHour, [
                          'endMissing',
                          'endColumnMissing',
                          'endNumberMissing',
                        ])}
                        placeholder="Cellule de fin"
                        disabled={disabled}
                      />
                    )}
                  />
                </Space>
              </Item>
            </Col>
            <Col span={4}>
              <Item label="Heures" validateStatus={getStatus(errors[index]?.hours, ['column', 'number'])}>
                <Space direction="vertical">
                  <Controller
                    name={`grids.${index}.hours.startCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.hours, [
                          'startMissing',
                          'startColumnMissing',
                          'startNumberMissing',
                        ])}
                        placeholder="Cellule de départ"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`grids.${index}.hours.endCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.hours, ['endMissing', 'endColumnMissing', 'endNumberMissing'])}
                        placeholder="Cellule de fin"
                        disabled={disabled}
                      />
                    )}
                  />
                </Space>
              </Item>
            </Col>
            <Col span={4}>
              <Item label="Coût/jour" validateStatus={getStatus(errors[index]?.costPerDay, ['column', 'number'])}>
                <Space direction="vertical">
                  <Controller
                    name={`grids.${index}.costPerDay.startCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.costPerDay, [
                          'startMissing',
                          'startColumnMissing',
                          'startNumberMissing',
                        ])}
                        placeholder="Cellule de départ"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`grids.${index}.costPerDay.endCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.costPerDay, [
                          'endMissing',
                          'endColumnMissing',
                          'endNumberMissing',
                        ])}
                        placeholder="Cellule de fin"
                        disabled={disabled}
                      />
                    )}
                  />
                </Space>
              </Item>
            </Col>
            <Col span={4}>
              <Item label="Jours" required validateStatus={getStatus(errors[index]?.days, ['column', 'number'])}>
                <Space direction="vertical">
                  <Controller
                    name={`grids.${index}.days.startCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.days, [
                          'startMissing',
                          'startColumnMissing',
                          'startNumberMissing',
                        ])}
                        placeholder="Cellule de départ"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`grids.${index}.days.endCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.days, ['endMissing', 'endColumnMissing', 'endNumberMissing'])}
                        placeholder="Cellule de fin"
                        disabled={disabled}
                      />
                    )}
                  />
                </Space>
              </Item>
            </Col>
            <Col span={4}>
              <Item
                label="Tarif HT"
                validateStatus={getStatus(errors[index]?.pricesWithoutTaxes, ['column', 'number'])}
              >
                <Space direction="vertical">
                  <Controller
                    name={`grids.${index}.pricesWithoutTaxes.startCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.pricesWithoutTaxes, [
                          'startMissing',
                          'startColumnMissing',
                          'startNumberMissing',
                        ])}
                        placeholder="Cellule de départ"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`grids.${index}.pricesWithoutTaxes.endCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.pricesWithoutTaxes, [
                          'endMissing',
                          'endColumnMissing',
                          'endNumberMissing',
                        ])}
                        placeholder="Cellule de fin"
                        disabled={disabled}
                      />
                    )}
                  />
                </Space>
              </Item>
            </Col>
            <Col span={4}>
              <Item
                label="Tarif TTC"
                required
                validateStatus={getStatus(errors[index]?.pricesWithTaxes, ['column', 'number'])}
              >
                <Space direction="vertical">
                  <Controller
                    name={`grids.${index}.pricesWithTaxes.startCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.pricesWithTaxes, [
                          'startMissing',
                          'startColumnMissing',
                          'startNumberMissing',
                        ])}
                        placeholder="Cellule de départ"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`grids.${index}.pricesWithTaxes.endCell`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={getStatus(errors[index]?.pricesWithTaxes, [
                          'endMissing',
                          'endColumnMissing',
                          'endNumberMissing',
                        ])}
                        placeholder="Cellule de fin"
                        disabled={disabled}
                      />
                    )}
                  />
                </Space>
              </Item>
            </Col>
          </Row>
          {Object.keys(errors[index] || {}).filter((error) => !['title', 'description'].includes(error)).length !==
            0 && (
            <Alert
              type="error"
              message={
                <>
                  {errors[index]?.costPerHour && (
                    <>
                      <p>Coût/horaire</p>
                      <ul>
                        {Object.entries(errors[index].costPerHour).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {errors[index]?.hours && (
                    <>
                      <p>Heures</p>
                      <ul>
                        {Object.entries(errors[index].hours).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {errors[index]?.costPerDay && (
                    <>
                      <p>Coût/jour</p>
                      <ul>
                        {Object.entries(errors[index].costPerDay).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {errors[index]?.days && (
                    <>
                      <p>Jours</p>
                      <ul>
                        {Object.entries(errors[index].days).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {errors[index]?.pricesWithoutTaxes && (
                    <>
                      <p>Tarif HT</p>
                      <ul>
                        {Object.entries(errors[index].pricesWithoutTaxes).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {errors[index]?.pricesWithTaxes && (
                    <>
                      <p>Tarif TTC</p>
                      <ul>
                        {Object.entries(errors[index].pricesWithTaxes).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              }
              style={{ marginBottom: 24 }}
            />
          )}
          <Item label="Centres et formations">
            <Collapse style={{ marginBottom: 20 }}>
              <Collapse.Panel header="Distanciel" key="remote" disabled={disabled}>
                <Item
                  style={{ margin: 0 }}
                  label="Formations disponibles"
                  extra="Seulement les formations éligibles au CPF sont affichées, si une formation eligible ne s'affiche pas vérifiez qu'elle possède un lien vers la page Mon Compte Formation. Laissez le champ vide si le centre ne correspond pas à la grille des tarifs."
                >
                  <Controller
                    name={`grids.${index}.agencies.remote`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Sélectionnez dans la liste"
                        disabled={disabled}
                        mode="multiple"
                        options={trainingsGroups}
                      />
                    )}
                  />
                </Item>
              </Collapse.Panel>
              {agencies?.data
                .sort((a, b) => sort(a, b, 'city'))
                .map((agency) => (
                  <Collapse.Panel header={agency.city} key={agency._id} disabled={disabled}>
                    <Item
                      style={{ margin: 0 }}
                      label="Formations disponibles"
                      extra="Seulement les formations éligibles au CPF sont affichées, si une formation eligible ne s'affiche pas vérifiez qu'elle possède un lien vers la page Mon Compte Formation. Laissez le champ vide si le centre ne correspond pas à la grille des tarifs."
                    >
                      <Controller
                        name={`grids.${index}.agencies.${agency._id}`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Sélectionnez dans la liste"
                            disabled={disabled}
                            mode="multiple"
                            options={trainingsGroups}
                          />
                        )}
                      />
                    </Item>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </Item>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default CPFImportPrices;
