import React, { useState } from 'react';
import { Table, Button, Space, Tooltip, Tag, message, Popconfirm, Card } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import RoleModal from './components/RoleModal';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { sort, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import tablePagination from '../../../lib/tablePagination';
import { roleRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const RolesList = () => {
  const { data: roles, isValidating, mutate } = useSWR(roleRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const { post } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await post(roleRoutes.delete, JSON.stringify({ ids: [id] }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Rôle',
      key: 'name',
      sorter: (a, b) => sort(a, b, 'name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('name'),
      render: (value) => <Tag color={value.color}>{value.name}</Tag>,
    },
    {
      title: '',
      key: 'actions',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'global', 'roles', 'search-role') && <RoleModal role={record} />}
          {checkAuthorization(user, 'global', 'roles', 'update-role') && (
            <Tooltip title="Modifier" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/roles/modifier-un-role/${record._id}`}>
                <Button className="gray-text" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          )}
          {checkAuthorization(user, 'global', 'roles', 'delete-role') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'global', 'roles', 'create-role') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/roles/nouveau-role">Nouveau rôle</Link>
          </Button>
        </div>
      )}
      <Table
        loading={!roles || isValidating || isProcessing}
        dataSource={roles?.data || []}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 320 }}
        rowKey={(row) => row._id}
        pagination={tablePagination(roles?.data)}
      />
    </Card>
  );
};

export default RolesList;
