import { Card, Form, Button, Upload, Checkbox, Space, Typography, Result, Tooltip, message, Progress } from 'antd';
import { Link, Prompt, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import qs from 'qs';
import useSWR from 'swr';
import chunkUpload from '../../../../lib/chunk-upload';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

const { Item } = Form;
const { Dragger } = Upload;

const SWROptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  refreshInterval: 5000,
};

const ModuleImportPackages = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [packageId, setPackageId] = useState(null);
  const { packageType } = useParams();
  const { handleSubmit, control, setError, formState, reset } = useForm({ file: null, options: [], debugOptions: [] });
  const { errors } = useFormState({ control });
  const { token, user } = useAuth();

  const { data: packageData, mutate } = useSWR(
    packageId !== null ? `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/formao/packages/${packageId}` : null,
    SWROptions,
  );

  useEffect(() => {
    if (formState.isDirty && packageId === null) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [formState.isDirty, packageId]);

  const onSubmit = async (form) => {
    setIsLoading(true);

    const params = qs.stringify(
      {
        upload: !form.debugOptions?.includes('disableFileUpload'),
        import: !form.debugOptions?.includes('disableCourseImport'),
      },
      { addQueryPrefix: true },
    );

    chunkUpload({
      action: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/import/${packageType}${params}`,
      data: { options: form?.options },
      file: form?.file,
      headers: { Authorization: `Bearer ${token}` },
      onProgress: ({ percent }) => setUploadProgress(percent),
      onError: (errors) => {
        setIsLoading(false);

        if (typeof errors !== 'string' && Object.keys(errors).length) {
          Object.entries(errors).forEach(([key, value]) => {
            setError(key, { message: value, type: 'manual' });
          });
        } else {
          message.error({ content: errors });
        }
      },
      onChunkUpload: ({ data }) => {
        setPackageId(data._id);
        mutate({ status: 200, message: '', data }, { revalidate: false });
      },
      onSuccess: ({ data }) => {
        setPackageId(data._id);
        mutate({ status: 200, message: '', data }, { revalidate: false });
        setIsLoading(false);
        setIsUploaded(true);
      },
    });
  };

  const onRestart = () => {
    reset();
    setIsLoading(false);
    setIsBlocking(false);
    setIsUploaded(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      {!isUploaded ? (
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Item
            required
            label="Package"
            extra={
              packageType === 'moodle'
                ? "L'outil accepte les fichiers au format .mbz et tar.gz"
                : "L'outil accepte les fichiers au format .zip"
            }
            validateStatus={errors?.file?.message && 'error'}
            help={errors?.file?.message}
          >
            <Controller
              control={control}
              name="file"
              render={({ field }) => (
                <Dragger
                  name={field.name}
                  maxCount={1}
                  showUploadList={false}
                  height={150}
                  style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                  onChange={({ file }) => field.onChange(file.originFileObj)}
                  disabled={isLoading}
                >
                  {field?.value?.name ? (
                    <p className="ant-upload-hint">{field.value.name}</p>
                  ) : (
                    <p className="ant-upload-hint">Sélectionnez ou déposez un package</p>
                  )}
                </Dragger>
              )}
            />
          </Item>
          <Space align="start" size="large">
            {packageType === 'moodle' ? (
              <Controller
                name="options"
                control={control}
                render={({ field }) => (
                  <Checkbox.Group {...field} disabled={isLoading}>
                    <Space direction="vertical" size="large">
                      <Item
                        label="Options générales d'importation"
                        validateStatus={errors?.options?.message && 'error'}
                        help={errors?.options?.message}
                      >
                        <Space direction="vertical">
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, les résumés et introductions normalement cachées sur Moodle ne seront pas importées."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="keepIntros" disabled={isLoading}>
                              Récupérer toutes les introductions.
                            </Checkbox>
                          </Tooltip>
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, les modules et chapitres cachés sur Moodle ne seront pas importés."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="hiddenPages" disabled={isLoading}>
                              Afficher les pages cachées.
                            </Checkbox>
                          </Tooltip>
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, les modules non-compatibles seront entièrement retirés du cours."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="compatibilityMode" disabled={isLoading}>
                              Garder le texte des modules non compatibles.
                            </Checkbox>
                          </Tooltip>
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, les modules et chapitres vides seront retirés du cours."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="keepEmpty" disabled={isLoading}>
                              Conserver les modules et chapitres vides.
                            </Checkbox>
                          </Tooltip>
                        </Space>
                      </Item>
                      <Item
                        label="Options d'importation des modules"
                        validateStatus={errors?.options?.message && 'error'}
                        help={errors?.options?.message}
                      >
                        <Space direction="vertical">
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, tous les sous-chapitres du Livre seront réunis sur la même page."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="splitBookPages" disabled={isLoading}>
                              Créer une page par sous-chapitre dans les Modules "Livres".
                            </Checkbox>
                          </Tooltip>
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, les fichiers attachés seront mis en ligne dans l'espace Ressources du cours. Cette option ne s'applique que pour les modules Fichiers en mode Téléchargement, Fenêtre et Ouvrir."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="foldersAsPages" disabled={isLoading}>
                              Transformer les Modules "Dossiers" et "Fichiers" en pages de liens de téléchargement.
                            </Checkbox>
                          </Tooltip>
                        </Space>
                      </Item>
                    </Space>
                  </Checkbox.Group>
                )}
              />
            ) : (
              <Controller
                name="options"
                control={control}
                render={({ field }) => (
                  <Checkbox.Group {...field} disabled={isLoading}>
                    <Space direction="vertical" size="large">
                      <Item
                        label="Options générales d'importation"
                        validateStatus={errors?.options?.message && 'error'}
                        help={errors?.options?.message}
                      >
                        <Space direction="vertical">
                          <Tooltip
                            title="Si l'option n'est pas sélectionnée, les modules et chapitres vides seront retirés du cours."
                            destroyTooltipOnHide={{ keepParent: false }}
                          >
                            <Checkbox value="keepEmpty" disabled={isLoading}>
                              Conserver les modules et chapitres vides.
                            </Checkbox>
                          </Tooltip>
                        </Space>
                      </Item>
                    </Space>
                  </Checkbox.Group>
                )}
              />
            )}
            {checkAuthorization(user, 'formao', 'packages', 'debug') && (
              <Item
                label="Options de débogage"
                validateStatus={errors?.debugOptions?.message && 'error'}
                help={errors?.debugOptions?.message}
              >
                <Controller
                  name="debugOptions"
                  control={control}
                  render={({ field }) => (
                    <Checkbox.Group {...field} disabled={isLoading}>
                      <Space direction="vertical">
                        <Tooltip
                          title="Ne téléverse pas les fichiers avec le cours."
                          destroyTooltipOnHide={{ keepParent: false }}
                        >
                          <Checkbox value="disableFileUpload" disabled={isLoading}>
                            Désactiver le téléversement des fichiers.
                          </Checkbox>
                        </Tooltip>
                        <Tooltip
                          title="N'ajoute pas le cours sur Formao à la fin du téléversement."
                          destroyTooltipOnHide={{ keepParent: false }}
                        >
                          <Checkbox value="disableCourseImport" disabled={isLoading}>
                            Désactiver l'ajout du cours dans Formao.
                          </Checkbox>
                        </Tooltip>
                      </Space>
                    </Checkbox.Group>
                  )}
                />
              </Item>
            )}
          </Space>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isLoading}>
              <Link to="/formao/cours">Revenir à la liste des cours</Link>
            </Button>
            <Space direction="vertical">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Démarrer l'importation
              </Button>
              {isLoading && <Progress percent={uploadProgress} showInfo={false} />}
            </Space>
          </div>
        </Form>
      ) : (
        <>
          <Result
            status="success"
            title="L'archive a été téléversée"
            subTitle={
              <>
                L'importation sera traitée, vous pouvez suivre l'avancement dans la page Cours.
                <br />
                Vous recevrez une notification par email à la fin du traitement
              </>
            }
            extra={[
              <Button>
                <Link to="/formao/cours">Revenir à la liste des cours</Link>
              </Button>,
              <Button type="primary" onClick={onRestart}>
                Importer un autre package
              </Button>,
            ]}
          />
        </>
      )}
    </Card>
  );
};

export default ModuleImportPackages;
