import { Button, Card, Popconfirm, Space, Table, Tooltip, message } from 'antd';
import { DeleteOutlined, EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { keysRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const KeysList = () => {
  const { data: keys, isValidating, mutate } = useSWR(keysRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { remove } = useFetch();
  const { user } = useAuth();

  const onDelete = async (id) => {
    const results = await remove(keysRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const columns = [
    {
      title: 'Intitulé',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => sort(a, b, 'name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Dernière utilisation',
      key: 'lastUse',
      width: 150,
    },
    {
      title: '',
      key: 'actions',
      width: 130,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'global', 'keys', 'logs') && (
            <Tooltip title="Consulter les logs" destroyTooltipOnHide={{ keepParent: false }}>
              <Button className="gray-text" icon={<FileSearchOutlined />} disabled />
            </Tooltip>
          )}
          {checkAuthorization(user, 'global', 'keys', 'update') && (
            <Tooltip title="Modifier" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/cles/modifier-une-cle/${record._id}`}>
                <Button className="gray-text" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          )}
          {checkAuthorization(user, 'global', 'keys', 'delete') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'global', 'keys', 'create') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/cles/nouvelle-cle">Nouvelle clé d'accès</Link>
          </Button>
        </div>
      )}
      <Table
        loading={!keys || isValidating}
        dataSource={keys?.data || []}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 320 }}
        rowKey={(row) => row._id}
        pagination={tablePagination(keys?.data)}
      />
    </Card>
  );
};

export default KeysList;
