import { memo, useMemo } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Space, Typography } from 'antd';
import classNames from 'classnames';
import { colors, getSizeFromTotal } from '../helpers';
import { EyeOutlined } from '@ant-design/icons';

const DraggableCell = ({ id, size, cellId, index, useColors, onVisibleChange, visible }) => {
  const width = useMemo(() => getSizeFromTotal(size).percentage, [size]);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    flex: `${width} 1 0px`,
    flexDirection: 'column',
    zIndex: isDragging ? 200 : 'auto',
    overflow: 'hidden',
    backgroundColor: useColors ? colors[index] : '#ffffff',
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={classNames('editor-grid-cell', { 'editor-grid-cell-hidden': !visible })}
      style={style}
    >
      <Typography.Text strong>{cellId}</Typography.Text>
      <Typography.Text type="secondary" strong>
        ({size})
      </Typography.Text>
      <Space data-no-dnd="true">
        {visible && (
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              onVisibleChange(id, visible);
            }}
            style={{ marginTop: 6 }}
          />
        )}
      </Space>
    </div>
  );
};

const PlaceholderCell = ({ item, mode, useColors }) => {
  const width = useMemo(() => getSizeFromTotal(item[mode].size).percentage, [item[mode].size]);

  const style = {
    display: 'flex',
    flex: `${width} 1 0px`,
    flexDirection: 'column',
    opacity: 0.7,
    backgroundColor: useColors ? colors[item.originIndex] : '#ffffff',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    borderColor: '#69b1ff',
  };

  return (
    <div className="editor-grid-cell" style={style}>
      <Typography.Text strong>{item.cellId}</Typography.Text>
      <Typography.Text type="secondary" strong>
        ({item[mode].size})
      </Typography.Text>
    </div>
  );
};

const Cell = memo(({ placeholder, ...props }) => {
  if (placeholder) {
    return <PlaceholderCell {...props} />;
  }

  return <DraggableCell {...props} />;
});

Cell.displayName = 'Cell';
export default Cell;
