import { Button, Modal, Tabs, Table, Collapse, Row, Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { memo, useState } from 'react';
import { sort } from '../../../shared/utils';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const CPFViewer = memo(({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      title: 'Coût/horaire',
      dataIndex: 'costPerHour',
      key: 'costPerHour',
      align: 'right',
      render: (value) => (value ? `${value.toFixed(2)} €` : '-'),
    },
    {
      title: 'Heures',
      dataIndex: 'hours',
      key: 'hours',
      align: 'right',
      render: (value) => value ?? '-',
    },
    {
      title: 'Coût/jour',
      dataIndex: 'costPerDay',
      key: 'costPerDay',
      align: 'right',
      render: (value) => (value ? `${value.toFixed(2)} €` : '-'),
    },
    {
      title: 'Jours',
      dataIndex: 'days',
      key: 'days',
      align: 'right',
      render: (value) => value ?? '-',
    },
    {
      title: 'Tarif HT',
      dataIndex: 'priceWithoutTaxes',
      key: 'priceWithoutTaxes',
      align: 'right',
      render: (value) => (value ? `${value} €` : '-'),
    },
    {
      title: 'Tarif TTC',
      dataIndex: 'priceWithTaxes',
      key: 'priceWithTaxes',
      align: 'right',
      render: (value) => (value ? `${value} €` : '-'),
    },
  ];

  return (
    <>
      <Button className="gray-text" icon={<EyeOutlined />} onClick={() => setIsOpen(true)} />
      <Modal
        title={data.title}
        visible={isOpen}
        footer={false}
        onCancel={() => setIsOpen(false)}
        bodyStyle={{ padding: 0 }}
        width={800}
        className="cpf-modal-viewer"
        destroyOnClose
      >
        <Tabs defaultActiveKey="grid">
          <TabPane key="grid" tab="Grille tarifaire">
            <Table
              columns={columns}
              dataSource={data.prices}
              rowKey={(row) => row._id}
              size="small"
              pagination={false}
              tableLayout="fixed"
            />
          </TabPane>
          <TabPane key="agencies" tab="Centres et formations">
            <Collapse style={{ marginBottom: 20 }}>
              {Object.entries(data.agencies).map(([key, { agency, trainings }]) => (
                <Panel header={agency} key={key}>
                  <Row>
                    <Col span={12}>
                      <p>Formations outils</p>
                      <ul>
                        {trainings
                          .filter((item) => item.type === 'outil')
                          .sort((a, b) => sort(a, b, 'title'))
                          .map(({ _id, title }) => (
                            <li key={_id}>{title}</li>
                          ))}
                      </ul>
                    </Col>
                    <Col span={12}>
                      <p>Formations métiers</p>
                      <ul>
                        {trainings
                          .filter((item) => item.type === 'metier')
                          .sort((a, b) => sort(a, b, 'title'))
                          .map(({ _id, title }) => (
                            <li key={_id}>{title}</li>
                          ))}
                      </ul>
                    </Col>
                  </Row>
                </Panel>
              ))}
            </Collapse>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
});

CPFViewer.displayName = 'CPFViewer';
export default CPFViewer;
