import { useFormContext, useFormState, useFieldArray, Controller } from 'react-hook-form';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Checkbox, Button, message, Row, Col } from 'antd';

const { Item } = Form;

const CheckboxList = ({ loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: checkbox, remove, append } = useFieldArray({ name: 'checkbox', control });

  const appendItem = () => {
    if (checkbox.length === 6) message.error('Vous ne pouvez pas avoir plus de 6 choix possibles');
    else append({ label: 'Nouvelle réponse', valid: false });
  };

  const removeItem = (index) => {
    if (checkbox.length <= 3) message.error('Vous ne pouvez pas avoir moins de 3 choix possibles');
    else remove(index);
  };

  return (
    <>
      {checkbox.map((checkbox, index) => (
        <Row key={checkbox.id}>
          <Col span={12}>
            <Item
              label={`Réponse n°${index + 1}`}
              validateStatus={errors?.checkbox?.[index]?.label?.message && 'error'}
              help={errors?.checkbox?.[index]?.label?.message}
              required={index <= 2}
            >
              <Controller
                name={`checkbox.[${index}].label`}
                control={control}
                render={({ field }) => (
                  <Input.Group compact>
                    <Input {...field} disabled={loading} style={{ width: 'calc(100% - 32px)' }} />
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={loading || checkbox.length === 3}
                      onClick={() => removeItem(index)}
                    />
                  </Input.Group>
                )}
              />
              <Controller
                name={`checkbox.[${index}].valid`}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    style={{ marginTop: 5 }}
                    disabled={loading}
                  >
                    Bonne réponse
                  </Checkbox>
                )}
              />
            </Item>
          </Col>
        </Row>
      ))}
      {errors?.checkboxes && <p style={{ color: '#ff4d4f' }}>{errors?.checkboxes?.message}</p>}
      <Button icon={<PlusOutlined />} disabled={loading || checkbox.length === 6} onClick={appendItem}>
        Ajouter une réponse
      </Button>
    </>
  );
};

export default CheckboxList;
