import { Form, Select, Collapse } from 'antd';
import useSWR from 'swr';
import { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { agencyRoutes, trainingRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const { Item } = Form;

const CPFUpdateAgencies = ({ disabled }) => {
  const { control } = useFormContext();
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: trainings } = useSWR(trainingRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const trainingsGroups = useMemo(
    () => [
      {
        label: 'Formations outils',
        options:
          trainings?.data
            .sort((a, b) => sort(a, b, 'title'))
            .filter((item) => item.type === 'outil' && !!item.cpf_url)
            .map((training) => ({
              label: training.title,
              value: training._id,
            })) || [],
      },
      {
        label: 'Formations métiers',
        options:
          trainings?.data
            .sort((a, b) => sort(a, b, 'title'))
            .filter((item) => item.type === 'metier' && !!item.cpf_url)
            .map((training) => ({
              label: training.title,
              value: training._id,
            })) || [],
      },
    ],
    [trainings],
  );

  return (
    <Collapse style={{ marginBottom: 20 }}>
      <Collapse.Panel header="Distanciel" key="remote" disabled={disabled}>
        <Item
          style={{ margin: 0 }}
          label="Formations disponibles"
          extra="Seulement les formations éligibles au CPF sont affichées, si une formation eligible ne s'affiche pas vérifiez qu'elle possède un lien vers la page Mon Compte Formation. Laissez le champ vide si le centre ne correspond pas à la grille des tarifs."
        >
          <Controller
            name="agencies.remote"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Sélectionnez dans la liste"
                disabled={disabled}
                mode="multiple"
                options={trainingsGroups}
              />
            )}
          />
        </Item>
      </Collapse.Panel>
      {agencies?.data
        .sort((a, b) => sort(a, b, 'city'))
        .map((agency) => (
          <Collapse.Panel header={agency.city} key={agency._id} disabled={disabled}>
            <Item
              style={{ margin: 0 }}
              label="Formations disponibles"
              extra="Seulement les formations éligibles au CPF sont affichées, si une formation eligible ne s'affiche pas vérifiez qu'elle possède un lien vers la page Mon Compte Formation. Laissez le champ vide si le centre ne correspond pas à la grille des tarifs."
            >
              <Controller
                name={`agencies.${agency._id}`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Sélectionnez dans la liste"
                    disabled={disabled}
                    mode="multiple"
                    options={trainingsGroups}
                  />
                )}
              />
            </Item>
          </Collapse.Panel>
        ))}
    </Collapse>
  );
};

export default CPFUpdateAgencies;
