import { Button, Form, Modal, Checkbox, Typography, Space } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';

const ModuleExportModal = ({ onConfirm, isOpen, onClose, course }) => {
  const { handleSubmit, control, reset } = useForm({ defaultValues: { options: [] } });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        title={`Exportation de ${course.title}`}
        visible={isOpen}
        onCancel={onClose}
        onOk={handleSubmit(onConfirm)}
        width={750}
        footer={[
          <Button key="back" onClick={onClose}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit(onConfirm)}>
            Démarrer l'exportation
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Veuillez cocher les options d'exportation désirées">
            <Controller
              name="options"
              control={control}
              render={({ field }) => (
                <Checkbox.Group {...field}>
                  <Checkbox value="downloadFiles">
                    <Space direction="vertical" style={{ display: 'flex' }}>
                      Inclure les fichiers ressources dans le package
                      <Typography.Paragraph type="secondary">
                        Par défaut, les ressources du cours sont incluses sous la forme de liens vers les fichiers
                        hébergés sur le serveur. En cochant cette option, les ressources seront intégrées dans
                        l'archive.
                      </Typography.Paragraph>
                    </Space>
                  </Checkbox>
                </Checkbox.Group>
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModuleExportModal;
