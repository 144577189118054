import { memo } from 'react';
import Editor from '../../../../ui/Editor';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const EditableAnswer = memo(({ isEditing, fieldProps, disabled }) => {
  const { user } = useAuth();

  return isEditing && !disabled && checkAuthorization(user, 'arinfo', 'faq', 'update-faq') ? (
    <Editor
      {...fieldProps}
      minHeight={300}
      borderless
      options={{
        heading: false,
        figure: false,
        strike: false,
        color: false,
        important: false,
        highlight: false,
        code: false,
        codeBlock: false,
        youtube: false,
        vimeo: false,
        cloudinaryVideo: false,
        blockquote: false,
        file: false,
        table: false,
      }}
    />
  ) : (
    <div dangerouslySetInnerHTML={{ __html: fieldProps?.value }} className="arinfo-faq-answer-display" />
  );
});
export default EditableAnswer;
