import { useFormContext, useWatch } from 'react-hook-form';

const IllustratedPreview = () => {
  const { control } = useFormContext();
  const values = useWatch({ control });

  return (
    <>
      <h3 className="preview-question">{values?.question}</h3>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: 20,
        }}
      >
        {values?.illustrated?.map((item, index) => (
          <div key={index} className="checkbox-illustrated-container checkbox-container-img">
            <div className="illustrated-image-hover">
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={item.preview || item?.image?.secure_url}
              />
            </div>
            <label className="checkbox-label checkbox-label-img">
              <input type="checkbox" className="checkbox-input" />
              <div className="checkbox-box" />
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default IllustratedPreview;
