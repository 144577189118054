import { useState, useEffect } from 'react';
import { Modal, Typography, Form, Upload, message, Button, Switch, Input } from 'antd';
import { Controller, useFormState, useForm } from 'react-hook-form';
import { UploadOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';
import { imaginelabDocumentsRoutes } from '../../../lib/routes';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';

const EditModal = ({ loading, onFinish, data, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { user } = useAuth();
  const { put } = useFetch();

  useEffect(() => {
    if (data) {
      methods.setValue('title', data.title);
      methods.setValue('description', data.description);
      methods.setValue('disabled', data.disabled);
    }
  }, [data]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(imaginelabDocumentsRoutes.update + '/' + data._id, formData, 'multipart/form-data');

    if (results.status === 200) {
      methods.reset();
      onClose();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onFileChange = ({ file }, onChange) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 10) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 10Mo');
    } else {
      onChange(file);
    }
  };

  const onCancel = () => {
    methods.reset();
    onClose();
  };

  return (
    <Modal
      title="Remplacer un document"
      visible={!!data}
      onCancel={onCancel}
      onOk={methods.handleSubmit(onSubmit)}
      okButtonProps={{ disabled: loading, loading: isProcessing }}
      cancelButtonProps={{ disabled: loading || isProcessing }}
      destroyOnClose
    >
      <Form noValidate layout="vertical">
        <Form.Item
          label="Document"
          className="imaginelab-document-upload"
          validateStatus={errors?.file?.message && 'error'}
          help={errors?.file?.message}
          required
        >
          <Controller
            name="file"
            control={methods.control}
            render={({ field }) => (
              <Upload
                name={field.name}
                disabled={loading || isProcessing}
                maxCount={1}
                showUploadList={false}
                accept=".pdf"
                beforeUpload={() => false}
                onChange={(img) => onFileChange(img, field.onChange)}
              >
                <Button
                  block
                  style={{
                    textAlign: 'left',
                    borderColor: errors?.file?.message && '#ff4d4f',
                  }}
                  disabled={loading || isProcessing}
                >
                  <Typography.Text
                    style={{
                      color: errors?.file?.message && '#ff4d4f',
                    }}
                    ellipsis
                  >
                    <UploadOutlined /> {field?.value ? field.value.name : data.file}
                  </Typography.Text>
                </Button>
              </Upload>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Titre"
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
          required
        >
          <Controller
            name="title"
            control={methods.control}
            render={({ field }) => <Input {...field} disabled={loading || isProcessing} />}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          validateStatus={errors?.description?.message && 'error'}
          help={errors?.description?.message}
          required
        >
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => <Input {...field} maxLength={50} disabled={loading || isProcessing} />}
          />
        </Form.Item>
        {(checkAuthorization(user, 'imaginelab', 'documents', 'enable-document') ||
          checkAuthorization(user, 'imaginelab', 'documents', 'disable-document')) && (
          <Form.Item label="Actif" required>
            <Controller
              name="disabled"
              control={methods.control}
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={!field.value}
                  maxLength={50}
                  onChange={(value) => {
                    const canAccess = !field.value
                      ? checkAuthorization(user, 'imaginelab', 'documents', 'disable-document')
                      : checkAuthorization(user, 'imaginelab', 'documents', 'enable-document');

                    if (canAccess) {
                      field.onChange(!field.value);
                    }
                  }}
                  disabled={
                    isProcessing ||
                    loading ||
                    (!field.value
                      ? !checkAuthorization(user, 'imaginelab', 'documents', 'disable-document')
                      : !checkAuthorization(user, 'imaginelab', 'documents', 'enable-document'))
                  }
                />
              )}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
export default EditModal;
