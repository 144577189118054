import { Card, Row, Col, Table, Typography } from 'antd';
import { useFormContext, useFieldArray } from 'react-hook-form';

const PricesTable = ({ index }) => {
  const { control } = useFormContext();
  const { fields: prices } = useFieldArray({ control, name: `grids.${index}.data` });

  const columns = [
    {
      title: 'Coût/horaire',
      dataIndex: 'costPerHour',
      key: 'costPerHour',
      align: 'right',
      render: (value) => (value ? `${value} €` : '-'),
    },
    {
      title: 'Heures',
      dataIndex: 'hours',
      key: 'hours',
      align: 'right',
      render: (value) => value ?? '-',
    },
    {
      title: 'Coût/jour',
      dataIndex: 'costPerDay',
      key: 'costPerDay',
      align: 'right',
      render: (value) => (value ? `${value} €` : '-'),
    },
    {
      title: 'Jours',
      dataIndex: 'days',
      key: 'days',
      align: 'right',
      render: (value) => value ?? '-',
    },
    {
      title: 'Tarif HT',
      dataIndex: 'priceWithoutTaxes',
      key: 'priceWithoutTaxes',
      align: 'right',
      render: (value) => (value ? `${value} €` : '-'),
    },
    {
      title: 'Tarif TTC',
      dataIndex: 'priceWithTaxes',
      key: 'priceWithTaxes',
      align: 'right',
      render: (value) => (value ? `${value} €` : '-'),
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      columns={columns}
      dataSource={prices}
      rowKey={(row) => row.id}
      size="small"
      pagination={false}
    />
  );
};

const CPFImportPreview = () => {
  const { control } = useFormContext();
  const { fields: grids } = useFieldArray({ control, name: 'grids' });

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
      {grids.map((grid, index) => (
        <Col span={12} key={index}>
          <Card
            className="cpf-card"
            title={
              <>
                <Typography.Text strong>{grid.title}</Typography.Text>
                <br />
                <Typography.Text disabled>{grid.description}</Typography.Text>
              </>
            }
            size="small"
          >
            <PricesTable index={index} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CPFImportPreview;
