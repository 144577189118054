import { EditorContent, useEditor } from '@tiptap/react';
import { Button, Space, Divider, Tooltip, message } from 'antd';
import { forwardRef, useEffect, useState } from 'react';
import { serialize } from 'object-to-formdata';
import cn from 'classnames';
import sanitizeHtml from 'sanitize-html';
import {
  ItalicOutlined,
  BoldOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  UndoOutlined,
  RedoOutlined,
  InfoCircleOutlined,
  DownSquareOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from '@ant-design/icons';
import { uploadRoutes } from '../../lib/routes';
import useFetch from '../../hooks/useFetch';
import Extension from './Extensions';
import { Code, CodeBlock, Blockquote } from './Icons';
import Link from './Link';
import Image from './Image';
import Youtube from './Youtube';
import CloudinaryVideoPopover from './CloudinaryVideoPopover';
import ContentEditorMenuGrid from './ContentEditorMenuGrid';
import Heading from './Heading';
import Highlight from './Highlight';
import Color from './Color';
import FilePopover from './FilePopover';
import TablePopover from './TablePopover';
import ContextMenu from './ContextMenu';
import './editor.css';
import { ContentEditorProvider } from './contentEditorContext';
import FigureResponsiveModal from './FigureResponsiveModal';
import Vimeo from './Vimeo';

const defaultOptions = {
  placeholder: {
    placeholder: 'Écrivez votre contenu ici...',
  },
  dropcursor: {
    width: 2,
  },
  image: {
    inline: true,
  },
  highlight: {
    multicolor: true,
    colors: ['#B3C0D8'],
  },
  color: {
    colors: ['#B3C0D8'],
  },
  link: {
    openOnClick: false,
  },
  table: {
    resizable: true,
  },
  textAlign: {
    types: ['heading', 'paragraph'],
    alignments: ['left', 'center', 'right'],
  },
};

const MENU_ITEMS = [
  [
    {
      key: 'undo',
      title: 'Annuler CTRL+Z',
      icon: <UndoOutlined />,
      onClick: (e) => e.commands.undo(),
    },
    {
      key: 'redo',
      title: 'Rétablir CTRL+SHIFT+Z',
      icon: <RedoOutlined />,
      onClick: (e) => e.commands.redo(),
    },
  ],
  [
    {
      key: 'heading',
      title: 'Texte',
      component: (editor, disabled) => <Heading key="heading" editor={editor} disabled={disabled} />,
    },
  ],
  [
    {
      key: 'bold',
      title: 'Gras',
      icon: <BoldOutlined />,
      onClick: (e) => e.chain().focus().toggleBold().run(),
    },
    {
      key: 'italic',
      title: 'Italique',
      icon: <ItalicOutlined />,
      onClick: (e) => e.chain().focus().toggleItalic().run(),
    },
    {
      key: 'underline',
      title: 'Souligné',
      icon: <UnderlineOutlined />,
      onClick: (e) => e.chain().focus().toggleUnderline().run(),
    },
    {
      key: 'strike',
      title: 'Barré',
      icon: <StrikethroughOutlined />,
      onClick: (e) => e.chain().focus().toggleStrike().run(),
    },
  ],
  [
    {
      key: 'color',
      title: 'Couleurs',
      component: (editor, disabled) => <Color key="color" editor={editor} disabled={disabled} />,
    },
    {
      key: 'highlight',
      title: 'Surligner',
      component: (editor, disabled) => <Highlight key="highlight" editor={editor} disabled={disabled} />,
    },
  ],
  [
    {
      key: 'bulletList',
      title: 'Liste à puces',
      icon: <UnorderedListOutlined />,
      onClick: (e) => e.chain().focus().toggleBulletList().run(),
    },
    {
      key: 'orderedList',
      title: 'Liste numérotée',
      icon: <OrderedListOutlined />,
      onClick: (e) => e.chain().focus().toggleOrderedList().run(),
    },
  ],
  [
    {
      key: 'blockquote',
      title: 'Bloc citation',
      icon: <Blockquote />,
      onClick: (e) => e.chain().focus().toggleBlockquote().run(),
    },
    {
      key: 'important',
      title: 'Bloc important',
      icon: <InfoCircleOutlined />,
      onClick: (e) => e.chain().focus().toggleImportant().run(),
    },
  ],
  [
    {
      key: 'code',
      title: 'Ligne de code CTRL+E',
      icon: <Code />,
      onClick: (e) => e.chain().focus().toggleCode().run(),
    },
    {
      key: 'codeBlock',
      title: 'Bloc de code CTRL+ALT+C',
      icon: <CodeBlock />,
      onClick: (e) => e.chain().focus().toggleCodeBlock().run(),
    },
  ],
  [
    {
      key: 'align-left',
      title: 'Aligner à gauche',
      icon: <AlignLeftOutlined />,
      onClick: (e) => e.chain().focus().setTextAlign('left').run(),
      // isActive: (e) => e?.isActive({ textAlign: 'left' }),
    },
    {
      key: 'align-center',
      title: 'Aligner au centre',
      icon: <AlignCenterOutlined />,
      onClick: (e) => e.chain().focus().setTextAlign('center').run(),
      // isActive: (e) => e?.isActive({ textAlign: 'center' }),
    },
    {
      key: 'align-right',
      title: 'Aligner à droite',
      icon: <AlignRightOutlined />,
      onClick: (e) => e.chain().focus().setTextAlign('right').run(),
      // isActive: (e) => e?.isActive({ textAlign: 'right' }),
    },
  ],
  [
    {
      key: 'link',
      title: 'Lien',
      component: (editor, disabled) => (
        <Link key="link" isActive={editor.isActive('link')} editor={editor} disabled={disabled} />
      ),
    },
    {
      key: 'details',
      title: 'Déroulant',
      icon: <DownSquareOutlined />,
      onClick: (e) => e.chain().focus().setDetails().run(),
    },
    {
      key: 'grid',
      title: 'Insérer une grille',
      component: (editor, options) => <ContentEditorMenuGrid key="grid" editor={editor} options={options?.grid} />,
    },
    {
      key: 'table',
      title: 'Tableau',
      component: (editor, disabled) => <TablePopover key="table" editor={editor} disabled={disabled} />,
    },
    {
      key: 'figure',
      title: 'Téléverser une image',
      // component: (editor, disabled) => <Image key="figure" editor={editor} disabled={disabled} />,
      component: (editor, disabled) => <Image key="figure" editor={editor} disabled={disabled} />,
    },
    {
      key: 'cloudinaryVideo',
      title: 'Téléverser une vidéo',
      component: (editor, disabled) => (
        <CloudinaryVideoPopover key="cloudinaryVideo" editor={editor} disabled={disabled} />
      ),
    },
    {
      key: 'youtube',
      title: 'Intégrer une vidéo Youtube',
      component: (editor, disabled) => <Youtube key="youtube" editor={editor} disabled={disabled} />,
    },
    {
      key: 'vimeo',
      title: 'Intégrer une vidéo Vimeo',
      component: (editor, disabled) => <Vimeo key="vimeo" editor={editor} disabled={disabled} />,
    },
    {
      key: 'file',
      title: 'Téléverser un fichier',
      component: (editor, disabled, uploadWorkspace) => (
        <FilePopover key="file" editor={editor} disabled={disabled} uploadWorkspace={uploadWorkspace} />
      ),
    },
  ],
];

const MenuBar = ({ editor, disabled, uploadWorkspace }) => {
  const { options } = editor.options.extensions[0];

  return (
    <div className="editor-menu">
      {MENU_ITEMS.map((items, i) => {
        let filter = [];

        for (let i = 0; i < items.length; i++) {
          if (typeof options[items[i].key] === 'undefined') {
            if (['align-left', 'align-center', 'align-right'].includes(items[i].key)) {
              if (options?.textAlign !== false) {
                filter.push(items[i]);
              }
            } else if (['undo', 'redo'].includes(items[i].key) && options.history !== false) {
              filter.push(items[i]);
            } else {
              filter.push(items[i]);
            }
          } else {
            if (options[items[i].key] !== false) {
              filter.push(items[i]);
            }
          }
        }

        if (filter.length !== 0) {
          return (
            <div className="menu-group" key={i}>
              <Space>
                {filter.map((item) => {
                  const itemOptions = options[item.key];

                  if (item.component) {
                    return item.component(editor, disabled, uploadWorkspace);
                  }

                  return (
                    <Tooltip key={item.key} title={itemOptions?.tooltip || item.title}>
                      <Button
                        className="editor-button"
                        icon={itemOptions?.icon || item.icon}
                        onClick={() => item.onClick(editor)}
                        type={editor.isActive(item.key) && 'primary'}
                        disabled={disabled}
                      />
                    </Tooltip>
                  );
                })}
              </Space>
              {i !== MENU_ITEMS.length - 1 && <Divider type="vertical" />}
            </div>
          );
        }
      })}
    </div>
  );
};

const Editor = forwardRef(
  ({ value, onChange, disabled, options, borderless, minHeight, className, uploadWorkspace }, ref) => {
    const [initialSet, setInitialSet] = useState(true);
    const { post } = useFetch();
    const editor = useEditor({
      extensions: [Extension.configure({ ...defaultOptions, ...options })],
      content: value,
      onUpdate: ({ editor }) => {
        let HTMLContent = editor.getHTML();

        return onChange(HTMLContent);
      },
      editorProps: {
        transformPastedHTML: (html) => {
          const parser = new DOMParser();
          const content = parser.parseFromString(html, 'text/html');
          const figures = content.querySelector('figure');
          const images = content.querySelector(':not(figure) > img');

          return sanitizeHtml(html, { allowedTags: [] });
        },
        handlePaste: async (view, event, slice) => {
          const items = Array.from(event.clipboardData?.items || []);

          for (const item of items) {
            if (item.type.indexOf('image') === 0) {
              message.loading({ content: "Copie de l'image en cours", key: 'image-paste' });

              const blob = item.getAsFile();

              if (blob.size / 1024 / 1024 < 10) {
                const formData = serialize({ image: blob }, { indices: true });
                const results = await post(uploadRoutes.image, formData, 'multipart/form-data', false);

                if (results.status === 201) {
                  message.success({ content: 'Image copiée', key: 'image-paste' });

                  const node = view.state.config.schema.nodes.figure.create({
                    src: results.data.secure_url,
                    alt: results.data.public_id,
                    id: results.data.public_id,
                    caption: '',
                  });

                  const transaction = view.state.tr.replaceSelectionWith(node);

                  view.dispatch(transaction);
                } else {
                  if (results.message) {
                    message.error({ content: results.message, key: 'image-paste' });
                  } else {
                    message.error({ content: 'Une erreur est survenue, veuillez réessayer', key: 'image-paste' });
                  }
                }
              } else {
                message.error({
                  content: 'Ce fichier est trop lourd, le poids maximum est de 10Mo',
                  key: 'image-paste',
                });
              }

              return true;
            }
          }

          view.dispatch(view.state.tr.replaceSelection(slice));
          return true;
        },
        attributes: {
          class: 'editor',
          style: minHeight && `min-height: ${minHeight}px;`,
        },
      },
    });

    useEffect(() => {
      if (editor?.isEmpty && value) {
        editor?.commands?.setContent(value);
      }
    }, [editor, value, initialSet]);

    if (!editor) return null;

    return (
      <div className={cn('editor-root', className, { disabled, borderless })}>
        <ContentEditorProvider>
          <MenuBar editor={editor} disabled={disabled} uploadWorkspace={uploadWorkspace} />
          <FigureResponsiveModal editor={editor} />
          <ContextMenu editor={editor}>
            <EditorContent ref={ref} editor={editor} />
          </ContextMenu>
        </ContentEditorProvider>
      </div>
    );
  },
);

Editor.displayName = 'Editor';
export default Editor;
