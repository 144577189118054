import { uniqueId } from 'lodash';
import { studentRoutes } from '../../../../lib/routes';

const accountValidator = (fields, post) => {
  return new Promise(async (resolve, reject) => {
    const clone = [...fields].map((item) => ({ ...item, errors: {}, _id: uniqueId() }));

    for (let i = 0; i < clone.length; i++) {
      // Détecter les champs vides
      if (!clone[i].last_name) {
        clone[i].errors.last_name = 'Missing';
      }

      if (!clone[i].first_name) {
        clone[i].errors.first_name = 'Missing';
      }

      if (!clone[i].email) {
        clone[i].errors.email = 'Missing';
      }

      // Détecter les duplicatas
      const duplicates = clone.filter((item) => item.email === clone[i].email && item._id !== clone[i]._id);

      if (duplicates.length !== 0) {
        clone[i].errors.duplicates = duplicates.map((item) => item._id);
      }

      // Check si un compte apprenant avec cet email existe déjà
      const { data } = await post(studentRoutes.testEmail, JSON.stringify({ email: clone[i].email }));

      if (data.result) {
        clone[i].errors.exist = true;
      }
    }

    resolve(clone);
  });
};

const formationValidator = (formation, students) => {
  const errors = {};

  if (!formation?.formation) {
    errors.formation = 'Ce champ est requis';
  }

  if (!formation?.referent) {
    errors.referent = 'Ce champ est requis';
  }

  if (!formation?.startAt) {
    errors.startAt = 'Ce champ est requis';
  }

  if (!formation?.endAt) {
    errors.endAt = 'Ce champ est requis';
  }

  if (!formation?.realizationMethod) {
    errors.realizationMethod = 'Ce champ est requis';
  }

  if (!formation?.formationDomain) {
    errors.formationDomain = 'Ce champ est requis';
  }

  if (!formation?.type) {
    errors.type = 'Ce champ est requis';
  }

  if (formation?.groupMode === 'new') {
    if (!formation?.agency) {
      errors.agency = 'Ce champ est requis';
    }
  }

  if (formation?.agencyMode === 'global') {
    if (!formation?.studentsAgency) {
      errors.studentsAgency = 'Ce champ est requis';
    }
  }

  if (formation?.agencyMode === 'unique') {
    if (Array.isArray(students)) {
      for (let i = 0; i < students.length; i++) {
        if (!students[i]?.agency) {
          errors[`students.${i}.agency`] = 'Ce champ est requis';
        }
      }
    }
  }

  return errors;
};

export { accountValidator, formationValidator };
