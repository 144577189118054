import { random } from 'lodash';

var sliceSize = 6000000;

const chunkUpload = ({
  onError,
  onProgress,
  onChunkUpload,
  onSuccess,
  data,
  filename,
  file,
  withCredentials,
  action,
  headers,
}) => {
  const uniqueId = random(0, 1000000000000000) + '_' + new Date().getTime();

  const slice = (file, start, end) => {
    const sliceFn = file?.mozSlice || file?.webkitSlice || file?.slice || function () {};

    return sliceFn.bind(file)(start, end);
  };

  const uploadSlice = async (slice, start, end, size) => {
    const formData = new FormData();

    formData.append('file', slice, file.name);

    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'file') {
        if (Array.isArray(value)) {
          for (let vi = 0; vi < value.length; vi++) {
            formData.append(`${key}[${vi}]`, value[vi]);
          }
        } else {
          formData.append(key, value);
        }
      }
    });

    try {
      const response = await fetch(action, {
        method: 'POST',
        body: formData,
        headers: {
          ...headers,
          'X-Unique-Upload-Id': uniqueId,
          'Content-Range': `bytes ${start}-${end}/${size}`,
        },
      });

      const json = await response.json();

      if (![200, 201].includes(response.status)) {
        if (json?.data && Object.keys(json?.data).length !== 0) {
          return { success: false, error: json.data };
        }

        return { success: false, error: json?.message || '' };
      }

      if (json?.secure_url || response.status === 201) {
        return { success: true, error: null, uploadedFile: json };
      }

      return { success: true, error: null, data: json };
    } catch (error) {
      return { success: false, error: error?.toString() || '' };
    }
  };

  const processFile = async (file) => {
    const size = file.size;
    const slices = Math.ceil(size / sliceSize);
    let start = 0;

    for (let i = 0; i < slices; i++) {
      let end = start + sliceSize;

      if (end > size) {
        end = size;
      }

      const currentSlice = slice(file, start, end);
      const { success, error, uploadedFile, data } = await uploadSlice(currentSlice, start, end - 1, size);
      const percent = Math.round(((sliceSize * (i + 1)) / size) * 100).toFixed(2);

      if (success) {
        if (end < size) {
          start += sliceSize;
        }

        if (onProgress && typeof onProgress === "function") {
          onProgress({ percent });
        }

        if (data && typeof onChunkUpload === 'function') {
          onChunkUpload(data);
        }

        if (uploadedFile) {
          onSuccess(uploadedFile);
        }

        continue;
      } else {
        onError(error);
        break;
      }
    }
  };

  processFile(file);
};

export default chunkUpload;
