import { memo, useMemo } from 'react';

import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';

import { uniqueId } from 'lodash';

const getSize = (value) => {
  if (!value || value === 'auto') {
    return '100%';
  }

  return `${value}px`;
};

const getStyles = (attrs) => ({
  desktop: {
    maxWidth: getSize(attrs['data-desktop-width']),
    maxHeight: getSize(attrs['data-desktop-height']),
  },
  tablet: {
    maxWidth: getSize(attrs['data-tablet-width']),
    maxHeight: getSize(attrs['data-tablet-height']),
  },
  mobile: {
    maxWidth: getSize(attrs['data-mobile-width']),
    maxHeight: getSize(attrs['data-mobile-height']),
  },
});

const View = memo(({ node }) => {
  const id = uniqueId('figure_');
  const styles = useMemo(() => getStyles(node.attrs), [node.attrs]);

  return (
    <NodeViewWrapper className="figure-container">
      <div id={id}>
        <NodeViewContent as="figure" {...node.attrs} />
        <style global>
          {`
            #${id} img {
              width: ${styles.mobile.maxWidth === '100%' ? 'auto' : '100%'};
              max-width: ${styles.mobile.maxWidth};
              height: ${styles.mobile.maxHeight === '100%' ? 'auto' : '100%'};
              max-height: ${styles.mobile.maxHeight};
            }

            @media screen and (min-width: 768px) {
              #${id} img {
                width: ${styles.tablet.maxWidth === '100%' ? 'auto' : '100%'};
                max-width: ${styles.tablet.maxWidth};
                height: ${styles.tablet.maxHeight === '100%' ? 'auto' : '100%'};
                max-height: ${styles.tablet.maxHeight};
              }
            }

            @media screen and (min-width: 992px) {
              #${id} img {
                width: ${styles.desktop.maxWidth === '100%' ? 'auto' : '100%'};
                max-width: ${styles.desktop.maxWidth};
                height: ${styles.desktop.maxHeight === '100%' ? 'auto' : '100%'};
                max-height: ${styles.desktop.maxHeight};
              }
            }
          `}
        </style>
      </div>
    </NodeViewWrapper>
  );
});

View.displayName = 'View';
export default View;
