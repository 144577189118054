import { useState } from 'react';
import { Row, Col, Input, DatePicker, Form, Button, Popconfirm, message } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import moment from 'moment';
import Editor from '../../../../../ui/Editor';
import useFetch from '../../../../../hooks/useFetch';
import { eventRoutes } from '../../../../../lib/routes';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const { Item } = Form;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'top',
};

const EditDrawerMeta = ({ disabled, eventId, onDelete }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { user } = useAuth();
  const { remove } = useFetch();

  const handleDelete = async () => {
    setIsProcessing(true);

    const results = await remove(eventRoutes.default + '/' + eventId);

    if (results.status === 200) {
      onDelete();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Item validateStatus={errors?.title?.message && 'error'} help={errors?.title?.message} label="Intitulé" required>
        <Controller control={control} name="title" render={({ field }) => <Input {...field} />} />
      </Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item validateStatus={errors?.start?.message && 'error'} help={errors?.start?.message} label="Début" required>
            <Controller
              control={control}
              name="start"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  format={['DD/MM/YYYY']}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(date)}
                  disabled={disabled || isProcessing}
                />
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item validateStatus={errors?.end?.message && 'error'} help={errors?.end?.message} label="Fin" required>
            <Controller
              control={control}
              name="end"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  format={['DD/MM/YYYY']}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(date)}
                  disabled={disabled || isProcessing}
                />
              )}
            />
          </Item>
        </Col>
      </Row>
      {/* <Item extra="En cochant cette case vous pourrez définir une date de rendu pour un exercice">
        <Controller
          control={control}
          name="should_return"
          defaultValue={true}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={disabled || isProcessing}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              Évènement lié à une formation
            </Checkbox>
          )}
        />
      </Item> */}
      <Item
        validateStatus={errors?.description?.message && 'error'}
        help={errors?.description?.message}
        label="Description"
      >
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Editor
              {...field}
              minHeight={200}
              disabled={disabled || isProcessing}
              options={{
                heading: false,
                code: false,
                codeBlock: false,
                image: false,
                quote: false,
                history: false,
                figure: false,
                important: false,
                blockquote: false,
                color: false,
                file: false,
                table: false,
                details: false,
                textAlign: false,
                grid: false,
                cloudinaryVideo: false,
                youtube: false,
                vimeo: false,
              }}
            />
          )}
        />
      </Item>
      {checkAuthorization(user, 'formao', 'events', 'delete-event') && (
        <Popconfirm {...popconfirmProps} onConfirm={handleDelete} disabled={disabled || isProcessing}>
          <Button danger loading={isProcessing} disabled={disabled}>
            Supprimer cet évènement
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default EditDrawerMeta;
