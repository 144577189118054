import { useFormContext, Controller } from 'react-hook-form';
import { Transfer, Form, Button, Alert } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { sort } from '../../../../shared/utils';
import { studentRoutes } from '../../../../lib/routes';
import { isBefore, isToday } from 'date-fns';
import EditStudentsModalContent from '../components/EditStudentsModalContent';
import { useState } from 'react';

const { Item } = Form;

const GroupEditStudents = ({ disabled, groupFormation, groupId }) => {
  const [isModalOpen, setIsModalOpen] = useState(null);
  const { control, watch, setValue } = useFormContext();
  const fields = watch('students');
  const {
    data: students,
    isValidating,
    mutate,
  } = useSWR(studentRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  // prettier-ignore
  const filtered = students?.data.filter((student) => student.formations.find((formation) => formation?.formation === groupFormation?._id  && (!formation?.group || formation.group === groupId))) || [];
  const formatted = filtered.map((student) => {
    const formationMatch = student.formations.find(
      (formation) =>
        formation?.formation === groupFormation?._id &&
        !isBefore(new Date(formation?.endAt), new Date()) &&
        !isToday(new Date(formation?.endAt)),
    );

    return formationMatch ? { ...student, disabled: student.customProgram } : { ...student, disabled: true };
  });

  const handleFilter = (inputValue, option) => {
    const title = option.last_name.toLowerCase() + ' ' + option.first_name.toLowerCase();

    return title.toLowerCase().includes(inputValue.toLowerCase());
  };

  const renderFooter = ({ direction }) => {
    if (direction === 'left') {
      return (
        <Button block type="text" onClick={() => mutate()} icon={<ReloadOutlined />}>
          Recharger
        </Button>
      );
    }
  };

  const onTransfer = (nextTargetKeys, onChange) => {
    let displayedStudents = [];
    let newFields = fields.filter((item) => nextTargetKeys.includes(item.studentId));

    for (let index = 0; index < nextTargetKeys.length; index++) {
      const alreadyPresent = newFields.find((item) => item.studentId.toString() === nextTargetKeys[index].toString());

      if (!alreadyPresent) {
        const studentMatch = students?.data?.find((stud) => stud._id.toString() === nextTargetKeys[index].toString());
        const matchingFormations = studentMatch.formations.filter(
          (item) =>
            item.formation.toString() === groupFormation._id.toString() && (!item?.group || item.group === groupId), // AJOUTER LA VERIF DE DATE NON DEPASSÉE
        );

        if (matchingFormations.length > 1) {
          displayedStudents.push({ student: studentMatch, matchingFormations });
        } else if (matchingFormations.length === 1) {
          newFields.push({ studentId: nextTargetKeys[index], formationId: matchingFormations[0]._id });
        }
      }
    }

    onChange(newFields);

    if (displayedStudents.length > 0) {
      setIsModalOpen(displayedStudents);
    }
  };

  return (
    <>
      <Alert
        type="info"
        showIcon
        message={`Seulement les apprenants étant inscrits dans la formation ${
          groupFormation ? groupFormation.title : 'correspondante'
        } sont affichés.`}
        style={{ marginBottom: 20 }}
      />
      <EditStudentsModalContent isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <Item extra="Déplacez les apprenants à droite pour les ajouter dans la session">
        <Controller
          control={control}
          name="students"
          render={({ field }) => (
            <Transfer
              dataSource={formatted.sort((a, b) => sort(a, b, 'last_name')) || []}
              rowKey={(record) => record._id}
              render={(record) =>
                `${record.last_name.toUpperCase()} ${
                  record.first_name.charAt(0).toUpperCase() + record.first_name.slice(1)
                }`
              }
              targetKeys={field.value?.map((item) => item.studentId)}
              onChange={(nextTargetKeys) => onTransfer(nextTargetKeys, field.onChange)}
              filterOption={handleFilter}
              showSearch
              showSelectAll={false}
              listStyle={{ height: 400, width: '100%' }}
              disabled={!students || isValidating || disabled}
              titles={['Tous les apprenants', 'Dans la session']}
              footer={renderFooter}
            />
          )}
        />
      </Item>
    </>
  );
};

export default GroupEditStudents;
