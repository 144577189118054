import { createContext, useContext, useMemo, useCallback, useReducer } from 'react';

const initialState = {
  figureResponsiveModalOpen: false,
  toggleFigureResponsiveModal: () => {},
};

const ContentEditorContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_FIGURE_RESPONSIVE_MODAL':
      if (action.value !== true && action.value !== false) {
        throw Error('La valeur passée doit être true OU false');
      }

      return { ...state, figureResponsiveModalOpen: action.value };
    default:
      return state;
  }
};

export const ContentEditorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const figureResponsiveModalOpen = useMemo(() => state.figureResponsiveModalOpen, [state.figureResponsiveModalOpen]);

  const toggleFigureResponsiveModal = useCallback(
    (value) => dispatch({ type: 'TOGGLE_FIGURE_RESPONSIVE_MODAL', value }),
    [dispatch],
  );

  const value = useMemo(
    () => ({ figureResponsiveModalOpen, toggleFigureResponsiveModal }),
    [figureResponsiveModalOpen, toggleFigureResponsiveModal],
  );

  return <ContentEditorContext.Provider value={value}>{children}</ContentEditorContext.Provider>;
};

export const useContentEditorContext = () => useContext(ContentEditorContext);
