import { Upload, Form, message, Alert, List, Button, Popconfirm, Tooltip, Card, Select } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { serialize } from 'object-to-formdata';
import useSWR from 'swr';
import useFetch from '../../../hooks/useFetch';
import { uploadRoutes, documentRoutes } from '../../../lib/routes';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import AgenciesModal from './AgenciesModal';

const { Item } = Form;
const { Dragger } = Upload;

const DocumentsList = () => {
  const [loading, setLoading] = useState(false);
  const { data: documents, isValidating, mutate } = useSWR(documentRoutes.public);
  const { post, remove } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const handleUpload = async ({ file }) => {
    if (!checkAuthorization(user, 'formao', 'documents', 'upload-document')) {
      message.error("Vous ne disposez pas de l'autorisation requise pour téléverser un fichier");
      return false;
    }

    setLoading(true);

    if (file.size / 1024 / 1024 > 10) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 10Mo');

      setLoading(false);

      return false;
    }

    const formData = serialize({ file, restricted: false }, { indices: true });
    const results = await post(uploadRoutes.file, formData, 'multipart/form-data');

    if (results.status === 201) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    setLoading(false);
  };

  const handleDelete = async (id) => {
    if (!checkAuthorization(user, 'formao', 'documents', 'delete-document')) {
      message.error("Vous ne disposez pas de l'autorisation requise pour supprimer un fichier");
      return false;
    }

    setLoading(true);

    const results = await remove(uploadRoutes.deleteFile + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    setLoading(false);
  };

  return (
    <Card>
      <Alert
        type="info"
        showIcon
        message="Les documents déposés dans cet espace seront accessible par tous les apprenants."
        style={{ marginBottom: 20 }}
      />
      <Item required>
        <Dragger
          name="file"
          maxCount={1}
          showUploadList={false}
          height={150}
          disabled={isValidating || loading}
          beforeUpload={() => false}
          onChange={handleUpload}
        >
          <p className="ant-upload-hint">Sélectionnez ou déposez un fichier</p>
        </Dragger>
      </Item>
      <List
        size="small"
        bordered
        style={{ marginBottom: 20 }}
        dataSource={documents?.data || []}
        renderItem={(item, index) => (
          <List.Item
            key={item._id}
            actions={[
              checkAuthorization(user, 'formao', 'documents', 'update-document') && (
                <AgenciesModal documentId={item._id} initialValue={item.agencies || []} onFinish={mutate} />
              ),
              <a
                href={`${process.env.REACT_APP_BASE_URL_API_URL}/download/formao/${item.name}${item.extension}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button icon={<DownloadOutlined />} />
              </a>,
              checkAuthorization(user, 'formao', 'documents', 'delete-document') && (
                <Tooltip title="Supprimer le document" key="delete" placement="right">
                  <Popconfirm
                    {...popconfirmProps}
                    onConfirm={() => {
                      handleDelete(item._id);
                    }}
                  >
                    <Button icon={<DeleteOutlined />} disabled={isValidating || loading} />
                  </Popconfirm>
                </Tooltip>
              ),
            ]}
          >
            <span>{item.originalName}</span>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default DocumentsList;
