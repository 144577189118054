import { useState } from 'react';
import { Drawer, Form, Button, Input, Select, Typography, message } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { userRoutes, roleRoutes } from '../../../../lib/routes';

const CreateUserDrawer = ({ onCreate }) => {
  const { handleSubmit, setError, control } = useForm();
  const { errors } = useFormState({ control });
  const { data: roles, isValidating } = useSWR(roleRoutes.default);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { post } = useFetch();

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(userRoutes.createAccount, JSON.stringify(form));

    if (results.status === 201) {
      setIsOpen(false);
      onCreate();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={() => setIsOpen(true)} className="gray-text">
          Nouvel utilisateur
        </Button>
      </div>
      <Drawer
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        maskClosable
        closable={false}
        width={310}
        title="Nouvel utilisateur"
        footer={
          <div>
            <Button type="primary" onClick={handleSubmit(onSubmit)} loading={isProcessing}>
              Enregistrer
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={onCancel} disabled={isProcessing}>
              Annuler
            </Button>
          </div>
        }
      >
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label="Nom"
            required
            validateStatus={errors?.last_name?.message && 'error'}
            help={errors?.last_name?.message}
          >
            <Controller
              control={control}
              name="last_name"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Prénom"
            required
            validateStatus={errors?.first_name?.message && 'error'}
            help={errors?.first_name?.message}
          >
            <Controller
              control={control}
              name="first_name"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Adresse email"
            required
            validateStatus={errors?.email?.message && 'error'}
            help={errors?.email?.message}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Rôle"
            required
            validateStatus={errors?.role?.message && 'error'}
            help={errors?.role?.message}
          >
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  disabled={isProcessing}
                  loading={!roles || isValidating}
                  options={
                    roles?.data.map((role) => ({
                      label: role.name,
                      value: role._id,
                    })) || []
                  }
                />
              )}
            />
          </Form.Item>
          <Typography.Text type="secondary" style={{ display: 'block' }}>
            L'identifiant et le mot de passe seront générés et envoyés à l'adresse email saisie
          </Typography.Text>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateUserDrawer;
