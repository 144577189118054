import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './authContext';
import { checkAuthorization } from './shared/utils';
import nprogress from 'nprogress';
import useFetch from './hooks/useFetch';
import { moduleRoutes } from './lib/routes';

const CustomFormaoModulesPrivateRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const { isAuth, user } = useAuth();
  const { get } = useFetch();
  nprogress.start();
  useEffect(() => nprogress.done());

  useEffect(() => {
    if (isAuth) {
      get(moduleRoutes.checkTempAccess + '/' + rest.computedMatch.params.id).then(({ status }) => {
        if (status === 200 || checkAuthorization(user, 'formao', 'modules', 'update-module')) {
          setAuthorized(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    !loading && (
      <Route
        {...rest}
        render={(props) =>
          authorized ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/connexion', state: { from: props.location } }} />
          )
        }
      />
    )
  );
};

export default CustomFormaoModulesPrivateRoute;
