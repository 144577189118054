import { memo } from 'react';
import parse from 'html-react-parser';
import { formatFigure, formatMark, formatTableCell } from './helpers';

const HtmlView = memo(({ data }) => {
  const content = parse(data?.content || '', {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'mark') {
        return formatMark(domNode);
      }

      if (domNode.type === 'tag' && domNode.name === 'figure') {
        return formatFigure(domNode);
      }

      if (domNode.type === 'tag' && ['td', 'th'].includes(domNode.name)) {
        return formatTableCell(domNode);
      }
    },
  });

  return content;
});

export default HtmlView;
