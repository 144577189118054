import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { Form, Select, Row, Col, DatePicker, Button, Typography, Table, message } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import { userRoutes } from '../../../../lib/routes';
import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { sort } from '../../../../shared/utils';

const { Item } = Form;
const { Title } = Typography;

const ModulesEditTempAccess = ({ disabled }) => {
  const [newAccess, setNewAccess] = useState({ user: null, expireAt: null });
  const [errors, setErrors] = useState({});
  const { control } = useFormContext();
  const { fields: tempAccesses, append, remove } = useFieldArray({ control, name: 'tempAccesses' });
  const { data: users, isValidating } = useSWR(userRoutes.default);

  const handleNewAccess = (event) => {
    event.preventDefault();

    const haveAccess = tempAccesses.map((item) => item.user);

    const newErrors = {};

    if (tempAccesses.length === 2) {
      return message.error("2 personnes au maximum peuvent être assignées à la modification d'un module");
    }

    if (haveAccess.includes(newAccess?.user)) {
      newErrors.user = "Cet utilisateur dispose déjà d'un accès";
    }

    if (!newAccess.user) {
      newErrors.user = 'Ce champ est requis';
    }

    if (!newAccess.expireAt) {
      newErrors.expireAt = 'Ce champ est requis';
    }

    if (Object.keys(newErrors).length !== 0) {
      return setErrors(newErrors);
    }

    setErrors({});
    setNewAccess({ user: null, expireAt: null });
    return append({ user: newAccess.user, expireAt: newAccess.expireAt });
  };

  const columns = [
    {
      title: 'Personne autorisée',
      dataIndex: 'user',
      key: 'user',
      render: (value, record) => (
        <Controller
          name={`tempAccesses.${record.index}.user`}
          control={control}
          render={({ field }) => {
            console.log(field.value);
            return (
              <Select
                {...field}
                showSearch
                disabled={disabled}
                loading={isValidating}
                placeholder="Sélectionnez dans la liste"
                style={{ width: '100%' }}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={
                  users?.data
                    .sort((a, b) => sort(a, b, 'last_name'))
                    .map((user) => ({
                      label: `${user.last_name.toUpperCase()} ${
                        user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                      }`,
                      value: user._id,
                    })) || []
                }
              />
            );
          }}
        />
      ),
    },
    {
      title: "Date d'expiration",
      dataIndex: 'expireAt',
      key: 'expireAt',
      width: 300,
      align: 'center',
      render: (value, record) => (
        <Controller
          name={`tempAccesses.${record.index}.expireAt`}
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              value={moment(field.value)}
              onChange={(date, dateString) => field.onChange(date.toDate())}
              format={['DD/MM/YYYY']}
              disabled={disabled}
              disabledDate={(current) => {
                if (current) {
                  return current < moment().endOf('day') || current > moment().add(1, 'M').endOf('day');
                }
              }}
              status={moment(new Date()).isAfter(field.value) && 'error'}
            />
          )}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      render: (record) => <Button icon={<DeleteOutlined />} onClick={() => remove(record.index)} />,
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: '#fafafa',
          padding: 10,
          borderRadius: 4,
          border: '1px solid #f0f0f0',
          marginBottom: 24,
        }}
      >
        <Title level={5}>Ajout d'un nouvel accès</Title>
        <Row gutter={16}>
          <Col span={10}>
            <Item label="Personne autorisée" validateStatus={errors?.user && 'error'} help={errors?.user}>
              <Select
                value={newAccess.user}
                onChange={(newValue) => setNewAccess((rest) => ({ ...rest, user: newValue }))}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={
                  users?.data
                    .sort((a, b) => sort(a, b, 'last_name'))
                    .map((user) => ({
                      label: `${user.last_name.toUpperCase()} ${
                        user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                      }`,
                      value: user._id,
                    })) || []
                }
                disabled={disabled}
                placeholder="Sélectionnez dans la liste"
                loading={isValidating}
                allowClear
              />
            </Item>
          </Col>
          <Col span={10}>
            <Item
              label="Date d'expiration"
              validateStatus={errors?.expireAt && 'error'}
              help={errors?.expireAt}
              extra="L'utilisateur perdra l'accès à cette date"
            >
              <DatePicker
                value={newAccess.expireAt ? moment(newAccess.expireAt) : null}
                onChange={(date, dateString) => setNewAccess((rest) => ({ ...rest, expireAt: date.toDate() }))}
                format={['DD/MM/YYYY']}
                disabled={disabled}
                disabledDate={(current) => {
                  if (current) {
                    return current < moment().endOf('day') || current > moment().add(1, 'M').endOf('day');
                  }
                }}
                allowClear
              />
            </Item>
          </Col>
          <Col span={4}>
            <Button htmlType="button" block type="primary" style={{ marginTop: 30 }} onClick={handleNewAccess}>
              Ajouter
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        style={{ marginBottom: 24 }}
        columns={columns}
        bordered
        size="small"
        rowKey={(record) => record.id}
        dataSource={tempAccesses.map((item, index) => ({ ...item, index }))}
        pagination={false}
      />
    </>
  );
};

export default ModulesEditTempAccess;
