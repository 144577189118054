/**
 * Extrait toutes les variables sous forme {VARIABLE} d'une chaîne de caractères
 * @param {string} str - La chaîne de caractères à analyser
 * @returns {string[]} - Un tableau de variables extraites
 * @example
 * const string = "Ceci est une string avec une {VARIABLE} et une autre {VAR2}.";
 *
 * const variables = extractVariablesFromString(string); // Affiche ["VARIABLE", "VAR2"]
 */
export const extractVariablesFromString = (str) => {
  const regex = /\{([^}]+)\}/g;
  let matches = new Set();
  let match;

  while ((match = regex.exec(str)) !== null) {
    matches.add(match[1]);
  }

  return Array.from(matches);
};

/**
 * Remplace chaque occurrence d'une variable dans une chaîne de caractères par une valeur donnée
 * @param {string} str - La chaîne de caractères à modifier
 * @param {object} replacements - Un objet contenant les variables et leurs valeurs de remplacement
 * @returns {string} - La chaîne de caractères modifiée
 * @example
 * const string = "Ceci est une string avec une {VARIABLE} et une autre {VAR2}.";
 *
 * const updatedString = replaceVariableInString(string, { 'VARIABLE': 'valeur remplacée', VAR2': 'autre valeur' });
 */
export const replaceVariableInString = (str, replacements) => {
  for (let variable in replacements) {
    const value = replacements[variable];
    const regex = new RegExp(`\\{${variable}\\}`, 'g');

    str = str.replace(regex, value);
  }
  return str;
};

/**
 * Extrait toutes les variables sous forme {VARIABLE} d'un JSON
 * @param {object} json - L'objet JSON à analyser
 * @returns {string[]} - Un tableau de variables extraites
 * @example
 * const json = {
 *  "message": "Ceci est une string avec une {VARIABLE}.",
 *  "details": {
 *   "info": "Voici une autre {VAR2}.",
 *    "nested": [
 *     "Variable {VAR3} ici.",
 *     {"deep": "Encore une {VAR4}. {VARIABLE}"}
 *    ]
 *   }
 * };
 *
 * const variables = extractVariablesFromJSON(json); // Affiche ["VARIABLE", "VAR2", "VAR3", "VAR4"]
 */
export const extractVariablesFromJSON = (json) => {
  let variables = new Set();

  const traverse = (obj) => {
    if (typeof obj === 'string') {
      let stringVariables = extractVariablesFromString(obj);

      stringVariables.forEach((variable) => variables.add(variable));
    } else if (Array.isArray(obj)) {
      for (let item of obj) {
        traverse(item);
      }
    } else if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          traverse(obj[key]);
        }
      }
    }
  };

  traverse(json);

  return Array.from(variables);
};

/**
 * Remplace chaque occurrence d'une variable dans un JSON par une valeur donnée
 * @param {object} json - L'objet JSON à modifier
 * @param {object} replacements - Un objet contenant les variables et leurs valeurs de remplacement.
 * @returns {object} - Le JSON modifié
 * @example
 * const json = {
 *  "message": "Ceci est une string avec une {VARIABLE}.",
 *  "details": {
 *   "info": "Voici une autre {VAR2}.",
 *    "nested": [
 *     "Variable {VAR3} ici.",
 *     {"deep": "Encore une {VAR4}. {VARIABLE}"}
 *    ]
 *   }
 * };
 *
 * const updatedJson = replaceVariableInJSON(json, { 'VARIABLE': 'valeur remplacée', 'VAR2': 'autre valeur', 'VAR3': 'troisième valeur', 'VAR4': 'quatrième valeur' });
 */
export const replaceVariableInJSON = (json, replacements) => {
  const traverse = (obj) => {
    if (typeof obj === 'string') {
      return replaceVariableInString(obj, replacements);
    } else if (Array.isArray(obj)) {
      return obj.map((item) => traverse(item));
    } else if (typeof obj === 'object' && obj !== null) {
      let newObj = {};

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = traverse(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  };

  return traverse(json);
};
