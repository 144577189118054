import TipTapImage from '@tiptap/extension-image';

export const Image = TipTapImage.extend({
  name: 'figureImage',
  selectable: false,
  draggable: false,
  priority: 150,
  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {
        ...this.parent?.()?.HTMLAttributes,
        draggable: false,
        contenteditable: false,
      },
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        default: null,
        parseHTML: (el) => el.getAttribute('id'),
      },
      height: {
        default: null,
        parseHTML: (el) => el.getAttribute('height'),
      },
      width: {
        default: null,
        parseHTML: (el) => el.getAttribute('width'),
      },
    };
  },
});
