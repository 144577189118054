import { Button, Checkbox, Collapse, Divider, Empty, Space, Spin, Timeline, Card } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { memo, useMemo, useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import { getLogList, parse } from './logParser';

const SWROptions = {
  revalidateIfStale: false,
  revalidateOnReconnect: false,
  revalidateOnFocus: true,
  refreshInterval: 0,
};

const logSectionsRecord = {
  general: 'Log généraux',
  chapters: 'Chapitre(s) et contenu(s)',
  upload: 'Téléversement des fichiers',
  modules: 'Page(s) de cours',
};

const ModuleImportLogsItem = memo(({ item, onChange, activeKey, options, isLast, ...props }) => {
  const hasLogs = !!item?.logs?.length;
  const hasChildren = !!item?.children?.length;

  return (
    <>
      <Collapse.Panel
        key={item.id}
        header={item.label in logSectionsRecord ? logSectionsRecord[item.label] : item.label}
        {...props}
      >
        {!hasLogs && !hasChildren ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            {hasLogs && (
              <Timeline style={{ paddingLeft: 16 }} className="log-timeline">
                {item.logs.map((itemLog, index) => (
                  <Timeline.Item
                    key={itemLog.id}
                    style={{ display: options.errorOnly && itemLog?.status !== 'error' ? 'none' : 'inherit' }}
                    color={itemLog?.status === 'error' ? 'red' : 'blue'}
                    className="log-item"
                  >
                    {itemLog.text}
                  </Timeline.Item>
                ))}
              </Timeline>
            )}
            {hasChildren && (
              <Collapse {...{ onChange, activeKey }} className="log-collapse" ghost>
                {item?.children?.map((itemChild, index) => (
                  <ModuleImportLogsItem
                    key={itemChild.id}
                    {...{ item: itemChild, onChange, activeKey }}
                    options={{ ...options, root: false }}
                    isLast={index === item?.children?.length - 1}
                  />
                ))}
              </Collapse>
            )}
          </>
        )}
      </Collapse.Panel>
      {!isLast && <Divider style={{ margin: 0 }} />}
    </>
  );
});

ModuleImportLogsItem.displayName = 'ModuleImportLogsItem';

const ModuleImportLogs = ({ logFile }) => {
  const [activeKey, setActiveKey] = useState([]);
  const [isDebugFolderDownloading, setIsDebugFolderDownloading] = useState(false);
  const [options, setOptions] = useState({ errorOnly: false, logOnly: false });
  const { data: logs, isValidating } = useSWR(
    `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/logs/${logFile}`,
    SWROptions,
  );
  const logRender = useMemo(() => parse(logs?.data || {}), [logs?.data]);
  const logList = useMemo(() => getLogList(logs?.data || []), [logs?.data]);
  const { token, user } = useAuth();

  const onChange = (keys) => {
    setActiveKey(keys);
  };

  const onOpenCloseAll = () => {
    const compareArrays = (source, target) => {
      let isSame = true;

      for (let i = 0; i < source.length; i++) {
        if (target.indexOf(source[i]) === -1) {
          isSame = false;
          break;
        }
      }

      return isSame;
    };

    if (compareArrays(['root', ...logRender.ids], activeKey)) {
      setActiveKey(['root']);
    } else {
      setActiveKey(['root', ...logRender.ids]);
    }
  };

  const onDownload = () => {
    const fileName = logFile;
    const json = JSON.stringify(logs?.data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const onDebugDownload = async () => {
    setIsDebugFolderDownloading(true);

    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/logs/download/${logFile}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        console.log('Impossible de récupérer les logs');
        return;
      }

      const buffer = Buffer.from(response.data.data);
      const blob = new Blob([buffer]);
      const href = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = href;
      link.download = logFile + '.zip';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDebugFolderDownloading(false);
    } catch (err) {
      console.log(err);
      setIsDebugFolderDownloading(false);
    }
  };

  if (!logs || !logs?.data || Object.keys(logs?.data).length === 0) {
    return (
      <div style={{ padding: 16 }}>
        <Empty />
      </div>
    );
  }

  return (
    <Card
      size="small"
      extra={[
        <Space key="log-download">
          {checkAuthorization(user, 'formao', 'packages', 'debug') && (
            <div onClick={(event) => event.stopPropagation()}>
              <Button
                icon={<DownloadOutlined />}
                size="small"
                onClick={onDebugDownload}
                loading={isDebugFolderDownloading}
              >
                Télécharger le dossier de débogage
              </Button>
            </div>
          )}
          <div onClick={(event) => event.stopPropagation()}>
            <Button icon={<DownloadOutlined />} type="primary" size="small" onClick={onDownload}>
              Télécharger le fichier de log
            </Button>
          </div>
        </Space>,
      ]}
    >
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 16, paddingBottom: 0 }}>
          <Space>
            <Checkbox
              checked={options.errorOnly}
              onChange={(event) => setOptions((rest) => ({ ...rest, errorOnly: event.target.checked }))}
              disabled={isValidating}
            >
              Erreurs uniquement
            </Checkbox>
            <Checkbox
              checked={options.logOnly}
              onChange={(event) => setOptions((rest) => ({ ...rest, logOnly: event.target.checked }))}
              disabled={isValidating}
            >
              Logs uniquement
            </Checkbox>
          </Space>
          {!options.logOnly && (
            <Button size="small" disabled={isValidating} onClick={onOpenCloseAll}>
              Tout ouvrir/fermer
            </Button>
          )}
        </div>
        <Spin spinning={isValidating}>
          {options.logOnly ? (
            <div style={{ minHeight: 46 }}>
              <Timeline style={{ paddingLeft: 16 }}>
                {logList.map((item, index) => (
                  <Timeline.Item
                    key={item.id}
                    style={{ display: options.errorOnly && item?.status !== 'error' ? 'none' : 'inherit' }}
                    color={item?.status === 'error' ? 'red' : 'blue'}
                    className="log-item"
                  >
                    {item.text}
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          ) : (
            <div style={{ minHeight: 46 }}>
              <Collapse {...{ onChange, activeKey }} className="log-collapse log-collapse-base">
                {logRender.items.map((item, index) => (
                  <ModuleImportLogsItem
                    key={item.id}
                    {...{ item, onChange, activeKey }}
                    options={{ ...options, root: true }}
                    isLast={index === logRender.items.length - 1}
                  />
                ))}
              </Collapse>
            </div>
          )}
        </Spin>
      </Space>
    </Card>
  );
};

export default ModuleImportLogs;
