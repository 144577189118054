import { useState, useEffect } from 'react';
import { Link, useHistory, Prompt } from 'react-router-dom';
import { CheckCircleOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Controller, FormProvider, useForm, useFieldArray, useFormState, useFormContext } from 'react-hook-form';
import {
  Card,
  Form,
  Typography,
  Row,
  Col,
  Empty,
  Space,
  Alert,
  Button,
  message,
  Tabs,
  Select,
  Tooltip,
  Badge,
} from 'antd';
import useSWR from 'swr';
import { formationRoutes, reportFormRoutes } from '../../../lib/routes';
import { searchInString } from '../../../lib/helpers';
import TextAreaItem from './components/TextAreaItem';
import useFetch from '../../../hooks/useFetch';
import TableItem from './components/TableItem';

const FormContent = ({ error, disabled, fieldName, showInfo }) => {
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({ name: fieldName, control: control });

  const getRecipient = () => {
    switch (fieldName) {
      case 'forms.former':
        return 'FORMER';
      case 'forms.tutor':
        return 'TUTOR';
      case 'forms.student':
        return 'STUDENT';
      default:
        return null;
    }
  };

  const onTextAdd = () => {
    const newText = {
      title: '',
      description: '',
      format: 'TEXTAREA',
      recipient: getRecipient(),
    };

    append(newText);
  };

  const onTableAdd = () => {
    const newTable = {
      title: '',
      description: '',
      format: 'TABLE',
      recipient: getRecipient(),
      fields: [{ title: '', description: '' }],
    };

    append(newTable);
  };

  return (
    <Row gutter={[24, 24]} wrap={false}>
      <Col flex="auto">
        {showInfo && (
          <Alert
            type="info"
            showIcon
            message="Veuillez d'abord saisir les champs type de formulaire et type de bilan."
          />
        )}
        {fields.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Aucune donnée, commencez par ajouter un élément depuis la colonne de droite"
          />
        ) : (
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            {fields.map((item, index) => {
              if (item.format === 'TEXTAREA') {
                return (
                  <TextAreaItem
                    key={item.id}
                    data={{ ...item, index }}
                    onMove={move}
                    onRemove={() => remove(index)}
                    fieldName={fieldName}
                    disabled={disabled}
                  />
                );
              } else if (item.format === 'TABLE') {
                return (
                  <TableItem
                    key={item.id}
                    data={{ ...item, index }}
                    onMove={move}
                    onRemove={() => remove(index)}
                    disabled={disabled}
                    fieldName={fieldName}
                  />
                );
              } else {
                return null;
              }
            })}
          </Space>
        )}
        {error?.message && (
          <Alert message="Vous n'avez ajouté aucun champ" type="error" style={{ marginTop: 24 }} showIcon />
        )}
      </Col>
      <Col flex="none">
        <Space direction="vertical" style={{ position: 'sticky', top: 60 }}>
          <Typography.Text>Éléments disponibles</Typography.Text>
          <Button block icon={<FormOutlined />} style={{ textAlign: 'left' }} onClick={onTextAdd} disabled={disabled}>
            Champ de texte
          </Button>
          <Button
            block
            icon={<CheckCircleOutlined />}
            style={{ textAlign: 'left' }}
            onClick={onTableAdd}
            disabled={disabled}
          >
            Tableau d'évaluation
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

const FormCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [formattedFormations, setFormattedFormations] = useState([]);
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: existingForms } = useSWR(reportFormRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const methods = useForm({ defaultValues: { forms: { student: [], former: [], tutor: [] } } });
  const { errors } = useFormState({ control: methods.control });
  const history = useHistory();
  const { post } = useFetch();
  const formType = methods?.watch('formType');
  const reportType = methods?.watch('reportType');

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.register('forms');
  }, []);

  useEffect(() => {
    let formatted = [];

    if (formType && (['MENSUEL', 'STAGE'].includes(formType) || reportType)) {
      formatted = formations?.data?.map((formation) => {
        const isAlreadyUsed = existingForms?.data?.find(
          (form) =>
            form.formType === formType && form.formations.includes(formation._id) && form.reportType === reportType,
        );

        return { label: formation.title, value: formation._id, disabled: !!isAlreadyUsed };
      });
    } else {
      formatted = formations?.data?.map((formation) => ({ label: formation.title, value: formation._id }));
    }

    setFormattedFormations(formatted);
  }, [formations?.data, existingForms?.data, formType, reportType]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(reportFormRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/formao/formulaires-de-bilans');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const handleFormType = (value, onChange) => {
    if (formType !== value) {
      methods.resetField('forms.student');
      methods.resetField('forms.former');
      methods.resetField('forms.tutor');
      methods.resetField('reportType');
      methods.resetField('formations');
    }

    onChange(value);
  };

  const handleReportType = (value, onChange) => {
    if (value !== reportType) {
      if (formType === 'FORMATION') {
        if (value === 'STAGE') {
          methods.resetField('forms.former');
        }
        if (reportType === 'STAGE') {
          methods.resetField('forms.tutor');
        }
      }

      onChange(value);
    }
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form layout="vertical" noValidate onFinish={methods.handleSubmit(onSubmit)}>
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label="Type de formulaire"
            validateStatus={errors?.formType?.message && 'error'}
            help={errors?.formType?.message}
            required
          >
            <Controller
              name="formType"
              control={methods.control}
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(value) => handleFormType(value, field.onChange)}
                  disabled={isProcessing}
                  placeholder="Sélectionnez dans la liste"
                >
                  <Select.Option value="COMPANY">Bilan de formation en alternance</Select.Option>
                  <Select.Option value="FORMATION">Bilan de formation en continu</Select.Option>
                  <Select.Option value="MENSUEL">Suivi mensuel</Select.Option>
                  <Select.Option value="STAGE">Bilan de stage</Select.Option>
                </Select>
              )}
            />
          </Form.Item>
          {formType && !['MENSUEL', 'STAGE'].includes(formType) && (
            <Form.Item
              label={
                <>
                  Type de bilan&nbsp;
                  <Tooltip title="Type de bilan auquel sera destiné le formulaire.">
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </>
              }
              validateStatus={errors?.reportType?.message && 'error'}
              help={errors?.reportType?.message}
              required
            >
              <Controller
                name="reportType"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(value) => handleReportType(value, field.onChange)}
                    placeholder="Sélectionnez dans la liste"
                    disabled={isProcessing || !formType}
                  >
                    {formType === 'COMPANY' && [
                      <Select.Option key="C_INTEGRATION" value="INTEGRATION">
                        Bilan d'intégration
                      </Select.Option>,
                      <Select.Option key="C_MI_PARCOURS" value="MI_PARCOURS">
                        Bilan mi-parcours
                      </Select.Option>,
                      <Select.Option key="C_FINAL" value="FINAL">
                        Bilan final
                      </Select.Option>,
                      <Select.Option key="C_INTERMEDIAIRE" value="INTERMEDIAIRE">
                        Bilan supplémentaire
                      </Select.Option>,
                    ]}
                    {formType === 'FORMATION' && [
                      <Select.Option key="F_MI_PARCOURS" value="MI_PARCOURS">
                        Bilan mi-parcours
                      </Select.Option>,
                      <Select.Option key="F_FINAL" value="FINAL">
                        Bilan final
                      </Select.Option>,
                    ]}
                  </Select>
                )}
              />
            </Form.Item>
          )}
          <Form.Item
            label={
              <>
                Formation(s) liée(s)&nbsp;
                <Tooltip title="Formations auxquelles sera destiné ce formulaire.">
                  <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                </Tooltip>
              </>
            }
            validateStatus={errors?.formations?.message && 'error'}
            help={errors?.formations?.message}
            required
          >
            <Controller
              name="formations"
              control={methods.control}
              render={({ field }) => (
                <Select
                  {...field}
                  mode="multiple"
                  filterOption={(input, option) => searchInString(input, option.label)}
                  options={formattedFormations || []}
                  disabled={
                    !formations ||
                    isFormationsValidating ||
                    isProcessing ||
                    !formType ||
                    (!['STAGE', 'MENSUEL'].includes(formType) && !reportType)
                  }
                  allowClear
                  placeholder="Sélectionnez dans la liste"
                />
              )}
            />
          </Form.Item>
          <Alert
            type="warning"
            message="Attention ! Une fois la création terminée vous ne pourrez plus modifier le type de formulaire et le type de bilan."
            showIcon
          />
          <Tabs>
            <Tabs.TabPane
              tab={<Badge dot={errors?.forms?.student}>Stagiaire</Badge>}
              key="student"
              disabled={isProcessing || !formType}
            >
              <FormContent
                error={errors?.forms?.student}
                fieldName="forms.student"
                showInfo={!formType || (!['MENSUEL', 'STAGE'].includes(formType) && !reportType)}
                disabled={isProcessing || !formType || (!['MENSUEL', 'STAGE'].includes(formType) && !reportType)}
              />
            </Tabs.TabPane>

            {(formType === 'MENSUEL' ||
              (formType === 'FORMATION' && ['MI_PARCOURS', 'FINAL'].includes(reportType))) && (
              <Tabs.TabPane
                tab={<Badge dot={errors?.forms?.former}>Formateur</Badge>}
                key="former"
                disabled={isProcessing || !formType || (!['MENSUEL', 'STAGE'].includes(formType) && !reportType)}
              >
                <FormContent
                  error={errors?.forms?.former}
                  fieldName="forms.former"
                  showInfo={false}
                  disabled={isProcessing || !formType}
                />
              </Tabs.TabPane>
            )}

            {(formType === 'STAGE' ||
              (formType === 'COMPANY' && reportType) ||
              (formType === 'FORMATION' && reportType === 'STAGE')) && (
              <Tabs.TabPane
                tab={<Badge dot={errors?.forms?.tutor}>Tuteur</Badge>}
                key="tutor"
                disabled={isProcessing || !formType || (!['MENSUEL', 'STAGE'].includes(formType) && !reportType)}
              >
                <FormContent
                  error={errors?.forms?.tutor}
                  fieldName="forms.tutor"
                  showInfo={false}
                  disabled={isProcessing || !formType}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
          <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/formao/formulaires-de-bilans/">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" disabled={isProcessing} loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default FormCreate;
