import { Card, Button, Tabs, Table, Dropdown, Menu, Popconfirm, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { format } from 'date-fns';
import { sort, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import { workshopRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import useColumnSearch from '../../../hooks/useColumnSearch';
import useFetch from '../../../hooks/useFetch';

const WorkshopsList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('enabled');
  const { data: workshops, isValidating, mutate } = useSWR(workshopRoutes.default);
  const enabledWorkshops = useMemo(() => workshops?.data.filter((item) => !item.disabled) || [], [workshops]);
  const disabledWorkshops = useMemo(() => workshops?.data.filter((item) => item.disabled) || [], [workshops]);
  const { getColumnSearchProps } = useColumnSearch();
  const { put, remove } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const enableWorkshop = async (id) => {
    setIsProcessing(true);

    const results = await put(workshopRoutes.default + '/enable/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableWorkshop = async (id) => {
    setIsProcessing(true);

    const results = await put(workshopRoutes.default + '/disable/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const deleteWorkshop = async (id) => {
    setIsProcessing(true);

    const results = await remove(workshopRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = ({ record }) => (
    <Menu>
      {checkAuthorization(user, 'arinfo', 'workshops', 'update') && (
        <Menu.Item className="gray-text">
          <Link to={`/arinfo/ateliers/modifier-un-atelier/${record._id}`}>Modifier</Link>
        </Menu.Item>
      )}
      {activeTab !== 'enabled'
        ? checkAuthorization(user, 'arinfo', 'workshops', 'enable') && (
            <Menu.Item className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => enableWorkshop(record._id)}>
                Activer
              </Popconfirm>
            </Menu.Item>
          )
        : checkAuthorization(user, 'arinfo', 'workshops', 'disable') && (
            <Menu.Item className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => disableWorkshop(record._id)}>
                Désactiver
              </Popconfirm>
            </Menu.Item>
          )}
      {checkAuthorization(user, 'arinfo', 'workshops', 'delete') && (
        <Menu.Item className="gray-text">
          <Popconfirm {...popconfirmProps} onConfirm={() => deleteWorkshop(record._id)}>
            Supprimer
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Code d'identification",
      dataIndex: '_id',
      key: '_id',
      width: 230,
      align: 'center',
      ...getColumnSearchProps('_id'),
    },
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Créé le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    {
      title: 'MàJ le',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'updatedAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (record.createdAt === record.updatedAt ? '/' : format(new Date(value), 'dd/MM/yyyy')),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
          <Button className="gray-text" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'workshops', 'create') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/arinfo/ateliers/nouvel-atelier">Nouvel atelier</Link>
          </Button>
        </div>
      )}
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane key="enabled" tab={`Activés (${enabledWorkshops.length})`}>
          <Table
            loading={!workshops || isValidating || isProcessing}
            dataSource={enabledWorkshops}
            columns={columns}
            bordered
            size="small"
            scroll={{ x: 320 }}
            rowKey={(row) => row._id}
            pagination={tablePagination(enabledWorkshops)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="disabled" tab={`Désactivés (${disabledWorkshops.length})`}>
          <Table
            loading={!workshops || isValidating || isProcessing}
            dataSource={disabledWorkshops}
            columns={columns}
            bordered
            size="small"
            scroll={{ x: 320 }}
            rowKey={(row) => row._id}
            pagination={tablePagination(disabledWorkshops)}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default WorkshopsList;
