import { Card, Button, Form, Tabs, message, Space } from 'antd';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { serialize } from 'object-to-formdata';
import WorkshopEditInfos from './WorkshopEditInfos';
import WorkshopEditDates from './WorkshopEditDates';
import { workshopRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const WorkshopEdit = () => {
  const { id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm();
  const { put } = useFetch();
  const history = useHistory();
  const { data: workshop, isValidating } = useSWR(workshopRoutes.default + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (workshop && workshop.data) {
      const dates = workshop.data.dates.map((item) => ({ ...item, agency: item.agency._id }));

      methods.setValue('title', workshop.data.title);
      methods.setValue('description', workshop.data.description);
      methods.setValue('training', workshop.data.training);
      methods.setValue('duration', workshop.data.duration);
      methods.setValue('visible', workshop.data.disabled === false);
      methods.setValue('dates', dates);
    }
  }, [workshop]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(workshopRoutes.default + '/' + id, formData, 'multipart/form-data');

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(workshopRoutes.default + '/' + id, formData, 'multipart/form-data');

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/arinfo/ateliers');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Tabs>
            <Tabs.TabPane tab="Informations" key="infos">
              <WorkshopEditInfos
                defaultImage={workshop?.data?.cover?.secure_url}
                setBlocking={() => setIsBlocking(true)}
                loading={!workshop || isProcessing || isValidating}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Dates" key="dates">
              <WorkshopEditDates loading={!workshop || isProcessing || isValidating} />
            </Tabs.TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/arinfo/ateliers">Annuler</Link>
            </Button>
            <Space>
              <Button htmlType="submit" onClick={methods.handleSubmit(onSubmit)} loading={isProcessing}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                loading={isProcessing}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default WorkshopEdit;
