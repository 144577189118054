import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import classnames from 'classnames';

const View = ({ updateAttributes, node }) => {
  const toggleOpen = () => {
    updateAttributes({ open: !node.attrs.open });
  };

  return (
    <NodeViewWrapper as="div" className="details-container">
      <span className="details-toggle" contentEditable={false}>
        <button onClick={toggleOpen}>
          {node.attrs.open ? (
            <UpOutlined style={{ color: '#315397' }} />
          ) : (
            <DownOutlined style={{ color: '#315397' }} />
          )}
        </button>
      </span>
      <NodeViewContent className={classnames('details', { 'is-open': node.attrs.open })} />
    </NodeViewWrapper>
  );
};

export default View;
