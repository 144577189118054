import { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { useFormContext, Controller, useFormState, useFieldArray } from 'react-hook-form';
import { Button, Card, Divider, Input, Radio, Space, Table, message } from 'antd';
import Editor from '../../../../ui/Editor';

const getFieldErrors = (errors, fieldName) => {
  switch (fieldName) {
    case 'forms.former':
      return errors?.forms?.former;
    case 'forms.tutor':
      return errors?.forms?.tutor;
    case 'forms.student':
      return errors?.forms?.student;
    default:
      return null;
  }
};

const TableItem = memo(({ data, onMove, onRemove, disabled, fieldName }) => {
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `${fieldName}.${data.index}.fields`,
    control: methods.control,
  });
  const { errors } = useFormState({ control: methods.control });
  const fieldErrors = getFieldErrors(errors, fieldName);
  const ref = useRef(null);
  const previewRef = useRef(null);
  const [, drop] = useDrop({
    accept: 'FIELD',
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = data.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      return onMove(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'FIELD',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;

  drag(ref);
  drop(preview(previewRef));

  const appendItem = () => {
    if (fields.length >= 20) {
      message.error('Vous ne pouvez pas avoir plus de 20 compétences par tableaux');
    } else {
      append({ text: '', description: '' });
    }
  };

  const columns = [
    {
      key: 'label',
      render: (value, record, fieldIndex) => (
        <Space style={{ width: '100%' }} direction="vertical">
          <Input.Group compact>
            <Controller
              name={`${fieldName}.${data.index}.fields.${fieldIndex}.title`}
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ width: 'calc(100% - 32px)' }}
                  placeholder="Compétence *"
                  status={fieldErrors?.[data.index]?.fields?.[fieldIndex]?.title?.message && 'error'}
                  disabled={disabled}
                />
              )}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => remove(fieldIndex)}
              style={fieldErrors?.[data.index]?.fields?.[fieldIndex]?.title?.message && { borderColor: 'red' }}
              disabled={disabled || fields?.length < 2}
            />
          </Input.Group>
          {fieldErrors?.[data.index]?.fields?.[fieldIndex]?.title?.message && (
            <small style={{ color: 'red' }}>{fieldErrors?.[data.index]?.fields?.[fieldIndex]?.title?.message}</small>
          )}
          <Controller
            name={`${fieldName}.${data.index}.fields.${fieldIndex}.description`}
            control={methods.control}
            render={({ field }) => (
              <Editor
                {...field}
                minHeight={100}
                disabled={disabled}
                options={{
                  placeholder: { placeholder: 'Description de la compétence (optionnel)' },
                  heading: false,
                  figure: false,
                  strike: false,
                  color: false,
                  important: false,
                  highlight: false,
                  code: false,
                  codeBlock: false,
                  youtube: false,
                  vimeo: false,
                  cloudinaryVideo: false,
                  blockquote: false,
                  file: false,
                  italic: false,
                  bulletList: false,
                  orderedList: false,
                  table: false,
                  grid: false,
                  details: false,
                  textAlign: false,
                }}
              />
            )}
          />
          {fieldErrors?.[data.index]?.fields?.[fieldIndex]?.description?.message && (
            <small style={{ color: 'red' }}>
              {fieldErrors?.[data.index]?.fields?.[fieldIndex]?.description?.message}
            </small>
          )}
        </Space>
      ),
    },
    {
      title: '-',
      align: 'center',
      width: 70,
      key: 'less',
      render: () => <Radio style={{ margin: 0 }} checked={false} disabled={disabled} />,
    },
    {
      title: '+/-',
      align: 'center',
      width: 70,
      key: 'more-less',
      render: () => <Radio style={{ margin: 0 }} checked={false} disabled={disabled} />,
    },
    {
      title: '+',
      align: 'center',
      width: 70,
      key: 'more',
      render: () => <Radio style={{ margin: 0 }} checked={false} disabled={disabled} />,
    },
    {
      title: '++',
      align: 'center',
      width: 70,
      key: 'more-more',
      render: () => <Radio style={{ margin: 0 }} checked={false} disabled={disabled} />,
    },
  ];

  return (
    <div ref={previewRef} style={{ opacity }}>
      <Card
        bordered
        size="small"
        title="Tableau d'évaluation"
        headStyle={{ background: '#f0f0f0' }}
        extra={
          <Space>
            <Button icon={<DragOutlined />} ref={ref} disabled={disabled} />
            <Button key="delete" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />
          </Space>
        }
      >
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Controller
            name={`${fieldName}.${data.index}.title`}
            control={methods.control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Question *"
                style={{ fontWeight: 700 }}
                disabled={disabled}
                status={fieldErrors?.[data.index]?.title?.message && 'error'}
              />
            )}
          />
          {fieldErrors?.[data.index]?.title?.message && (
            <small style={{ color: 'red' }}>{fieldErrors?.[data.index]?.title?.message}</small>
          )}
          <Controller
            name={`${fieldName}.${data.index}.description`}
            control={methods.control}
            render={({ field }) => (
              <Editor
                {...field}
                minHeight={100}
                disabled={disabled}
                options={{
                  placeholder: { placeholder: 'Description de la question (optionnel)' },
                  heading: false,
                  figure: false,
                  strike: false,
                  color: false,
                  important: false,
                  highlight: false,
                  code: false,
                  codeBlock: false,
                  youtube: false,
                  vimeo: false,
                  cloudinaryVideo: false,
                  blockquote: false,
                  file: false,
                  italic: false,
                  bulletList: false,
                  orderedList: false,
                  table: false,
                  grid: false,
                  details: false,
                  textAlign: false,
                }}
              />
            )}
          />
          {fieldErrors?.[data.index]?.description?.message && (
            <small style={{ color: 'red' }}>{fieldErrors?.[data.index]?.description?.message}</small>
          )}
          <Divider />
          <Table
            disabled={disabled}
            bordered
            size="small"
            dataSource={fields || []}
            rowKey={(row) => row.id}
            columns={columns}
            pagination={false}
            style={fieldErrors?.[data.index]?.fields?.message && { border: '1px solid red', borderRadius: 2 }}
          />
          {fieldErrors?.[data.index]?.fields?.message && (
            <small style={{ color: 'red' }}>{fieldErrors?.[data.index]?.fields?.message}</small>
          )}
          <div style={{ textAlign: 'right' }}>
            <Button onClick={appendItem} disabled={disabled || fields.length >= 20}>
              Ajouter une ligne
            </Button>
          </div>
        </Space>
      </Card>
    </div>
  );
});

TableItem.displayName = 'TableItem';
export default TableItem;
