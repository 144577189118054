import { memo, useState, useEffect } from 'react';
import { Button, Modal, Select, message, Typography } from 'antd';
import useSWR from 'swr';
import { agencyRoutes, documentRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const AgenciesModal = memo(({ documentId, onFinish, initialValue }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const { data: agencies, isValidating } = useSWR(isOpen ? agencyRoutes.default : null);
  const { put } = useFetch();

  useEffect(() => {
    if (isOpen) {
      setValue(initialValue);
    }
  }, [isOpen]);

  const handleSave = async () => {
    setIsProcessing(true);

    const results = await put(documentRoutes.default + '/' + documentId, JSON.stringify({ agencies: value }));

    if (results.status === 200) {
      onFinish();
      setIsOpen(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Button type="link" onClick={() => setIsOpen(true)}>
        {initialValue.length === 0
          ? 'Aucun centre sélectionné'
          : `${initialValue.length} centre${initialValue.length > 1 ? 's' : ''} sélectionné${
              initialValue.length > 1 ? 's' : ''
            }`}
      </Button>
      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={handleSave}
        okButtonProps={{ loading: isProcessing, disabled: !agencies || isProcessing || isValidating }}
        cancelButtonProps={{ disabled: !agencies || isProcessing || isValidating }}
        title={
          value.length === 0
            ? 'Aucun centre sélectionné'
            : `${value.length} centre${value.length > 1 ? 's' : ''} sélectionné${value.length > 1 ? 's' : ''}`
        }
      >
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Laisser vide pour que le document soit accessible dans tous les centres</li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Select
          disabled={!agencies || isProcessing || isValidating}
          loading={isValidating}
          placeholder="Sélectionnez dans la liste"
          allowClear
          style={{ width: '100%' }}
          mode="multiple"
          value={value}
          onChange={(newValue) => setValue(newValue)}
          options={
            agencies?.data.map((agency) => ({
              label: agency.city,
              value: agency._id,
            })) || []
          }
        />
      </Modal>
    </>
  );
});

AgenciesModal.displayName = 'AgenciesModal';
export default AgenciesModal;
