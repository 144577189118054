import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { aiRoutes } from '../../../../lib/routes';

const NewApiKeyModal = memo(({ id, ai, onFinish }) => {
  const [visible, setVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: { name: '', value: '', restrictions: [] },
  });
  const { errors } = useFormState({ control });
  const { data: versions, isValidating } = useSWR(aiRoutes.default + '/' + id + '/versions', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { post } = useFetch();

  useEffect(() => {
    if (visible) {
      reset();
      setIsProcessing(false);
    }
  }, [visible]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(aiRoutes.keys, JSON.stringify({ ...form, ai: id.toString() }));

    if (results.status === 201) {
      setVisible(false);
      reset();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Button size="small" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        Nouvelle clé API
      </Button>
      <Modal
        visible={visible}
        title={`Nouvelle clé API pour ${ai}`}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{ loading: isProcessing }}
        cancelButtonProps={{ disabled: isProcessing }}
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item
            label="Intitulé (10 caractères max)"
            required
            validateStatus={errors?.name?.message && 'error'}
            help={errors?.name?.message}
          >
            <Controller {...{ control }} name="name" render={({ field }) => <Input {...field} maxLength={10} />} />
          </Form.Item>
          <Form.Item
            label="Clé"
            required
            validateStatus={errors?.value?.message && 'error'}
            help={errors?.value?.message}
          >
            <Controller {...{ control }} name="value" render={({ field }) => <Input.Password {...field} />} />
          </Form.Item>
          <Form.Item
            label="Restrictions"
            tooltip="Seulement les versions sélectionnées seront utilisables avec cette clé. Laissez vide pour n'appliquer aucune restriction."
            validateStatus={errors?.restrictions?.message && 'error'}
            help={errors?.restrictions?.message}
          >
            <Controller
              {...{ control }}
              name="restrictions"
              render={({ field }) => (
                <Select
                  {...field}
                  mode="multiple"
                  options={versions?.data?.map((item) => ({ label: item.name, value: item._id }))}
                  loading={isValidating}
                  placeholder="Sélectionnez dans la liste"
                />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

NewApiKeyModal.displayName = 'NewApiKeyModal';
export default NewApiKeyModal;
