import { useState, useEffect } from 'react';
import { Card, Input, Form, Row, Col, Button, Upload, message, Select, Typography, Tabs, Switch } from 'antd';
import { useForm, useFormState, Controller, FormProvider } from 'react-hook-form';
import { Link, useHistory, Prompt } from 'react-router-dom';
import Editor from '../../../../ui/Editor';
import { serialize } from 'object-to-formdata';
import { imaginelabWorkshopRoutes } from '../../../../lib/routes';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import useFetch from '../../../../hooks/useFetch';
import DatesCalendar from './DatesCalendar';
import placeholder from '../../../../assets/images/placeholder-image.jpg';
import '../editor.css';

const themes = ['Fabrique à BD', "Anim'Studio", 'ElektroLab', 'Robot Factory', 'Game Makers', 'Créa 3D'];

const WorkshopCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  const [tab, setTab] = useState('workshop');
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { user } = useAuth();
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await post(imaginelabWorkshopRoutes.create, formData, 'multipart/form-data');

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/imaginelab/ateliers');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onImageChange = ({ file }, onChange) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 2) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 2Mo');
    } else {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
        onChange(file);
      });

      reader.readAsDataURL(file);
    }
  };

  const onImageDelete = () => {
    setImagePreview(null);
    methods.setValue('image', null);
  };

  const onTagAdd = (value, onChange) => {
    if (value.length < 6) {
      onChange(value);
    } else {
      message.error('Veuillez saisir 5 mots clés maximum');
    }
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Tabs defaultActiveKey="workshop" activeKey={tab} onChange={setTab}>
            <Tabs.TabPane tab="Atelier" key="workshop">
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    label="Intitulé"
                    validateStatus={errors?.title && 'error'}
                    help={errors?.title?.message}
                    required
                  >
                    <Controller
                      name="title"
                      control={methods.control}
                      render={({ field }) => <Input maxLength={110} {...field} disabled={isProcessing} />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Thématique"
                    validateStatus={errors?.title && 'error'}
                    help={errors?.title?.message}
                    required
                  >
                    <Controller
                      name="theme"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          disabled={!themes || isProcessing}
                          placeholder="Sélectionnez dans la liste"
                          options={themes.map((theme) => ({
                            label: theme,
                            value: theme,
                          }))}
                        />
                      )}
                    />
                  </Form.Item>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Form.Item
                        label="Durée"
                        validateStatus={errors?.duration && 'error'}
                        help={errors?.duration?.message}
                        required
                      >
                        <Controller
                          name="duration"
                          control={methods.control}
                          render={({ field }) => <Input {...field} placeholder="2h40" disabled={isProcessing} />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Tarif / pers."
                        validateStatus={errors?.price && 'error'}
                        help={errors?.price?.message}
                        required
                      >
                        <Controller
                          name="price"
                          control={methods.control}
                          render={({ field }) => (
                            <Input {...field} placeholder="50" disabled={isProcessing} suffix="€" />
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {(checkAuthorization(user, 'imaginelab', 'workshops', 'enable-workshop') ||
                    checkAuthorization(user, 'imaginelab', 'workshops', 'disable-workshop')) && (
                    <Form.Item label="Actif" required>
                      <Controller
                        name="disabled"
                        control={methods.control}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            checked={!field.value}
                            maxLength={50}
                            onChange={(value) => {
                              const canAccess = field.value
                                ? checkAuthorization(user, 'imaginelab', 'workshops', 'enable-workshop')
                                : checkAuthorization(user, 'imaginelab', 'workshops', 'disable-workshop');

                              if (canAccess) {
                                field.onChange(!value);
                              }
                            }}
                            disabled={
                              isProcessing ||
                              (field.value
                                ? !checkAuthorization(user, 'imaginelab', 'workshops', 'enable-workshop')
                                : !checkAuthorization(user, 'imaginelab', 'workshops', 'disable-workshop'))
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Image de couverture"
                    validateStatus={errors?.image && 'error'}
                    help={errors?.image?.message}
                    required
                  >
                    <Controller
                      name="image"
                      control={methods.control}
                      render={({ field }) => (
                        <Card
                          className="imaginelab-workshops-image-card"
                          bodyStyle={{ padding: 0 }}
                          cover={
                            imagePreview ? (
                              <img
                                className="imaginelab-workshops-image-card-img"
                                src={imagePreview}
                                alt="Image de couverture"
                              />
                            ) : (
                              <img
                                className="imaginelab-workshops-image-card-placeholder"
                                src={placeholder}
                                alt="Image de couverture"
                              />
                            )
                          }
                          actions={[
                            ...(imagePreview
                              ? [
                                  <Button type="link" disabled={isProcessing} onClick={onImageDelete}>
                                    Supprimer
                                  </Button>,
                                ]
                              : []),
                            <Upload
                              name={field.name}
                              maxCount={1}
                              showUploadList={false}
                              accept="image/*"
                              disabled={isProcessing}
                              beforeUpload={() => false}
                              onChange={(img) => onImageChange(img, field.onChange)}
                            >
                              <Button type="link" disabled={isProcessing}>
                                {imagePreview ? 'Remplacer' : 'Ajouter'}
                              </Button>
                            </Upload>,
                          ]}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  validateStatus={errors?.description && 'error'}
                  help={errors?.description?.message}
                  required
                >
                  <Controller
                    name="description"
                    control={methods.control}
                    render={({ field }) => (
                      <Editor
                        {...field}
                        minHeight={300}
                        disabled={isProcessing}
                        className="editor-imaginelab-workshop"
                        options={{
                          heading: false,
                          figure: false,
                          strike: false,
                          color: false,
                          important: false,
                          highlight: false,
                          code: false,
                          codeBlock: false,
                          link: false,
                          blockquote: false,
                          file: false,
                          table: false,
                          details: false,
                          textAlign: false,
                        }}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Dates" key="dates">
              <DatesCalendar loading={isProcessing} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="SEO" key="seo" forceRender>
              <Col span={12}>
                <Form.Item
                  label="Description rapide (160 caractères)"
                  validateStatus={errors?.seo_description && 'error'}
                  help={errors?.seo_description?.message}
                  required
                >
                  <Controller
                    name="seo_description"
                    control={methods.control}
                    render={({ field }) => <Input {...field} disabled={isProcessing} maxLength={160} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Mots clés (5 maximum)"
                  validateStatus={errors?.seo_tags && 'error'}
                  help={errors?.seo_tags?.message}
                  required
                >
                  <Controller
                    name="seo_tags"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        mode="tags"
                        {...field}
                        disabled={isProcessing}
                        onChange={(value) => onTagAdd(value, field.onChange)}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Tabs.TabPane>
          </Tabs>
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/imaginelab/ateliers">Annuler</Link>
            </Button>

            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default WorkshopCreate;
