import { Card, Table, Tooltip, Button, Descriptions, Select, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { useState } from 'react';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { workshopRegistrationRoutes, agencyRoutes, workshopRoutes } from '../../../../lib/routes';
import tablePagination from '../../../../lib/tablePagination';
import { sort, checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import useFetch from '../../../../hooks/useFetch';
import useColumnSearch from '../../../../hooks/useColumnSearch';

const getDate = (record) => {
  const dates = record?.workshop?.dates;

  if (!dates) {
    return null;
  }

  const date = dates.find((item) => item._id === record.date);

  if (!date) {
    return null;
  }

  if (!date.agency) {
    return null;
  }

  return date;
};

const WorkshopRegistrations = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { data: registrations, isValidating, mutate } = useSWR(workshopRegistrationRoutes.default + '/search');
  const { data: agencies } = useSWR(agencyRoutes.default);
  const { data: workshops } = useSWR(workshopRoutes.default);
  const { patch } = useFetch();
  const { user } = useAuth();

  const setStatus = async (record, newState) => {
    setIsProcessing(true);

    const results = await patch(
      workshopRegistrationRoutes.default + '/' + record._id,
      JSON.stringify({ state: newState }),
    );

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Atelier',
      dataIndex: 'workshop',
      key: 'workshop',
      width: 200,
      filters: workshops?.data.map((workshop) => ({ text: workshop.title, value: workshop._id })) || [],
      onFilter: (value, record) => record.workshop?._id === value,
      render: (value, record) => {
        if (!value?._id) return 'Indisponible';
        const date = getDate(record);

        if (date === null) return 'Indisponible';

        return (
          <Button size="small" type="link" onClick={() => setSelectedDate({ ...record, date })}>
            {value?._id}
          </Button>
        );
      },
    },
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 200,
      sorter: (a, b) => sort(a, b, 'last_name'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => value.toUpperCase(),
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 200,
      sorter: (a, b) => sort(a, b, 'first_name'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1),
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Centre',
      key: 'agency',
      width: 200,
      filters: agencies?.data.map((agency) => ({ text: agency.city, value: agency._id })) || [],
      onFilter: (value, record) => {
        const date = getDate(record);

        if (!date) return null;

        return date.agency._id === value;
      },
      render: (record) => {
        const date = getDate(record);

        if (!date) return '';

        return date.agency.city;
      },
    },
    {
      title: "Date d'envoi",
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    ...(checkAuthorization(user, 'arinfo', 'workshops', 'update-registration')
      ? [
          {
            title: 'Statut',
            dataIndex: 'state',
            key: 'state',
            width: 150,
            filters: [
              { text: 'Confirmée', value: 'Confirmée' },
              { text: 'Refusée', value: 'Refusée' },
              { text: 'Non-traitée', value: 'Non-traitée' },
            ],
            onFilter: (value, record) => record.state === value,
            render: (value, record) => (
              <Select
                style={{ width: '100%' }}
                value={value}
                onChange={(newValue) => setStatus(record, newValue)}
                options={[
                  { label: 'Confirmée', value: 'Confirmée' },
                  { label: 'Refusée', value: 'Refusée' },
                  { label: 'Non-traitée', value: 'Non-traitée' },
                ]}
              />
            ),
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Tooltip title="Envoyer un email" destroyTooltipOnHide={{ keepParent: false }}>
          <a href={`mailto:${record.email}`}>
            <Button className="gray-text" icon={<MailOutlined />} />
          </a>
        </Tooltip>
      ),
    },
  ];

  return (
    <Card>
      <Modal
        footer={false}
        visible={!!selectedDate}
        onCancel={() => setSelectedDate(null)}
        title={selectedDate?.workshop?.title || ''}
      >
        {selectedDate !== null && (
          <Descriptions>
            <Descriptions.Item label="Durée" span={3}>
              {selectedDate.workshop?.duration || 'Indisponible'}
            </Descriptions.Item>
            <Descriptions.Item label="Date" span={3}>
              {format(new Date(selectedDate.date.start), 'dd/MM/yyyy HH:mm', { locale: fr })}
            </Descriptions.Item>
            <Descriptions.Item label="Centre" span={3}>
              {selectedDate.date.agency.city}
            </Descriptions.Item>
            <Descriptions.Item label="Distanciel disponible" span={3}>
              {selectedDate.date.remote ? 'Oui' : 'Non'}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
      <Table
        loading={!registrations || isValidating || isProcessing}
        dataSource={registrations?.data || []}
        bordered
        size="small"
        rowKey={(row) => row._id}
        columns={columns}
        pagination={tablePagination(registrations?.data)}
        rowClassName={(record) => {
          if (record.state === 'Confirmée') return 'ant-table-row-success';
          if (record.state === 'Refusée') return 'ant-table-row-danger';
          return 'ant-table-row-warning';
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Descriptions>
              <Descriptions.Item label="Adresse email" span={3}>
                {record.email}
              </Descriptions.Item>
              <Descriptions.Item label="Téléphone" span={3}>
                {record.phone.match(/.{2}/g).join(' ')}
              </Descriptions.Item>
            </Descriptions>
          ),
        }}
      />
    </Card>
  );
};

export default WorkshopRegistrations;
