/* eslint-disable */
import axios from "axios";

const imageUploadURL = `${process.env.REACT_APP_BASE_URL_API_URL}/global/images/upload`;

export const imageUpload = (token) => ({
  // image_dimensions: false,
  file_picker_types: "image",
  content_style: "img {max-width: 100%;height: auto;}",
  file_picker_callback(cb, value, meta) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = (event) => {
      const { files } = event.target;

      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const id = `blobid${new Date().getTime()}`;
        const { blobCache } = tinymce.activeEditor.editorUpload;
        const base64 = reader.result.split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      };

      reader.readAsDataURL(file);
    };

    input.click();
  },
  images_upload_handler(blobInfo, success, failure) {
    const formData = new FormData();
    formData.append("image", blobInfo.blob(), blobInfo.filename());

    axios
      .post(imageUploadURL, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        success(data.data.secure_url);
      })
      .catch((error) => {
        tinymce.activeEditor.undoManager.undo();
        failure(error.response.data.message);
      });
  },
});
