import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Input, Space, Select, Row, Col } from 'antd';
import { memo } from 'react';
import { useFormContext, Controller, useFormState } from 'react-hook-form';

const options = [
  { label: '4 niveaux', value: 4 },
  { label: '6 niveaux', value: 6 },
];

const LevelItem = memo(({ index, onRemove, disabled }) => {
  const methods = useFormContext();
  const { errors } = useFormState({ control: methods.control });

  return (
    <Card
      bordered
      size="small"
      title="Question à échelle"
      headStyle={{ background: '#f0f0f0' }}
      className="evaluation-level"
      extra={<Button key="delete" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />}
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Controller
          name={`form.${index}.title`}
          control={methods.control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Question"
              style={{ fontWeight: 700 }}
              disabled={disabled}
              status={errors?.form?.[index]?.title?.message && 'error'}
            />
          )}
        />
        <Controller
          name={`form.${index}.description`}
          control={methods.control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Description (optionnelle)"
              disabled={disabled}
              status={errors?.form?.[index]?.description?.message && 'error'}
            />
          )}
        />
      </Space>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Controller
            name={`form.${index}.labels.from`}
            control={methods.control}
            render={({ field }) => <Input {...field} placeholder="Libellé (optionnel)" disabled={disabled} />}
          />
        </Col>
        <Col span={8}>
          <Controller
            name={`form.${index}.levels`}
            control={methods.control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                style={{ width: '100%' }}
                disabled={disabled}
                status={errors?.form?.[index]?.levels?.message && 'error'}
              />
            )}
          />
        </Col>
        <Col span={8}>
          <Controller
            name={`form.${index}.labels.to`}
            control={methods.control}
            render={({ field }) => <Input {...field} placeholder="Libellé (optionnel)" disabled={disabled} />}
          />
        </Col>
      </Row>
    </Card>
  );
});

LevelItem.displayName = 'LevelItem';
export default LevelItem;
