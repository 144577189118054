import { Form, Drawer, Button, Space, message, Tabs, Typography, Badge } from 'antd';
import { useState, useEffect, memo } from 'react';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { eventRoutes } from '../../../../../lib/routes';
import useFetch from '../../../../../hooks/useFetch';
import EditDrawerMeta from './EditDrawerMeta';
import EditDrawerConstraint from './EditDrawerConstraint';
import useSWR from 'swr';

const { TabPane } = Tabs;

const TabPaneTitle = memo(({ children, errorFields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const tabErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

  return (
    <Badge
      dot={tabErrors.length !== 0}
      status="error"
      title={`${tabErrors.length} erreur${tabErrors.length > 1 ? 's' : ''}`}
    >
      {children}
    </Badge>
  );
});

const EditDrawer = ({ visible, onClose, onFinish, eventId }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm({ defaultValues: { should_return: false } });
  const { data: event, isValidating } = useSWR(visible && eventId ? eventRoutes.unique + '/' + eventId : null);
  const { put } = useFetch();

  useEffect(() => {
    if (event?.data) {
      methods.setValue('title', event?.data.title);
      methods.setValue('description', event?.data.description);
      methods.setValue('start', new Date(event?.data.start));
      methods.setValue('end', new Date(event?.data.end));
      methods.setValue('agencies', event?.data.agencies);
      methods.setValue('students', event?.data.students);
      methods.setValue('groups', event?.data.groups);
    }
  }, [event]);

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(eventRoutes.default + '/' + eventId, JSON.stringify(form));

    if (results.status === 201) {
      handleClose();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Drawer
      size="large"
      placement="right"
      title={event?.data.title}
      destroyOnClose
      visible={visible}
      onClose={handleClose}
      footer={
        <Space>
          <Button
            type="primary"
            onClick={methods.handleSubmit(onSubmit)}
            loading={isProcessing}
            disabled={!event || isProcessing || isValidating}
          >
            Enregistrer
          </Button>
          <Button onClick={handleClose} disabled={!event || isProcessing || isValidating}>
            Annuler
          </Button>
        </Space>
      }
    >
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Tabs defaultActiveKey="students">
            <TabPane tab={<TabPaneTitle errorFields={['title', 'start', 'end']}>Évènement</TabPaneTitle>} key="event">
              <EditDrawerMeta
                disabled={!event || isProcessing || isValidating}
                eventId={eventId}
                onDelete={() => {
                  onClose();
                  onFinish();
                }}
              />
            </TabPane>
            <TabPane tab="Restrictions" key="contraint">
              <EditDrawerConstraint disabled={!event || isProcessing || isValidating} />
            </TabPane>
          </Tabs>
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export default EditDrawer;
