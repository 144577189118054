import { useState, useRef, useCallback } from 'react';
import { useFormContext, useFormState, useFieldArray } from 'react-hook-form';
import { Button, Space, Tooltip, Collapse, Popconfirm } from 'antd';
import { DragOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDrop, useDrag } from 'react-dnd';
import StudentEditModules from './StudentEditModules';
import StudentEditTitle from './StudentEditTitle';

const { Panel } = Collapse;

const StudentEditUnits = ({ formationIndex, disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const {
    fields: units,
    append,
    remove,
    move,
  } = useFieldArray({ control, name: `formations[${formationIndex}].units` });
  const [keysOpen, setKeysOpen] = useState([]);
  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const DraggableCard = ({ unit, index }) => {
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: 'CHAPTER',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        move(dragIndex, hoverIndex);
        forceUpdate();
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'CHAPTER',
      item: { id: unit.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <div ref={previewRef} style={{ opacity }}>
        <Collapse activeKey={keysOpen} onChange={setKeysOpen} className="student-collapse">
          <Panel
            key={unit._id || unit.id}
            header={
              <div
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                <StudentEditTitle fieldName={`formations.${formationIndex}.units.${index}.title`} />
              </div>
            }
            style={{ marginBottom: 20, padding: 0 }}
            className="drag-collapse"
            extra={
              <Space>
                <Tooltip title="Déplacer le chapitre" key="move" placement="left">
                  <Button ref={ref} data-handler-id={handlerId} icon={<DragOutlined />} disabled={disabled} />
                </Tooltip>
                <Tooltip title="Supprimer le chapitre" key="delete" placement="right">
                  <Popconfirm
                    {...popconfirmProps}
                    onConfirm={() => {
                      remove(index);
                    }}
                  >
                    <Button onClick={(e) => e.stopPropagation()} icon={<DeleteOutlined />} disabled={disabled} />
                  </Popconfirm>
                </Tooltip>
              </Space>
            }
          >
            <StudentEditModules
              disabled={disabled}
              formationIndex={formationIndex}
              unitIndex={index}
              onUpdate={forceUpdate}
            />
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
      {units.map((unit, index) => (
        <DraggableCard key={unit.id} unit={unit} index={index} />
      ))}
      <Button block disabled={disabled} onClick={() => append({ title: 'Nouvelle unité', modules: [] })} type="primary">
        Ajouter une unité
      </Button>
    </Space>
  );
};

export default StudentEditUnits;
