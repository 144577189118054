import { useFormContext, Controller } from 'react-hook-form';
import { Input, Button } from 'antd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { useRef, memo } from 'react';
import { useDrag } from 'react-dnd';

const { Group } = Input;

const OrganizeLabel = memo(({ data, fieldName, loading }) => {
  const ref = useRef(null);
  const previewRef = useRef(null);
  const { control } = useFormContext();
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'LABEL',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(ref);
  preview(previewRef);

  return (
    <div ref={previewRef} style={{ opacity }}>
      <Group compact>
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Input {...field} disabled={loading} style={{ width: 'calc(100% - 64px)', height: 32 }} />
          )}
        />
        <Button disabled={loading} icon={<DragOutlined />} ref={ref} />
        <Button disabled={loading} icon={<DeleteOutlined />} onClick={data.remove} />
      </Group>
    </div>
  );
});

OrganizeLabel.displayName = 'OrganizeLabel';
export default OrganizeLabel;
