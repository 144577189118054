import { useFormContext, Controller, useFormState } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Form } from 'antd';

const { Group } = Input;
const { Item } = Form;

const LegendsBox = ({ side, loading, removeColumn, index }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
    cancelButtonProps: { disabled: loading },
  };

  return (
    <Item
      validateStatus={errors?.legends?.[index]?.label?.content?.message && 'error'}
      help={errors?.legends?.[index]?.label?.content?.message}
    >
      <Group className="listsbox-container" compact>
        {side === 'left' && (
          <Popconfirm {...popconfirmProps} onConfirm={removeColumn}>
            <Button icon={<DeleteOutlined />} disabled={loading} />
          </Popconfirm>
        )}
        <Controller
          name={`legends.${index}.label.content`}
          control={control}
          render={({ field }) => (
            <Input {...field} disabled={loading} style={{ width: 'calc(100% - 32px)', height: 32 }} />
          )}
        />
        {side === 'right' && (
          <Popconfirm {...popconfirmProps} onConfirm={removeColumn}>
            <Button icon={<DeleteOutlined />} disabled={loading} />
          </Popconfirm>
        )}
      </Group>
    </Item>
  );
};

export default LegendsBox;
