import { useFormContext, Controller } from 'react-hook-form';
import { Typography } from 'antd';
import { memo } from 'react';

const ModuleEditTitle = memo(({ fieldName }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <Typography.Text editable={{ onChange: field.onChange, tooltip: 'Renommer' }}>{field.value}</Typography.Text>
      )}
    />
  );
});

ModuleEditTitle.displayName = 'ModuleEditTitle';
export default ModuleEditTitle;
