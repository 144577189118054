const eventValidation = (data) => {
  const errors = {};

  if (!data.start) {
    errors.start = 'Ce champ est requis';
  }

  if (!data.agency) {
    errors.agency = 'Ce champ est requis';
  }

  return errors;
};

export default eventValidation;
