import {
  DeleteOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  MergeCellsOutlined,
  RetweetOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';

export const table = [
  {
    key: 'toggle-cell-type',
    label: 'Changer le format de la cellule',
    icon: <RetweetOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().toggleHeaderCell().run();
    },
  },
  {
    type: 'divider',
  },
  {
    key: 'merge',
    label: 'Fusionner la sélection',
    icon: <MergeCellsOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().mergeCells().run();
    },
  },
  {
    key: 'split',
    label: 'Séparer la cellule',
    icon: <SplitCellsOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().splitCell().run();
    },
  },
  {
    type: 'divider',
  },
  {
    key: 'add-col-left',
    label: 'Ajouter une colonne à gauche',
    icon: <InsertRowLeftOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().addColumnBefore().run();
    },
  },
  {
    key: 'add-col-right',
    label: 'Ajouter une colonne à droite',
    icon: <InsertRowRightOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().addColumnAfter().run();
    },
  },
  {
    key: 'delete-col',
    label: 'Supprimer la colonne',
    icon: <DeleteOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().deleteColumn().run();
    },
  },
  {
    type: 'divider',
  },
  {
    key: 'add-row-above',
    label: 'Ajouter une rangée au dessus',
    icon: <InsertRowAboveOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().addRowBefore().run();
    },
  },
  {
    key: 'add-row-below',
    label: 'Ajouter une rangée en dessous',
    icon: <InsertRowBelowOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().addRowAfter().run();
    },
  },
  {
    key: 'delete-row',
    label: 'Supprimer la rangée',
    icon: <DeleteOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().deleteRow().run();
    },
  },
  {
    type: 'divider',
  },
  {
    key: 'delete-table',
    label: 'Supprimer le tableau',
    icon: <DeleteOutlined />,
    onClick: (_, editor) => {
      editor.chain().focus().deleteTable().run();
    },
  },
];
