import { memo, useEffect } from 'react';
import ViewSDKClient from '../../../../lib/adobePdfViewer';

const PdfView = memo(({ data }) => {
  useEffect(() => {
    const viewSDKClient = new ViewSDKClient();

    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        'adobe-pdf-embed',
        {
          defaultViewMode: 'FIT_WIDTH',
          showZoomControl: true,
          showFullScreen: true,
          showAnnotationTools: false,
          enableFormFilling: false,
          showDownloadPDF: false,
          showPrintPDF: false,
          showBookmarks: false,
          showThumbnails: false,
        },
        {
          name: data.name,
          url: `${process.env.REACT_APP_BASE_URL_API_URL}/public/${data.name}.pdf`,
        },
      );
    });
  }, []);

  return (
    <div
      id="adobe-pdf-embed"
      style={{
        width: '100%',
        height: 600,
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        border: 'none',
        zIndex: 200,
      }}
    />
  );
});

export default PdfView;
