import React, { useState } from 'react';
import { Table, Button, Space, Tooltip, message, Popconfirm, Card } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import tablePagination from '../../../lib/tablePagination';
import StatisticsExpanded from './components/StatisticsExpanded';
import { statisticRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const StatisticsList = () => {
  const { data: statistics, isValidating, mutate } = useSWR(statisticRoutes.default);
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const { remove } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(statisticRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Centre',
      key: 'agency',
      render: (record) => (record.agency ? record.agency.city : record.title),
    },
    {
      title: 'Mise à jour le',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      width: 100,
      align: 'center',
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'arinfo', 'statistics', 'update-statistics') && (
            <Tooltip title="Modifier" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/arinfo/statistiques/modifier-les-statistiques/${record._id}`}>
                <Button className="gray-text" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          )}
          {checkAuthorization(user, 'arinfo', 'statistics', 'delete-statistics') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'statistics', 'create-statistics') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/arinfo/statistiques/nouvelles-statistiques">Nouvelles statistiques</Link>
          </Button>
        </div>
      )}
      <Table
        bordered
        size="small"
        columns={columns}
        loading={!statistics || isValidating || isProcessing}
        dataSource={statistics?.data || []}
        rowKey={(row) => row._id}
        className="table-expanded-paddingless"
        expandable={{
          expandedRowRender: (record) => (
            <StatisticsExpanded values={record?.values || []} learning={record?.learning || []} />
          ),
        }}
        scroll={{ x: 992 }}
        pagination={tablePagination(statistics?.data)}
      />
    </Card>
  );
};

export default StatisticsList;
