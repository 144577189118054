import { createContext, useContext, useCallback, useMemo, useReducer } from 'react';
import FilesModal from './index';

const initialState = {
  isOpen: false,
  selection: [],
  onSelect: () => {},
  setSelection: () => {},
  openFilesModal: () => {},
  closeFilesModal: () => {},
};

const FilesModalContext = createContext(initialState);

const filesModalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, isOpen: true };
    case 'OPEN_MODAL_WITH_SELECTION':
      return { ...state, selection: action.selection };
    case 'CLOSE_MODAL':
      return { ...state, isOpen: false };
    case 'HANDLE_SELECTION':
      let newSelection = [...state.selection];

      if (newSelection.includes(action.id)) {
        const index = newSelection.indexOf(action.id);
        newSelection.splice(index, 1);
      } else {
        newSelection.push(action.id);
      }

      return { ...state, selection: newSelection };
    default:
      return state;
  }
};

export const FilesModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filesModalReducer, initialState);

  const openFilesModal = useCallback(() => dispatch({ type: 'OPEN_MODAL' }), [dispatch]);

  const openFilesModalWithSelection = useCallback(
    (selection) => dispatch({ type: 'OPEN_MODAL_WITH_SELECTION', selection }),
    [dispatch],
  );

  const closeFilesModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch]);

  const handleSelection = useCallback((id) => dispatch({ type: 'HANDLE_SELECTION', id }), [dispatch]);

  const isOpen = useMemo(() => state.isOpen, [state.isOpen]);

  const selection = useMemo(() => state.selection, [state.selection]);

  const value = useMemo(
    () => ({ isOpen, selection, handleSelection, openFilesModal, openFilesModalWithSelection, closeFilesModal }),
    [isOpen, selection, handleSelection, openFilesModal, openFilesModalWithSelection, closeFilesModal],
  );

  return (
    <FilesModalContext.Provider value={value}>
      {children}
      <FilesModal
        visible={isOpen}
        selection={selection}
        handleSelection={handleSelection}
        onOk={handleSelection}
        onClose={closeFilesModal}
      />
    </FilesModalContext.Provider>
  );
};

export const useFilesModalContext = () => useContext(FilesModalContext);
