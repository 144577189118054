import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Table, Popconfirm, Tabs, Menu, Dropdown } from 'antd';
import useSWR from 'swr';
import { MoreOutlined, DeleteOutlined, DownOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { sort, checkAuthorization } from '../../../shared/utils';
import { imaginelabWorkshopRoutes } from '../../../lib/routes';
import useColumnSearch from '../../../hooks/useColumnSearch';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';

const { TabPane } = Tabs;

const ImaginelabWorkshopsList = () => {
  const [tab, setTab] = useState('enabled');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selection, setSelection] = useState([]);
  const { data: workshops, isValidating, mutate } = useSWR(imaginelabWorkshopRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { user } = useAuth();
  const { post, patch } = useFetch();
  const enabledWorkshops = workshops?.data?.filter((workshop) => !workshop.disabled) || [];
  const disabledWorkshops = workshops?.data?.filter((workshop) => workshop.disabled) || [];

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (ids) => {
    setIsProcessing(true);

    const results = await post(imaginelabWorkshopRoutes.delete, JSON.stringify({ ids: ids }));

    if (results.status === 200) {
      if (selection.length !== 0) {
        const clone = [...selection];
        ids.forEach((id) => {
          const index = clone.findIndex((item) => item === id);

          if (index !== -1) {
            clone.splice(index, 1);
          }
        });
        setSelection(clone);
      }
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDisable = async (ids) => {
    setIsProcessing(true);

    const results = await patch(imaginelabWorkshopRoutes.disable, JSON.stringify({ ids: ids }));

    if (results.status === 200) {
      if (selection.length !== 0) {
        const clone = [...selection];
        ids.forEach((id) => {
          const index = clone.findIndex((item) => item === id);

          if (index !== -1) {
            clone.splice(index, 1);
          }
        });
        setSelection(clone);
      }
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onEnable = async (ids) => {
    setIsProcessing(true);

    const results = await patch(imaginelabWorkshopRoutes.enable, JSON.stringify({ ids: ids }));

    if (results.status === 200) {
      if (selection.length !== 0) {
        const clone = [...selection];
        ids.forEach((id) => {
          const index = clone.findIndex((item) => item === id);

          if (index !== -1) {
            clone.splice(index, 1);
          }
        });
        setSelection(clone);
      }
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = ({ record }) => (
    <Menu>
      {tab === 'enabled'
        ? checkAuthorization(user, 'imaginelab', 'workshops', 'disable-workshop') && (
            <Menu.Item key="disable" className="gray-text" disabled={isProcessing}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDisable([record._id])}>
                Désactiver
              </Popconfirm>
            </Menu.Item>
          )
        : checkAuthorization(user, 'imaginelab', 'workshops', 'enable-workshop') && (
            <Menu.Item key="enable" className="gray-text" disabled={isProcessing}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onEnable([record._id])}>
                Activer
              </Popconfirm>
            </Menu.Item>
          )}

      {checkAuthorization(user, 'imaginelab', 'workshops', 'update-workshop') && (
        <Menu.Item key="edit" className="gray-text" disabled={isProcessing}>
          <Link to={'/imaginelab/ateliers/modifier-un-atelier/' + record._id}>Modifier</Link>
        </Menu.Item>
      )}

      {checkAuthorization(user, 'imaginelab', 'workshops', 'delete-workshop') && (
        <Menu.Item key="delete" className="gray-text" disabled={isProcessing}>
          <Popconfirm {...popconfirmProps} onConfirm={() => onDelete([record._id])}>
            Supprimer
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      ...getColumnSearchProps('title'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Thématique',
      dataIndex: 'theme',
      key: 'theme',
      filters: [
        { text: "Anim'Studio", value: "Anim'Studio" },
        { text: 'Créa 3D', value: 'Créa 3D' },
        { text: 'ElektroLab', value: 'ElektroLab' },
        { text: 'Fabrique à BD', value: 'Fabrique à BD' },
        { text: 'Game Makers', value: 'Game Makers' },
        { text: 'Robot Factory', value: 'Robot Factory' },
      ],
      onFilter: (value, record) => record.theme === value,
      render: (value) => value,
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} trigger={['click']} disabled={isProcessing}>
          <Button className="gray-text" icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  const menu = (
    <Menu>
      {tab === 'enabled'
        ? checkAuthorization(user, 'imaginelab', 'workshops', 'disable-workshop') && (
            <Menu.Item key="selection-disable" icon={<LockOutlined />} disabled={isProcessing}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDisable(selection)}>
                Désactiver
              </Popconfirm>
            </Menu.Item>
          )
        : checkAuthorization(user, 'imaginelab', 'workshops', 'enable-workshop') && (
            <Menu.Item key="selection-enable" icon={<UnlockOutlined />} disabled={isProcessing}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onEnable(selection)}>
                Activer
              </Popconfirm>
            </Menu.Item>
          )}

      {checkAuthorization(user, 'imaginelab', 'workshops', 'disable-workshop') && (
        <Menu.Item key="selection-delete" icon={<DeleteOutlined />} disabled={isProcessing}>
          <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(selection)}>
            Supprimer
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Dropdown disabled={selection.length === 0} trigger={['click']} overlay={menu}>
          <Button disabled={isProcessing}>
            Sélection ({selection.length})
            <DownOutlined />
          </Button>
        </Dropdown>

        {checkAuthorization(user, 'imaginelab', 'workshops', 'create-workshop') && (
          <Link to="/imaginelab/ateliers/nouvel-atelier">
            <Button className="gray-text" disabled={isProcessing}>
              Nouvel atelier
            </Button>
          </Link>
        )}
      </div>
      <Tabs
        defaultActiveKey="enabled"
        activeKey={tab}
        onChange={(tab) => {
          setTab(tab);
          setSelection([]);
        }}
      >
        <TabPane tab={`Actifs (${enabledWorkshops.length})`} key="enabled">
          <Table
            loading={!workshops || isValidating}
            dataSource={enabledWorkshops || []}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            rowSelection={{
              selectedRowKeys: selection,
              onChange: setSelection,
            }}
            pagination={tablePagination(workshops?.data)}
          />
        </TabPane>
        <TabPane tab={`Inactifs (${disabledWorkshops.length})`} key="disabled">
          <Table
            loading={!workshops || isValidating}
            dataSource={disabledWorkshops || []}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            rowSelection={{
              selectedRowKeys: selection,
              onChange: setSelection,
            }}
            pagination={tablePagination(workshops?.data)}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ImaginelabWorkshopsList;
