import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Table, Dropdown, Menu, Popconfirm, notification, message } from 'antd';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons/lib/icons';
import useSWR from 'swr';
import { checkAuthorization, sort } from '../../../shared/utils';
import { reportFormRoutes } from '../../../lib/routes';
import { useAuth } from '../../../authContext';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import useColumnSearch from '../../../hooks/useColumnSearch';

const formTypes = [
  { text: 'Suivi mensuel', value: 'MENSUEL' },
  { text: 'Bilan de formation en continu', value: 'FORMATION' },
  { text: 'Bilan de formation en alternance', value: 'COMPANY' },
  { text: 'Bilan de stage', value: 'STAGE' },
];

const reportTypes = [
  { text: "Bilan d'intégration", value: 'INTEGRATION' },
  { text: 'Bilan mi parcours', value: 'MI_PARCOURS' },
  { text: 'Bilan final', value: 'FINAL' },
  { text: 'Bilan supplémentaire', value: 'INTERMEDIAIRE' },
  { text: 'Sans type', value: null },
];

const FormList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: forms, isValidating, mutate } = useSWR(reportFormRoutes.default + '/?select=name,formType,reportType');
  const { user } = useAuth();
  const { remove, get } = useFetch();
  const { getColumnSearchProps } = useColumnSearch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const deleteForm = async (id) => {
    setIsProcessing(true);

    const results = await remove(reportFormRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const generatePdf = async (form) => {
    const key = form._id;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await get(reportFormRoutes.preview + '/' + form._id);

    if (results.status === 201) {
      const blob = new Blob([Buffer.from(results.data)], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      link.download = `Prévisualisation_${form.name}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  const Actions = ({ record }) => (
    <Menu>
      {checkAuthorization(user, 'formao', 'reportForms', 'preview') && (
        <Menu.Item key="preview" className="gray-text" onClick={() => generatePdf(record)}>
          Prévisualiser
        </Menu.Item>
      )}
      {checkAuthorization(user, 'formao', 'reportForms', 'update') && (
        <Menu.Item key="update" className="gray-text">
          <Link to={`/formao/formulaires-de-bilans/modifier-un-formulaire/${record._id}`}>Modifier</Link>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'formao', 'reportForms', 'delete') && (
        <Menu.Item key="delete" className="gray-text">
          <Popconfirm {...popconfirmProps} onConfirm={() => deleteForm(record._id)}>
            Supprimer
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Intitulé du formulaire',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => sort(a, b, 'name'),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Type de formulaire',
      key: 'formType',
      dataIndex: 'formType',
      filters: formTypes,
      onFilter: (value, record) => record.formType === value,
      sorter: (a, b) => sort(a, b, 'formType'),
      render: (record) => {
        const match = formTypes.find((method) => method.value === record);

        return match ? match.text : '';
      },
    },
    {
      title: 'Type de bilan',
      key: 'reportType',
      dataIndex: 'reportType',
      filters: reportTypes,
      onFilter: (value, record) => (record.reportType ? record.reportType === value : value === null),
      sorter: (a, b) => sort(a, b, 'reportType', ''),
      render: (record) => {
        const match = reportTypes.find((method) => method.value === record);

        return match ? match.text : '';
      },
    },
    ...(checkAuthorization(user, 'formao', 'reportForms', 'update') ||
    checkAuthorization(user, 'formao', 'reportForms', 'delete')
      ? [
          {
            title: '',
            key: 'actions',
            width: 50,
            fixed: 'right',
            render: (record) => {
              return (
                <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
                  <Button className="gray-text" icon={<MoreOutlined />} />
                </Dropdown>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <Card>
      {checkAuthorization(user, 'formao', 'reportForms', 'create') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/formao/formulaires-de-bilans/nouveau-formulaire">Nouveau formulaire</Link>
          </Button>
        </div>
      )}
      <Table
        loading={!forms || isValidating || isProcessing}
        dataSource={forms?.data || []}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination(forms?.data)}
      />
    </Card>
  );
};

export default FormList;
