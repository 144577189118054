import { mergeAttributes, Node } from '@tiptap/core';

export const Cell = Node.create({
  name: 'gridCell',
  group: 'gridCell',
  content: '(paragraph|block)*',
  isolating: true,
  selectable: false,
  addAttributes() {
    return {
      'data-desktop-size': {
        parseHTML: (el) => el.getAttribute('data-desktop-size'),
      },
      'data-desktop-order': {
        parseHTML: (el) => el.getAttribute('data-desktop-order'),
      },
      'data-tablet-size': {
        parseHTML: (el) => el.getAttribute('data-tablet-size'),
      },
      'data-tablet-order': {
        parseHTML: (el) => el.getAttribute('data-tablet-order'),
      },
      'data-tablet-hidden': {
        parseHTML: (el) => el.getAttribute('data-tablet-hidden'),
      },
      'data-mobile-size': {
        parseHTML: (el) => el.getAttribute('data-mobile-size'),
      },
      'data-mobile-order': {
        parseHTML: (el) => el.getAttribute('data-mobile-order'),
      },
      'data-mobile-hidden': {
        parseHTML: (el) => el.getAttribute('data-mobile-hidden'),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div.grid-cell',
        priority: 150,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'grid-cell' }), 0];
  },
  addNodeView() {
    return ({ HTMLAttributes }) => {
      const wrapper = document.createElement('div');
      const cell = document.createElement('div');

      wrapper.classList.add('grid-cell');
      wrapper.appendChild(cell);

      cell.classList.add('grid-cell-content');

      if (this?.options?.HTMLAttributes) {
        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          wrapper.setAttribute(key, value);
        });
      }

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        wrapper.setAttribute(key, value);
      });

      return {
        dom: wrapper,
        contentDOM: cell,
      };
    };
  },
});
