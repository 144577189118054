import { Form, Modal, Select, DatePicker, Slider } from 'antd';
import { useEffect, useState } from 'react';
import { range } from 'lodash';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';
import dateValidation from './validations/dateValidation';

const formatOptions = [
  {
    label: 'Découverte',
    value: 'Découverte',
  },
  {
    label: 'Stage vacances',
    value: 'Stage vacances',
  },
  {
    label: 'Hebdomadaire',
    value: 'Hebdomadaire',
  },
  {
    label: 'Intergénérationnel',
    value: 'Intergénérationnel',
  },
];

const ageRangeOptions = [
  { label: 'Personnalisée', value: 'custom' },
  {
    label: 'Présélection',
    options: [
      { label: '6-8 ans', value: '6-8' },
      { label: '9-11 ans', value: '9-11' },
      { label: '11-14 ans', value: '11-14' },
      { label: '14+', value: '14-18' },
      { label: 'Adultes', value: '18-18' },
    ],
  },
];

const ageMarks = { ...range(6, 18).reduce((prev, curr) => ({ ...prev, [curr]: curr.toString() }), {}), 18: 'Adultes' };

const CreateModal = ({ agencies, agenciesLoading, visible, onClose, selectedDate, onFinish, loading }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [customValues, setCustomValues] = useState(true);
  const [values, setValues] = useState({
    date: null,
    age: [6, 18],
    format: null,
    agency: null,
  });
  const [errors, setErrors] = useState({});
  const title = format(new Date(selectedDate), 'PPP', { locale: fr });

  useEffect(() => {
    setValues((rest) => ({ ...rest, date: new Date(selectedDate) }));
  }, [selectedDate]);

  const onOk = () => {
    setIsProcessing(true);

    const errors = dateValidation(values);

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
    } else {
      onFinish(values);
      setErrors();
      setValues({
        date: null,
        age: [6, 18],
        format: null,
        agency: null,
      });
      onClose();
    }

    setIsProcessing(false);
  };

  const onCancel = () => {
    setErrors();
    setValues({
      date: null,
      age: [6, 18],
      format: null,
      agency: null,
    });
    onClose();
  };

  const onAgeChange = (newValue) => {
    if (newValue !== 'custom') {
      const age = newValue.split('-');

      setValues((rest) => ({ ...rest, age }));
      setCustomValues(false);
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{ loading: isProcessing }}
      cancelButtonProps={{ disabled: isProcessing || loading }}
    >
      <Form layout="vertical" noValidate>
        <Form.Item label="Heure de démarrage" validateStatus={errors?.date && 'error'} help={errors?.date} required>
          <DatePicker
            dropdownClassName="imaginelab-workshops-datepicker"
            picker="time"
            format="HH:mm"
            showNow={false}
            hideDisabledOptions
            defaultValue={moment(selectedDate?.setHours(8, 0, 0))}
            value={moment(values.date)}
            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23]}
            minuteStep={5}
            allowClear={false}
            onSelect={(value) => setValues((rest) => ({ ...rest, date: new Date(value._d) }))}
            disabled={isProcessing || loading}
          />
        </Form.Item>
        <Form.Item label="Tranche d'âges" validateStatus={errors?.age && 'error'} help={errors?.age} required>
          <Select
            disabled={isProcessing || loading}
            placeholder="Présélection"
            options={ageRangeOptions}
            value={customValues ? 'custom' : values?.age.join('-')}
            onChange={onAgeChange}
          />
          <Slider
            range
            marks={ageMarks}
            step={1}
            min={6}
            max={18}
            value={values.age}
            onChange={(value) => {
              setCustomValues(true);
              setValues((rest) => ({ ...rest, age: value }));
            }}
          />
        </Form.Item>
        <Form.Item label="Format" validateStatus={errors?.format && 'error'} help={errors?.format} required>
          <Select
            disabled={isProcessing || loading}
            placeholder="Sélectionnez dans la liste"
            options={formatOptions}
            value={values.format}
            onChange={(value) => setValues((rest) => ({ ...rest, format: value }))}
          />
        </Form.Item>
        <Form.Item label="Centre" required validateStatus={errors?.agency && 'error'} help={errors?.agency}>
          <Select
            placeholder="Sélectionnez dans la liste"
            options={agencies}
            value={values.agency}
            onChange={(value) => setValues((rest) => ({ ...rest, agency: value }))}
            loading={agenciesLoading}
            disabled={isProcessing || loading}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateModal;
