import { useEffect, useState } from 'react';

import {
  CloseOutlined,
  DesktopOutlined,
  LoadingOutlined,
  MobileOutlined,
  SaveOutlined,
  TabletOutlined,
} from '@ant-design/icons';
import { Alert, Button, Collapse, Divider, Form, Modal, Segmented, Typography } from 'antd';
import { contains, findChildrenByType, findParentNode, hasParentNodeOfType } from 'prosemirror-utils';
import { FormProvider, useForm } from 'react-hook-form';

import { useContentEditorContext } from '../contentEditorContext';

import View from './View';
import useFetch from '../../../hooks/useFetch';

const defaultValues = {
  desktop: {
    hidden: false,
    fit: 'cover',
    width: 'auto',
    height: 'auto',
  },
  tablet: {
    hidden: false,
    fit: 'cover',
    width: 'auto',
    height: 'auto',
  },
  mobile: {
    hidden: false,
    fit: 'cover',
    width: 'auto',
    height: 'auto',
  },
};

const FigureResponsiveModal = ({ editor }) => {
  const [view, setView] = useState('desktop');
  const [loading, setLoading] = useState(true);
  const [currDimensions, setCurrDimensions] = useState([]);
  const [prevDimensions, setPrevDimensions] = useState({});
  const methods = useForm({ defaultValues });
  const { figureResponsiveModalOpen, toggleFigureResponsiveModal } = useContentEditorContext();
  const { get } = useFetch();

  useEffect(() => {
    setView('desktop');
    setCurrDimensions([]);
    setLoading(true);

    if (figureResponsiveModalOpen) {
      const { state, schema } = editor;
      const { selection, empty } = state;

      // Si la sélection est vide ou qu'aucune image légendée n'est présente, le modal est refermé
      if (empty || !hasParentNodeOfType(schema.nodes.figure)(selection)) {
        toggleFigureResponsiveModal(false);
        return;
      }

      // Tentative de récupération de l'élément "figure"
      let figure = findParentNode((node) => node.type === schema.nodes.figure)(selection);

      // Si aucune image n'est présente dans l'élément "figure"
      if (!contains(figure.node, schema.nodes.figureImage)) {
        toggleFigureResponsiveModal(false);
        return;
      }

      // Récupère l'image à l'intérieur de l'élément "figure"
      const figureImage = findChildrenByType(figure.node, schema.nodes.figureImage)[0];

      // Initialise les attributs
      const newValues = { ...defaultValues };

      if (!!figure.node.attrs && Object.keys(figure.node.attrs).length !== 0) {
        // Configuration sur ordinateur
        newValues.desktop.hidden = [true, 'true'].includes(figure.node.attrs?.['data-desktop-hidden']);
        newValues.desktop.fit = figure.node.attrs?.['data-desktop-fit'] || newValues.desktop.fit;
        newValues.desktop.width = figure.node.attrs?.['data-desktop-width'] || newValues.desktop.width;
        newValues.desktop.height = figure.node.attrs?.['data-desktop-height'] || newValues.desktop.height;

        // Configuration sur tablette
        newValues.tablet.hidden = [true, 'true'].includes(figure.node.attrs?.['data-tablet-hidden']);
        newValues.tablet.fit = figure.node.attrs?.['data-tablet-fit'] || newValues.tablet.fit;
        newValues.tablet.width = figure.node.attrs?.['data-tablet-width'] || newValues.tablet.width;
        newValues.tablet.height = figure.node.attrs?.['data-tablet-height'] || newValues.tablet.height;

        // Configuration sur mobile
        newValues.mobile.hidden = [true, 'true'].includes(figure.node.attrs?.['data-mobile-hidden']);
        newValues.mobile.fit = figure.node.attrs?.['data-mobile-fit'] || newValues.mobile.fit;
        newValues.mobile.width = figure.node.attrs?.['data-mobile-width'] || newValues.mobile.width;
        newValues.mobile.height = figure.node.attrs?.['data-mobile-height'] || newValues.mobile.height;

        // Met à jours les champs du formulaire
        methods.reset(newValues);

        if (!prevDimensions[figureImage.node.attrs?.id]) {
          if (!figureImage.node.attrs?.height || !figureImage.node.attrs?.width) {
            (async () => {
              const url = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/medias/data/${figureImage.node.attrs?.id}`;
              const results = await get(url);

              if (results?.status === 200) {
                setCurrDimensions([results.data.width, results.data.height]);
                setPrevDimensions((prevDimensions) => ({
                  ...prevDimensions,
                  [figureImage.node.attrs?.id]: [results.data.width, results.data.height],
                }));
                setLoading(false);
              } else {
                // Si la récupération des dimensions de l'image via l'API de Cloudinary à échouée, une seconde tentative manuelle est réalisée
                console.error('Cloudinary fetch failed, trying to manually get image dimensions');

                const img = new Image();
                img.src = figureImage.node.attrs?.src;

                img.onload = () => {
                  setCurrDimensions([img.width, img.height]);
                  setPrevDimensions((prevDimensions) => ({
                    ...prevDimensions,
                    [figureImage.node.attrs?.id]: [img.width, img.height],
                  }));
                  setLoading(false);
                };

                img.onerror = (err) => {
                  console.error(err);
                  setLoading(false);
                };
              }
            })();
          } else {
            setCurrDimensions([figureImage.node.attrs?.width, figureImage.node.attrs?.height]);
            setPrevDimensions((prevDimensions) => ({
              ...prevDimensions,
              [figureImage.node.attrs?.id]: [figureImage.node.attrs?.width, figureImage.node.attrs?.height],
            }));
            setLoading(false);
          }
        } else {
          setCurrDimensions(prevDimensions[figureImage.node.attrs?.id]);
          setLoading(false);
        }
      }
    }
  }, [figureResponsiveModalOpen]);

  const onSubmit = (form) => {
    editor.commands.updateAttributes('figure', {
      'data-desktop-hidden': form.desktop.hidden === true,
      'data-desktop-fit': form.desktop.fit,
      'data-desktop-width': form.desktop.width,
      'data-desktop-height': form.desktop.height,
      'data-tablet-hidden': form.tablet.hidden === true,
      'data-tablet-fit': form.tablet.fit,
      'data-tablet-width': form.tablet.width,
      'data-tablet-height': form.tablet.height,
      'data-mobile-hidden': form.mobile.hidden === true,
      'data-mobile-fit': form.mobile.fit,
      'data-mobile-width': form.mobile.width,
      'data-mobile-height': form.mobile.height,
    });

    toggleFigureResponsiveModal(false);
  };

  return (
    <Modal
      title="Configuration du responsive"
      open={figureResponsiveModalOpen}
      onCancel={() => toggleFigureResponsiveModal(false)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => toggleFigureResponsiveModal(false)} icon={<CloseOutlined />}>
            Annuler
          </Button>
          <Button type="primary" onClick={methods.handleSubmit(onSubmit)} icon={<SaveOutlined />} disabled={loading}>
            Enregistrer
          </Button>
        </div>
      }
    >
      <Segmented
        value={view}
        onChange={setView}
        size="small"
        block
        disabled={loading}
        options={[
          {
            value: 'desktop',
            label: 'Ordinateur',
            icon: <DesktopOutlined />,
          },
          {
            value: 'tablet',
            label: 'Tablette',
            icon: <TabletOutlined />,
          },
          {
            value: 'mobile',
            label: 'Mobile',
            icon: <MobileOutlined />,
          },
        ]}
      />
      <FormProvider {...methods}>
        <Form noValidate size="small" layout="vertical">
          <Typography.Paragraph type="secondary" style={{ marginTop: 16 }}>
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>
                  Les champs marqués d'un astérisque (<span className="danger">*</span>) sont obligatoires
                </li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          {loading ? (
            <Alert
              style={{ marginBottom: 16 }}
              showIcon
              type="info"
              message="Chargement des dimensions de l'image..."
              icon={<LoadingOutlined />}
            />
          ) : typeof currDimensions[0] === 'undefined' || typeof currDimensions[1] === 'undefined' ? (
            <Alert
              style={{ marginBottom: 16 }}
              showIcon
              type="error"
              message="La récupération des dimensions de l'image à échouée"
            />
          ) : null}
          {view === 'desktop' && <View {...{ loading }} mode="desktop" dimensions={currDimensions} />}
          {view === 'tablet' && <View {...{ loading }} mode="tablet" dimensions={currDimensions} />}
          {view === 'mobile' && <View {...{ loading }} mode="mobile" dimensions={currDimensions} />}
        </Form>
      </FormProvider>
      <Divider>Aide</Divider>
      <Collapse accordion size="small">
        <Collapse.Panel header="Le mode caché" key="hidden">
          <Typography.Text>
            Le mode caché permet à une image d'être présente dans le code et la page mais invisible par l'utilisateur.
          </Typography.Text>
        </Collapse.Panel>
        <Collapse.Panel header="L'ajustement" key="fit">
          <Typography.Text>
            <ul>
              <li>
                <strong>Ajusté</strong> : Le contenu remplacé est dimensionné pour maintenir ses proportions tout en
                étant ajusté à la boîte de contenu : sa taille réelle est résolue en utilisant la largeur et la hauteur
                de l'élément comme contraintes de contenant.
              </li>
              <li>
                <strong>Étiré</strong> : Le contenu remplacé est dimensionné pour maintenir son ratio d'affichage tout
                en remplissant toute la boîte de contenu. La taille réelle est calculée pour couvrir la zone décrite par
                la hauteur et la largeur de l'élément. Si les ratios de l'objet et de la boîte ne correspondent pas, le
                contenu remplacé sera rogné.
              </li>
              <li>
                <strong>Remplissage</strong> : Le contenu remplacé est dimensionné pour remplir la boîte de contenu. La
                taille réelle de l'objet est déterminée grâce à la hauteur et à la largeur de l'élément. Il est donc
                étiré afin de remplir la boîte.
              </li>
            </ul>
          </Typography.Text>
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
};

export default FigureResponsiveModal;
