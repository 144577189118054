import { useFormContext, useFieldArray, useFormState } from 'react-hook-form';
import { Button, Space, Typography, message, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';
import OrganizeLabel from './OrganizeLabel';

const { Text } = Typography;
const { Item } = Form;

const ListLabels = ({ listIndex, loading }) => {
  const ref = useRef(null);
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: labels, remove, append } = useFieldArray({ name: `lists.${listIndex}.labels`, control });
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'LABEL',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (item.listIndex === listIndex) {
        return;
      }

      if (labels.length >= 5) {
        message.error('Vous ne pouvez pas avoir plus de 5 éléments par colonnes');
        return;
      }

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.listIndex;
      delete itemCopy.remove;

      item.remove();
      append(itemCopy);
    },
  });

  const appendItem = () => {
    if (labels.length >= 5) message.error('Vous ne pouvez pas avoir plus de 5 éléments par colonnes');
    else append({ content: 'Nouvel élément' });
  };

  drop(ref);

  return (
    <div
      ref={ref}
      style={{
        padding: 12,
        height: '100%',
        backgroundColor: isOver ? '#bae7ff' : canDrop ? '#e6f7ff' : '#fafafa',
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text>Éléments</Text>
        {labels.map((item, index) => (
          <Item
            className="organize-label"
            key={item.id}
            validateStatus={errors?.lists?.[listIndex]?.labels?.[index]?.content?.message && 'error'}
            help={errors?.lists?.[listIndex]?.labels?.[index]?.content?.message}
          >
            <OrganizeLabel
              data={{ ...item, index, listIndex, remove: () => remove(index) }}
              fieldName={`lists.${listIndex}.labels.${index}.content`}
              loading={loading}
            />
          </Item>
        ))}
        <Button icon={<PlusOutlined />} disabled={loading || labels.length >= 5} block onClick={appendItem}>
          Nouvel élément
        </Button>
      </Space>
    </div>
  );
};

export default ListLabels;
