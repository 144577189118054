export const sizes = [
  { size: 1, percentage: 4.166666666666666 },
  { size: 2, percentage: 8.333333333333332 },
  { size: 3, percentage: 12.5 },
  { size: 4, percentage: 16.666666666666664 },
  { size: 5, percentage: 20.833333333333336 },
  { size: 6, percentage: 25 },
  { size: 7, percentage: 29.166666666666668 },
  { size: 8, percentage: 33.33333333333333 },
  { size: 9, percentage: 37.5 },
  { size: 10, percentage: 41.66666666666667 },
  { size: 11, percentage: 45.83333333333333 },
  { size: 12, percentage: 50 },
  { size: 13, percentage: 54.166666666666664 },
  { size: 14, percentage: 58.333333333333336 },
  { size: 15, percentage: 62.5 },
  { size: 16, percentage: 66.66666666666666 },
  { size: 17, percentage: 70.83333333333334 },
  { size: 18, percentage: 75 },
  { size: 19, percentage: 79.16666666666666 },
  { size: 20, percentage: 83.33333333333334 },
  { size: 21, percentage: 87.5 },
  { size: 22, percentage: 91.66666666666666 },
  { size: 23, percentage: 95.83333333333334 },
  { size: 24, percentage: 100 },
];

export const colors = [
  '#ffffff',
  '#ffccc7',
  '#ffd8bf',
  '#ffe7ba',
  '#fff1b8',
  '#ffffb8',
  '#d9f7be',
  '#b5f5ec',
  '#bae0ff',
  '#efdbff',
  '#ffd6e7',
  '#f0f0f0',
  '#b3dec1',
  '#c7d66d',
  '#f6efa6',
  '#c3b59f',
  '#d6d4a0',
  '#ebd2b4',
  '#dbf9b8',
  '#d0a5c0',
  '#c7d3dd',
  '#e4c3ad',
  '#bfcde0',
  '#ede580',
];

/**
 * @function getSizeFromTotal
 * @description Récupère la taille à partir de la taille en fraction
 * @param {number} number Taille en fraction à rechercher (entre 1 et 24)
 * @returns {object} La taille correspondante
 */
export const getSizeFromTotal = (number) => {
  if (number < 1) {
    return null;
  }

  if (number > 24) {
    return null;
  }

  const index = sizes.findIndex((item) => item.size === number);

  if (index === -1) {
    return null;
  }

  return sizes[index];
};

/**
 * @function getSizeFromTotal
 * @description Récupère la taille depuis le pourcentage le plus proche
 * @param {number} percentage Taille en pourcentage à rechercher
 * @returns {object} La taille correspondante
 */
export const getSizeFromPercentage = (percentage) => {
  if (percentage < 0) {
    return null;
  }

  if (percentage > 100) {
    return null;
  }

  return sizes.reduce((prev, curr) => {
    return Math.abs(curr.percentage - percentage) < Math.abs(prev.percentage - percentage) ? curr : prev;
  });
};
