import React, { useState } from 'react';
import { Drawer, Form, Button, Input, Select, Typography, message, Divider } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import { useAuth } from '../../../../authContext';
import useFetch from '../../../../hooks/useFetch';
import { quizRoutes, agencyRoutes, userRoutes, typeRoutes, evaluationsRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const CreateQuizDrawer = ({ onFinish }) => {
  const { user } = useAuth();
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: users, isValidating: isUsersValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: types, isValidating: isTypesValidating } = useSWR(typeRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: evaluations, isValidating: isEvaluationsValidating } = useSWR(evaluationsRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { handleSubmit, control, setError, reset, clearErrors } = useForm({
    defaultValues: { referrer: user._id },
  });
  const { errors } = useFormState({ control });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { post } = useFetch();

  const onCancel = () => {
    clearErrors();
    reset();
    setIsOpen(false);
  };

  const isAvailable = (type) => {
    // Récupère le type commun
    const commun = types.data.find((el) => el.title === 'Commun' && el.acronym === 'Commun').totals;

    // Vérifie que le type commun est disponible
    if (commun.easy.enabled >= 3 && commun.medium.enabled >= 4 && commun.hard.enabled >= 3) {
      // Vérifie que le type est disponible
      if (type.totals.easy.enabled >= 5 && type.totals.medium.enabled >= 10 && type.totals.hard.enabled >= 5) {
        return true;
      }
    }
    return false;
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(quizRoutes.create, JSON.stringify(form));

    if (results.status === 201) {
      onFinish();
      onCancel();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={() => setIsOpen(true)} className="gray-text">
          Nouveau test de positionnement
        </Button>
      </div>
      <Drawer
        visible={isOpen}
        onClose={onCancel}
        maskClosable
        closable={false}
        width={310}
        title="Nouveau test de positionnement"
        footer={
          <div>
            <Button type="primary" onClick={handleSubmit(onSubmit)} loading={isProcessing}>
              Enregistrer
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={onCancel} disabled={isProcessing}>
              Annuler
            </Button>
          </div>
        }
      >
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            validateStatus={errors?.agency?.message && 'error'}
            help={errors?.agency?.message}
            label="Centre"
            required
          >
            <Controller
              name="agency"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!agencies || isAgenciesValidating || isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={
                    agencies &&
                    agencies.data.map((agency) => ({
                      label: agency.city,
                      value: agency._id,
                    }))
                  }
                />
              )}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors?.type?.message && 'error'}
            help={errors?.type?.message}
            label="Catégorie du test"
            required
          >
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!types || isTypesValidating || isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={types?.data
                    .filter(
                      (type) =>
                        !type.disabled && type.acronym !== 'Commun' && type.title !== 'Commun' && isAvailable(type),
                    )
                    .sort((a, b) => sort(a, b, 'title', ''))
                    .map((type) => ({ label: type.title, value: type._id }))}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors?.referrer?.message && 'error'}
            help={errors?.referrer?.message}
            label="Auto-évaluation"
            extra="Laisser vide pour ne pas ajouter d'auto-évaluation"
          >
            <Controller
              name="evaluation"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!evaluations || isEvaluationsValidating || isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  allowClear
                  options={
                    evaluations?.data
                      .sort((a, b) => sort(a, b, 'name'))
                      .map((item) => ({
                        label: item.name,
                        value: item._id,
                      })) || []
                  }
                />
              )}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors?.referrer?.message && 'error'}
            help={errors?.referrer?.message}
            label="Personne à notifier"
            extra="Une notification sera envoyée par email lors de la finalisation du test"
            required
          >
            <Controller
              name="referrer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!users || isUsersValidating || isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={
                    users &&
                    users.data.map((user) => ({
                      label: `${user.last_name.toUpperCase()} ${
                        user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                      }`,
                      value: user._id,
                    }))
                  }
                />
              )}
            />
          </Form.Item>
          <Divider>Personne passant le test</Divider>
          <Form.Item
            validateStatus={errors?.last_name?.message && 'error'}
            help={errors?.last_name?.message}
            label="Nom"
            required
          >
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors?.first_name?.message && 'error'}
            help={errors?.first_name?.message}
            label="Prénom"
            required
          >
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors?.email?.message && 'error'}
            help={errors?.email?.message}
            label="Adresse email"
            required
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} type="email" />}
            />
          </Form.Item>
          <Form.Item validateStatus={errors?.phone?.message && 'error'} help={errors?.phone?.message} label="Téléphone">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} maxLength={10} />}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateQuizDrawer;
