import { Card, Tabs, Form, Space, Button, Typography, Alert, message } from 'antd';
import { Link, useHistory, useParams, Prompt } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { serialize } from 'object-to-formdata';
import useSWR from 'swr';
import ModuleEditMeta from './ModuleEditMeta';
import ModulesEditTempAccess from './ModulesEditTempAccess';
import ModuleEditContent from './ModuleEditContent';
import ModuleEditResources from './ModuleEditResources';
import ModuleEditModal from './ModuleEditModal';
import useFetch from '../../../../hooks/useFetch';
import { moduleRoutes } from '../../../../lib/routes';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const getDefaultValues = (module) => {
  if (!module || !module?.data) {
    return {};
  }

  return {
    title: module.data.title,
    goals: module.data.goals,
    level: module.data.level,
    tempAccesses: module.data.tempAccesses,
    chapters: module.data.chapters,
    resources: module.data.resources.filter((resource) => resource?.file),
  };
};

const ModuleEdit = () => {
  const { id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeModal, setActiveModal] = useState({});
  const history = useHistory();
  const { put } = useFetch();
  const { user } = useAuth();
  const { data: module, isValidating } = useSWR(moduleRoutes.default + '/id/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const defaultValues = useMemo(() => getDefaultValues(module), [module?.data]);
  const methods = useForm({ defaultValues });
  const { errors, dirtyFields } = useFormState({ control: methods.control });

  useEffect(() => {
    if (!isBlocking) {
      if (methods.formState.isDirty && Object.keys(dirtyFields).length !== 0) {
        setIsBlocking(true);
      }
    }
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (module?.data) {
      if (module.data.image?.secure_url) {
        setImagePreview(module.data.image.secure_url);
      }
    }
  }, [module]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(moduleRoutes.default + '/' + id, formData, 'multipart/form-data');

    if (results.status === 200) {
      methods.reset({ ...defaultValues, ...form });
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(moduleRoutes.default + '/' + id, formData, 'multipart/form-data');

    if (results.status === 200) {
      methods.reset({ ...defaultValues, ...form });
      setIsBlocking(false);
      history.push('/formao/cours');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onImageChange = ({ file }) => {
    setIsBlocking(true);
    methods.setValue('image', file.originFileObj);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagePreview(reader.result));
    reader.readAsDataURL(file.originFileObj);
  };

  return (
    <Card className="module-edit">
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          {checkAuthorization(user, 'formao', 'modules', 'update-module') ? (
            <Tabs defaultActiveKey="account">
              <TabPane tab="Informations" key="account">
                <ModuleEditMeta
                  disabled={isProcessing || isValidating}
                  preview={imagePreview}
                  setPreview={onImageChange}
                />
              </TabPane>
              <TabPane tab="Contenu" key="training">
                <Alert
                  style={{ marginBottom: 20 }}
                  type="info"
                  showIcon
                  message="Le fichier PDF est prioritaire sur le contenu"
                  description="Tant qu'un fichier PDF est présent, les apprenants n’ont pas accès au cours réalisé via l’éditeur intégré. Cela vous permet donc de construire un cours dans l’éditeur en “arrière plan” sans que les apprenants ne le voient. Une fois le cours construit dans l’éditeur, supprimer le fichier PDF pour le rendre accessible à tous."
                />
                <ModuleEditContent setActiveModal={setActiveModal} disabled={isProcessing || isValidating} />
                <ModuleEditResources disabled={isProcessing || isValidating} />
                <ModuleEditModal
                  title={activeModal?.title}
                  fieldName={activeModal?.field}
                  onClose={() => setActiveModal({})}
                  onSave={methods.handleSubmit(onSubmit)}
                  loading={isProcessing}
                  disabled={isProcessing || isValidating}
                />
              </TabPane>
              <TabPane tab="Accès restreint" key="tempAccesses">
                <ModulesEditTempAccess disabled={isProcessing || isValidating} />
              </TabPane>
            </Tabs>
          ) : (
            <>
              <Alert
                style={{ marginBottom: 20 }}
                type="info"
                showIcon
                message="Le fichier PDF est prioritaire sur le contenu"
                description="Tant qu'un fichier PDF est présent, les apprenants n’ont pas accès au cours réalisé via l’éditeur intégré. Cela vous permet donc de construire un cours dans l’éditeur en “arrière plan” sans que les apprenants ne le voient. Une fois le cours construit dans l’éditeur, supprimer le fichier PDF pour le rendre accessible à tous."
              />
              <ModuleEditContent setActiveModal={setActiveModal} disabled={isProcessing || isValidating} />
              <ModuleEditResources disabled={isProcessing || isValidating} />
              <ModuleEditModal
                title={activeModal?.title}
                fieldName={activeModal?.field}
                onClose={() => setActiveModal({})}
                onSave={methods.handleSubmit(onSubmit)}
                loading={isProcessing}
                disabled={isProcessing || isValidating}
              />
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing || isValidating}>
              <Link to="/formao/cours">Annuler</Link>
            </Button>
            <Space>
              <Button onClick={methods.handleSubmit(onSubmit)} loading={isProcessing} disabled={isValidating}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                loading={isProcessing}
                disabled={isValidating}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ModuleEdit;
