import { Button, Modal } from 'antd';
import { useCloudinaryContext } from '../cloudinaryContext';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';

const CloudinaryStatus = () => {
  const { status, usage } = useCloudinaryContext();

  if ([null, 'ok'].includes(status)) {
    return null;
  }

  return (
    <Button
      type="primary"
      danger={status === 'danger'}
      onClick={() => {
        if (status === 'warning') {
          return Modal.warning({
            title: 'Statut de Cloudinary',
            content:
              "Le quota d'utilisation mensuel à dépassé les 50%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos sera désactivé à partir de 75% et ce jusqu'a la prochaine mensualité.",
          });
        }

        return Modal.error({
          title: 'Statut de Cloudinary',
          content:
            "Le quota d'utilisation mensuel à dépassé les 75%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos est désactivé jusqu'a la prochaine mensualité.",
        });
      }}
      style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 9999 }}
      className={classnames({ 'ant-btn-warning': status === 'warning' })}
      icon={status === 'danger' ? <CloseCircleOutlined /> : <ExclamationCircleOutlined />}
    >
      Cloudinary
    </Button>
  );
};

export default CloudinaryStatus;
