import { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, Form, Modal, Space, Tabs, message } from 'antd';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { useForm, useFormState, FormProvider } from 'react-hook-form';
import { capitalize } from 'lodash';
import {
  addDays,
  addMonths,
  eachMonthOfInterval,
  getDate,
  getDaysInMonth,
  isAfter,
  isBefore,
  isSameDay,
  lastDayOfMonth,
  setDate,
  subDays,
} from 'date-fns';
import moment from 'moment';
import useSWR from 'swr';
import { reportFormRoutes, reportRoutes } from '../../../lib/routes';
import CompanyStep from './components/CompanyStep';
import StudentStep from './components/StudentStep';
import ReportsStep from './components/ReportsStep';
import useFetch from '../../../hooks/useFetch';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ReportUpdate = ({ reportsType }) => {
  const { id } = useParams();
  const [tab, setTab] = useState('STUDENT');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [formsOptions, setFormsOptions] = useState([]);
  const [showAllForms, toggleShowAllForms] = useState(true);
  const { patch } = useFetch();
  const history = useHistory();
  const { data: report } = useSWR(reportRoutes.getOne + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    data: forms,
    isValidating: isFormsValidating,
    mutate: reloadForms,
  } = useSWR(reportFormRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const formattedForms = useMemo(
    () =>
      forms?.data.map((form) => ({
        label: form.name,
        value: form._id,
        formations: form.formations,
        formType: form.formType,
        reportType: form.reportType,
      })),
    [forms],
  );
  const methods = useForm({ defaultValues: { reports: [] } });
  const { errors } = useFormState({ control: methods.control });

  const studentProps = {
    disabled: isProcessing,
    formattedForms,
    isEditing: true,
    setFormsOptions,
    toggleShowAllForms,
  };

  const reportsProps = {
    disabled: isProcessing,
    step: report?.data?.step,
    reportsType,
    showAllForms,
    formattedForms,
    forms,
    isFormsValidating,
    formsOptions,
    isEditing: true,
    setFormsOptions,
    toggleShowAllForms,
    reloadForms,
  };

  const getTab = (tab) => {
    let errorFields = {};
    let title = '';

    switch (tab) {
      case 'STUDENT':
        errorFields = [
          'studentEmail',
          'studentPhone',
          'studentFirstName',
          'studentLastName',
          'studentFormation',
          'studentFormationId',
          'studentId',
          'studentFormationDates',
          'studentAgency',
          'studentFormer',
          'referent',
        ];
        title = 'Stagiaire et formation';
        break;
      case 'COMPANY':
        errorFields = [
          'companyName',
          'companyTutorPhone',
          'companyTutorFirstName',
          'companyTutorLastName',
          'companyTutorFunction',
          'companyTutorEmail',
        ];
        title = 'Entreprise et tuteur';
        break;
      case 'REPORTS':
        errorFields = ['reports'];
        title = 'Envoi des bilans';
        break;
      default:
        break;
    }

    const stepErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

    if (stepErrors.length !== 0) {
      return <Badge dot={true}>{title}</Badge>;
    } else {
      return title;
    }
  };

  useEffect(() => {
    if (report && report.data) {
      const referent = {
        value: report.data.referent?._id,
        label: report.data.referent?.last_name.toUpperCase() + ' ' + capitalize(report.data.referent?.first_name),
      };
      let formattedReports = [];

      methods.setValue('referent', referent);
      methods.setValue('reportsType', report.data.reportsType);

      if (report.data.studentId) {
        const formationDetails = report.data.student.formations.find(
          (formation) => formation._id === report.data.student.formationId,
        );

        if (report.data.reportsType === 'MENSUEL') {
          const eachMonths = eachMonthOfInterval({
            start: new Date(formationDetails.startAt),
            end: new Date(formationDetails.endAt),
          });

          const startDate = getDate(new Date(formationDetails.startAt));
          const formattedMonths = [];

          for (let i = 0; i < eachMonths.length; i++) {
            const month = addMonths(new Date(eachMonths[i]), 1);
            const daysInMonth = getDaysInMonth(new Date(month));

            const originalDate =
              daysInMonth >= startDate
                ? setDate(new Date(month), startDate)
                : setDate(new Date(month), getDate(lastDayOfMonth(new Date(month))));

            formattedMonths.push(originalDate);
          }

          for (let i = 0; i < report.data.reports.length; i++) {
            const nearestDate = formattedMonths.find(
              (date) =>
                (isAfter(date, subDays(new Date(report.data.reports[i].sendAt), 7)) &&
                  isBefore(date, addDays(new Date(report.data.reports[i].sendAt), 7))) ||
                isSameDay(date, new Date(report.data.reports[i].sendAt)),
            );

            formattedReports.push({ ...report.data.reports[i], originalDate: nearestDate });
          }
        }

        methods.setValue('studentId', report.data.studentId);
        methods.setValue('studentFormation', formationDetails?.formation);
        methods.setValue('studentFormationId', report.data.student.formationId);
      } else {
        const former = {
          value: report.data.student?.former?._id,
          label:
            report.data.student?.former?.last_name?.toUpperCase() +
            ' ' +
            capitalize(report.data.student?.former?.first_name),
        };

        if (report.data.reportsType === 'MENSUEL') {
          const eachMonths = eachMonthOfInterval({
            start: new Date(report.data.student.formationStartAt),
            end: new Date(report.data.student.formationEndAt),
          });

          const startDate = getDate(new Date(report.data.student.formationStartAt));
          const formattedMonths = [];

          for (let i = 0; i < eachMonths.length; i++) {
            const month = addMonths(new Date(eachMonths[i]), 1);
            const daysInMonth = getDaysInMonth(new Date(month));

            const originalDate =
              daysInMonth >= startDate
                ? setDate(new Date(month), startDate)
                : setDate(new Date(month), getDate(lastDayOfMonth(new Date(month))));

            formattedMonths.push(originalDate);
          }

          for (let i = 0; i < report.data.reports.length; i++) {
            const nearestDate = formattedMonths.find(
              (date) =>
                (isAfter(date, subDays(new Date(report.data.reports[i].sendAt), 7)) &&
                  isBefore(date, addDays(new Date(report.data.reports[i].sendAt), 7))) ||
                isSameDay(date, new Date(report.data.reports[i].sendAt)),
            );

            formattedReports.push({ ...report.data.reports[i], originalDate: nearestDate });
          }
        }

        methods.setValue('studentFormer', former);
        if (typeof report.data.student.formation === 'object') {
          methods.setValue('studentFormation', {
            label: report.data.student.formation?.title,
            value: report.data.student.formation?._id,
          });
        } else {
          methods.setValue('studentFormation', report.data.student.formation);
        }

        methods.setValue('studentAgency', report.data.student.agency);
        methods.setValue('studentFormationDates', [
          moment(report.data.student.formationStartAt),
          moment(report.data.student.formationEndAt),
        ]);
      }

      methods.setValue('studentFirstName', report.data.student.firstName);
      methods.setValue('studentLastName', report.data.student.lastName);
      methods.setValue('studentEmail', report.data.student.contact?.email);
      methods.setValue('studentPhone', report.data.student.contact?.phone);
      methods.setValue('studentGroup', report.data.student.group);

      if (report.data.reportsType === 'MENSUEL') {
        if (report.data?.company?.name) {
          methods.setValue('courseType', 'ALTERNANCE');
        } else {
          methods.setValue('courseType', 'CONTINU');
        }
      }

      if (
        ['STAGE', 'COMPANY'].includes(report.data.reportsType) ||
        (report.data.reportsType === 'MENSUEL' && report.data.company?.name)
      ) {
        methods.setValue('companyName', report.data.company.name);
        methods.setValue('companyTutorLastName', report.data.company.tutor.lastName);
        methods.setValue('companyTutorFirstName', report.data.company.tutor.firstName);
        methods.setValue('companyTutorFunction', report.data.company.tutor.function);
        methods.setValue('companyTutorEmail', report.data.company.tutor.contact?.email);
        methods.setValue('companyTutorPhone', report.data.company.tutor.contact?.phone);
      }

      methods.setValue('reports', formattedReports.length !== 0 ? formattedReports : report.data.reports);

      const formationId = report.data?.student?.formation || report.data?.student?.formationId;
      const selectedForms = report.data?.reports?.map((item) => item?.form?.toString());

      if (formationId) {
        const newFormsOptions = forms?.data?.filter(
          (form) =>
            form?.formations?.length === 0 ||
            form?.formations?.includes(formationId.toString()) ||
            selectedForms.includes(form?._id),
        );

        if (newFormsOptions?.length !== 0) {
          const formatted = newFormsOptions?.map((form) => ({ label: form.name, value: form._id, type: form.type }));

          setFormsOptions(formatted);
        } else {
          setFormsOptions([]);
        }
      } else {
        setFormsOptions([]);
      }
    }
  }, [report]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const resendReport = async (form, submit) => {
    let studentEmailChange = false;
    let formerEmailChange = false;
    let tutorEmailChange = false;
    let modalContent = null;

    if (!form.studentId) {
      if (form?.studentFormer?.value.toString() !== report.data?.student?.former?._id?.toString()) {
        formerEmailChange = true;
      }

      if (form.studentEmail !== report.data?.student?.contact?.email) {
        studentEmailChange = true;
      }
    }

    if (report.data.company?.tutor?.contact?.email !== form.companyTutorEmail) {
      tutorEmailChange = true;
    }

    if (['COMPANY', 'FORMATION'].includes(report.data?.reportsType)) {
      if (report.data?.step && !report.data?.step.endsWith('COMPLÉTÉ')) {
        const pendingReport = report.data?.reports?.find((item) => !item.finishedAt && item.token && !item.ignored);

        if (pendingReport) {
          if (pendingReport.results && report.data?.reportsType === 'FORMATION' && formerEmailChange) {
            modalContent =
              "Vous venez de modifier l'adresse mail du formateur. Un bilan est en attente de complétion par le formateur, souhaitez vous renvoyer le bilan à cette nouvelle adresse ?";
          }
          if (pendingReport.results && report.data?.reportsType !== 'FORMATION' && tutorEmailChange) {
            modalContent =
              "Vous venez de modifier l'adresse mail du tuteur. Un bilan est en attente de complétion par le tuteur, souhaitez vous renvoyer le bilan à cette nouvelle adresse ?";
          }
          if (!pendingReport.results && studentEmailChange) {
            modalContent =
              "Vous venez de modifier l'adresse mail de l'apprenant. Un bilan est en attente de complétion par l'apprenant, souhaitez vous renvoyer le bilan à cette nouvelle adresse ?";
          }
        }
      }
    }

    if (report.data?.reportsType === 'MENSUEL') {
      const formerPendingReports = report.data?.reports?.filter(
        (item) => !item.finishedAt && item.token && item.results,
      );
      const studentPendingReports = report.data?.reports?.filter(
        (item) => !item.finishedAt && item.token && !item.results,
      );

      if (
        formerPendingReports.length > 0 &&
        studentPendingReports.length > 0 &&
        formerEmailChange &&
        studentEmailChange
      ) {
        modalContent =
          "Vous venez de modifier l'adresse mail du formateur et de l'apprenant. Un ou plusieurs suivis sont en attente de complétion par le formateur et l'apprenant, souhaitez vous renvoyer ces suivis à ces nouvelles adresses ?";
      } else {
        if (formerPendingReports.length > 0 && formerEmailChange) {
          modalContent =
            "Vous venez de modifier l'adresse mail du formateur. Un ou plusieurs suivis sont en attente de complétion par le formateur, souhaitez vous renvoyer ces suivis à cette nouvelle adresse ?";
        }

        if (studentPendingReports.length > 0 && studentEmailChange) {
          modalContent =
            "Vous venez de modifier l'adresse mail de l'apprenant. Un ou plusieurs suivis sont en attente de complétion par l'apprenant, souhaitez vous renvoyer ces suivis à cette nouvelle adresse ?";
        }
      }
    }

    if (report.data?.reportsType === 'STAGE') {
      const trainingReport = report.data?.reports[0];
      const pendingReport = !trainingReport.finishedAt && trainingReport.token;

      if (pendingReport) {
        if (!trainingReport.results && studentEmailChange) {
          modalContent =
            "Vous venez de modifier l'adresse mail de l'apprenant. Le bilan de stage est en attente de complétion par l'apprenant, souhaitez vous renvoyer le bilan à cette nouvelle adresse ?";
        }
        if (trainingReport.results && tutorEmailChange) {
          modalContent =
            "Vous venez de modifier l'adresse mail du tuteur. Le bilan de stage est en attente de complétion par le tuteur, souhaitez vous renvoyer le bilan à cette nouvelle adresse ?";
        }
      }
    }

    if (modalContent) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Modification',
        content: modalContent,
        okText: 'Oui',
        cancelText: 'Non',
        onOk: () => submit(true),
        onCancel: () => submit(false),
      });
    } else {
      submit(false);
    }
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    await resendReport(form, async (resendToNewEmail) => {
      let [studentStartAt, studentEndAt] = [,];
      let updatePath = '';

      switch (reportsType) {
        case 'FORMATION':
          updatePath = '/formation';
          break;
        case 'COMPANY':
          updatePath = '/company';
          break;
        case 'MENSUEL':
          updatePath = '/monthly';
          break;
        case 'STAGE':
          updatePath = '/training';
          break;
        default:
          break;
      }

      if (form?.studentFormationDates) {
        [studentStartAt, studentEndAt] = form?.studentFormationDates;
      }

      const results = await patch(
        reportRoutes.default + updatePath + '/' + id,
        JSON.stringify({
          ...form,
          studentStartAt,
          studentEndAt,
          resendToNewEmail,
          referent: form?.referent?.value,
          studentFormer: form?.studentFormer?.value,
          studentFormation: form?.studentFormation?.value || form?.studentFormation,
          reports: form?.reports?.map((item) => {
            return {
              sendAt: item.sendAt,
              form: item.form?.value ?? item.form,
              ignored: item.ignored ? item.ignored : null,
            };
          }),
        }),
      );

      if (results.status === 200) {
        setIsBlocking(false);
      } else {
        if (results.message) {
          message.error(results.message);
        } else {
          Object.entries(results.errors).forEach(([key, value]) => {
            if (key === 'studentStartAt' || key === 'studentEndAt') {
              methods.setError('studentFormationDates', { type: 'manual', message: value });
            } else if (key === 'studentId') {
              methods.setError('studentEmail', { type: 'manual', message: value });
            } else if (key === 'studentFormationId') {
              methods.setError('studentFormation', { type: 'manual', message: value });
            } else {
              methods.setError(key, { type: 'manual', message: value });
            }
          });
        }
      }
    });

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    await resendReport(form, async (resendToNewEmail) => {
      let [studentStartAt, studentEndAt] = [,];
      let updatePath = '';

      switch (reportsType) {
        case 'FORMATION':
          updatePath = '/formation';
          break;
        case 'COMPANY':
          updatePath = '/company';
          break;
        case 'MENSUEL':
          updatePath = '/monthly';
          break;
        case 'STAGE':
          updatePath = '/training';
          break;
        default:
          break;
      }

      if (form?.studentFormationDates) {
        [studentStartAt, studentEndAt] = form?.studentFormationDates;
      }

      const results = await patch(
        reportRoutes.default + updatePath + '/' + id,
        JSON.stringify({
          ...form,
          studentStartAt,
          studentEndAt,
          resendToNewEmail,
          referent: form?.referent?.value,
          studentFormer: form?.studentFormer?.value,
          studentFormation: form?.studentFormation?.value || form?.studentFormation,
          reports: form?.reports?.map((item) => {
            return { sendAt: item.sendAt, form: item.form?.value ?? item.form };
          }),
        }),
      );

      if (results.status === 200) {
        setIsBlocking(false);
        history.push('/formao/bilans');
      } else {
        if (results.message) {
          message.error(results.message);
        } else {
          Object.entries(results.errors).forEach(([key, value]) => {
            if (key === 'studentStartAt' || key === 'studentEndAt') {
              methods.setError('studentFormationDates', { type: 'manual', message: value });
            } else if (key === 'studentId') {
              methods.setError('studentEmail', { type: 'manual', message: value });
            } else if (key === 'studentFormationId') {
              methods.setError('studentFormation', { type: 'manual', message: value });
            } else {
              methods.setError(key, { type: 'manual', message: value });
            }
          });
        }
      }
    });

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Tabs activeKey={tab} onChange={setTab}>
        <Tabs.TabPane tab={getTab('STUDENT')} key="STUDENT" />
        {(['COMPANY', 'STAGE'].includes(reportsType) || (reportsType === 'MENSUEL' && report?.data?.company?.name)) && (
          <Tabs.TabPane tab={getTab('COMPANY')} key="COMPANY" />
        )}
        <Tabs.TabPane tab={getTab('REPORTS')} key="REPORTS" />
      </Tabs>

      <FormProvider {...methods}>
        <Form layout="vertical" noValidate onFinish={methods.handleSubmit(onSubmit)}>
          {tab === 'STUDENT' && <StudentStep {...studentProps} />}
          {tab === 'COMPANY' &&
            (['STAGE', 'COMPANY'].includes(report.data.reportsType) ||
              (reportsType === 'MENSUEL' && report?.data?.company?.name)) && <CompanyStep disabled={isProcessing} />}
          {tab === 'REPORTS' && <ReportsStep {...reportsProps} />}
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/formao/bilans">Annuler</Link>
            </Button>
            <Space>
              <Button onClick={methods.handleSubmit(onSubmit)} disabled={isProcessing} loading={isProcessing}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                disabled={isProcessing}
                loading={isProcessing}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ReportUpdate;
