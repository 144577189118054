import { useFormContext, useFormState, useFieldArray, Controller } from 'react-hook-form';
import { Form, Card, Input, Button, message, Typography, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import OrganizeListLabels from './OrganizeListLabels';

const { Item } = Form;
const { Group } = Input;
const { Text } = Typography;

const OrganizeLists = ({ loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: lists, remove, append } = useFieldArray({ name: 'lists', control });

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
    cancelButtonProps: { disabled: loading },
  };

  const appendItem = () => {
    if (lists.length >= 3) message.error('Vous ne pouvez pas avoir plus de 3 colonnes');
    else append({ title: 'Nouvelle colonne', labels: [] });
  };

  const removeItem = (index) => {
    if (lists.length <= 2) message.error('Vous ne pouvez pas avoir moins de 3 colonnes');
    else remove(index);
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 20,
      }}
    >
      {lists.map((list, index) => (
        <Item className="organizelist-item" key={list.id} label={`Colonne ${index + 1}`}>
          <Card
            size="small"
            style={{ overflow: 'hidden', height: '100%' }}
            bodyStyle={{
              padding: 0,
              height: '100%',
            }}
            title={
              <Item
                className="organize-list-title"
                validateStatus={errors?.lists?.[index]?.title?.message && 'error'}
                help={errors?.lists?.[index]?.title?.message}
              >
                <Group compact>
                  <Controller
                    name={`lists.${index}.title`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} disabled={loading} style={{ width: 'calc(100% - 32px)', height: 32 }} />
                    )}
                  />
                  <Popconfirm {...popconfirmProps} onConfirm={() => removeItem(index)}>
                    <Button icon={<DeleteOutlined />} disabled={loading} />
                  </Popconfirm>{' '}
                </Group>
              </Item>
            }
          >
            <OrganizeListLabels listIndex={index} loading={loading} />
          </Card>
        </Item>
      ))}
      {lists.length < 3 && (
        <Item className="organizelist-item" label={`Colonne 3`}>
          <Card style={{ height: '100%' }} size="small" title="Colonne C">
            <Text>Éléments</Text>
            <div className="organizelist-item-overlay">
              <Button icon={<PlusOutlined />} disabled={loading} onClick={appendItem}>
                Activer la colonne
              </Button>
            </div>
          </Card>
        </Item>
      )}
    </div>
  );
};

export default OrganizeLists;
