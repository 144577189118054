import useSWR from 'swr';
import { Table } from 'antd';
import tablePagination from '../../../../lib/tablePagination';
import { groupRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';
import useColumnSearch from '../../../../hooks/useColumnSearch';

const FormationEditGroups = ({ formationId }) => {
  const { data: groups, isValidating } = useSWR(groupRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const registered = groups?.data.filter((group) => group.formation?._id === formationId) || [];

  const columns = [
    {
      title: 'Intitulé',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sort(a, b, 'name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Centre',
      dataIndex: 'agency',
      key: 'agency',
      render: (value, record) => {
        if (record.remote) {
          return 'Distanciel';
        }

        return value?.city || 'Introuvable';
      },
    },
  ];

  return (
    <Table
      loading={isValidating}
      dataSource={registered}
      columns={columns}
      bordered
      size="small"
      rowKey={(row) => row._id}
      pagination={tablePagination(registered)}
    />
  );
};

export default FormationEditGroups;
