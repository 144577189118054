import React, { useState } from "react";
import { Tooltip, Button, Modal, Image } from "antd";
import { FileImageOutlined } from "@ant-design/icons";

const ImageModal = ({ image }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Tooltip title="Voir l'image" destroyTooltipOnHide={{ keepParent: false }}>
      <Button
        icon={<FileImageOutlined />}
        shape="circle"
        onClick={() => setIsVisible(true)}
      />
      <Image
        src={image.secure_url}
        style={{ display: "none" }}
        preview={{
          visible: isVisible,
          onVisibleChange: (visible) => setIsVisible(visible),
        }}
      />
    </Tooltip>
  );
};

export default ImageModal;
