import { useMemo, useState } from 'react';
import { Button, Card, Col, Dropdown, Empty, Form, Input, Result, Row, Space, Typography, message } from 'antd';
import { Controller, FormProvider, useFieldArray, useForm, useFormState, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import AIBlock from './components/AIBlock';
import useFetch from '../../../hooks/useFetch';
import { keysRoutes } from '../../../lib/routes';

const filterAuthorizations = (authorizations) => {
  const aiAuthorizations = [];

  for (let i = 0; i < (authorizations || []).length; i++) {
    switch (authorizations[i].type) {
      case 'aiAuthorization':
        aiAuthorizations.push(authorizations[i].system);
      default:
        continue;
    }
  }

  return { aiAuthorizations };
};

const KeyCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'authorizations' });
  const authorizations = useWatch({ control: methods.control, name: 'authorizations' });
  const filteredAuthorizations = useMemo(() => filterAuthorizations(authorizations), [authorizations]);
  const { post } = useFetch();

  const handleOptionSelect = (value) => {
    append({ type: value });
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(keysRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsCreated(results.data.accessKey);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      {isCreated ? (
        <Result
          status="success"
          title="Nouvelle clé d'accès créée"
          subTitle={
            <Space direction="vertical" style={{ display: 'flex' }}>
              <Typography.Text type="secondary">
                Assurez-vous de copier la clé et de la garder dans un endroit sécurisé.
              </Typography.Text>
              <Typography.Text ellipsis copyable>
                {isCreated}
              </Typography.Text>
            </Space>
          }
          extra={[
            <Button type="primary" key="console">
              <Link to="/cles">Retour à la liste des clés</Link>
            </Button>,
          ]}
        />
      ) : (
        <FormProvider {...{ ...methods }} disabled={isProcessing}>
          <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
            <Typography.Paragraph type="secondary">
              <blockquote style={{ marginTop: 0 }}>
                <ul>
                  <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
                </ul>
              </blockquote>
            </Typography.Paragraph>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item
                  label="Intitulé (Application, Site web...)"
                  required
                  validateStatus={errors?.name?.message && 'error'}
                  help={errors?.name?.message}
                >
                  <Controller
                    control={methods.control}
                    name="name"
                    render={({ field }) => <Input {...field} disabled={isProcessing} />}
                  />
                </Form.Item>
                <Form.Item label="Autorisations">
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: [
                        {
                          key: 0,
                          label: 'Accès à une IA',
                          onClick: () => handleOptionSelect('aiAuthorization'),
                        },
                        {
                          key: 1,
                          label: 'Accès à une ressource Arinfo',
                          onClick: () => handleOptionSelect('RESOURCE_ARINFO'),
                          disabled: true,
                        },
                        {
                          key: 2,
                          label: 'Accès à une ressource Formao',
                          onClick: () => handleOptionSelect('RESOURCE_FORMAO'),
                          disabled: true,
                        },
                        {
                          key: 3,
                          label: 'Accès à une ressource I-magineLAB',
                          onClick: () => handleOptionSelect('RESOURCE_IMAGINELAB'),
                          disabled: true,
                        },
                        {
                          key: 4,
                          label: 'Accès à une ressource Posiao',
                          onClick: () => handleOptionSelect('RESOURCE_POSIAO'),
                          disabled: true,
                        },
                      ],
                    }}
                  >
                    <Form.Item
                      validateStatus={errors?.authorizations?.message && 'error'}
                      help={errors?.authorizations?.message}
                    >
                      <Button block danger={!!errors?.authorizations?.message} disabled={isProcessing}>
                        Ajouter une autorisation de la liste <DownOutlined />
                      </Button>
                    </Form.Item>
                  </Dropdown>
                </Form.Item>
                {fields.length === 0 ? (
                  <Empty />
                ) : (
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    {fields.map((field, index) => {
                      if (field.type === 'aiAuthorization') {
                        return (
                          <AIBlock
                            key={field.id}
                            index={index}
                            data={field}
                            onRemove={() => remove(index)}
                            authorizations={filteredAuthorizations.aiAuthorizations}
                          />
                        );
                      }

                      return null;
                    })}
                  </Space>
                )}
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
              <Button className="gray-text" disabled={isProcessing}>
                <Link to="/cles">Annuler</Link>
              </Button>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            </div>
          </Form>
        </FormProvider>
      )}
    </Card>
  );
};

export default KeyCreate;
