import { useFormContext, useFormState, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Input, Space, Button, Row, Col, Form, message } from 'antd';
import cn from 'classnames';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Item } = Form;
const { Group } = Input;

const ConnectTo = ({ loading, onSelect, selected }) => {
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: toList, append, remove } = useFieldArray({ name: 'toList', control });
  const lines = useWatch({ name: 'lines', control });

  const removeItem = (index) => {
    if (toList.length <= 3) {
      message.error('Vous ne pouvez pas avoir moins de 3 réponses possibles');
    } else {
      const id = toList[index].id;
      const lineIndex = lines.findIndex((line) => line.to === index);

      if (lineIndex !== -1) {
        const newLines = [...lines];
        newLines.splice(lineIndex, 1);
        setValue('lines', newLines);
      }

      remove(index);
    }
  };

  const appendItem = () => {
    if (toList.length === 6) message.error('Vous ne pouvez pas avoir plus de 6 réponses possibles');
    else append({ content: 'Nouvelle réponse' });
  };

  return (
    <Item label="Colonne droite">
      <Space direction="vertical" style={{ width: '100%' }}>
        {toList.map((item, index) => (
          <div key={item.id}>
            <Row>
              <Col span={21} order={2}>
                <Group compact>
                  <Controller
                    name={`toList.${index}.content`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} disabled={loading} style={{ width: 'calc(100% - 32px)', height: 32 }} />
                    )}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    disabled={loading || toList.length === 3}
                    onClick={() => removeItem(index)}
                  />
                </Group>
              </Col>
              <Col span={3} order={1} style={{ alignItems: 'center', display: 'flex' }}>
                <div
                  onClick={() => {
                    if (!loading) {
                      onSelect(index);
                    }
                  }}
                  className={cn(`square to-square-${index}`, {
                    selected: selected === index && lines.findIndex((line) => line.to === index) === -1,
                    linked: lines.findIndex((line) => line.to === index) !== -1,
                    linked_selected: selected === index && lines.findIndex((line) => line.to === index) !== -1,
                    square_disabled: loading,
                  })}
                />
              </Col>
            </Row>
            {errors?.toList?.[index]?.content && (
              <Row style={{ color: '#ff4d4f', float: 'right' }}>{errors?.toList?.[index]?.content?.message}</Row>
            )}
          </div>
        ))}
        <Button block icon={<PlusOutlined />} onClick={appendItem} disabled={loading || toList.length === 6}>
          Ajouter une réponse
        </Button>
      </Space>
    </Item>
  );
};

export default ConnectTo;
