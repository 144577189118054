import { useState } from 'react';
import { Result, Button, Space } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { QuestionCircleFilled } from '@ant-design/icons';
import StudentImportFormation from './StudentImportFormation';

const StudentImportPreFormation = ({ onPrev, onNext, onFinish }) => {
  const { setValue, control } = useFormContext();
  const withFormation = useWatch({ control, name: 'withFormation' });
  const [showFormationSelection, setShowFormationSelection] = useState(withFormation || false);

  const handleFormation = () => {
    setValue('withFormation', true);
    setShowFormationSelection(true);
  };

  const handleFinish = () => {
    setValue('withFormation', false);
    onFinish();
  };

  if (showFormationSelection) {
    return <StudentImportFormation onNext={onNext} onCancel={() => setShowFormationSelection(false)} />;
  }

  return (
    <>
      <Result
        icon={<QuestionCircleFilled />}
        title="Voulez-vous assigner une formations ?"
        subTitle="Les formations entrées/sorties doivent êtres définies individuellement après l'importation"
        extra={
          <Space direction="vertical" size="small">
            <Button onClick={handleFormation}>Assigner une formation</Button>
          </Space>
        }
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onPrev}>Précédent</Button>
        <Button onClick={handleFinish}>Terminer l'importation</Button>
      </div>
    </>
  );
};

export default StudentImportPreFormation;
