import { Extension } from '@tiptap/core';

import { Caption } from './Caption';
import { Figure } from './Figure';
import { Image } from './Image';

export const ContentEditorFigureExtension = Extension.create({
  name: 'figureExtension',
  addExtensions() {
    return [Figure, Image, Caption];
  },
});
