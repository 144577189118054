import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core';
import axios from 'axios';

// prettier-ignore
export const VIMEO_EMBED_REGEX = /^https:\/\/player\.vimeo\.com\/video\//;
// prettier-ignore
export const VIMEO_REGEX = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

export const isValidVimeoUrl = (url) => {
  console.log(url);
  if (!url) {
    return false;
  }

  return url.match(VIMEO_REGEX);
};

export const getEmbedUrl = (url) => {
  if (!url) {
    return null;
  }

  if (!url.match(VIMEO_REGEX)) {
    return null;
  }

  if (url.match(VIMEO_EMBED_REGEX)) {
    return url;
  }

  return axios
    .get(`https://vimeo.com/api/oembed.json?url=${url}`)
    .then(({ data }) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data.html, 'text/html');
      const iframe = doc.querySelector('iframe');
      const src = iframe.getAttribute('src');

      return src;
    })
    .catch(() => {
      return null;
    });
};

export const Vimeo = Node.create({
  name: 'vimeo',
  addOptions() {
    return {
      addPasteHandler: true,
      height: 480,
      width: 640,
      allowFullscreen: true,
      origin: '',
      HTMLAttributes: {},
      inline: false,
    };
  },
  inline() {
    return this.options.inline;
  },
  group() {
    return this.options.inline ? 'inline' : 'block';
  },
  draggable: true,
  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div[data-vimeo-video] iframe',
      },
    ];
  },
  addCommands() {
    return {
      setVimeoVideo:
        (options) =>
        ({ commands }) => {
          if (!isValidVimeoUrl(options.src)) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: VIMEO_REGEX,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input };
        },
      }),
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { 'data-vimeo-video': '' },
      [
        'iframe',
        mergeAttributes(
          this.options.HTMLAttributes,
          {
            width: this.options.width,
            height: this.options.height,
            allowfullscreen: this.options.allowFullscreen,
            origin: this.options.origin,
          },
          HTMLAttributes,
        ),
      ],
    ];
  },
});
