import OrganizeLists from './OrganizeLists';
import OrganizeLabels from './OrganizeLabels';

const Organize = ({ loading }) => {
  return (
    <>
      <OrganizeLists loading={loading} />
      <OrganizeLabels loading={loading} />
    </>
  );
};

export default Organize;
