import { useState, useEffect } from 'react';
import { Button, Input, Space, Table, Typography, Tag, Tooltip, Popconfirm, Descriptions, Form, message } from 'antd';
import { FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import qs from 'qs';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import useFetch from '../../../../hooks/useFetch';
import { quizRoutes } from '../../../../lib/routes';
import tablePagination from '../../../../lib/tablePagination';
import SendResultsModal from './SendResultsModal';

const Archives = ({ downloadPdf, createPDF, onDelete }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState();
  const [values, setValues] = useState({
    last_name: null,
    email: null,
    string: '',
  });
  const [quizzes, setQuizzes] = useState([]);
  const { user } = useAuth();
  const { get } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
    cancelButtonProps: { disabled: isProcessing },
  };

  useEffect(() => {
    const string = qs.stringify(
      {
        last_name: values.last_name,
        email: values.email,
      },
      { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true },
    );

    setValues((rest) => ({ ...rest, string }));
  }, [values.last_name, values.email]);

  const getQuizzes = async () => {
    setIsProcessing(true);

    const results = await get(quizRoutes.archives + values.string);

    if (results.status === 200) {
      setQuizzes(results.data);
      setErrors();
    } else {
      setQuizzes([]);
      if (results.message) {
        message.error(results.message);
      } else {
        setErrors(results.errors);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Catégorie',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      width: 150,
      render: (value) => {
        return value?.acronym ? (
          <Tag style={{ width: '100%' }}>{value?.acronym}</Tag>
        ) : (
          <Typography.Text type="danger">Catégorie introuvable</Typography.Text>
        );
      },
    },
    {
      title: 'Nom',
      key: 'last_name',
      render: (record) => record.identity.last_name.toUpperCase(),
    },
    {
      title: 'Prénom',
      key: 'type',
      render: (record) => record.identity.first_name.charAt(0).toUpperCase() + record.identity.first_name.slice(1),
    },
    {
      title: 'Centre',
      key: 'agency',
      dataIndex: 'agency',
      render: (value) => value?.city || <Typography.Text type="danger">Centre introuvable</Typography.Text>,
    },
    {
      title: "Date d'envoi",
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      align: 'center',
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    {
      title: 'Date de complétion',
      key: 'finishedAt',
      width: 150,
      align: 'center',
      render: (record) => format(new Date(record.results.finishedAt), 'dd/MM/yyyy'),
    },
    {
      title: 'Score',
      key: 'score',
      width: 120,
      align: 'center',
      render: (record) => {
        const { score, percentage } = record.results;
        return `${score}/30 (${percentage}%)`;
      },
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          <>
            {checkAuthorization(user, 'posiao', 'quizzes', 'read-results') && (
              <Tooltip title="Consulter le PDF" destroyTooltipOnHide={{ keepParent: false }}>
                <Button
                  className="gray-text"
                  onClick={() => {
                    if (record?.results?.pdf) {
                      downloadPdf(record?.results?.pdf);
                    } else {
                      createPDF(record, getQuizzes);
                    }
                  }}
                  icon={<FilePdfOutlined />}
                />
              </Tooltip>
            )}
            {checkAuthorization(user, 'posiao', 'quizzes', 'share') && <SendResultsModal quizId={record._id} />}
            {checkAuthorization(user, 'posiao', 'quizzes', 'delete-complete') && (
              <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
                <Popconfirm
                  {...popconfirmProps}
                  onConfirm={async () => {
                    await onDelete(record._id);
                    getQuizzes();
                  }}
                >
                  <Button className="gray-text" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            )}
          </>
        </Space>
      ),
    },
  ];

  const Expandable = (record) => {
    const Address = () => {
      if (record.address) {
        const {
          city = '',
          postal_code = '',
          street_type = '',
          street = '',
          street_indicator = '',
          street_number = '',
        } = record.address;

        const indicator = street_indicator ? `${street_indicator} ` : '';

        return record.address ? `${street_number} ${indicator}${street_type} ${street}, ${postal_code} ${city}` : null;
      }

      return null;
    };

    return (
      <Descriptions>
        <Descriptions.Item label="Date de naissance" span={3}>
          {record.identity.birth_date ? format(new Date(record.identity.birth_date), 'dd/MM/yyyy') : null}
        </Descriptions.Item>
        <Descriptions.Item label="Adresse email" span={3}>
          {record.contact.email ? record.contact.email : null}
        </Descriptions.Item>
        <Descriptions.Item label="Téléphone" span={3}>
          {record.contact.phone ? record.contact.phone.match(/.{2}/g).join(' ') : 'Non précisé'}
        </Descriptions.Item>
        <Descriptions.Item label="Adresse complète" span={3}>
          <Address />
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <>
      <Form className="archives-search-container">
        <Space align="start">
          <Form.Item validateStatus={errors?.search && 'error'} help={errors?.search} className="archives-input">
            <Input
              style={{ width: 250 }}
              placeholder="Nom"
              value={values.last_name}
              onChange={(e) =>
                setValues((rest) => ({ ...rest, last_name: e.target.value === '' ? null : e.target.value }))
              }
              allowClear
              onPressEnter={getQuizzes}
              disabled={isProcessing}
            />
          </Form.Item>
          <Form.Item
            validateStatus={(errors?.search || errors?.email) && 'error'}
            help={errors?.email}
            className="archives-input"
          >
            <Input
              style={{ width: 300 }}
              placeholder="Adresse email"
              value={values.email}
              onChange={(e) => setValues((rest) => ({ ...rest, email: e.target.value === '' ? null : e.target.value }))}
              allowClear
              onPressEnter={getQuizzes}
              disabled={isProcessing}
            />
          </Form.Item>

          <Button
            onClick={() =>
              setValues({
                last_name: null,
                email: null,
                string: '',
              })
            }
            loading={isProcessing}
          >
            Vider
          </Button>
          <Button type="primary" onClick={getQuizzes} loading={isProcessing}>
            Rechercher
          </Button>
        </Space>
      </Form>

      <Table
        size="small"
        bordered
        rowKey={(row) => row._id}
        loading={!quizzes || isProcessing}
        dataSource={quizzes}
        columns={columns}
        rowClassName={(record) => {
          if (record.results.percentage >= 70) return 'ant-table-row-success';
          return 'ant-table-row-warning';
        }}
        expandable={{
          expandedRowRender: (record) => Expandable(record),
        }}
        pagination={tablePagination(quizzes)}
      />
    </>
  );
};
export default Archives;
