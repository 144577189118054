import { useFormContext, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const PostPreview = ({ defaultDate }) => {
  const { control } = useFormContext();
  const content = useWatch({ control, name: 'content' });
  const title = useWatch({ control, name: 'title' });

  return (
    <>
      <h1 className="post-title">{title}</h1>
      <h2 className="post-date">
        {defaultDate
          ? format(new Date(defaultDate), 'dd MMMM yyyy', { locale: fr })
          : format(Date.now(), 'dd MMMM yyyy', { locale: fr })}
      </h2>
      <div className="post-content" dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};

export default PostPreview;
