import { useState, useRef, useCallback } from 'react';
import { useFormContext, useFormState, useFieldArray, Controller } from 'react-hook-form';
import {
  Button,
  Space,
  Collapse,
  Popconfirm,
  Input,
  Upload,
  Typography,
  Tooltip,
  message,
  notification,
  Progress,
} from 'antd';
import { DragOutlined, DeleteOutlined, UploadOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDrop, useDrag } from 'react-dnd';
import { serialize } from 'object-to-formdata';
import ModuleEditCourses from './ModuleEditCourses';
import ModuleEditTitle from './ModuleEditTitle';
import { uploadRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const { Panel } = Collapse;
const { Group } = Input;

const ModuleEditChapters = ({ disabled, setActiveModal }) => {
  const [toAppend, setToAppend] = useState({});
  const [loading, setLoading] = useState([]);
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: chapters, append, remove, move } = useFieldArray({ control, name: 'chapters' });
  const [activeKeys, setActiveKeys] = useState(chapters.map((chapter) => chapter._id));
  const { post, remove: fetchRemove } = useFetch();
  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onKeyChange = (key) => {
    const newKeys = [...activeKeys];
    const index = newKeys.indexOf(key);

    if (index !== -1) {
      newKeys.splice(index, 1);
    } else {
      newKeys.push(key);
    }

    setActiveKeys(newKeys);
  };

  const onFileChange = async (index, { file }, onChange) => {
    setLoading((rest) => [...rest, index]);

    notification.open({
      key: 'upload',
      message: 'Téléversement du fichier',
      description: 'Cela peu prendre quelques minutes.',
      icon: <LoadingOutlined />,
      closeIcon: <></>,
      duration: null,
    });

    const size = file.size;
    const chunkSize = 5 * 1024 * 1024;
    const chunkCount = size / chunkSize;
    const fileName = 'upload_' + Date.now();

    if (file.type !== 'application/pdf') {
      const newLoading = [...loading];
      const loadingIndex = newLoading.indexOf((i) => i === index);

      newLoading.splice(loadingIndex, 1);

      setLoading(newLoading);

      notification.error({
        key: 'upload',
        message: 'Téléversement du fichier',
        description: 'Seulement les fichiers PDF sont acceptés.',
        duration: 3,
      });

      return false;
    }

    if (file.size / 1024 / 1024 > 100) {
      const newLoading = [...loading];
      const loadingIndex = newLoading.indexOf((i) => i === index);

      newLoading.splice(loadingIndex, 1);

      setLoading(newLoading);

      notification.error({
        key: 'upload',
        message: 'Téléversement du fichier',
        description: 'Ce fichier est trop lourd, le poids maximum est de 100Mo.',
        duration: 3,
      });

      return false;
    }

    for (let i = 0; i < chunkCount; i++) {
      const startPos = chunkSize * i;
      const endPos = chunkSize * (i + 1);
      const percent = Math.round(((chunkSize * (i + 1)) / size) * 100);

      const formData = serialize(
        { file, chunk: file.slice(startPos, endPos), index: i, total: chunkCount, name: fileName },
        { indices: true },
      );

      const results = await post(uploadRoutes.largeFile, formData, 'multipart/form-data', false);

      if (results.status === 201) {
        onChange({ originalName: results.data.originalName, name: results.data.name, _id: results.data._id });

        notification.success({
          key: 'upload',
          message: 'Téléversement du fichier',
          description: 'Téléversement terminé.',
          duration: 3,
        });
      } else if (results.status === 200) {
        notification.open({
          key: 'upload',
          message: 'Téléversement du fichier',
          description: <Progress percent={percent} />,
          icon: <LoadingOutlined />,
          closeIcon: <></>,
          duration: null,
        });

        continue;
      } else {
        if (results.message) {
          notification.error({
            key: 'upload',
            message: 'Téléversement du fichier',
            description: results.message,
            duration: 3,
          });
        } else {
          notification.error({
            key: 'upload',
            message: 'Téléversement du fichier',
            description: results.errors,
            duration: 3,
          });
        }

        break;
      }
    }

    const newLoading = [...loading];
    const loadingIndex = newLoading.indexOf((i) => i === index);

    newLoading.splice(loadingIndex, 1);

    setLoading(newLoading);
  };

  const onFileDelete = async (index, id, onChange) => {
    setLoading((rest) => [...rest, index]);

    const results = await fetchRemove(uploadRoutes.deleteFile + '/' + id);

    if (results.status === 200) {
      onChange(null);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    const newLoading = [...loading];
    const loadingIndex = newLoading.indexOf((i) => i === index);

    newLoading.splice(loadingIndex, 1);

    setLoading(newLoading);
  };

  const DraggableCard = ({ chapter, index, open, onChange }) => {
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: 'CHAPTER',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        move(dragIndex, hoverIndex);
        forceUpdate();
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'CHAPTER',
      item: { id: chapter.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <div ref={previewRef} style={{ opacity }}>
        <Collapse
          activeKey={open ? chapter._id || chapter.id : null}
          onChange={() => onChange(chapter._id || chapter.id)}
          className="module-collapse"
        >
          <Panel
            key={chapter._id || chapter.id}
            header={
              <div
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                <ModuleEditTitle fieldName={`chapters.${index}.title`} />
              </div>
            }
            style={{ marginBottom: 20, padding: 0 }}
            className="drag-collapse"
            extra={
              <Space>
                <Controller
                  control={control}
                  name={`chapters.${index}.file`}
                  render={({ field }) => (
                    <div onClick={(e) => e.stopPropagation()}>
                      <Group compact>
                        <Upload
                          beforeUpload={() => false}
                          onChange={(file) => onFileChange(index, file, field.onChange)}
                          maxCount={1}
                          accept=".pdf"
                          showUploadList={false}
                          disabled={disabled || loading.includes(index)}
                        >
                          <Button icon={<UploadOutlined />} loading={loading.includes(index)} disabled={disabled}>
                            <Typography.Text
                              ellipsis={true}
                              style={{
                                width: 130,
                                marginLeft: 8,
                                textAlign: 'left',
                              }}
                            >
                              {field.value?.originalName || 'Téléverser un fichier'}
                            </Typography.Text>
                          </Button>
                        </Upload>
                        <Tooltip title="Télécharger le PDF">
                          <a
                            href={`${process.env.REACT_APP_BASE_URL_API_URL}/download/formao/${field.value?.name}.pdf`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              icon={<DownloadOutlined />}
                              disabled={!field.value?._id || loading.includes(index)}
                            />
                          </a>
                        </Tooltip>
                        <Tooltip title="Supprimer le PDF">
                          <Popconfirm
                            {...popconfirmProps}
                            onConfirm={() => onFileDelete(index, field.value._id, field.onChange)}
                            disabled={!field.value?._id || loading.includes(index)}
                          >
                            <Button icon={<DeleteOutlined />} disabled={!field.value?._id || loading.includes(index)} />
                          </Popconfirm>
                        </Tooltip>
                      </Group>
                    </div>
                  )}
                />
                <div onClick={(e) => e.stopPropagation()}>
                  <Button ref={ref} data-handler-id={handlerId} icon={<DragOutlined />} disabled={disabled} />
                </div>
                <div onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    {...popconfirmProps}
                    onConfirm={(e) => {
                      remove(index);
                    }}
                  >
                    <Button icon={<DeleteOutlined />} disabled={disabled} />
                  </Popconfirm>
                </div>
              </Space>
            }
          >
            <ModuleEditCourses
              setActiveModal={setActiveModal}
              disabled={disabled}
              chapterIndex={index}
              onUpdate={forceUpdate}
              toAppend={toAppend[chapter.id] || 1}
              onToAppendChange={(value) => setToAppend((rest) => ({ ...rest, [chapter.id]: value }))}
            />
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <>
      {chapters.map((chapter, index) => (
        <DraggableCard
          key={chapter.id}
          open={activeKeys.includes(chapter._id || chapter.id)}
          onChange={onKeyChange}
          chapter={chapter}
          index={index}
        />
      ))}
      <Button
        block
        disabled={disabled}
        onClick={() => append({ title: 'Nouveau cours', courses: [] })}
        type="primary"
        style={{ marginBottom: 20 }}
      >
        Ajouter un cours
      </Button>
    </>
  );
};

export default ModuleEditChapters;
