import { useState } from 'react';
import { Card, Table, Switch, Button, Popconfirm, Space } from 'antd';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { imaginelabDocumentsRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import CreateModal from './CreateModal';
import { sort, checkAuthorization } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import EditModal from './EditModal';
import { useAuth } from '../../../authContext';

const ImaginelabDocumentsList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [toEdit, setToEdit] = useState(null);
  const { data: documents, isValidating, mutate } = useSWR(imaginelabDocumentsRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { user } = useAuth();
  const { patch, remove } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onEnableDisable = async (value, id) => {
    setIsProcessing(true);

    const results = await patch(
      (value ? imaginelabDocumentsRoutes.enable : imaginelabDocumentsRoutes.disable) + '/' + id,
    );

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(imaginelabDocumentsRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      ...getColumnSearchProps('title'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => sort(a, b, 'description'),
      ...getColumnSearchProps('description'),
      sortDirections: ['ascend', 'descend'],
    },
    ...(checkAuthorization(user, 'imaginelab', 'documents', 'enable-document') ||
    checkAuthorization(user, 'imaginelab', 'documents', 'disable-document')
      ? [
          {
            title: 'Actif',
            dataIndex: 'disabled',
            key: 'disabled',
            width: 100,
            align: 'center',
            render: (value, record) => (
              <Switch
                checked={!value}
                onChange={() => {
                  const canAccess = value
                    ? checkAuthorization(user, 'imaginelab', 'documents', 'enable-document')
                    : checkAuthorization(user, 'imaginelab', 'documents', 'disable-document');

                  if (canAccess) {
                    onEnableDisable(value, record._id);
                  }
                }}
                disabled={
                  isProcessing || value
                    ? !checkAuthorization(user, 'imaginelab', 'documents', 'enable-document')
                    : !checkAuthorization(user, 'imaginelab', 'documents', 'disable-document')
                }
              />
            ),
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'imaginelab', 'documents', 'update-document') && (
            <Button
              className="gray-text"
              icon={<EditOutlined />}
              onClick={() => setToEdit(record)}
              disabled={isProcessing}
            />
          )}
          {checkAuthorization(user, 'imaginelab', 'documents', 'delete-document') && (
            <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
              <Button className="gray-text" icon={<DeleteOutlined />} disabled={isProcessing} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'imaginelab', 'documents', 'create-document') && (
        <CreateModal loading={isProcessing} onFinish={mutate} />
      )}
      <EditModal loading={isProcessing} onFinish={mutate} data={toEdit} onClose={() => setToEdit(null)} />
      <Table
        loading={!documents || isValidating}
        dataSource={documents?.data || []}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination(documents?.data)}
      />
    </Card>
  );
};

export default ImaginelabDocumentsList;
