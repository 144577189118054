import { Modal, Form, Input, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useForm, useFormState, Controller, FormProvider } from 'react-hook-form';
import useFetch from '../../../../hooks/useFetch';
import { externalInterventionRoutes } from '../../../../lib/routes';
import DebounceSelect from './DebounceSelect';
import { sort } from '../../../../shared/utils';

const UpdateInterventionModal = ({ isOpen, setIsOpen, onFinish }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [typeOptions, setTypeOptions] = useState(false);
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { put, get } = useFetch();

  useEffect(() => {
    if (isOpen) {
      methods.setValue('last_name', isOpen.last_name);
      methods.setValue('first_name', isOpen.first_name);
      methods.setValue('email', isOpen.email);
      methods.setValue('type', isOpen.type);
    }
  }, [isOpen]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(externalInterventionRoutes.default + `/${isOpen._id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsOpen(false);
      setTypeOptions([]);
      methods.reset();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const fetchTypesList = async (value) => {
    const results = await get(externalInterventionRoutes.default + '/types/?search=' + value);

    if (results.status !== 200) {
      return [];
    } else {
      const formatted = results.data.map((result) => ({
        label: result,
        value: result,
      }));
      const sorted = formatted.sort((a, b) => sort(a, b, 'label'));

      return sorted;
    }
  };

  return (
    <Modal
      title="Modifier une intervention externe"
      open={isOpen}
      onOk={methods.handleSubmit(onSubmit)}
      onCancel={() => {
        setIsOpen(null);
        setTypeOptions([]);
        methods.clearErrors();
      }}
      destroyOnClose={true}
    >
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label="Type d'intervention"
            required
            validateStatus={errors?.type?.message && 'error'}
            help={errors?.type?.message}
          >
            <DebounceSelect
              fieldName="type"
              fetchOptions={fetchTypesList}
              options={typeOptions}
              setOptions={setTypeOptions}
              disabled={isProcessing}
            />
          </Form.Item>
          <Form.Item
            label="Nom"
            required
            validateStatus={errors?.last_name?.message && 'error'}
            help={errors?.last_name?.message}
          >
            <Controller
              control={methods.control}
              name="last_name"
              render={({ field }) => <Input {...field} disabled={isProcessing} placeholder="Exemple : DOE" />}
            />
          </Form.Item>
          <Form.Item
            label="Prénom"
            required
            validateStatus={errors?.first_name?.message && 'error'}
            help={errors?.first_name?.message}
          >
            <Controller
              control={methods.control}
              name="first_name"
              render={({ field }) => <Input {...field} disabled={isProcessing} placeholder="Exemple : John" />}
            />
          </Form.Item>
          <Form.Item
            label="Adresse mail"
            required
            validateStatus={errors?.email?.message && 'error'}
            help={errors?.email?.message}
          >
            <Controller
              control={methods.control}
              name="email"
              render={({ field }) => (
                <Input {...field} disabled={isProcessing} placeholder="Exemple : john.doe@mail.com" />
              )}
            />
          </Form.Item>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default UpdateInterventionModal;
