import { Button, Table, Popconfirm, Card, Space, message, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { cpfRoutes } from '../../../lib/routes';
import { checkAuthorization } from '../../../shared/utils';
import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import CPFViewer from './CPFViewer';

const CPFList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: cpf, isValidating, mutate } = useSWR(cpfRoutes.default);
  const { user } = useAuth();
  const { remove } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(cpfRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Intitulé',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '',
      key: 'actions',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          <CPFViewer data={record} />
          {checkAuthorization(user, 'arinfo', 'cpf', 'update-cpf') && (
            <Link to={`/arinfo/simulateur-cpf/modifier-une-grille/${record._id}`}>
              <Button className="gray-text" disabled={isProcessing} icon={<EditOutlined />} />
            </Link>
          )}
          {checkAuthorization(user, 'arinfo', 'cpf', 'delete-cpf') && (
            <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
              <Button className="gray-text" disabled={isProcessing} icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'cpf', 'import') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Space>
            <Link to="/arinfo/simulateur-cpf/import">
              <Button disabled={isProcessing}>Importer de nouvelles grilles</Button>
            </Link>
            <Link to="/arinfo/simulateur-cpf/durees-conseillees">
              <Button disabled={isProcessing}>Configuration des durées conseillées</Button>
            </Link>
          </Space>
        </div>
      )}
      <Tabs>
        <Tabs.TabPane tab="Grilles tarifaires" key="prices">
          <Table
            loading={!cpf || isValidating || isProcessing}
            dataSource={cpf?.data || []}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(cpf?.data)}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default CPFList;
