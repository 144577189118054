import { useState } from 'react';
import { Card, Button, Tabs, Form } from 'antd';
import { Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import Meta from '../components/Meta';
import Completion from './Completion';
import { Preview, CompletionPreview } from '../components/Preview/index';

const { TabPane } = Tabs;

const CreateCompletionQuestion = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm({
    defaultValues: {
      time: 30,
    },
  });

  const onSubmit = (form) => console.log(form);

  return (
    <Card>
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Tabs defaultActiveKey="form">
            <TabPane tab="Formulaire" key="form">
              <Meta
                loading={isProcessing}
                withImage={false}
                withQuestion={false}
                //prettier-ignore
                instructions={['Remplir la zone de texte et sélectionner les parties de textes que vous souhaitez transformer en trou une par une puis cliquez sur le bouton remplacer. Si vous avez fais une erreur, survolez le texte surligné puis cliquez sur supprimer.']}
              />
              <Completion loading={isProcessing} />
            </TabPane>
            <TabPane tab="Prévisualisation" key="preview">
              <Preview>
                <CompletionPreview />
              </Preview>
            </TabPane>
          </Tabs>
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/posiao/banque-de-question">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default CreateCompletionQuestion;
