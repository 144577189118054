import { memo } from 'react';
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Card, Image, List, Popconfirm, Popover, Space, Tooltip, Typography } from 'antd';
import useSWR from 'swr';
import NewApiKeyModal from './NewApiKeyModal';
import NewVersionModal from './NewVersionModal';
import { aiRoutes } from '../../../../lib/routes';
import TestModal from './TestModal';
import useFetch from '../../../../hooks/useFetch';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const SystemListItem = memo(({ item }) => {
  const withLogo = !!item?.logo;
  const withDescription = !!item?.description;
  const { user } = useAuth();
  const { remove } = useFetch();
  const {
    data: versions,
    isValidating: isVersionsValidating,
    mutate: mutateVersions,
  } = useSWR(aiRoutes.default + '/' + item.id + '/versions', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    data: keys,
    isValidating: isKeysValidating,
    mutate: mutateKeys,
  } = useSWR(aiRoutes.default + '/' + item.id + '/keys', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const onDeleteVersion = async (id) => {
    const results = await remove(aiRoutes.versions + '/' + id);

    if (results.status === 200) {
      mutateVersions();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const onDeleteKey = async (id) => {
    const results = await remove(aiRoutes.keys + '/' + id);

    if (results.status === 200) {
      mutateKeys();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  return (
    <Card
      bordered
      style={{ marginBottom: 24 }}
      size="small"
      title={
        <Space>
          {withLogo && (
            <div style={{ width: 24, height: 24 }}>
              <Image src={item.logo} preview={false} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
          )}
          <Typography.Text>{item.name}</Typography.Text>
        </Space>
      }
      extra={(item?.links || []).map((item, index) => (
        <Typography.Link key={index} href={item.url} target="_blank" rel="noopener noreferrer">
          {item.label}
        </Typography.Link>
      ))}
    >
      {withDescription ? (
        <Typography.Text>{item.description}</Typography.Text>
      ) : (
        <Typography.Text type="secondary">
          <i>Aucune description.</i>
        </Typography.Text>
      )}
      {checkAuthorization(user, 'ai', 'versions', 'search') || checkAuthorization(user, 'ai', 'keys', 'search') ? (
        <div style={{ display: 'flex', marginTop: '1em' }} className="ai-list-compact">
          {checkAuthorization(user, 'ai', 'versions', 'search') && (
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <List
                header={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography.Text>Versions</Typography.Text>
                    {checkAuthorization(user, 'ai', 'versions', 'create') && (
                      <NewVersionModal id={item.id} ai={item.name} onFinish={mutateVersions} />
                    )}
                  </div>
                }
                bordered
                size="small"
                dataSource={versions?.data}
                loading={isVersionsValidating}
                rowKey={(dataItem) => dataItem._id}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 3,
                  size: 'small',
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                  },
                }}
                renderItem={(dataItem) => (
                  <List.Item style={{ marginLeft: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography.Text ellipsis>{dataItem.name}</Typography.Text>
                        <Space size="small">
                          {checkAuthorization(user, 'ai', 'versions', 'update') && (
                            <Tooltip title="Modifier la version" destroyTooltipOnHide>
                              <Button key="update" size="small" icon={<EditOutlined />} disabled />
                            </Tooltip>
                          )}
                          {checkAuthorization(user, 'ai', 'versions', 'delete') && (
                            <Tooltip title="Supprimer la version" destroyTooltipOnHide>
                              <Popconfirm {...popconfirmProps} onConfirm={() => onDeleteVersion(dataItem._id)}>
                                <Button size="small" icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </Space>
                      </div>
                      <Typography.Text type="secondary" ellipsis>
                        {dataItem.value}
                      </Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
          {checkAuthorization(user, 'ai', 'keys', 'search') && (
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <List
                header={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography.Text>Clés API</Typography.Text>
                    {checkAuthorization(user, 'ai', 'keys', 'create') && (
                      <NewApiKeyModal id={item.id} ai={item.name} onFinish={mutateKeys} />
                    )}
                  </div>
                }
                bordered
                size="small"
                dataSource={keys?.data}
                loading={isKeysValidating}
                rowKey={(dataItem) => dataItem._id}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 3,
                  size: 'small',
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                  },
                }}
                renderItem={(dataItem) => (
                  <List.Item style={{ marginLeft: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Space size="small">
                          <Typography.Text ellipsis>{dataItem.name}</Typography.Text>
                          {!dataItem.restrictions || dataItem.restrictions?.length === 0 ? (
                            <Typography.Text type="secondary">Aucune restriction</Typography.Text>
                          ) : (
                            <Popover
                              content={dataItem.restrictions.map((dataItem) => dataItem?.name || dataItem).join(', ')}
                            >
                              <Typography.Link>
                                {dataItem.restrictions?.length} restriction{dataItem.restrictions?.length >= 2 && 's'}
                              </Typography.Link>
                            </Popover>
                          )}
                        </Space>
                        <Space size="small">
                          {checkAuthorization(user, 'ai', 'keys', 'test') && (
                            <TestModal ai={item} item={dataItem} versions={versions} />
                          )}
                          {checkAuthorization(user, 'ai', 'keys', 'update') && (
                            <Tooltip title="Modifier la clé" destroyTooltipOnHide>
                              <Button size="small" icon={<EditOutlined />} disabled />
                            </Tooltip>
                          )}
                          {checkAuthorization(user, 'ai', 'keys', 'delete') && (
                            <Tooltip title="Supprimer la clé" destroyTooltipOnHide>
                              <Popconfirm {...popconfirmProps} onConfirm={() => onDeleteKey(dataItem._id)}>
                                <Button size="small" icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </Space>
                      </div>
                      <Typography.Text type="secondary" ellipsis>
                        {'*'.repeat(50)}
                      </Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
      ) : null}
    </Card>
  );
});

SystemListItem.displayName = 'SystemListItem';
export default SystemListItem;
