import { useState, memo, useEffect } from 'react';
import { useForm, FormProvider, useFormState, useFormContext } from 'react-hook-form';
import { Card, Form, Tabs, Typography, Button, message, Badge } from 'antd';
import { Link, useHistory, Prompt } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { studentRoutes } from '../../../../lib/routes';
import StudentCreateAccount from './StudentCreateAccount';
import StudentCreateFormation from './StudentCreateFormation';
import { useAuth } from '../../../../authContext';

const { TabPane } = Tabs;

const TabPaneTitle = memo(({ children, errorFields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const tabErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

  return (
    <Badge
      dot={tabErrors.length !== 0}
      status="error"
      title={`${tabErrors.length} erreur${tabErrors.length > 1 ? 's' : ''}`}
    >
      {children}
    </Badge>
  );
});

const StudentCreate = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const methods = useForm({
    defaultValues: {
      referent: user._id,
      custom_formation: false,
      units: [{ title: 'Ma première unité !', modules: [] }],
    },
  });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(studentRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/formao/apprenants');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="student-create">
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
                <li>Le nom d'utilisateur et le mot de passe seront générés puis envoyés par email à l'apprenant</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Tabs defaultActiveKey="account">
            <TabPane
              tab={
                <TabPaneTitle errorFields={['last_name', 'first_name', 'email', 'agency', 'referent']}>
                  Compte
                </TabPaneTitle>
              }
              key="account"
            >
              <StudentCreateAccount disabled={isProcessing} />
            </TabPane>
            <TabPane
              tab={<TabPaneTitle errorFields={['formation', 'startAt', 'endAt']}>Formation</TabPaneTitle>}
              key="training"
            >
              <StudentCreateFormation disabled={isProcessing} />
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/formao/apprenants">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default StudentCreate;
