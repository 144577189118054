import React, { useState, useEffect } from 'react';
import { Tabs, Table, Tag, Dropdown, Button, Menu, Popconfirm, message, Card, Avatar } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { uniqBy } from 'lodash';
import { sort, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import useColumnSearch from '../../../hooks/useColumnSearch';
import CreateUserDrawer from './components/CreateUserDrawer';
import UpdateRoleModal from './components/UpdateRoleModal';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { userRoutes } from '../../../lib/routes';

const Data = (props) => (
  <Table
    {...props}
    bordered
    size="small"
    scroll={{ x: 1024 }}
    rowKey={(row) => row._id}
    pagination={tablePagination(props?.dataSource)}
  />
);

// TODO: Correction de la superposition du menu déroulant et du modal d'édition du rôle
const UsersList = () => {
  const { data: users, isValidating, mutate } = useSWR(userRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('enabled');
  const { user, token } = useAuth();
  const { put } = useFetch();
  const [data, setData] = useState({
    activeUsers: [],
    disabledUsers: [],
    roles: [],
  });

  const resetPassword = async (id) => {
    setIsProcessing(true);

    const results = await put(userRoutes.resetPassword + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableAccount = async (id) => {
    setIsProcessing(true);

    const results = await put(userRoutes.disableAccount, JSON.stringify({ ids: [id] }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const enableAccount = async (id) => {
    setIsProcessing(true);

    const results = await put(userRoutes.enableAccount, JSON.stringify({ ids: [id] }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const Actions = ({ record }) => (
    <Menu>
      {checkAuthorization(user, 'global', 'users', 'reset-password') && (
        <Menu.Item key="reset" className="gray-text">
          <Popconfirm {...popconfirmProps} onConfirm={() => resetPassword(record._id)}>
            Réinitialiser le mot de passe
          </Popconfirm>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'global', 'users', 'update-role') && (
        <Menu.Item key="update" className="gray-text">
          <UpdateRoleModal user={record} onFinish={mutate} token={token} />
        </Menu.Item>
      )}
      {activeTab === 'disabled'
        ? checkAuthorization(user, 'global', 'users', 'enable-account') && (
            <Menu.Item key="enable" className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => enableAccount(record._id)}>
                Activer le compte
              </Popconfirm>
            </Menu.Item>
          )
        : checkAuthorization(user, 'global', 'users', 'disable-account') && (
            <Menu.Item key="disable" className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => disableAccount(record._id)}>
                Désactiver le compte
              </Popconfirm>
            </Menu.Item>
          )}
    </Menu>
  );

  const columns = [
    {
      width: 50,
      dataIndex: 'avatar',
      key: 'avatar',
      align: 'center',
      render: (value, record) => (
        <Avatar
          src={value?.secure_url}
          style={{ textTransform: 'uppercase', fontSize: 16, backgroundColor: '#9BA1CB' }}
          size={32}
        >
          {record.first_name.charAt(0) + record.last_name.charAt(0)}
        </Avatar>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => sort(a, b, 'last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
      render: (value) => value.toUpperCase(),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => sort(a, b, 'first_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('first_name'),
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1),
    },
    {
      title: "Nom d'utilisateur",
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => sort(a, b, 'username'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => sort(a, b, 'email'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Rôle',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      filters: data.roles,
      sorter: (a, b) => sort(a, b, 'role.name', 'Aucun rôle'),
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => (record.role !== null ? record.role._id === value : value === 'empty'),
      render: (value) =>
        value !== null ? (
          <Tag key="role" color={value.color}>
            {value.name}
          </Tag>
        ) : (
          <Tag key="role">Aucun rôle</Tag>
        ),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (record) =>
        user._id === record._id ? null : (
          <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
            <Button className="gray-text" icon={<MoreOutlined />} />
          </Dropdown>
        ),
    },
  ];

  const handleTabChange = (key) => setActiveTab(key);

  useEffect(() => {
    if (users && users.data) {
      // Récupère la liste des comptes actifs
      const activeUsers = users.data.filter((user) => !user.disabled);
      // Récupère la liste des comptes désactivés
      const disabledUsers = users.data.filter((user) => user.disabled);
      // Récupère la liste des rôles
      const roles = users.data.map((user) =>
        user.role !== null
          ? {
              text: user.role.name,
              value: user.role._id,
            }
          : {
              text: 'Aucun rôle',
              value: 'empty',
            },
      );

      // Retire les duplicatas des rôles
      const cleanRoles = uniqBy(roles, 'value');

      setData({ activeUsers, disabledUsers, roles: cleanRoles });
    }
  }, [users]);

  const tableProps = {
    loading: !users || isValidating || isProcessing,
    dataSource: activeTab === 'disabled' ? data.disabledUsers : data.activeUsers,
    columns: columns,
  };

  return (
    <Card>
      {checkAuthorization(user, 'global', 'users', 'create-account') && <CreateUserDrawer onCreate={mutate} />}
      <Tabs defaultActiveKey="enabled" activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane tab={`Actifs (${data.activeUsers.length})`} key="enabled">
          <Data {...tableProps} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Inactifs (${data.disabledUsers.length})`} key="disabled">
          <Data {...tableProps} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default UsersList;
