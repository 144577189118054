import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import cookies from 'js-cookie';
import { Spin } from 'antd';
import { SWRConfig } from 'swr';

const userURL = `${process.env.REACT_APP_BASE_URL_API_URL}/global/users/account`;

const AuthContext = createContext({
  isAuth: false,
  user: null,
  token: null,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const useProvideAuth = () => {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const login = (authToken, loggedUser, cb) => {
    // Enregistre le token de connexion
    cookies.set('admin-auth', authToken, { expires: 99999 });

    setToken(authToken);
    setUser(loggedUser);
    setIsAuth(true);
    if (loading) setLoading(false);
    cb();
  };

  const logout = (cb) => {
    // Supprime le token de connexion
    cookies.remove('admin-auth');

    setUser(null);
    setIsAuth(false);
    if (loading) setLoading(false);
    cb();
  };

  const reloadUser = () => {
    // Récupère le token de connexion
    const authToken = cookies.get('admin-auth');

    if (authToken) {
      axios
        .get(userURL, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
        .then(({ data }) => {
          if (data.data.disabled) {
            logout(() => {});
          } else {
            setToken(authToken);
            setUser(data.data);
            setIsAuth(true);
            setLoading(false);
          }
        })
        .catch(() => logout(() => {}));
    } else {
      setLoading(false);
    }
  };

  return { loading, isAuth, user, token, login, logout, reloadUser };
};

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  useEffect(() => {
    auth.reloadUser();
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {auth.loading ? (
        <div
          style={{
            backgroundColor: '#f0f2f5',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <SWRConfig
          value={{
            fetcher: (...args) =>
              fetch(...args, {
                headers: { Authorization: `Bearer ${auth.token}` },
              }).then((res) => res.json()),
          }}
        >
          {children}
        </SWRConfig>
      )}
    </AuthContext.Provider>
  );
};
