import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import CloudinaryStatus from './CloudinaryStatus';
import { useAuth } from '../authContext';
import { CloudinaryProvider } from '../cloudinaryContext';

const { Content } = Layout;

const PageLayout = ({ children }) => {
  const location = useLocation();
  const { isAuth } = useAuth();

  return isAuth && location.pathname !== '/connexion' ? (
    <CloudinaryProvider>
      <Layout>
        <Sidebar />
        <Layout>
          <Navbar />
          <Content style={{ marginTop: 48, marginLeft: 256, minHeight: '100vh' }}>
            <div style={{ padding: 20 }}>{children}</div>
          </Content>
          <CloudinaryStatus />
        </Layout>
      </Layout>
    </CloudinaryProvider>
  ) : (
    children
  );
};

export default PageLayout;
