import { useFormContext, useFormState, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Input, Space, Button, Col, Row, Form, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';

const { Item } = Form;
const { Group } = Input;

const ConnectFrom = ({ loading, onSelect, selected }) => {
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: fromList, append, remove } = useFieldArray({ name: 'fromList', control });
  const lines = useWatch({ name: 'lines', control });

  const removeItem = (index) => {
    if (fromList.length <= 3) {
      message.error('Vous ne pouvez pas avoir moins de 3 choix possibles');
    } else {
      const id = fromList[index]._id || fromList[index].id;
      const lineIndex = lines.findIndex((line) => line.from === id);

      if (lineIndex !== -1) {
        const newLines = [...lines];
        newLines.splice(lineIndex, 1);
        setValue('lines', newLines);
      }

      remove(index);
    }
  };

  const appendItem = () => {
    if (fromList.length === 6) message.error('Vous ne pouvez pas avoir plus de 6 choix possibles');
    else append({ content: 'Nouveau choix' });
  };

  return (
    <Item label="Colonne gauche">
      <Space direction="vertical" style={{ width: '100%', display: 'flex' }}>
        {fromList.map((item, index) => {
          const id = item._id || item.id;

          return (
            <div key={id}>
              <Row>
                <Col span={21}>
                  <Group compact>
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={loading || fromList.length === 3}
                      onClick={() => removeItem(index)}
                    />
                    <Controller
                      name={`fromList.${index}.content`}
                      control={control}
                      render={({ field }) => (
                        <Input {...field} disabled={loading} style={{ width: 'calc(100% - 32px)', height: 32 }} />
                      )}
                    />
                  </Group>
                </Col>
                <Col span={3} style={{ alignItems: 'center', display: 'flex' }}>
                  <div
                    onClick={() => {
                      if (!loading) {
                        onSelect(index);
                      }
                    }}
                    className={cn(`square from-square-${index}`, {
                      selected: selected === index && lines.findIndex((line) => line.from === index) === -1,
                      linked: lines.findIndex((line) => line.from === index) !== -1,
                      linked_selected: selected === index && lines.findIndex((line) => line.from === index) !== -1,
                      square_disabled: loading,
                    })}
                  />
                </Col>
              </Row>
              {errors?.fromList?.[index]?.content && (
                <Row style={{ color: '#ff4d4f' }}>{errors?.fromList?.[index]?.content?.message}</Row>
              )}
            </div>
          );
        })}
        <Button block icon={<PlusOutlined />} onClick={appendItem} disabled={loading || fromList.length === 6}>
          Ajouter un choix
        </Button>
      </Space>
    </Item>
  );
};

export default ConnectFrom;
