import { Fragment, memo, useCallback, useRef, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import classnames from 'classnames';
import { useFieldArray, useFormContext } from 'react-hook-form';

import useLetterId from '../../../../hooks/useLetterId';

import { getSizeFromPercentage, getSizeFromTotal } from '../helpers';
import Cell from './Cell';
import ResizeHandle from './ResizeHandle';

const DesktopGrid = memo(({ onUpdate, useColors }) => {
  const containerRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const { getId } = useLetterId('C');
  const { control, watch } = useFormContext();
  const { replace } = useFieldArray({ control: control, name: 'cells' });
  const cells = watch('cells');

  const handleAdd = () => {
    // Si il a 24 cellule, l'action est annulée car le total de colonnes est atteint
    if (cells.length === 24) {
      return;
    }

    // Duplique les cellules
    const newItems = [...cells];
    // Récupère la cellule la plus à droite et qui est d'une taille supérieure à 1 pour la réduire
    const elementIndexToReduce = newItems.findLastIndex((el) => el.desktop.size > 1);

    // Réduit la cellule
    newItems[elementIndexToReduce].desktop.size -= 1;
    // Ajoute une nouvelle cellule à la fin
    newItems.push({
      cellId: getId(),
      desktop: { size: 1, hidden: false, order: newItems.length + 1 },
      tablet: { size: 24, hidden: false, order: newItems.length + 1, hidden: false },
      mobile: { size: 24, hidden: false, order: newItems.length + 1, hidden: false },
    });

    return replace(newItems);
  };

  // Récupère l'index de la cellule qui héritera de la taille de la cellule à supprimer
  const findNearestElementToExtend = (order, mode) => {
    // Ajoute l'index d'origine de la cellule puis ordonne les cellules par leur ordre
    const array = [...cells]
      .map((item, index) => ({ ...item, originIndex: index }))
      .sort((a, b) => a[mode].order - b[mode].order);
    // Récupère l'index de la cellule à supprimer dans le tableau ordonné
    const itemIndexToRemove = array.findIndex((item) => item[mode].order === order);

    // Calcule la taille des cellules précédentes + la cellule à supprimer, si le résultat est un multiple de 24
    // cela signifie que la cellule est la dernière d'une rangée, sa taille est donc ajoutée à la cellule précédente
    // dans le cas contraire, sa taille est ajoutée à la cellule suivante
    if (array.slice(0, itemIndexToRemove + 1).reduce((prev, curr) => prev + curr[mode].size, 0) % 24 === 0) {
      return array[itemIndexToRemove - 1].originIndex;
    } else {
      return array[itemIndexToRemove + 1].originIndex;
    }
  };

  const handleDelete = (index) => {
    // Si il n'y a qu'une cellule, l'action est annulée
    if (cells.length === 1) {
      return;
    }

    // Duplique les cellules
    const newItems = [...cells];
    // Récupère la cellule la plus proche pour lui ajouter la taille de la cellule à supprimer
    const elementIndexToExtend = index === 0 ? index + 1 : index - 1;

    // Ajoute la taille de la cellule à supprimer à la cellule la plus proche
    newItems[elementIndexToExtend].desktop.size += newItems[index].desktop.size;

    // Recalcule la répartition de la taille des rangées sur l'affichage tablette
    if (newItems[index].tablet.size !== 24) {
      const tabletElementIndexToExtend = findNearestElementToExtend(newItems[index].tablet.order, 'tablet');

      newItems[tabletElementIndexToExtend].tablet.size += newItems[index].tablet.size;
    }

    // Recalcule la répartition de la taille des rangées sur l'affichage mobile
    if (newItems[index].mobile.size !== 24) {
      const mobileElementIndexToExtend = findNearestElementToExtend(newItems[index].mobile.order, 'mobile');

      newItems[mobileElementIndexToExtend].mobile.size += newItems[index].mobile.size;
    }

    // Modifie l'ordre des cellules situées après celle à supprimer
    for (let i = 0; i < newItems.length; i++) {
      // Retire 1 à l'ordre des cellules situées après celle à supprimer en mode ordinateur
      if (newItems[i].desktop.order > newItems[index].desktop.order) {
        newItems[i].desktop.order -= 1;
      }

      // Retire 1 à l'ordre des cellules situées après celle à supprimer en mode tablette
      if (newItems[i].tablet.order > newItems[index].tablet.order) {
        newItems[i].tablet.order -= 1;
      }

      // Retire 1 à l'ordre des cellules situées après celle à supprimer en mode mobile
      if (newItems[i].mobile.order > newItems[index].mobile.order) {
        newItems[i].mobile.order -= 1;
      }
    }

    // Supprime la cellule
    newItems.splice(index, 1);

    return replace(newItems);
  };

  const handleResize = useCallback(
    (handleIndex, event) => {
      // Récupère les cellules à gauche du curseur
      const elementsBeforeHandle = [...cells.slice(0, handleIndex + 1)];
      // Calcule la taille totale des cellules de gauche
      const beforeTotalSize = elementsBeforeHandle.reduce((prev, curr) => prev + curr.desktop.size, 0);

      // Récupère les cellules à droite du curseur
      const elementsAfterHandle = [...cells.slice(handleIndex + 1)];
      // Calcule la taille totale des cellules de droite
      const afterTotalSize = elementsAfterHandle.reduce((prev, curr) => prev + curr.desktop.size, 0);

      // Récupère le largeur du bloc
      const containerWidth = containerRef.current.clientWidth;
      // Récupère la distance entre le bloc et le côté gauche de la page
      const containerOffsetLeft = containerRef.current.getBoundingClientRect().left;
      // Calcule la position horizontale du curseur dans le bloc
      const pointerRelativeXPos = ((event.clientX - containerOffsetLeft) * 100) / containerWidth;
      // Récupère la position du curseur dans le bloc en pourcentage (ex: si le bloc est au milieu, le pourcentage est de 50%)
      const currentStep = getSizeFromPercentage(pointerRelativeXPos);
      // Défini la direction du redimensionnement
      const direction = pointerRelativeXPos < getSizeFromTotal(beforeTotalSize).percentage ? 'left' : 'right';

      // Si aucune étape ne correspond, l'action est annulée
      if (!currentStep) {
        return;
      }

      // En déplaçant à gauche, le bloc de gauche est réduit et celui de droite augmenté
      // En déplaçant à droite, le bloc de droite est réduit et celui de gauche augmenté
      if (direction === 'left') {
        const beforeElementToResize = elementsBeforeHandle.findLastIndex((el) => el.desktop.size > 1);

        // Si aucun élément à gauche ne peux être redimensionné, l'action est annulée
        if (beforeElementToResize === -1) {
          return;
        }

        // Calcule les nouvelles tailles
        const newBlockToReduceSize =
          currentStep.size - (beforeTotalSize - elementsBeforeHandle[beforeElementToResize].desktop.size);
        const newBlockToExtendSize = elementsAfterHandle[0].desktop.size + (24 - (afterTotalSize + currentStep.size));

        // Si la nouvelle taille du bloc à réduire est en dessous de 1, annule l'action
        if (newBlockToReduceSize < 1) {
          return;
        }

        // Assigne les nouvelles tailles
        elementsBeforeHandle[beforeElementToResize].desktop.size = newBlockToReduceSize;
        elementsAfterHandle[0].desktop.size = newBlockToExtendSize;
      } else {
        const beforeElementToResize = elementsBeforeHandle.length - 1;
        const afterElementToResize = elementsAfterHandle.findIndex((el) => el.desktop.size > 1);

        // Si aucun élément à droite ne peux être redimensionné, l'action est annulée
        if (afterElementToResize === -1) {
          return;
        }

        // Calcule les nouvelles tailles
        const newBlockToReduceSize =
          24 - currentStep.size - (afterTotalSize - elementsAfterHandle[afterElementToResize].desktop.size);
        const newBlockToExtendSize =
          currentStep.size - (beforeTotalSize - elementsBeforeHandle[beforeElementToResize].desktop.size);

        // Si la nouvelle taille du bloc à réduire est en dessous de 1, annule l'action
        if (newBlockToReduceSize < 1) {
          return;
        }

        // Assigne les nouvelles tailles
        elementsBeforeHandle[beforeElementToResize].desktop.size = newBlockToExtendSize;
        elementsAfterHandle[afterElementToResize].desktop.size = newBlockToReduceSize;
      }

      return replace([...elementsBeforeHandle, ...elementsAfterHandle]);
    },
    [cells],
  );

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <div
        ref={containerRef}
        className={classnames('editor-grid-container editor-grid-container-desktop', {
          'editor-grid-container-dragging': isResizing,
        })}
      >
        {cells.map((item, index) => (
          <Fragment key={`desktop_${index}`}>
            <Cell
              {...{ index, useColors }}
              {...item}
              onDelete={() => handleDelete(index)}
              disabled={cells.length === 1}
            />
            {index + 1 < cells.length && (
              <ResizeHandle
                onDrag={setIsResizing}
                onResizeStart={onUpdate}
                onResize={(event) => handleResize(index, event)}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button size="small" icon={<PlusOutlined />} onClick={handleAdd} disabled={cells.length === 24}>
          Ajouter une cellule
        </Button>
      </div>
    </Space>
  );
});

DesktopGrid.displayName = 'DesktopGrid';
export default DesktopGrid;
