import { Button, Dropdown, Menu, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import ModuleImportExcelModal from './ModuleImportExcelModal';
import { useCloudinaryContext } from '../../../../cloudinaryContext';

const { Item } = Menu;

const ModuleImport = ({ onFinish }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();
  const { status } = useCloudinaryContext();

  const showCLoudinaryModal = () => {
    if (status === 'warning') {
      return Modal.warning({
        title: 'Statut de Cloudinary',
        content:
          "Le quota d'utilisation mensuel à dépassé les 50%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos sera désactivé à partir de 75% et ce jusqu'a la prochaine mensualité.",
      });
    }

    return Modal.error({
      title: 'Statut de Cloudinary',
      content:
        "Le quota d'utilisation mensuel à dépassé les 75%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos est désactivé jusqu'a la prochaine mensualité.",
    });
  };

  const ImportSelection = () => (
    <Menu key="import">
      <Item key="excel" onClick={() => setIsModalOpen(true)}>
        Excel
      </Item>
      {checkAuthorization(user, 'formao', 'packages', 'import') && (
        <>
          <Item key="moodle" onClick={[null, 'ok'].includes(status) ? null : showCLoudinaryModal}>
            {[null, 'ok'].includes(status) ? (
              <Link to={`/formao/cours/importer-un-package/moodle`}>Moodle</Link>
            ) : (
              <>Moodle</>
            )}
          </Item>
          <Item key="scorm" onClick={[null, 'ok'].includes(status) ? null : showCLoudinaryModal}>
            {[null, 'ok'].includes(status) ? (
              <Link to={`/formao/cours/importer-un-package/scorm`}>SCORM</Link>
            ) : (
              <>SCORM</>
            )}
          </Item>
        </>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={<ImportSelection />} placement="bottomRight" trigger={['click']}>
        <Button>
          Importer <DownOutlined />
        </Button>
      </Dropdown>
      <ModuleImportExcelModal onFinish={onFinish} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default ModuleImport;
