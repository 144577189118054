import { memo, useRef } from 'react';
import { Typography, List, Space, Tooltip, Button, Popconfirm, Select } from 'antd';
import { useFormContext, useFieldArray, Controller, useWatch } from 'react-hook-form';
import { PlusOutlined, DragOutlined, DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { useDrop, useDrag } from 'react-dnd';
import { moduleRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const { Link } = Typography;
const { Item } = List;

const getTitle = (record) => {
  let title = record.title;

  if (record.level) {
    if (record.level === 'easy') {
      title = `${title} - Rookie (Débutant)`;
    }

    if (record.level === 'medium') {
      title = `${title} - Aventurier (Avancé)`;
    }

    if (record.level === 'hard') {
      title = `${title} - Explorateur (Expert)`;
    }
  }

  return title;
};

const getModulesInFormation = (units) => {
  return units
    .map((unit) => {
      if (unit.modules === []) {
        return [];
      } else {
        let ids = [];

        for (let i = 0; i < unit.modules.length; i++) {
          if (unit.modules[i].module) {
            ids.push(unit.modules[i].module);
          }
        }

        return ids;
      }
    })
    .flat();
};

const StudentEditModules = ({ formationIndex, unitIndex, onUpdate, disabled }) => {
  const { control, setValue, getValues } = useFormContext();
  const { data: modules, isValidating } = useSWR(moduleRoutes.default);
  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: `formations[${formationIndex}].units[${unitIndex}].modules`,
  });
  const units = useWatch({ control, name: `formations[${formationIndex}].units` });
  const modulesInFormation = getModulesInFormation(units);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const handleFilter = (inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase());

  const MemoizedItem = memo(({ field, index }) => {
    const { control } = useFormContext();
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [, drop] = useDrop({
      accept: 'COURSE',
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        const hoverItem = getValues(`formations.${formationIndex}.units[${unitIndex}].modules[${hoverIndex}]`);

        if (item.id === hoverItem.id) {
          return;
        }

        if (item.unitIndex === unitIndex) {
          move(dragIndex, hoverIndex);
          onUpdate();
          return;
        }

        const dragArray = getValues(`formations.${formationIndex}.units[${item.unitIndex}].modules`);
        dragArray.splice(item.index, 1);

        const itemCopy = { ...item };
        delete itemCopy.id;
        delete itemCopy.index;
        delete itemCopy.unitIndex;

        insert(hoverIndex, itemCopy);
        setValue(`formations.${formationIndex}.units[${item.unitIndex}].modules`, dragArray);
        onUpdate();
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'COURSE',
      item: { ...field, index, unitIndex: unitIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <Item className="course-list-item">
        <div ref={previewRef} style={{ opacity, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Space>
            <Controller
              name={`formations.${formationIndex}.units[${unitIndex}].modules[${index}].module`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  filterOption={handleFilter}
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Sélectionnez dans la liste"
                  loading={isValidating}
                  disabled={!modules || isValidating || disabled}
                  options={modules?.data
                    .sort((a, b) => sort(a, b, 'title'))
                    .map((module) => {
                      let title = getTitle(module);
                      const isDisabled = !!module?.disabled;
                      const isAlreadyIn = modulesInFormation.includes(module._id) && field.value !== module._id;

                      if (isAlreadyIn) {
                        title += ' (Déjà présent dans la formation)';
                      }

                      if (isDisabled) {
                        title += ' (Cours désactivé)';
                      }

                      return {
                        label: title,
                        value: module._id,
                        disabled: isDisabled || isAlreadyIn,
                      };
                    })}
                />
              )}
            />
          </Space>
          <Space>
            <Controller
              name={`formations.${formationIndex}.units[${unitIndex}].modules[${index}].disabled`}
              control={control}
              render={({ field }) => (
                <Tooltip title={field.value ? 'Débloquer le cours' : 'Bloquer le cours'}>
                  <Button
                    icon={field.value ? <LockOutlined /> : <UnlockOutlined />}
                    onClick={() => {
                      if (field.value) field.onChange(false);
                      else field.onChange(true);
                    }}
                    disabled={disabled}
                  />
                </Tooltip>
              )}
            />
            <Tooltip title="Déplacer le cours">
              <Button ref={ref} icon={<DragOutlined />} disabled={disabled} />
            </Tooltip>
            <Tooltip title="Supprimer le cours">
              <Popconfirm
                {...popconfirmProps}
                onConfirm={() => {
                  remove(index);
                }}
              >
                <Button icon={<DeleteOutlined />} disabled={disabled} />
              </Popconfirm>
            </Tooltip>
          </Space>
        </div>
      </Item>
    );
  });

  MemoizedItem.displayName = 'MemoizedItem';

  const [, drop] = useDrop({
    accept: 'COURSE',
    drop: (item, monitor) => {
      if (fields.length > 0) {
        return;
      }

      const dragArray = getValues(`formations.${formationIndex}.units[${item.unitIndex}].modules`);
      dragArray.splice(item.index, 1);

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.unitIndex;

      append(itemCopy);
      setValue(`formations.${formationIndex}.units[${item.unitIndex}].modules`, dragArray);
      onUpdate();
    },
  });

  return (
    <List size="small">
      <div ref={drop} style={{ minHeight: 48.8 }}>
        {fields.map((field, index) => (
          <MemoizedItem key={field._id || field.id} field={field} index={index} unitIndex={unitIndex} />
        ))}
      </div>
      <Item style={{ borderTop: '1px solid #f0f0f0' }}>
        <Link disabled={disabled} onClick={() => append({ module: null, disabled: false })}>
          <PlusOutlined /> Ajouter un cours
        </Link>
      </Item>
    </List>
  );
};

export default StudentEditModules;
