import { useState } from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { format, getDate, getDaysInMonth, subMonths } from 'date-fns';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Button, List, Popconfirm, Space, Table, Tag, Tooltip, Typography, message, notification } from 'antd';
import useSWR from 'swr';
import { agencyRoutes, groupRoutes, reportRoutes } from '../../../../../lib/routes';
import { checkAuthorization, sort } from '../../../../../shared/utils';
import { useAuth } from '../../../../../authContext';
import useColumnSearch from '../../../../../hooks/useColumnSearch';
import useFetch from '../../../../../hooks/useFetch';
import AbortModal from '../../components/AbortModal';

const MonthlyReportList = ({ data, mutate, disabled }) => {
  const [resendLoading, setResendLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { data: groups } = useSWR(groupRoutes.default);
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { get, patch, remove } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const columns = [
    {
      title: 'Parcours',
      dataIndex: 'company',
      key: 'format',
      align: 'center',
      width: 100,
      sorter: (a, b) => (a?.company?.name && b?.company?.name ? 1 : -1),
      render: (record) => <Tag>{record?.name ? 'ALTERNANCE' : 'CONTINU'}</Tag>,
    },
    {
      title: 'Stagiaire',
      children: [
        {
          title: 'Nom',
          dataIndex: ['student', 'lastName'],
          key: 'studentLastName',
          sorter: (a, b) => sort(a, b, 'student.lastName', ''),
          sortDirections: ['ascend', 'descend'],
          ...getColumnSearchProps('student.lastName'),
          render: (record) => record?.toUpperCase(),
        },
        {
          title: 'Prénom',
          dataIndex: ['student', 'firstName'],
          key: 'studentFirstName',
          sorter: (a, b) => sort(a, b, 'student.firstName', ''),
          sortDirections: ['ascend', 'descend'],
          ...getColumnSearchProps('student.firstName'),
          render: (record) => capitalize(record),
        },
      ],
    },
    {
      title: 'Formateur',
      children: [
        {
          title: 'Nom',
          dataIndex: ['student', 'former', 'last_name'],
          key: 'formerLastName',
          sorter: (a, b) => sort(a, b, 'student.former.last_name', ''),
          sortDirections: ['ascend', 'descend'],
          ...getColumnSearchProps('student.former.last_name'),
          render: (record) => record?.toUpperCase(),
        },
        {
          title: 'Prénom',
          dataIndex: ['student', 'former', 'first_name'],
          key: 'formerFirstName',
          sorter: (a, b) => sort(a, b, 'student.former.first_name', ''),
          sortDirections: ['ascend', 'descend'],
          ...getColumnSearchProps('student.former.first_name'),
          render: (record) => capitalize(record),
        },
      ],
    },
    {
      title: 'Formation',
      dataIndex: ['student', 'formation', 'title'],
      key: 'formationTitle',
      sorter: (a, b) => sort(a, b, 'student.formation.title', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('student.formation.title'),
    },
    {
      title: 'Date de début',
      dataIndex: ['student', 'formation', 'startAt'],
      key: 'formationStartAt',
      sorter: (a, b) => sort(a, b, 'student.formation.startAt', ''),
      render: (record) => format(new Date(record), 'dd/MM/yyyy'),
    },
    {
      title: 'Date de fin',
      dataIndex: ['student', 'formation', 'endAt'],
      key: 'formationEndAt',
      sorter: (a, b) => sort(a, b, 'student.formation.endAt', ''),
      render: (record) => format(new Date(record), 'dd/MM/yyyy'),
    },
    {
      title: 'Centre',
      dataIndex: ['student', 'agency', 'city'],
      key: 'formationAgency',
      sorter: (a, b) => sort(a, b, 'student.agency.city', 'Centre introuvable'),
      sortDirections: ['ascend', 'descend'],
      filters: agencies?.data.map((agency) => ({ text: agency.city, value: agency._id })) || [],
      onFilter: (value, record) => record.student.agency._id === value,
    },
    {
      title: 'Groupe',
      dataIndex: ['student', 'group', 'name'],
      key: 'formationGroup',
      sorter: (a, b) => sort(a, b, 'student.group.name', ''),
      sortDirections: ['ascend', 'descend'],
      filters: groups?.data.map((group) => ({ text: group.name, value: group._id })) || [],
      onFilter: (value, record) => record.student?.group?._id === value,
    },
    {
      key: 'actions',
      width: 50,
      render: (record, report) => (
        <Space>
          {report?.step === 'ANNULÉ' ? (
            <Tooltip title={`Suivi résilié le : ${format(new Date(report.abortDate), 'dd/MM/yyyy')}`}>
              <Button type="primary" danger>
                Résilié
              </Button>
            </Tooltip>
          ) : (
            checkAuthorization(user, 'formao', 'monthlyReports', 'abort') && (
              <Button danger onClick={() => setIsModalOpen(report._id)}>
                Résilier
              </Button>
            )
          )}
          {report?.step !== 'ANNULÉ' && (
            <>
              <Link to={`/formao/bilans/suivis-mensuels/modifier-un-bilan/${record._id}`}>
                <Button icon={<EditOutlined />} />
              </Link>
              <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(record._id)}>
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  const generatePDF = async (data, report, formattedDate) => {
    const key = report._id;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await get(reportRoutes.pdf + '/' + report.results);

    if (results.status === 201) {
      const blob = new Blob([Buffer.from(results.data)], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      link.download = `Suivi-mensuel-du-${formattedDate}_${data.student.lastName.toUpperCase()}-${capitalize(
        data.student.firstName,
      )}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  const deleteReport = async (id) => {
    setIsProcessing(true);

    const results = await remove(reportRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const resendMail = async (id, reportId) => {
    if (checkAuthorization(user, 'formao', 'monthlyReports', 'resend')) {
      setResendLoading(true);

      const results = await patch(reportRoutes.resend + '/' + id + '?reportId=' + reportId);

      if (results.status === 200) {
        mutate();
        setResendLoading(false);
      } else {
        if (results.message) {
          message.error(results.message);
        }
        setResendLoading(false);
      }
    }
  };

  return (
    <>
      <AbortModal isOpen={isModalOpen} reportType="MENSUEL" onClose={() => setIsModalOpen(false)} onFinish={mutate} />

      <Table
        className="monthly-reports-table"
        dataSource={data?.items || []}
        columns={columns}
        pagination={false}
        bordered
        size="small"
        rowKey={(row) => row._id}
        disabled={!data?.items || disabled || isProcessing}
        expandable={{
          expandedRowRender: (record) => (
            <List
              dataSource={record?.reports || []}
              size="small"
              renderItem={(item) => {
                const daysInMonth = getDaysInMonth(new Date(item.sendAt));
                const day = getDate(new Date(item.sendAt));
                const date = day <= daysInMonth / 2 ? subMonths(new Date(item.sendAt), 1) : new Date(item.sendAt);
                const formattedDate = format(date, 'MM/yyyy');

                return (
                  <List.Item
                    extra={
                      <Space>
                        <Tooltip title="Copier le lien du suivi">
                          <Button
                            size="small"
                            disabled={item?.finishedAt || !item.token}
                            icon={<CopyOutlined />}
                            onClick={() => {
                              if (item.token && !item?.finishedAt) {
                                navigator.clipboard.writeText(`https://bilan.arinfo.fr/?t=${item.token}`);
                              }
                            }}
                          />
                        </Tooltip>
                        {checkAuthorization(user, 'formao', 'monthlyReports', 'resend') && (
                          <Tooltip title="Renvoyer le suivi">
                            <Button
                              size="small"
                              disabled={item?.finishedAt || !item.token}
                              onClick={() => resendMail(record._id, item._id)}
                              icon={<MailOutlined />}
                              loading={resendLoading}
                            />
                          </Tooltip>
                        )}
                        {checkAuthorization(user, 'formao', 'monthlyReports', 'download') && (
                          <Tooltip title="Consulter le PDF">
                            <Button
                              size="small"
                              disabled={!item?.finishedAt}
                              onClick={() => generatePDF(record, item, formattedDate)}
                              icon={<FilePdfOutlined />}
                            />
                          </Tooltip>
                        )}
                      </Space>
                    }
                  >
                    <Typography.Text>Bilan du {formattedDate}</Typography.Text>
                  </List.Item>
                );
              }}
            />
          ),
        }}
      />
    </>
  );
};

export default MonthlyReportList;
