import { Table, Button, Space, Popconfirm, InputNumber, Form } from 'antd';
import { useRef, useCallback, useState, memo } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { DeleteOutlined, PlusOutlined, CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { useDrop, useDrag } from 'react-dnd';

const { Item } = Form;

const DraggableBodyRow = memo(({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: 'ROW',
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: 'ROW',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
});

DraggableBodyRow.displayName = 'DraggableBodyRow';

const EditableCell = memo(({ editing, dataIndex, withAddon, toFixed, record, index, children, ...restProps }) => (
  <td {...restProps}>
    {editing ? (
      <Item name={dataIndex} style={{ margin: 0 }}>
        <InputNumber min={0} addonAfter={withAddon && '€'} style={{ width: '100%' }} />
      </Item>
    ) : (
      <>
        {typeof children[1] === 'number'
          ? withAddon
            ? `${children[1].toFixed(toFixed)} €`
            : children[1].toFixed(toFixed)
          : children}
      </>
    )}
  </td>
));

EditableCell.displayName = 'EditableCell';

const CPFUpdateTable = () => {
  const [form] = Form.useForm();
  const [editingId, setEditingId] = useState('');
  const { control } = useFormContext();
  const { fields, remove, update, insert, move, append } = useFieldArray({ control, name: 'prices' });

  const isEditing = (record) => record.id === editingId;

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const edit = (record) => {
    form.setFieldsValue({
      costPerHour: '',
      hours: '',
      costPerDay: '',
      days: '',
      priceWithoutTaxes: '',
      priceWithTaxes: '',
      ...record,
    });
    setEditingId(record.id);
  };

  const save = async (record, index) => {
    const row = await form.validateFields();

    update(index, { ...record, ...row });
    setEditingId('');
  };

  const stopEdit = () => setEditingId('');

  const moveRow = useCallback((dragIndex, hoverIndex) => move(dragIndex, hoverIndex), []);

  const removeRow = (index) => {
    setEditingId('');
    remove(index);
  };

  const columns = [
    {
      title: 'Coût/horaire',
      dataIndex: 'costPerHour',
      key: 'costPerHour',
      align: 'right',
      onCell: (record, index) => ({
        record,
        dataIndex: 'costPerHour',
        index,
        withAddon: true,
        toFixed: 2,
        editing: isEditing(record),
      }),
    },
    {
      title: 'Heures',
      dataIndex: 'hours',
      key: 'hours',
      align: 'right',
      onCell: (record, index) => ({
        record,
        index,
        dataIndex: 'hours',
        toFixed: 0,
        editing: isEditing(record),
      }),
    },
    {
      title: 'Coût/jour',
      dataIndex: 'costPerDay',
      key: 'costPerDay',
      align: 'right',
      onCell: (record, index) => ({
        record,
        index,
        dataIndex: 'costPerDay',
        withAddon: true,
        toFixed: 2,
        editing: isEditing(record),
      }),
    },
    {
      title: 'Jours',
      dataIndex: 'days',
      key: 'days',
      align: 'right',
      onCell: (record, index) => ({
        record,
        index,
        dataIndex: 'days',
        toFixed: 0,
        editing: isEditing(record),
      }),
    },
    {
      title: 'Tarif HT',
      dataIndex: 'priceWithoutTaxes',
      key: 'priceWithoutTaxes',
      align: 'right',
      onCell: (record, index) => ({
        record,
        index,
        dataIndex: 'priceWithoutTaxes',
        withAddon: true,
        toFixed: 0,
        editing: isEditing(record),
      }),
    },
    {
      title: 'Tarif TTC',
      dataIndex: 'priceWithTaxes',
      key: 'priceWithTaxes',
      align: 'right',
      onCell: (record, index) => ({
        record,
        index,
        dataIndex: 'priceWithTaxes',
        withAddon: true,
        toFixed: 0,
        editing: isEditing(record),
      }),
    },
    {
      title: (
        <Button
          icon={<PlusOutlined />}
          block
          onClick={() =>
            insert(0, {
              costPerHour: null,
              hours: null,
              costPerDay: null,
              days: null,
              priceWithoutTaxes: null,
              priceWithTaxes: null,
            })
          }
        />
      ),
      key: 'actions',
      width: 90,
      align: 'center',
      render: (_, record, index) => {
        const editable = isEditing(record);

        return editable ? (
          <Space>
            <Button icon={<CheckOutlined />} onClick={() => save(record, index)} />
            <Button icon={<CloseOutlined />} onClick={stopEdit} />
          </Space>
        ) : (
          <Space>
            <Button icon={<EditOutlined />} disabled={editingId !== ''} onClick={() => edit(record)} />
            <Popconfirm {...popconfirmProps} disabled={editingId !== ''} onConfirm={() => removeRow(index)}>
              <Button icon={<DeleteOutlined />} disabled={editingId !== ''} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        className="fixed-table"
        bordered
        columns={columns}
        dataSource={fields}
        tableLayout="fixed"
        size="small"
        pagination={false}
        rowKey={(row) => row.id}
        style={{ marginBottom: 24 }}
        components={{
          body: {
            cell: EditableCell,
            row: DraggableBodyRow,
          },
        }}
        onRow={(_, index) => {
          const attr = { index, moveRow };

          return attr;
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right">
                Coût/horaire
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                Heures
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                Coût/jour
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                Jours{' '}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                Tarif HT
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} align="right">
                Tarif TTC
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <Button
                  icon={<PlusOutlined />}
                  block
                  onClick={() =>
                    append({
                      costPerHour: null,
                      hours: null,
                      costPerDay: null,
                      days: null,
                      priceWithoutTaxes: null,
                      priceWithTaxes: null,
                    })
                  }
                />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </Form>
  );
};

export default CPFUpdateTable;
