import { Card, Typography } from 'antd';

import ImportExportTaskList from './ImportExportTaskList'

const ApiScorm = () => {
  return (
    <Card>
      <Typography.Title level={4}>API SCORM</Typography.Title>
      <ImportExportTaskList/>
    </Card>
  );
};

export default ApiScorm;
