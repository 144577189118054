import { mergeAttributes, Node } from '@tiptap/core';

export const Summary = Node.create({
  name: 'detailsSummary',
  content: 'text*',
  selectable: false,
  isolating: true,
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'details-summary',
      },
    };
  },
  parseHTML() {
    return [{ tag: 'summary' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['summary', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
