import { Form, Input, Radio, Space } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';

const { Item } = Form;
const { Group } = Radio;

const CPFUpdateInformations = ({ disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Item label="Intitulé" required validateStatus={errors?.title?.message && 'error'} help={errors?.title?.message}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple : TUTORAT niv.1" />}
        />
      </Item>
      <Item
        label="Description"
        required
        validateStatus={errors?.description?.message && 'error'}
        help={errors?.description?.message}
      >
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Input {...field} disabled={disabled} placeholder="Exemple : Prestation (logiciel + lieu) : classique" />
          )}
        />
      </Item>
      <Item label="Format" required validateStatus={errors?.format?.message && 'error'} help={errors?.format?.message}>
        <Controller
          name={`format`}
          control={control}
          render={({ field }) => (
            <Group {...field} disabled={disabled}>
              <Space direction="vertical">
                <Radio value="shared">Formateur partagé (En groupe)</Radio>
                <Radio value="dedicated">Formateur dédié (100% sur mesure)</Radio>
              </Space>
            </Group>
          )}
        />
      </Item>
    </>
  );
};

export default CPFUpdateInformations;
