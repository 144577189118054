import React, { useState } from 'react';
import { Table, Button, Space, Tooltip, message, Popconfirm, Card, Switch } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { sort, checkAuthorization } from '../../../shared/utils';
import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import { postRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const PostsList = () => {
  const { data: posts, isValidating, mutate } = useSWR(postRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const { remove, patch } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(postRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onEnableDisable = async (value, id) => {
    setIsProcessing(true);

    const results = await patch((value ? postRoutes.enable : postRoutes.disable) + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Créée le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    ...(checkAuthorization(user, 'arinfo', 'posts', 'enable-post') ||
    checkAuthorization(user, 'arinfo', 'posts', 'disable-post')
      ? [
          {
            title: 'Publiée',
            dataIndex: 'disabled',
            key: 'disabled',
            width: 100,
            align: 'center',
            render: (value, record) => (
              <Switch
                checked={!value}
                onChange={() => {
                  const canAccess = value
                    ? checkAuthorization(user, 'arinfo', 'posts', 'enable-post')
                    : checkAuthorization(user, 'arinfo', 'posts', 'disable-post');

                  if (canAccess) {
                    onEnableDisable(value, record._id);
                  }
                }}
                disabled={
                  value
                    ? !checkAuthorization(user, 'arinfo', 'posts', 'enable-post')
                    : !checkAuthorization(user, 'arinfo', 'posts', 'disable-post')
                }
              />
            ),
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'arinfo', 'posts', 'update-post') && (
            <Tooltip title="Modifier" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/arinfo/actualites/modifier-une-actualite/${record._id}`}>
                <Button className="gray-text" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          )}
          {checkAuthorization(user, 'arinfo', 'posts', 'delete-post') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'posts', 'create-post') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/arinfo/actualites/nouvelle-actualite">Nouvelle actualité</Link>
          </Button>
        </div>
      )}
      <Table
        loading={!posts || isValidating || isProcessing}
        dataSource={posts?.data || []}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 320 }}
        rowKey={(row) => row._id}
        pagination={tablePagination(posts?.data)}
      />
    </Card>
  );
};

export default PostsList;
