import { useState, useEffect, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { uniqueId } from 'lodash';
import LineTo from 'react-lineto';
import Move from '../../../../../assets/posiao/move.svg';

const getSides = (items) => {
  let leftSide = items.filter((item) => item.coords[0] <= 225) || [];
  let rightSide = items.filter((item) => item.coords[0] > 225) || [];

  if (leftSide.length > 5) {
    const toRemove = leftSide.length - 5;
    const cleanSide = [...leftSide].sort((a, b) => a.coords[0] - b.coords[0]);
    const rest = cleanSide.splice(5, toRemove);

    leftSide = cleanSide;
    rightSide = rightSide.concat(rest);
  }

  if (rightSide.length > 5) {
    const toRemove = rightSide.length - 5;
    const cleanSide = [...rightSide].sort((a, b) => a.coords[0] - b.coords[0]).reverse();
    const rest = cleanSide.splice(5, toRemove);

    rightSide = cleanSide;
    leftSide = leftSide.concat(rest);
  }

  return {
    leftSide: leftSide.sort((a, b) => a.coords[1] - b.coords[1]),
    rightSide: rightSide.sort((a, b) => a.coords[1] - b.coords[1]),
  };
};

const LegendPreview = ({ imagePreview, currentTab }) => {
  const { control } = useFormContext();
  const values = useWatch({ control });
  const legends = useMemo(
    () => values.legends.map((rest) => ({ ...rest, id: uniqueId('preview-') })),
    [values.legends],
  );
  const { leftSide, rightSide } = getSides(legends);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const Listener = () => {
      forceUpdate();
    };
    window.addEventListener('scroll', Listener);
    window.addEventListener('resize', Listener);

    return () => {
      window.removeEventListener('resize', Listener);
      window.removeEventListener('scroll', Listener);
    };
  }, [forceUpdate]);

  return (
    <>
      <div className="legend-preview-container" style={{ display: 'flex', gap: '24px', margin: '24px 0' }}>
        <div style={{ flex: 1 }}>
          {leftSide.map((legend) => (
            <div className={`${legend.id} lists-box-main`} key={legend.id}>
              {currentTab === 'preview' && (
                <LineTo
                  from={`${legend.id}`}
                  to={`point-${legend.id}`}
                  fromAnchor="right"
                  toAnchor="center"
                  borderWidth={1}
                  borderColor="#315397"
                  delay={true}
                  className="lines"
                />
              )}
            </div>
          ))}
        </div>
        {imagePreview && (
          <div className="legend-img-container">
            <img src={imagePreview} className="legend-img " alt="image légendée" id="legend__img" />
            {legends.map((legend) => (
              <span
                className={`point-${legend.id}`}
                style={{
                  position: 'absolute',
                  top: legend.coords[1],
                  left: legend.coords[0],
                  border: '5px solid #315397',
                  borderRadius: '5px',
                }}
                key={legend.id}
              />
            ))}
          </div>
        )}
        <div style={{ flex: 1 }}>
          {rightSide.map((legend) => (
            <div className={`${legend.id} lists-box-main`} key={legend.id}>
              {currentTab === 'preview' && (
                <LineTo
                  from={`${legend.id}`}
                  to={`point-${legend.id}`}
                  fromAnchor="left"
                  toAnchor="center"
                  borderWidth={1}
                  borderColor="#315397"
                  delay={true}
                  className="lines"
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="labels-box">
        <div className="labels-box-title">Éléments à placer</div>
        <div className="labels-box-drop">
          {values.labels.concat(values.legends).map((item, index) => (
            <div key={`labelbox-label${index}`} className="item">
              {item.content ?? item.label.content}
              <img src={Move} alt="move icon" style={{ maxWidth: '20px' }} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LegendPreview;
