const { isEmpty } = require('lodash');

const formats = ['Découverte', 'Stage vacances', 'Hebdomadaire', 'Intergénérationnel'];

const dateValidation = (data) => {
  const errors = {};

  data.age = !isEmpty(data?.age) ? data.age : '';
  data.format = !isEmpty(data?.format) ? data.format : '';
  data.agency = !isEmpty(data?.agency) ? data.agency : '';

  if (!data.date) {
    errors.date = 'Ce champ est requis';
  }

  if (!data.age) {
    errors.age = 'Ce champ est requis';
  }

  if (!data.format) {
    errors.format = 'Ce champ est requis';
  } else {
    if (!formats.includes(data.format)) {
      errors.format = 'Format invalide';
    }
  }

  if (!data.agency) {
    errors.agency = 'Ce champ est requis';
  }

  return errors;
};

export default dateValidation;
