import { useFormContext, Controller, useFormState } from 'react-hook-form';
import { Form, Input, Row, Col } from 'antd';

const { Item } = Form;

const StudentEditAccount = ({ disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Nom"
            required
            validateStatus={errors?.last_name?.message && 'error'}
            help={errors?.last_name?.message}
          >
            <Controller
              control={control}
              name="last_name"
              render={({ field }) => <Input {...field} disabled={disabled} />}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Prénom"
            required
            validateStatus={errors?.first_name?.message && 'error'}
            help={errors?.first_name?.message}
          >
            <Controller
              control={control}
              name="first_name"
              render={({ field }) => <Input {...field} disabled={disabled} />}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Adresse email"
            required
            validateStatus={errors?.email?.message && 'error'}
            help={errors?.email?.message}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => <Input {...field} disabled={disabled} />}
            />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default StudentEditAccount;
