import useSWR from 'swr';
import { Table } from 'antd';
import tablePagination from '../../../../lib/tablePagination';
import { studentRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';
import useColumnSearch from '../../../../hooks/useColumnSearch';

const FormationEditStudents = ({ formationId }) => {
  const { data: students, isValidating } = useSWR(studentRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  // prettier-ignore
  const registered = students?.data.filter((student) => student.formations.find(({ formation }) => formation === formationId)) || [];

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => sort(a, b, 'last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
      render: (value) => value.toUpperCase(),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => sort(a, b, 'first_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('first_name'),
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1),
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => sort(a, b, 'email'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('email'),
    },
  ];

  return (
    <Table
      loading={isValidating}
      dataSource={registered}
      columns={columns}
      bordered
      size="small"
      rowKey={(row) => row._id}
      pagination={tablePagination(registered)}
    />
  );
};

export default FormationEditStudents;
