import { Fragment, memo, useCallback, useMemo } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { useDroppable } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import classnames from 'classnames';

import { getSizeFromPercentage, getSizeFromTotal } from '../helpers';
import Cell from './Cell';
import ResizeHandle from './ResizeHandle';

const DroppableRow = ({ mode, index, items, onResize, onResizeEnd, onResizeStart, onCellVisibleChange, useColors }) => {
  const { setNodeRef, node } = useDroppable({ id: `row_${index}` });
  const ids = useMemo(() => items.map((item) => item.id), [items]);
  const totalItemsSize = useMemo(() => items.reduce((prev, curr) => prev + curr[mode].size, 0), [items]);

  const handleResize = useCallback(
    (handleIndex, event) => {
      // Récupère les cellules à gauche du curseur
      const elementsBeforeHandle = [...items.slice(0, handleIndex + 1)];
      // Calcule la taille totale des cellules de gauche
      const beforeTotalSize = elementsBeforeHandle.reduce((prev, curr) => prev + curr[mode].size, 0);

      // Récupère les cellules à droite du curseur
      const elementsAfterHandle = [...items.slice(handleIndex + 1)];
      // Calcule la taille totale des cellules de droite
      const afterTotalSize = elementsAfterHandle.reduce((prev, curr) => prev + curr[mode].size, 0);

      // Récupère le largeur du bloc
      const containerWidth = node.current.clientWidth;
      // Récupère la distance entre le bloc et le côté gauche de la page
      const containerOffsetLeft = node.current.getBoundingClientRect().left;
      // Calcule la position horizontale du curseur dans le bloc
      const pointerRelativeXPos = ((event.clientX - containerOffsetLeft) * 100) / containerWidth;
      // Récupère la position du curseur dans le bloc en pourcentage (ex: si le bloc est au milieu, le pourcentage est de 50%)
      const currentStep = getSizeFromPercentage(pointerRelativeXPos);
      // Défini la direction du redimensionnement
      const direction = pointerRelativeXPos < getSizeFromTotal(beforeTotalSize).percentage ? 'left' : 'right';

      // Si aucune étape ne correspond, l'action est annulée
      if (!currentStep) {
        return;
      }

      // En déplaçant à gauche, le bloc de gauche est réduit et celui de droite augmenté
      // En déplaçant à droite, le bloc de droite est réduit et celui de gauche augmenté
      if (direction === 'left') {
        const beforeElementToResize = elementsBeforeHandle.findLastIndex((el) => el[mode].size > 1);

        // Si aucun élément à gauche ne peux être redimensionné, l'action est annulée
        if (beforeElementToResize === -1) {
          return;
        }

        // Calcule les nouvelles tailles
        const newBlockToReduceSize =
          currentStep.size - (beforeTotalSize - elementsBeforeHandle[beforeElementToResize][mode].size);
        const newBlockToExtendSize = elementsAfterHandle[0][mode].size + (24 - (afterTotalSize + currentStep.size));

        // Si la nouvelle taille du bloc à réduire est en dessous de 1, annule l'action
        if (newBlockToReduceSize < 1) {
          return;
        }

        // Assigne les nouvelles tailles
        elementsBeforeHandle[beforeElementToResize][mode].size = newBlockToReduceSize;
        elementsAfterHandle[0][mode].size = newBlockToExtendSize;
      } else {
        const beforeElementToResize = elementsBeforeHandle.length - 1;
        const afterElementToResize = elementsAfterHandle.findIndex((el) => el[mode].size > 1);

        // Si aucun élément à droite ne peux être redimensionné, l'action est annulée
        if (afterElementToResize === -1) {
          return;
        }

        // Calcule les nouvelles tailles
        const newBlockToReduceSize =
          24 - currentStep.size - (afterTotalSize - elementsAfterHandle[afterElementToResize][mode].size);
        const newBlockToExtendSize =
          currentStep.size - (beforeTotalSize - elementsBeforeHandle[beforeElementToResize][mode].size);

        // Si la nouvelle taille du bloc à réduire est en dessous de 1, annule l'action
        if (newBlockToReduceSize < 1) {
          return;
        }

        // Assigne les nouvelles tailles
        elementsBeforeHandle[beforeElementToResize][mode].size = newBlockToExtendSize;
        elementsAfterHandle[afterElementToResize][mode].size = newBlockToReduceSize;
      }

      return onResizeEnd([...elementsBeforeHandle, ...elementsAfterHandle]);
    },
    [items],
  );

  return (
    <SortableContext id={`row_${index}`} strategy={horizontalListSortingStrategy} items={ids}>
      <div ref={setNodeRef} className="editor-grid-row">
        {items.map((item, index) => (
          <Fragment key={item.id}>
            <Cell
              {...{ useColors }}
              index={item.originIndex}
              id={item.id}
              {...item}
              size={item[mode].size}
              visible={!item[mode].hidden}
              onVisibleChange={onCellVisibleChange}
            />
            {!item[mode].hidden &&
            ((items.length !== 1 && totalItemsSize < 24) || (items.length !== 1 && index + 1 !== items.length)) ? (
              <ResizeHandle {...{ onResizeStart }} onDrag={onResize} onResize={(event) => handleResize(index, event)} />
            ) : null}
          </Fragment>
        ))}
      </div>
    </SortableContext>
  );
};

const PlaceholderRow = ({ index, first, last }) => {
  const { setNodeRef, isOver } = useDroppable({ id: `placeholderRow_${index}` });

  return (
    <div
      ref={setNodeRef}
      className={classnames('editor-grid-row editor-grid-row-placeholder', {
        'editor-grid-row-placeholder-start': first,
        'editor-grid-row-placeholder-end': last,
        'editor-grid-row-placeholder-over': isOver,
      })}
    >
      <PlusOutlined className="editor-grid-row-placeholder-icon" />
    </div>
  );
};

const Row = memo(({ placeholder, ...props }) => {
  if (placeholder) {
    return <PlaceholderRow {...props} />;
  }

  return <DroppableRow {...props} />;
});

Row.displayName = 'Row';
export default Row;
