import { Button, Popover, Space, Typography, Upload, message, Form, Tooltip, Input, Alert } from 'antd';
import { useState, useEffect } from 'react';
import { CloseOutlined, CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';
import { Image as ImageIcon } from './Icons';
import { uploadRoutes } from '../../lib/routes';
import useFetch from '../../hooks/useFetch';
import { useCloudinaryContext } from '../../cloudinaryContext';

const Image = ({ editor, disabled }) => {
  const { status, showUsageModal, refreshCache } = useCloudinaryContext();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [caption, setCaption] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const { post } = useFetch();

  useEffect(() => {
    if (!isOpen) {
      setCaption('');
      setFile(null);
      setError(null);
      setLoading(false);
    }
  }, [isOpen]);

  const handleToggle = () => {
    if (disabled || [null, 'danger'].includes(status)) {
      showUsageModal();
      return setIsOpen(false);
    }

    if (!isOpen) {
      return setIsOpen(true);
    }

    if (isOpen && !loading) {
      return setIsOpen(false);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setIsOpen(false);
    }
  };

  const handleFileChange = ({ file }) => setFile(file.originFileObj);

  const handleSubmit = async () => {
    setLoading(true);

    const formData = serialize({ image: file }, { indices: true });
    const results = await post(uploadRoutes.image, formData, 'multipart/form-data');

    if (results.status === 201) {
      editor
        .chain()
        .focus()
        .setFigure({
          image: {
            src: results.data.secure_url,
            alt: results.data.public_id || '',
            id: results.data.public_id,
            height: results.data.height,
            width: results.data.width,
          },
          caption: caption || '',
        })
        .run();
      refreshCache();
      setIsOpen(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        setError(results.errors);
      }
    }

    setLoading(false);
  };

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={handleToggle}
      trigger="click"
      title="Téléverser une image"
      placement="bottom"
      style={{ width: 500 }}
      content={
        <Space direction="vertical" style={{ width: 500 }}>
          <Alert
            type="warning"
            showIcon
            description="Le quota d'utilisation mensuel à dépassé les 50%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos sera désactivé à partir de 75% et ce jusqu'a la prochaine mensualité."
          />
          <Form.Item
            extra="PNG, JPG, GIF et SVG uniquement"
            validateStatus={error && 'error'}
            help={error}
            style={{ margin: 0 }}
          >
            <Upload
              className="upload-input"
              showUploadList={false}
              maxCount={1}
              disabled={loading || disabled}
              onChange={handleFileChange}
            >
              <Button block icon={<UploadOutlined />} disabled={loading || disabled}>
                <Typography.Text ellipsis style={{ paddingRight: 14 }}>
                  {file ? file.name : 'Sélectionner un fichier'}
                </Typography.Text>
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item validateStatus={error && 'error'} help={error} style={{ margin: 0 }}>
            <Input
              name="legend"
              placeholder="Légende"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              disabled={loading || disabled}
            />
          </Form.Item>
          <Space style={{ marginTop: 10 }}>
            <Button
              icon={<CheckOutlined />}
              type="primary"
              loading={loading}
              disabled={disabled}
              onClick={handleSubmit}
            />
            <Button icon={<CloseOutlined />} onClick={handleClose} disabled={loading || disabled} />
          </Space>
        </Space>
      }
    >
      <Tooltip key="figure" title="Téléverser une image">
        <Button
          icon={<ImageIcon />}
          disabled={loading || disabled || [null, 'danger'].includes(status)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        />
      </Tooltip>
    </Popover>
  );
};

export default Image;
