import { Button, Popover, Space, Tooltip, Form, Upload, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';
import { uploadRoutes } from '../../lib/routes';
import useFetch from '../../hooks/useFetch';
import { getSelectedText } from './helpers';

const FilePopover = ({ editor, disabled, isActive, uploadWorkspace }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { post } = useFetch();
  const selectedText = getSelectedText(editor);

  useEffect(() => {
    if (!isOpen) setUploading(false);
  }, [isOpen]);

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={setIsOpen}
      trigger="click"
      title="Téléverser un fichier"
      overlayStyle={{ width: 350 }}
      destroyTooltipOnHide
      placement="bottom"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item style={{ margin: 0 }}>
            <Upload
              customRequest={async ({ file, onProgress, onSuccess, onError }) => {
                if (!uploadWorkspace) {
                  return onError();
                }

                const size = file.size;
                const chunkSize = 5 * 1024 * 1024;
                const chunkCount = size / chunkSize;
                const fileName = 'upload_' + Date.now();

                for (let i = 0; i < chunkCount; i++) {
                  const startPos = chunkSize * i;
                  const endPos = chunkSize * (i + 1);
                  const percent = Math.round(((chunkSize * (i + 1)) / size) * 100);

                  const formData = serialize(
                    { file, chunk: file.slice(startPos, endPos), index: i, total: chunkCount, name: fileName },
                    { indices: true },
                  );

                  const results = await post(uploadRoutes.largeFile, formData, 'multipart/form-data', false);

                  if (results.status === 201) {
                    onSuccess(results.data);
                  } else if (results.status === 200) {
                    onProgress(percent);
                    continue;
                  } else {
                    onError();
                    break;
                  }
                }
              }}
              beforeUpload={(file) => {
                if (file.type.startsWith('video/')) {
                  return Upload.LIST_IGNORE;
                }

                if (file.type.startsWith('image/')) {
                  return Upload.LIST_IGNORE;
                }

                const fileSize = file.size / 1024 / 1024;

                if (fileSize > 100) {
                  message.error('Téléversement limité à 100 Mo');
                  return Upload.LIST_IGNORE;
                }
              }}
              onChange={({ fileList }) => {
                setUploading(true);
                const awaiting = fileList.filter((item) => item.status !== 'done');

                if (awaiting.length === 0) {
                  const { response } = fileList[0];

                  editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({
                      href: `${process.env.REACT_APP_BASE_URL_API_URL}/download/${uploadWorkspace}/${response.name}${response.extension}`,
                      target: '_blank',
                      rel: 'noopener noreferrer nofollow',
                    })
                    .run();

                  setUploading(false);
                  return setIsOpen(false);
                }
              }}
              showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
              className="upload-input"
              multiple={false}
              maxCount={1}
            >
              <Button block icon={<UploadOutlined />} disabled={uploading}>
                <Typography.Text ellipsis style={{ paddingRight: 14 }}>
                  Sélectionner un fichier
                </Typography.Text>
              </Button>
            </Upload>
          </Form.Item>
        </Space>
      }
    >
      <Tooltip key="file" title="Téléverser un fichier">
        <Button icon={<FileTextOutlined />} type={isActive && 'primary'} disabled={disabled || selectedText === ''} />
      </Tooltip>
    </Popover>
  );
};

export default FilePopover;
