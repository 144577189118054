export const getRows = (field, data) => {
  const { startCell, endCell } = field;

  if (!startCell && !endCell) {
    return [];
  }

  const column = startCell.match(/\D+|\d+/g)[0].toUpperCase();
  const startAt = Number(startCell.match(/\D+|\d+/g)[1]);
  const endAt = Number(endCell.match(/\D+|\d+/g)[1]);
  const rows = [];

  for (let i = startAt; i !== endAt + 1; i++) {
    const row = data[column + i]?.v || [];

    rows.push(row);
  }

  return rows;
};

export const validatePrices = (fields) => {
  const { title, description, costPerDay, costPerHour, days, hours, pricesWithoutTaxes, pricesWithTaxes, format } =
    fields;
  const errors = { costPerDay: {}, costPerHour: {}, days: {}, hours: {}, pricesWithoutTaxes: {}, pricesWithTaxes: {} };

  if (!title) {
    errors.title = 'Ce champ est requis';
  }

  if (!description) {
    errors.description = 'Ce champ est requis';
  }

  if (!format) {
    errors.format = 'Ce champ est requis';
  }

  if (costPerDay?.startCell || costPerDay?.endCell) {
    if (!costPerDay?.startCell) {
      errors.costPerDay.startMissing = 'La cellule de départ est manquante';
    }

    if (!costPerDay?.endCell) {
      errors.costPerDay.endMissing = 'La cellule de fin est manquante';
    }

    if (Object.keys(errors.costPerDay).length === 0) {
      const startColumn = costPerDay?.startCell.replace(/[^A-Za-z]/g, '');
      const startAt = costPerDay?.startCell.replace(/[^0-9]/g, '');
      const endColumn = costPerDay?.endCell.replace(/[^A-Za-z]/g, '');
      const endAt = costPerDay?.endCell.replace(/[^0-9]/g, '');

      if (!startColumn) {
        errors.costPerDay.startColumnMissing = 'La colonne de départ est manquante';
      }

      if (!startAt) {
        errors.costPerDay.startNumberMissing = 'La ligne de départ est manquante';
      }

      if (!endColumn) {
        errors.costPerDay.endColumnMissing = 'La colonne de fin est manquante';
      }

      if (!endAt) {
        errors.costPerDay.endNumberMissing = 'La ligne de fin est manquante';
      }

      if (startColumn !== endColumn) {
        errors.costPerDay.column = 'Les cellules doivent être dans la même colonne';
      }

      if (Number(startAt) === Number(endAt)) {
        errors.costPerDay.number = 'Les cellule ne peuvent pas être identiques';
      }

      if (Number(startAt) > Number(endAt)) {
        errors.costPerDay.number = 'La cellule de départ ne peut pas être plus grande que la cellule de fin';
      }
    }
  }

  if (costPerHour?.startCell || costPerHour?.endCell) {
    if (!costPerHour?.startCell) {
      errors.costPerHour.startMissing = 'La cellule de départ est manquante';
    }

    if (!costPerHour?.endCell) {
      errors.costPerHour.endMissing = 'La cellule de fin est manquante';
    }

    if (Object.keys(errors.costPerHour).length === 0) {
      const startColumn = costPerHour?.startCell.replace(/[^A-Za-z]/g, '');
      const startAt = costPerHour?.startCell.replace(/[^0-9]/g, '');
      const endColumn = costPerHour?.endCell.replace(/[^A-Za-z]/g, '');
      const endAt = costPerHour?.endCell.replace(/[^0-9]/g, '');

      if (!startColumn) {
        errors.costPerHour.startColumnMissing = 'La colonne de départ est manquante';
      }

      if (!startAt) {
        errors.costPerHour.startNumberMissing = 'La ligne de départ est manquante';
      }

      if (!endColumn) {
        errors.costPerHour.endColumnMissing = 'La colonne de fin est manquante';
      }

      if (!endAt) {
        errors.costPerHour.endNumberMissing = 'La ligne de fin est manquante';
      }

      if (startColumn !== endColumn) {
        errors.costPerHour.column = 'Les cellules doivent être dans la même colonne';
      }

      if (Number(startAt) === Number(endAt)) {
        errors.costPerHour.number = 'Les cellule ne peuvent pas être identiques';
      }

      if (Number(startAt) > Number(endAt)) {
        errors.costPerHour.number = 'La cellule de départ ne peut pas être plus grande que la cellule de fin';
      }
    }
  }

  if (!days?.startCell) {
    errors.days.startMissing = 'La cellule de départ est manquante';
  }

  if (!days?.endCell) {
    errors.days.endMissing = 'La cellule de fin est manquante';
  }

  if (Object.keys(errors.days).length === 0) {
    const startColumn = days?.startCell.replace(/[^A-Za-z]/g, '');
    const startAt = days?.startCell.replace(/[^0-9]/g, '');
    const endColumn = days?.endCell.replace(/[^A-Za-z]/g, '');
    const endAt = days?.endCell.replace(/[^0-9]/g, '');

    if (!startColumn) {
      errors.days.startColumnMissing = 'La colonne de départ est manquante';
    }

    if (!startAt) {
      errors.days.startNumberMissing = 'La ligne de départ est manquante';
    }

    if (!endColumn) {
      errors.days.endColumnMissing = 'La colonne de fin est manquante';
    }

    if (!endAt) {
      errors.days.endNumberMissing = 'La ligne de fin est manquante';
    }

    if (startColumn !== endColumn) {
      errors.days.column = 'Les cellules doivent être dans la même colonne';
    }

    if (Number(startAt) === Number(endAt)) {
      errors.days.number = 'Les cellule ne peuvent pas être identiques';
    }

    if (Number(startAt) > Number(endAt)) {
      errors.days.number = 'La cellule de départ ne peut pas être plus grande que la cellule de fin';
    }
  }

  if (hours?.startCell || hours?.endCell) {
    if (!hours?.startCell) {
      errors.hours.startMissing = 'La cellule de départ est manquante';
    }

    if (!hours?.endCell) {
      errors.hours.endMissing = 'La cellule de fin est manquante';
    }

    if (Object.keys(errors.hours).length === 0) {
      const startColumn = hours?.startCell.replace(/[^A-Za-z]/g, '');
      const startAt = hours?.startCell.replace(/[^0-9]/g, '');
      const endColumn = hours?.endCell.replace(/[^A-Za-z]/g, '');
      const endAt = hours?.endCell.replace(/[^0-9]/g, '');

      if (!startColumn) {
        errors.hours.startColumnMissing = 'La colonne de départ est manquante';
      }

      if (!startAt) {
        errors.hours.startNumberMissing = 'La ligne de départ est manquante';
      }

      if (!endColumn) {
        errors.hours.endColumnMissing = 'La colonne de fin est manquante';
      }

      if (!endAt) {
        errors.hours.endNumberMissing = 'La ligne de fin est manquante';
      }

      if (startColumn !== endColumn) {
        errors.hours.column = 'Les cellules doivent être dans la même colonne';
      }

      if (Number(startAt) === Number(endAt)) {
        errors.hours.number = 'Les cellule ne peuvent pas être identiques';
      }

      if (Number(startAt) > Number(endAt)) {
        errors.hours.number = 'La cellule de départ ne peut pas être plus grande que la cellule de fin';
      }
    }
  }

  if (pricesWithoutTaxes?.startCell || pricesWithoutTaxes?.endCell) {
    if (!pricesWithoutTaxes?.startCell) {
      errors.pricesWithoutTaxes.startMissing = 'La cellule de départ est manquante';
    }

    if (!pricesWithoutTaxes?.endCell) {
      errors.pricesWithoutTaxes.endMissing = 'La cellule de fin est manquante';
    }

    if (Object.keys(errors.pricesWithoutTaxes).length === 0) {
      const startColumn = pricesWithoutTaxes?.startCell.replace(/[^A-Za-z]/g, '');
      const startAt = pricesWithoutTaxes?.startCell.replace(/[^0-9]/g, '');
      const endColumn = pricesWithoutTaxes?.endCell.replace(/[^A-Za-z]/g, '');
      const endAt = pricesWithoutTaxes?.endCell.replace(/[^0-9]/g, '');

      if (!startColumn) {
        errors.pricesWithoutTaxes.startColumnMissing = 'La colonne de départ est manquante';
      }

      if (!startAt) {
        errors.pricesWithoutTaxes.startNumberMissing = 'La ligne de départ est manquante';
      }

      if (!endColumn) {
        errors.pricesWithoutTaxes.endColumnMissing = 'La colonne de fin est manquante';
      }

      if (!endAt) {
        errors.pricesWithoutTaxes.endNumberMissing = 'La ligne de fin est manquante';
      }

      if (startColumn !== endColumn) {
        errors.pricesWithoutTaxes.column = 'Les cellules doivent être dans la même colonne';
      }

      if (Number(startAt) === Number(endAt)) {
        errors.pricesWithoutTaxes.number = 'Les cellule ne peuvent pas être identiques';
      }

      if (Number(startAt) > Number(endAt)) {
        errors.pricesWithoutTaxes.number = 'La cellule de départ ne peut pas être plus grande que la cellule de fin';
      }
    }
  }

  if (!pricesWithTaxes?.startCell) {
    errors.pricesWithTaxes.startMissing = 'La cellule de départ est manquante';
  }

  if (!pricesWithTaxes?.endCell) {
    errors.pricesWithTaxes.endMissing = 'La cellule de fin est manquante';
  }

  if (Object.keys(errors.pricesWithTaxes).length === 0) {
    const startColumn = pricesWithTaxes?.startCell.replace(/[^A-Za-z]/g, '');
    const startAt = pricesWithTaxes?.startCell.replace(/[^0-9]/g, '');
    const endColumn = pricesWithTaxes?.endCell.replace(/[^A-Za-z]/g, '');
    const endAt = pricesWithTaxes?.endCell.replace(/[^0-9]/g, '');

    if (!startColumn) {
      errors.pricesWithTaxes.startColumnMissing = 'La colonne de départ est manquante';
    }

    if (!startAt) {
      errors.pricesWithTaxes.startNumberMissing = 'La ligne de départ est manquante';
    }

    if (!endColumn) {
      errors.pricesWithTaxes.endColumnMissing = 'La colonne de fin est manquante';
    }

    if (!endAt) {
      errors.pricesWithTaxes.endNumberMissing = 'La ligne de fin est manquante';
    }

    if (startColumn !== endColumn) {
      errors.pricesWithTaxes.column = 'Les cellules doivent être dans la même colonne';
    }

    if (Number(startAt) === Number(endAt)) {
      errors.pricesWithTaxes.number = 'Les cellule ne peuvent pas être identiques';
    }

    if (Number(startAt) > Number(endAt)) {
      errors.pricesWithTaxes.number = 'La cellule de départ ne peut pas être plus grande que la cellule de fin';
    }
  }

  if (Object.keys(errors.costPerDay).length === 0) delete errors.costPerDay;
  if (Object.keys(errors.costPerHour).length === 0) delete errors.costPerHour;
  if (Object.keys(errors.days).length === 0) delete errors.days;
  if (Object.keys(errors.hours).length === 0) delete errors.hours;
  if (Object.keys(errors.pricesWithoutTaxes).length === 0) delete errors.pricesWithoutTaxes;
  if (Object.keys(errors.pricesWithTaxes).length === 0) delete errors.pricesWithTaxes;

  return errors;
};
