import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { Form, Radio } from 'antd';

const { Item } = Form;

const TrueFalse = ({ loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Item
      label="Sélectionnez la réponse"
      validateStatus={errors?.trueFalse?.message && 'error'}
      help={errors?.trueFalse?.message}
      required
    >
      <Controller
        name="trueFalse"
        control={control}
        render={({ field }) => (
          <Radio.Group
            {...field}
            disabled={loading}
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: 'Vrai', value: true },
              { label: 'Faux', value: false },
            ]}
          />
        )}
      />
    </Item>
  );
};

export default TrueFalse;
