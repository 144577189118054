import { useFormContext, Controller } from 'react-hook-form';
import { Typography } from 'antd';
import { memo } from 'react';

const EditableTitle = memo(({ fieldName, isEditing, disabled, authorized }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <Typography.Text
          disabled={disabled}
          editable={isEditing && authorized ? { onChange: field.onChange, tooltip: 'Renommer' } : false}
        >
          {field.value}
        </Typography.Text>
      )}
    />
  );
});

export default EditableTitle;
