import { useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/logo/logo_Arinfo_RVB.svg';
import { useAuth } from '../../authContext';
import useFetch from '../../hooks/useFetch';
import { authRoutes } from '../../lib/routes';
import './Login.css';

const Login = () => {
  const { handleSubmit, control, setError } = useForm();
  const { errors } = useFormState({ control });
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const { login } = useAuth();
  const { post } = useFetch();

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(authRoutes.login, JSON.stringify(form));

    if (results.status === 200) {
      const { user, token } = results.data;

      login(token, user, () => {
        history.push('/');
      });
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <div className="login-page">
      <Card style={{ margin: '0 20px' }}>
        <Form noValidate onFinish={handleSubmit(onSubmit)} className="form" layout="vertical">
          <div className="logo-container">
            <img src={Logo} alt="Logo" className="logo" />
          </div>
          <Form.Item
            label="Nom d'utilisateur"
            validateStatus={errors?.username?.message && 'error'}
            help={errors?.username?.message}
          >
            <Controller
              control={control}
              name="username"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Mot de passe"
            validateStatus={errors?.password?.message && 'error'}
            help={errors?.password?.message}
          >
            <Controller
              control={control}
              name="password"
              render={({ field }) => <Input {...field} type="password" disabled={isProcessing} />}
            />
          </Form.Item>
          <Button htmlType="submit" type="primary" block loading={isProcessing}>
            Connexion
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
