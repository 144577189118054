import { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { get } from 'lodash';

const useColumnSearch = () => {
  const [search, setSearch] = useState({ searchText: '', searchedColumn: '' });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearch({ searchText: selectedKeys[0], searchedColumn: dataIndex });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearch({ searchText: '', searchedColumn: '' });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div>
        <div style={{ padding: '5px 12px' }}>
          <Input
            placeholder="Rechercher"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ maxWidth: 500, display: 'block' }}
            autoFocus
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '7px 12px 7px 5px',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          <Button type="link" size="small" onClick={() => handleReset(clearFilters)} disabled={!search.searchText}>
            Réinitialiser
          </Button>
          <Button type="primary" size="small" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
            OK
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) => {
      return search.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[search.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );
    },
  });

  return { search, getColumnSearchProps };
};

export default useColumnSearch;
