import { Row, Col, Modal, Form, TimePicker, Select, InputNumber, Checkbox, Button, Space } from 'antd';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useMemo, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import useSWR from 'swr';
import moment from 'moment';
import { useFormContext, useFieldArray, useForm, Controller, useFormState } from 'react-hook-form';
import { agencyRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';
import eventValidation from './eventValidation';

const fetcher = (url) => fetch(url).then((res) => res.json());

const clearData = (array) => {
  array = array.map((item) => ({ label: item.city, value: item._id }));

  array = array.sort((a, b) => sort(a, b, 'label'));

  return array;
};

const CreateModal = ({ agencies, agenciesLoading, selectedDate, onFinish, onClose }) => {
  const { control, handleSubmit, setValue, setError, reset } = useForm();
  const { errors } = useFormState({ control });
  const drawerTitle = useMemo(() => format(new Date(selectedDate), 'PPP', { locale: fr }) || '', [selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      setValue('start', moment(selectedDate));
    }
  }, [selectedDate]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (form) => {
    const newErrors = eventValidation(form);

    if (Object.keys(newErrors).length !== 0) {
      Object.entries(newErrors).forEach(([key, value]) => {
        setError(key, { type: 'manual', message: value });
      });
    } else {
      let data = { ...form };

      data.start = new Date(data.start);

      if (data.end) {
        data.end = new Date(data.end);
      }

      onFinish(data);
      handleClose();
    }
  };

  return (
    <Modal
      width={600}
      visible={!!selectedDate}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleClose}
      title={drawerTitle}
    >
      <Form noValidate layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Heure de démarrage"
              required
              validateStatus={errors?.start?.message && 'error'}
              help={errors?.start?.message}
            >
              <Controller
                control={control}
                name="start"
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    showSecond={false}
                    placeholder="Sélectionnez l'heure"
                    showNow={false}
                    format="HH:mm"
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Heure de fin">
              <Controller
                control={control}
                name="end"
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    showSecond={false}
                    placeholder="Sélectionnez l'heure"
                    showNow={false}
                    format="HH:mm"
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              label="Centre"
              required
              validateStatus={errors?.agency?.message && 'error'}
              help={errors?.agency?.message}
            >
              <Controller
                control={control}
                name="agency"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Sélectionnez dans la liste"
                    options={agencies}
                    loading={agenciesLoading}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Places">
              <Controller
                control={control}
                name="places"
                render={({ field }) => <InputNumber {...field} style={{ width: '100%' }} min={0} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Controller
            control={control}
            name="remote"
            render={({ field }) => (
              <Checkbox {...field} checked={field.value}>
                Distanciel
              </Checkbox>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditModal = ({ selectedEvent, agencies, agenciesLoading, onFinish, onClose, onDelete }) => {
  const { control, handleSubmit, setValue, setError, reset } = useForm();
  const { errors } = useFormState({ control });
  const drawerTitle = useMemo(
    () => (selectedEvent?.start ? format(new Date(selectedEvent.start), 'PPP', { locale: fr }) : ''),
    [selectedEvent],
  );

  useEffect(() => {
    if (selectedEvent) {
      setValue('agency', selectedEvent?.agency);
      setValue('places', selectedEvent?.places);
      setValue('remote', selectedEvent?.remote);

      setValue('start', moment(selectedEvent?.start));

      if (selectedEvent?.end) {
        setValue('end', moment(selectedEvent?.end));
      }
    }
  }, [selectedEvent]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (form) => {
    const newErrors = eventValidation(form);

    if (Object.keys(newErrors).length !== 0) {
      Object.entries(newErrors).forEach(([key, value]) => {
        setError(key, { type: 'manual', message: value });
      });
    } else {
      let data = { ...form };

      data.start = new Date(data.start);

      if (data.end) {
        data.end = new Date(data.end);
      }

      onFinish({ _id: selectedEvent._id, index: selectedEvent.index, ...data });
      handleClose();
    }
  };

  const handleDelete = () => {
    onDelete(selectedEvent?.index);
    handleClose();
  };

  return (
    <Modal
      width={600}
      visible={!!selectedEvent}
      title={drawerTitle}
      onCancel={handleClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="danger" onClick={handleDelete}>
            Supprimer
          </Button>
          <Space>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="primary" onClick={handleSubmit(onSubmit)}>
              Ok
            </Button>
          </Space>
        </div>
      }
    >
      <Form noValidate layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Heure de démarrage"
              required
              validateStatus={errors?.start?.message && 'error'}
              help={errors?.start?.message}
            >
              <Controller
                control={control}
                name="start"
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    showSecond={false}
                    placeholder="Sélectionnez l'heure"
                    showNow={false}
                    format="HH:mm"
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Heure de fin">
              <Controller
                control={control}
                name="end"
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    showSecond={false}
                    placeholder="Sélectionnez l'heure"
                    showNow={false}
                    format="HH:mm"
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              label="Centre"
              required
              validateStatus={errors?.agency?.message && 'error'}
              help={errors?.agency?.message}
            >
              <Controller
                control={control}
                name="agency"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Sélectionnez dans la liste"
                    options={agencies}
                    loading={agenciesLoading}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Places">
              <Controller
                control={control}
                name="places"
                render={({ field }) => <InputNumber {...field} style={{ width: '100%' }} min={0} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Controller
            control={control}
            name="remote"
            render={({ field }) => (
              <Checkbox {...field} checked={field.value}>
                Distanciel
              </Checkbox>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const getFormattedEvents = (data, agencies) => {
  let events = [...data].map((item, index) => ({ ...item, index }));

  events = events.map((item) => {
    const agency = agencies.find((itm) => itm.value === item.agency);

    return { ...item, title: agency?.label || item.agency };
  });

  return events;
};

const WorkshopEditDates = ({ loading }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [lockedDays, setLockedDays] = useState([]);
  const { control } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({ control, name: 'dates' });
  const { data: holidaysData } = useSWR('https://calendrier.api.gouv.fr/jours-feries/metropole.json', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const agenciesData = useMemo(() => clearData(agencies?.data || []), [agencies]);
  const events = useMemo(() => getFormattedEvents(fields, agenciesData) || [], [fields, agenciesData]);

  useEffect(() => {
    if (typeof holidaysData !== 'undefined') {
      setLockedDays(Object.keys(holidaysData).map((key) => key));
      setHolidays(
        Object.entries(holidaysData).map(([key, value]) => ({
          title: `${value} - Férié`,
          start: key,
          description: 'Jour férié',
          display: 'background',
          groupId: 'holidays',
        })),
      );
    }
  }, [holidaysData]);

  const handleDateSelect = (info) => {
    if (!loading && !lockedDays.includes(info.dateStr)) {
      setSelectedDate(info.date);
    }
  };

  const handleEventSelect = (info) => {
    if (!loading) {
      const index = info.event?.extendedProps?.index;

      if (index !== -1) {
        const event = events.find((item) => item.index === index);

        setSelectedEvent(event);
      }
    }
  };

  return (
    <>
      <CreateModal
        agencies={agenciesData}
        agenciesLoading={isValidating}
        selectedDate={selectedDate}
        onFinish={append}
        onClose={() => setSelectedDate(null)}
      />
      <EditModal
        agencies={agenciesData}
        agenciesLoading={isValidating}
        selectedEvent={selectedEvent}
        onFinish={(data) => {
          update(data.index, data);
        }}
        onClose={() => setSelectedEvent(null)}
        onDelete={remove}
      />
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        locale={frLocale}
        initialView="dayGridWeek"
        events={[...holidays, ...events]}
        dateClick={handleDateSelect}
        eventClick={handleEventSelect}
        height={300}
      />
      <span style={{ marginBottom: 24, display: 'block' }} />
    </>
  );
};

export default WorkshopEditDates;
