import { Form, Select, Input, Upload, Button, Typography, Radio, Row, Col, message, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import { typeRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';
import { searchInString } from '../../../../lib/helpers';
import placeholder from '../../../../assets/images/placeholder-image.jpg';

const { Item } = Form;
const { Paragraph } = Typography;
const { Group } = Radio;

const Meta = ({ withQuestion = true, withImage = true, loading, instructions, imagePreview, setImagePreview }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { data: types, isValidating: isTypesValidating } = useSWR(typeRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const onProTitleChange = (value, onChange) => {
    const communId = types.data.find((type) => type.title === 'Commun' && type.acronym === 'Commun')._id;

    if (value.includes(communId)) {
      if (value.length > 1 && value[0] === communId) {
        value.splice(0, 1);
        onChange(value);
      } else {
        onChange([communId]);
      }
    } else {
      onChange(value);
    }
  };

  const onImageChange = ({ file }, onChange) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 2) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 2Mo');
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
        onChange(file);
      });
      reader.readAsDataURL(file);
    }
  };

  const onImageDelete = (event, onChange) => {
    event.stopPropagation();
    setImagePreview(null);
    onChange(null);
  };

  return (
    <>
      <Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            {instructions?.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))}
          </ul>
        </blockquote>
      </Paragraph>
      <Row>
        <Col span={12}>
          <Item
            label="Catégories"
            extra="La catégorie Commun ne peut pas être sélectionnée avec d'autres catégories"
            validateStatus={errors?.trainings?.message && 'error'}
            help={errors?.trainings?.message}
            required
          >
            <Controller
              name="trainings"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={loading || !types || isTypesValidating}
                  placeholder="Sélectionnez dans la liste"
                  mode="multiple"
                  filterOption={(input, option) => searchInString(input, option.label)}
                  options={types?.data
                    .sort((a, b) => sort(a, b, 'title', ''))
                    .map((type) => ({ label: type.title, value: type._id }))}
                  onChange={(value) => onProTitleChange(value, field.onChange)}
                />
              )}
            />
          </Item>
          <Item
            label="Difficulté"
            validateStatus={errors?.difficulty?.message && 'error'}
            help={errors?.difficulty?.message}
            required
          >
            <Controller
              name="difficulty"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={loading}
                  placeholder="Sélectionnez dans la liste"
                  options={[
                    { label: 'Facile', value: 'easy' },
                    { label: 'Moyen', value: 'medium' },
                    { label: 'Difficile', value: 'hard' },
                  ]}
                />
              )}
            />
          </Item>
          <Item
            label="Temps disponible"
            validateStatus={errors?.duration?.message && 'error'}
            help={errors?.duration?.message}
            required
          >
            <Controller
              name="duration"
              control={control}
              defaultValue={30}
              render={({ field }) => (
                <Group {...field} disabled={loading} optionType="button" buttonStyle="solid">
                  <Radio value={30}>30 secondes</Radio>
                  <Radio value={45}>45 secondes</Radio>
                  <Radio value={60}>60 secondes</Radio>
                  <Radio value={75}>75 secondes</Radio>
                  <Radio value={90}>90 secondes</Radio>
                </Group>
              )}
            />
          </Item>
          {withImage && (
            <Item
              label="Image"
              style={{ display: 'block' }}
              validateStatus={errors?.image?.message && 'error'}
              help={errors?.image?.message}
            >
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <Card
                    style={{ overflow: 'hidden' }}
                    className="posiao-meta-card"
                    cover={
                      imagePreview ? (
                        imagePreview === 'loading' ? (
                          <div className="posiao-meta-card-loading">
                            <LoadingOutlined style={{ fontSize: 32 }} />
                          </div>
                        ) : (
                          <img className="posiao-meta-card-img" src={imagePreview} alt="Image de la question" />
                        )
                      ) : (
                        <img className="posiao-meta-card-placeholder" src={placeholder} alt="Image de la question" />
                      )
                    }
                    actions={[
                      ...(imagePreview
                        ? [
                            <Button onClick={(e) => onImageDelete(e, field.onChange)} type="link" disabled={loading}>
                              Supprimer
                            </Button>,
                          ]
                        : []),
                      <Upload
                        name={field.name}
                        disabled={loading}
                        maxCount={1}
                        showUploadList={false}
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={(img) => onImageChange(img, field.onChange)}
                      >
                        <Button disabled={loading} type="link">
                          {imagePreview ? 'Remplacer' : 'Ajouter'}
                        </Button>
                      </Upload>,
                    ]}
                    bodyStyle={{ padding: 0 }}
                  />
                )}
              />
            </Item>
          )}
          {withQuestion ? (
            <Item
              label="Question"
              validateStatus={errors?.question?.message && 'error'}
              help={errors?.question?.message}
              required
            >
              <Controller
                name="question"
                control={control}
                render={({ field }) => <Input {...field} disabled={loading} />}
              />
            </Item>
          ) : (
            <Item
              label="Description"
              extra="La description sert à identifier la question plus facilement, elle n'est pas visible par la personne passant le test"
              validateStatus={errors?.description?.message && 'error'}
              help={errors?.description?.message}
              required
            >
              <Controller
                name="description"
                control={control}
                render={({ field }) => <Input {...field} disabled={loading} />}
              />
            </Item>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Meta;
