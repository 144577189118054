import { memo, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';

const VideoPlayer = memo(({ publicId, textTracks, ...props }) => {
  const id = uniqueId();
  const videoRef = useRef();
  const cloudinaryRef = useRef();
  const playerRef = useRef();

  // Store the Cloudinary window instance to a ref when the page renders
  useEffect(() => {
    if (cloudinaryRef.current) return;

    cloudinaryRef.current = window.cloudinary;

    playerRef.current = cloudinaryRef.current.videoPlayer(videoRef.current, {
      cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
      secure: true,
      fluid: true,
      analytics: false,
      ...{ textTracks },
    });
  }, []);

  return (
    <div style={{ aspectRatio: '1920 / 1080' }}>
      <video
        ref={videoRef}
        id={id}
        width={1920}
        height={1080}
        className="cld-video-player"
        controls
        data-cld-public-id={publicId}
        {...props}
      />
    </div>
  );
});

VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
