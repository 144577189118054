import { useState, useEffect } from 'react';
import { Link, useParams, Prompt, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, Form, Tabs, Space, Button, message } from 'antd';
import useSWR from 'swr';
import { questionRoutes } from '../../../../lib/routes';
import Meta from '../components/Meta';
import Ordering from './Ordering';
import { OrderingPreview, Preview } from '../components/Preview/index';
import useFetch from '../../../../hooks/useFetch';

const { TabPane } = Tabs;

const UpdateOrderingQuestion = () => {
  const [tab, setTab] = useState('form');
  const [isBlocking, setIsBlocking] = useState(false);
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: question, isValidating } = useSWR(questionRoutes.findOne + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const methods = useForm({
    defaultValues: {
      duration: 30,
    },
  });
  const { put } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (question && question.data) {
      methods.setValue('trainings', question.data.trainings);
      methods.setValue('duration', question.data.duration);
      methods.setValue('difficulty', question.data.difficulty);
      methods.setValue('question', question.data.question);
      methods.setValue('ordering', question.data.ordering);
    }
  }, [question]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(questionRoutes.update + '/ordering' + `/${question.data._id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(questionRoutes.update + '/ordering' + `/${question.data._id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/posiao/banque-de-question');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Tabs defaultActiveKey="form" activeKey={tab} onChange={setTab}>
            <TabPane tab="Formulaire" key="form">
              <Meta
                loading={isProcessing || isValidating}
                withImage={false}
                instructions={['Insérez au moins 3 éléments dans le bon ordre.']}
              />
              <Ordering loading={isProcessing || isValidating} />
            </TabPane>
            <TabPane tab="Prévisualisation" key="preview">
              <Preview type="ordering">
                <OrderingPreview />
              </Preview>
            </TabPane>
          </Tabs>
          {tab === 'form' && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
              <Button className="gray-text" disabled={isProcessing}>
                <Link to="/posiao/banque-de-question">Annuler</Link>
              </Button>
              <Space>
                <Button loading={isProcessing} onClick={methods.handleSubmit(onSubmit)}>
                  Enregistrer
                </Button>
                <Button type="primary" loading={isProcessing} onClick={methods.handleSubmit(onSubmitAndLeave)}>
                  Enregistrer et quitter
                </Button>
              </Space>
            </div>
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default UpdateOrderingQuestion;
