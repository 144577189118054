import { useFormContext, useFieldArray } from 'react-hook-form';
import { Collapse, List, Button, Popconfirm, Upload, message, Space } from 'antd';
import { UploadOutlined, DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { useState, memo, useRef } from 'react';
import { serialize } from 'object-to-formdata';
import { useDrop, useDrag } from 'react-dnd';
import ModuleEditTitle from './ModuleEditTitle';
import { uploadRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const { Item } = List;
const { Panel } = Collapse;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const MemoizedItem = memo(({ item, index, move, onDelete, deleting, disabled }) => {
  const ref = useRef(null);
  const previewRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'RESOURCE',
    drop: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      move(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'RESOURCE',
    item: { ...item, index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0.2 : 1;
  drag(ref);
  drop(preview(previewRef));

  return (
    <Item className="course-list-item">
      <div ref={previewRef} style={{ opacity, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Space>
          <ModuleEditTitle fieldName={`resources.${index}.name`} />
        </Space>
        <Space>
          <Button ref={ref} disabled={disabled} icon={<DragOutlined />} />
          <Popconfirm
            {...popconfirmProps}
            onConfirm={() => onDelete(typeof item.file === 'object' ? item.file._id : item.file, index)}
          >
            <Button disabled={disabled} loading={deleting} icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      </div>
    </Item>
  );
});

MemoizedItem.displayName = 'MemoizedItem';

const ModuleEditRessources = ({ disabled }) => {
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState([]);
  const { control } = useFormContext();
  const { fields: resources, append, move, remove } = useFieldArray({ control, name: 'resources' });
  const { post, remove: fetchRemove } = useFetch();

  const onFileChange = async ({ file }) => {
    setUploading(true);

    const allowedExts = [
      'application/pdf',
      'application/x-rar-compressed',
      'application/x-tar',
      'application/zip',
      'application/x-7z-compressed',
      'application/gzip',
      'application/tar+gzip',
      'application/x-zip-compressed',
    ];

    if (!allowedExts.includes(file.type)) {
      message.error('Format non pris en charge');
      setUploading(false);
      return false;
    }

    const formData = serialize({ file }, { indices: true });
    const results = await post(uploadRoutes.file, formData, 'multipart/form-data');

    if (results.status === 201) {
      append({ name: results.data.originalName, file: results.data._id });
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    setUploading(false);
  };

  const onFileDelete = async (id, index) => {
    setDeleting((rest) => [...rest, index]);

    const results = await fetchRemove(uploadRoutes.deleteFile + '/' + id);

    if (results.status === 200) {
      remove(index);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    const newDeleting = [...deleting];
    const loadingIndex = newDeleting.indexOf((i) => i === index);

    newDeleting.splice(loadingIndex, 1);

    setDeleting(newDeleting);
  };

  return (
    <Collapse activeKey="resources" className="resources-collapse">
      <Panel
        key="resources"
        showArrow={false}
        header="Ressources"
        style={{ marginBottom: 20, padding: 0 }}
        extra={
          <Upload
            onChange={onFileChange}
            accept=".pdf,.rar,.zip,.gzip,.tar.gz,.7z"
            showUploadList={false}
            maxCount={1}
            beforeUpload={() => false}
            disabled={disabled || uploading}
          >
            <Button icon={<UploadOutlined />} loading={uploading}>
              Téléverser un fichier
            </Button>
          </Upload>
        }
      >
        <List size="small" style={{ minHeight: 48.8 }}>
          <div>
            {resources.map((resource, index) => (
              <MemoizedItem
                key={resource._id || resource.id}
                item={resource}
                index={index}
                move={move}
                disabled={disabled}
                deleting={deleting.includes(index)}
                onDelete={onFileDelete}
              />
            ))}
          </div>
        </List>
      </Panel>
    </Collapse>
  );
};

export default ModuleEditRessources;
