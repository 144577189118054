import { memo, useState } from 'react';
import {
  Card,
  Button,
  Table,
  message,
  Tabs,
  Menu,
  Dropdown,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  Checkbox,
  Divider,
  Typography,
} from 'antd';
import {
  MoreOutlined,
  DeleteOutlined,
  FileTextOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileZipOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import moment from 'moment';
import { addWeeks, format } from 'date-fns';
import { capitalize } from 'lodash';
import axios from 'axios';

import { moduleRoutes, packageRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { sort, checkAuthorization } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import ModuleImport from './ModuleImportExport';
import ModuleExportModal from './ModuleImportExport/ModuleExportModal';
import ModuleModal from './ModuleModal';
import ModuleLogsModal from './ModuleLogsModal';

const { Item } = Menu;
const { TabPane } = Tabs;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ModulesList = () => {
  const [exportDownload, setExportDownload] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDebugFolderDownloading, setIsDebugFolderDownloading] = useState(false);
  const [moduleModalOpen, setModuleModalOpen] = useState(false);
  const [logsModalOpen, setLogsModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const { data: modules, isValidating, mutate } = useSWR(moduleRoutes.default);
  const {
    data: packages,
    isValidating: isPackageValidating,
    mutate: mutatePackage,
  } = useSWR(packageRoutes.default, { refreshInterval: 5000 });
  const enabledModules = modules?.data.filter((module) => !module.disabled) || [];
  const disabledModules = modules?.data.filter((module) => module.disabled) || [];
  const importedPackages = packages?.data.filter((item) => item?.type.includes('_IMPORT')) || [];
  const exportedPackages = packages?.data.filter((item) => item?.type.includes('_EXPORT')) || [];
  const { getColumnSearchProps } = useColumnSearch();
  const { token, user } = useAuth();
  const { put, remove, get } = useFetch();

  const enableModule = async (id) => {
    setIsProcessing(true);

    const results = await put(moduleRoutes.enable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableModule = async (id) => {
    setIsProcessing(true);

    const results = await put(moduleRoutes.disable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onExportDownload = async (exportId) => {
    setExportDownload(exportId)

    try {
      // Génération du lien
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/export/create-download/${exportId}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        setExportDownload(null)
        message.error(response?.data?.message || 'Une erreur est survenue, le package ne peut pas être téléchargé');
        return;
      }

      // Téléchargement du fichier
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/export/download/${response.data.data.exportToken}`;
      link.download = response.data.data.filename;
      link.target = "_blank"
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      setExportDownload(null)
    } catch (err) {
      setExportDownload(null)
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          'Une erreur est survenue, le package ne peut pas être téléchargé',
      );
    }
  };

  const exportModule = async (id, options) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/export/scorm/${id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: { options: options || [] },
      });

      if (response.data.status === 200) {
        message.info(response.data.message);
      } else {
        message.success("Exportation du cours demandée, retrouvez la progression depuis l'onglet « Exportés »");
        mutatePackage();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setExportModalOpen(false);
    }
  };

  const onDebugDownload = async (logFile) => {
    setIsDebugFolderDownloading(true);

    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/logs/download/${logFile}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        message.error('Une erreur est survenue, le dossier ne peut pas être téléchargé');
        return;
      }

      const buffer = Buffer.from(response.data.data);
      const blob = new Blob([buffer]);
      const href = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = href;
      link.download = logFile + '.zip';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setIsDebugFolderDownloading(false);
    } catch (err) {
      console.log(err);
      setIsDebugFolderDownloading(false);
    }
  };

  const deletePackage = async (id) => {
    setIsProcessing(true);

    const results = await remove(packageRoutes.default + '/package/' + id);

    if (results.status === 200) {
      mutatePackage();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const deleteExport = async (id) => {
    setIsProcessing(true);

    const results = await remove(packageRoutes.default + '/export/' + id);

    if (results.status === 200) {
      mutatePackage();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ record }) => {
    const tempAccess = record?.tempAccesses.find((item) => item.user.toString() === user._id.toString());

    return (
      <Menu key={`menu-${record._id}`}>
        <Item key="show" onClick={() => setModuleModalOpen(record)}>
          Consulter
        </Item>
        {checkAuthorization(user, 'formao', 'modules', 'update-module') ||
        (tempAccess && !moment(new Date()).isAfter(tempAccess.expireAt)) ? (
          <Item key="edit">
            <Link to={`/formao/cours/modifier-un-cours/${record._id}`}>Modifier</Link>
          </Item>
        ) : null}
        {checkAuthorization(user, 'formao', 'packages', 'export') && (
          <Item key="export" onClick={() => setExportModalOpen(record)}>
            Exporter
          </Item>
        )}
        {record.disabled
          ? checkAuthorization(user, 'formao', 'modules', 'enable-module') && (
              <Item key="enable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={() => enableModule(record._id)}>
                  Activer
                </Popconfirm>
              </Item>
            )
          : checkAuthorization(user, 'formao', 'modules', 'disable-module') && (
              <Item key="disable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={() => disableModule(record._id)}>
                  Désactiver
                </Popconfirm>
              </Item>
            )}
      </Menu>
    );
  });

  const columns = [
    {
      title: 'Intitulé',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Difficulté',
      dataIndex: 'level',
      key: 'level',
      width: 200,
      filters: [
        { text: 'Rookie (Débutant)', value: 'easy' },
        { text: 'Aventurier (Avancé)', value: 'medium' },
        { text: 'Explorateur (Expert)', value: 'hard' },
      ],
      onFilter: (value, record) => record.level === value,
      render: (value) => {
        if (value === 'easy') return 'Rookie (Débutant)';
        if (value === 'medium') return 'Aventurier (Avancé)';
        if (value === 'hard') return 'Explorateur (Expert)';
        return null;
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  const columnsImport = [
    {
      title: 'Format',
      dataIndex: 'type',
      key: 'type',
      width: 80,
      filters: [
        { text: 'Moodle', value: 'MOODLE_IMPORT' },
        { text: 'SCORM', value: 'SCORM_IMPORT' },
      ],
      onFilter: (value, record) => record.type === value,
      render: (record) => {
        const packageTypeRecord = {
          MOODLE_IMPORT: {
            label: 'Moodle',
            color: 'blue',
          },
          SCORM_IMPORT: {
            label: 'SCORM',
            color: 'purple',
          },
        };

        return (
          <Tag style={{ width: '100%', textAlign: 'center' }} color={packageTypeRecord[record]?.color || 'default'}>
            {packageTypeRecord[record]?.label || 'export'}
          </Tag>
        );
      },
    },
    {
      title: 'Nom du package',
      dataIndex: 'package',
      key: 'package',
      sorter: (a, b) => sort(a, b, 'package'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('package'),
    },
    {
      title: 'Utilisateur',
      key: 'user',
      width: 300,
      render: (record) => `${capitalize(record.user.first_name)} ${record.user.last_name.toUpperCase()}`,
    },
    {
      title: "Etape d'importation",
      dataIndex: 'status',
      key: 'status',
      width: 200,
      filters: [
        { text: 'Téléversement du package', value: 'UPLOADING_PACKAGE' },
        { text: "En file d'attente", value: 'QUEUED' },
        { text: 'Formattage du cours', value: 'FORMATTING' },
        { text: 'Téléversement des fichiers', value: 'UPLOADING_FILES' },
        { text: 'Import terminé', value: 'DONE' },
        { text: 'Import échoué', value: 'ERRORED' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (value, record) => {
        const packageTypeRecord = {
          UPLOADING_PACKAGE: {
            label: 'Téléversement du package',
            color: 'gold',
            icon: <SyncOutlined spin />,
          },
          QUEUED: {
            label: "En file d'attente",
            color: 'default',
            icon: <ClockCircleOutlined />,
            tooltip: "L'élément sera traité prochainement",
          },
          FORMATTING: {
            label: 'Formattage du cours',
            color: 'orange',
            icon: <SyncOutlined spin />,
          },
          UPLOADING_FILES: {
            label: 'Téléversement des fichiers',
            color: 'yellow',
            icon: <SyncOutlined spin />,
          },
          DONE: {
            label: 'Import terminé',
            color: 'success',
            icon: <CheckCircleOutlined />,
          },
          ERRORED: {
            label: 'Import échoué',
            color: 'error',
            icon: <CloseCircleOutlined />,
            tooltip: record?.error || '',
          },
        };

        return (
          <Tooltip title={packageTypeRecord[value]?.tooltip || null} destroyTooltipOnHide={{ keepParent: false }}>
            <Tag
              style={{ width: '100%', textAlign: 'center' }}
              icon={packageTypeRecord[value]?.icon || null}
              color={packageTypeRecord[value]?.color || 'default'}
            >
              {packageTypeRecord[value]?.label || 'export'}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: 'Démarré le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      render: (record) => format(new Date(record), 'dd/MM/yyyy à HH:mm'),
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Suppression des logs au',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      align: 'center',
      render: (record) => format(addWeeks(new Date(record), 1), 'dd/MM/yyyy'),
      sorter: (a, b) => sort(a, b, 'updatedAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {['DONE', 'ERRORED'].includes(record.status) && (
            <>
              <Tooltip title="Visualiser les logs d'import" destroyTooltipOnHide={{ keepParent: false }}>
                <Button icon={<FileTextOutlined />} disabled={isProcessing} onClick={() => setLogsModalOpen(record)} />
              </Tooltip>
              {checkAuthorization(user, 'formao', 'packages', 'debug') && (
                <Tooltip title="Télécharger le dossier de débogage" destroyTooltipOnHide={{ keepParent: false }}>
                  <Button
                    icon={<FileZipOutlined />}
                    disabled={isProcessing}
                    loading={isDebugFolderDownloading}
                    onClick={() => onDebugDownload(record.logFile)}
                  />
                </Tooltip>
              )}
            </>
          )}
          {checkAuthorization(user, 'formao', 'packages', 'delete') && (
            <Tooltip title="Supprimer le package" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => deletePackage(record._id)}>
                <Button icon={<DeleteOutlined />} disabled={isProcessing} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const columnsExport = [
    {
      title: 'Format',
      dataIndex: 'type',
      key: 'type',
      width: 80,
      filters: [
        { text: 'Moodle', value: 'MOODLE_EXPORT' },
        { text: 'SCORM', value: 'SCORM_EXPORT' },
      ],
      onFilter: (value, record) => record.type === value,
      render: (record) => {
        const packageTypeRecord = {
          MOODLE_EXPORT: {
            label: 'Moodle',
            color: 'blue',
          },
          SCORM_EXPORT: {
            label: 'SCORM',
            color: 'purple',
          },
        };

        return (
          <Tag style={{ width: '100%', textAlign: 'center' }} color={packageTypeRecord[record]?.color || 'default'}>
            {packageTypeRecord[record]?.label || 'export'}
          </Tag>
        );
      },
    },
    {
      title: 'Cours',
      key: 'course',
      render: (record) => {
        return record?.course?.title || '';
      },
    },
    {
      title: 'Utilisateur',
      key: 'user',
      width: 300,
      render: (record) => `${capitalize(record.user.first_name)} ${record.user.last_name.toUpperCase()}`,
    },
    {
      title: "Etape d'exportation",
      dataIndex: 'status',
      key: 'status',
      width: 200,
      filters: [
        { text: "En file d'attente", value: 'QUEUED' },
        { text: 'Récupération des fichiers', value: 'COPY_FILES' },
        { text: 'Création des fichiers de cours', value: 'GENERATE_FILES' },
        { text: "Création de l'archive", value: 'BUILD_PACKAGE' },
        { text: 'Export terminé', value: 'DONE' },
        { text: 'Export échoué', value: 'ERRORED' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (value, record) => {
        const packageTypeRecord = {
          QUEUED: {
            label: "En file d'attente",
            color: 'default',
            icon: <ClockCircleOutlined />,
            tooltip: "L'élément sera traité prochainement",
          },
          COPY_FILES: {
            label: 'Récupération des fichiers',
            color: 'orange',
            icon: <SyncOutlined spin />,
          },
          GENERATE_FILES: {
            label: 'Création des fichiers de cours',
            color: 'yellow',
            icon: <SyncOutlined spin />,
          },
          BUILD_PACKAGE: {
            label: 'Création des fichiers de cours',
            color: 'lime',
            icon: <SyncOutlined spin />,
          },
          DONE: {
            label: 'Export terminé',
            color: 'success',
            icon: <CheckCircleOutlined />,
          },
          ERRORED: {
            label: 'Export échoué',
            color: 'error',
            icon: <CloseCircleOutlined />,
          },
        };

        if (value === 'ERRORED') {
          return (
            <Tooltip title={record.error} destroyTooltipOnHide={{ keepParent: false }}>
              <Tag
                style={{ width: '100%', textAlign: 'center' }}
                icon={packageTypeRecord[value]?.icon || null}
                color={packageTypeRecord[value]?.color || 'default'}
              >
                {packageTypeRecord[value]?.label || 'export'}
              </Tag>
            </Tooltip>
          );
        }

        return (
          <Tag
            style={{ width: '100%', textAlign: 'center' }}
            icon={packageTypeRecord[value]?.icon || null}
            color={packageTypeRecord[value]?.color || 'default'}
          >
            {packageTypeRecord[value]?.label || 'export'}
          </Tag>
        );
      },
    },
    {
      title: 'Exporté le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      render: (record) => format(new Date(record), 'dd/MM/yyyy à HH:mm'),
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {record.status === 'DONE' && (
            <Tooltip title="Télécharger" destroyTooltipOnHide={{ keepParent: false }}>
              <Button icon={<FileZipOutlined />} loading={exportDownload === record._id} disabled={isProcessing} onClick={() => onExportDownload(record._id)} />
            </Tooltip>
          )}
          {checkAuthorization(user, 'formao', 'packages', 'delete') && ['DONE', 'ERRORED'].includes(record.status) ? (
            <Tooltip title="Supprimer le package" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => deleteExport(record._id)}>
                <Button icon={<DeleteOutlined />} disabled={isProcessing} />
              </Popconfirm>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'formao', 'modules', 'create-module') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Space>
            <ModuleImport onFinish={mutate} />
            <Button className="gray-text">
              <Link to="/formao/cours/nouveau-cours">Nouveau cours</Link>
            </Button>
          </Space>
        </div>
      )}
      <ModuleExportModal
        onConfirm={({ options }) => exportModule(exportModalOpen?._id, options)}
        isOpen={!!exportModalOpen}
        course={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
      />
      <ModuleLogsModal visible={!!logsModalOpen} data={logsModalOpen} onClose={() => setLogsModalOpen(false)} />
      <ModuleModal visible={!!moduleModalOpen} data={moduleModalOpen} onClose={() => setModuleModalOpen(false)} />
      <Tabs>
        <TabPane key="enabled" tab={`Actifs (${enabledModules.length})`}>
          <Table
            loading={!modules || isValidating || isProcessing}
            dataSource={enabledModules}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(enabledModules)}
          />
        </TabPane>
        <TabPane key="disabled" tab={`Inactifs (${disabledModules.length})`}>
          <Table
            loading={!modules || isValidating || isProcessing}
            dataSource={disabledModules}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(disabledModules)}
          />
        </TabPane>
        {checkAuthorization(user, 'formao', 'packages', 'import') && (
          <TabPane key="importing" tab={`Importés (${(importedPackages || []).length})`}>
            <Table
              loading={!packages || isPackageValidating || isProcessing}
              columns={columnsImport}
              dataSource={importedPackages || []}
              bordered
              size="small"
              rowKey={(row) => row._id}
              pagination={tablePagination(importedPackages)}
              expandable={{
                expandedRowRender: (record) => {
                  if (record.type === 'SCORM_IMPORT') {
                    return (
                      <Space direction="vertical" style={{ display: 'flex' }}>
                        <Checkbox checked={record.options.keepEmpty}>
                          Conserver les modules et chapitres vides.
                        </Checkbox>
                      </Space>
                    );
                  }

                  return (
                    <Space direction="vertical" style={{ display: 'flex' }}>
                      <Checkbox checked={record.options.keepIntros}>Récupérer toutes les introductions</Checkbox>
                      <Checkbox checked={record.options.hiddenPages}>Afficher les pages cachées.</Checkbox>
                      <Checkbox checked={record.options.compatibilityMode}>
                        Garder le texte des modules non compatibles.
                      </Checkbox>
                      <Checkbox checked={record.options.keepEmpty}>Conserver les modules et chapitres vides.</Checkbox>
                      <Divider style={{ margin: 4 }} />
                      <Checkbox checked={record.options.splitBookPages}>
                        Créer une page par sous-chapitre dans les Modules "Livres".
                      </Checkbox>
                      <Checkbox checked={record.options.foldersAsPages}>
                        Transformer les Modules "Dossiers" en pages de liens de téléchargement.
                      </Checkbox>
                    </Space>
                  );
                },
              }}
            />
          </TabPane>
        )}
        {checkAuthorization(user, 'formao', 'packages', 'export') && (
          <TabPane key="exporting" tab={`Exportés (${(exportedPackages || []).length})`}>
            <Table
              loading={!packages || isPackageValidating || isProcessing}
              columns={columnsExport}
              dataSource={exportedPackages || []}
              bordered
              size="small"
              rowKey={(row) => row._id}
              pagination={tablePagination(exportedPackages)}
              expandable={{
                expandedRowRender: (record) => {
                  if (record.type === 'SCORM_EXPORT') {
                    return (
                      <Space direction="vertical" style={{ display: 'flex' }}>
                        <Checkbox checked={record.options.downloadFiles}>
                          Inclure les fichiers ressources dans le package
                        </Checkbox>
                      </Space>
                    );
                  }

                  return (
                    <Space direction="vertical" style={{ display: 'flex' }}>
                      <Typography.Text type="secondary">Aucune option disponible pour ce format</Typography.Text>
                    </Space>
                  );
                },
              }}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default ModulesList;
