import { Modal } from 'antd';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import cookies from 'js-cookie';
import useSWR from 'swr';
import { cloudinaryRoutes } from './lib/routes';

const initialState = {
  usage: {},
  status: null,
  isLoading: false,
  showUsageModal: () => {},
  refreshCache: () => {},
};

const CloudinaryContext = createContext(initialState);

export const CloudinaryProvider = ({ children }) => {
  const [mount, setMount] = useState(false);
  const { data, isValidating, mutate } = useSWR(cloudinaryRoutes.usage, {
    revalidateOnReconnect: true,
    revalidateOnFocus: false,
    refreshInterval: 30000,
  });

  const usage = useMemo(() => data?.data, [data]);
  const status = useMemo(
    () => (!data ? null : usage?.credits?.usage > 200 ? 'danger' : usage?.credits.usage > 150 ? 'warning' : 'ok'),
    [usage],
  );
  // const status = useMemo(
  //   () =>
  //     !data ? null : usage?.credits.used_percent > 75 ? 'danger' : usage?.credits.used_percent > 50 ? 'warning' : 'ok',
  //   [usage],
  // );
  const isLoading = useMemo(() => !usage || isValidating, [usage, isValidating]);

  const showUsageModal = () => {
    return Modal.error({
      title: 'Téléversement désactivé',
      content:
        "Le quota d'utilisation mensuel à dépassé les 75%, pour éviter des coûts supplémentaires le téléversement des images et des vidéos est désactivé jusqu'a la prochaine mensualité.",
    });
  };

  const refreshCache = () => {
    const authToken = cookies.get('admin-auth');

    fetch(cloudinaryRoutes.refresh, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then(({ data }) => mutate(data));
  };

  useEffect(() => {
    if (!mount) {
      refreshCache();
      setMount(true);
    }
  }, []);

  const value = useMemo(
    () => ({ usage, status, isLoading, showUsageModal, refreshCache }),
    [usage, status, isLoading, showUsageModal, refreshCache],
  );

  return <CloudinaryContext.Provider value={value}>{children}</CloudinaryContext.Provider>;
};

export const useCloudinaryContext = () => useContext(CloudinaryContext);
