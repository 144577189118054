import { read, utils } from 'xlsx';

const parseExcelTest = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = read(e.target.result, { type: 'array' });
      const obj = {};

      for (let i = 0; i < data.SheetNames.length; i++) {
        obj[data.SheetNames[i]] = data.Sheets[data.SheetNames[i]];
      }

      resolve(obj);
    };

    reader.readAsArrayBuffer(file);
  });
};

export default parseExcelTest;
