import { Card, Divider, Typography } from 'antd';
import AvatarEdit from './components/AvatarEdit';
import PasswordEdit from './components/PasswordEdit';

const Settings = () => {
  return (
    <Card>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            <li>
              Le mot de passe doit contenir au moins 8 caractères comprenant au moins une majuscule, une minuscule, un
              caractère spécial et un chiffre
            </li>
          </ul>
        </blockquote>
      </Typography.Paragraph>

      <AvatarEdit />

      <Divider>Mot de passe</Divider>

      <PasswordEdit />
    </Card>
  );
};

export default Settings;
