import { Button, Popover, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { HighlightOutlined } from '@ant-design/icons';

const Highlight = ({ editor, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const colors = editor.extensionManager.extensions.filter((el) => el.name === 'highlight')[0]?.options?.colors;

  const handleHighlight = (color) => editor.chain().focus().toggleHighlight({ color }).run();

  if (colors.length > 1) {
    return (
      <Popover
        visible={isOpen}
        onVisibleChange={() => setIsOpen(!isOpen)}
        trigger="click"
        overlayStyle={{ width: 160 }}
        overlayClassName="editor-highlight"
        placement="bottom"
        content={
          <div className="editor-highlight-select">
            {colors.map((color, index) => (
              <span
                key={index}
                className="editor-highlight-option"
                onClick={() => handleHighlight(color)}
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        }
      >
        <Tooltip key="highlight" title="Surligner">
          <Button
            icon={<HighlightOutlined />}
            disabled={disabled}
            type={editor.isActive('highlight') && 'primary'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          />
        </Tooltip>
      </Popover>
    );
  }

  return (
    <Tooltip key="highlight" title="Surligner">
      <Button
        icon={<HighlightOutlined />}
        onClick={() => handleHighlight(colors[0])}
        disabled={disabled}
        type={editor.isActive('highlight', { color: colors[0] }) && 'primary'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      />
    </Tooltip>
  );
};

export default Highlight;
