import { useState, useEffect } from 'react';
import { Card, Button, Tabs, Form } from 'antd';
import { Link, useHistory, Prompt } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import Meta from '../components/Meta';
import Organize from './Organize';
import { Preview, OrganizePreview } from '../components/Preview/index';
import { questionRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const { TabPane } = Tabs;

const CreateOrganizeQuestion = () => {
  const [tab, setTab] = useState('form');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const methods = useForm({
    defaultValues: {
      duration: 30,
      labels: [{ content: 'Élément A' }, { content: 'Élément B' }],
      lists: [
        { title: 'Colonne A', labels: [{ content: 'Élément D' }] },
        { title: 'Colonne B', labels: [{ content: 'Élément E' }, { content: 'Élément F' }] },
      ],
    },
  });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.register('labels');
  }, []);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(questionRoutes.create + '/organize', JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/posiao/banque-de-question');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Tabs defaultActiveKey="form" activeKey={tab} onChange={setTab}>
            <TabPane tab="Formulaire" key="form">
              <Meta
                loading={isProcessing}
                withImage={false}
                instructions={[
                  'Saisir une question',
                  'Ajouter le nombre de colonnes souhaitées et les nommer',
                  'Ajouter des éléments et les nommer',
                  'Placer les éléments dans les bonnes colonnes',
                  'Certains éléments peuvent être piège et donc dans aucune colonne',
                ]}
              />
              <Organize loading={isProcessing} />
            </TabPane>
            <TabPane tab="Prévisualisation" key="preview">
              <Preview type="organize">
                <OrganizePreview />
              </Preview>
            </TabPane>
          </Tabs>
          {tab === 'form' && (
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
              <Button className="gray-text" disabled={isProcessing}>
                <Link to="/posiao/banque-de-question">Annuler</Link>
              </Button>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            </div>
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default CreateOrganizeQuestion;
