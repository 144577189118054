import { Button, Popover, Space, Form, Tooltip, Input } from 'antd';
import { useState, useEffect } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import VimeoIcon from '../../icons/vimeo';
import { getEmbedUrl, isValidVimeoUrl } from './VimeoExtension';

const Vimeo = ({ editor, disabled }) => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setError(null);
    }
  }, [isOpen]);

  const handleToggle = () => {
    if (!isOpen) {
      return setIsOpen(true);
    }

    if (isOpen && !loading) {
      return setIsOpen(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    if (isValidVimeoUrl(url)) {
      getEmbedUrl(url).then((src) => {
        editor.commands.setVimeoVideo({ src: src });

        setUrl('');
        setIsOpen(false);
        setLoading(false);
      });
    } else {
      setError('URL invalide');
      setLoading(false);
    }
  };

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={handleToggle}
      trigger="click"
      title="Intégrer une vidéo Vimeo"
      overlayStyle={{ width: 350 }}
      placement="bottom"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item validateStatus={error && 'error'} help={error} style={{ margin: 0 }}>
            <Input
              name="url"
              placeholder="URL de la vidéo"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              disabled={disabled || loading}
            />
          </Form.Item>
          <Space style={{ marginTop: 10 }}>
            <Button
              icon={<CheckOutlined />}
              type="primary"
              disabled={disabled}
              onClick={handleSubmit}
              loading={loading}
            />
            <Button
              icon={<CloseOutlined />}
              onClick={() => {
                if (!loading) {
                  setIsOpen(false);
                }
              }}
              disabled={disabled || loading}
            />
          </Space>
        </Space>
      }
    >
      <Tooltip key="vimeo" title="Intégrer une vidéo Vimeo">
        <Button
          disabled={disabled}
          icon={
            <span className="anticon">
              <VimeoIcon style={{ height: 16, width: 16, fill: 'currentColor' }} />
            </span>
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        />
      </Tooltip>
    </Popover>
  );
};

export default Vimeo;
