import { mergeAttributes, Node } from '@tiptap/core';

export const Content = Node.create({
  name: 'detailsContent',
  content: 'block+',
  selectable: false,
  isolating: true,
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'details-content',
      },
    };
  },
  parseHTML() {
    return [{ tag: 'div' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
