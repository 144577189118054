import { Alert, Drawer, Button } from 'antd';
import { useState } from 'react';
import StudentEditUnits from './StudentEditUnits';

const StudentEditProgram = ({ index, disabled }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)}>Modifier la formation</Button>
      <Drawer
        title="Formation personnalisée"
        placement="right"
        visible={visible}
        onClose={() => setVisible(false)}
        size="large"
        className="student-create"
      >
        <Alert
          type="info"
          showIcon
          message="La personnalisation d'une formation n'est disponible que pour les entrées/sorties."
          style={{ marginBottom: 20 }}
        />
        <StudentEditUnits formationIndex={index} disabled={disabled} />
      </Drawer>
    </>
  );
};

export default StudentEditProgram;
