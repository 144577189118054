import { useEffect, useState, useCallback } from 'react';
import { useFormContext, useFormState, Controller, useFieldArray } from 'react-hook-form';
import { Upload, Form, message } from 'antd';
import LineTo from 'react-lineto';
import ImgCrop from 'antd-img-crop';
import LegendLabels from './LegendLabels';
import LegendsBox from './LegendsBox';
import placeholder from '../../../../assets/images/placeholder-image.jpg';

const { Dragger } = Upload;
const { Item } = Form;

const getSides = (items) => {
  if (items) {
    items = items.map((item, index) => ({ ...item, originIndex: index }));
    let leftSide = items.filter((item) => item.coords[0] <= 225) || [];
    let rightSide = items.filter((item) => item.coords[0] > 225) || [];

    if (leftSide.length > 5) {
      const toRemove = leftSide.length - 5;
      const cleanSide = [...leftSide].sort((a, b) => a.coords[0] - b.coords[0]);
      const rest = cleanSide.splice(5, toRemove);

      leftSide = cleanSide;
      rightSide = rightSide.concat(rest);
    }

    if (rightSide.length > 5) {
      const toRemove = rightSide.length - 5;
      const cleanSide = [...rightSide].sort((a, b) => a.coords[0] - b.coords[0]).reverse();
      const rest = cleanSide.splice(5, toRemove);

      rightSide = cleanSide;
      leftSide = leftSide.concat(rest);
    }

    return {
      leftSide: leftSide.sort((a, b) => a.coords[1] - b.coords[1]),
      rightSide: rightSide.sort((a, b) => a.coords[1] - b.coords[1]),
    };
  }
};

const Legend = ({ loading, imagePreview, setImagePreview, currentTab }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: legends, append, remove } = useFieldArray({ control, name: 'legends' });
  const { leftSide, rightSide } = getSides(legends || []);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const onImageChange = (img, onChange) => {
    const fileSize = img.size / 1024 / 1024;

    if (fileSize > 2) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 2Mo');
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
        remove();
        onChange(img);
      });
      reader.readAsDataURL(img);
    }
  };

  const newPoint = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const imgWidth = e.target.clientWidth;
    const imgHeight = e.target.clientHeight;
    //const side = offsetX > imgWidth / 2 ? 'right' : 'left';
    const pointHeight = offsetY + (450 - imgHeight) / 2;
    const pointWidth = offsetX + (450 - imgWidth) / 2;

    if (legends?.length >= 10) {
      return message.error('Trop de légendes présentes');
    }

    append({ label: { content: 'Nouvelle légende' }, coords: [pointWidth, pointHeight] });
  };

  const removeColumn = (id) => {
    const columnIndex = legends.findIndex((legend) => legend.id === id);
    remove(columnIndex);
  };

  useEffect(() => {
    const Listener = () => {
      forceUpdate();
    };
    window.addEventListener('scroll', Listener);
    window.addEventListener('resize', Listener);

    return () => {
      window.removeEventListener('resize', Listener);
      window.removeEventListener('scroll', Listener);
    };
  }, [forceUpdate]);

  return (
    <>
      <Item label="Image" validateStatus={errors?.image?.message && 'error'} help={errors?.image?.message} required>
        <Controller
          name="image"
          control={control}
          render={({ field }) => (
            <ImgCrop
              name={field.name}
              aspect={450 / 450}
              rotate
              zoom
              modalTitle="Modifier l'image"
              modalCancel="Annuler"
              modalOk="Confirmer"
              onModalOk={(img) => onImageChange(img, field.onChange)}
            >
              <Dragger maxCount={1} showUploadList={false} accept="image/*" disabled={loading}>
                <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
              </Dragger>
            </ImgCrop>
          )}
        />
      </Item>
      <div className="legend-container" style={{ display: 'flex', gap: '24px', margin: '24px 0' }}>
        <div style={{ flex: 1 }}>
          {leftSide.map((legend) => (
            <div style={{ marginBottom: 24 }} className={legend.id} key={legend.id}>
              <LegendsBox
                index={legend.originIndex}
                side="left"
                loading={loading}
                removeColumn={() => removeColumn(legend.id)}
              />
              {currentTab === 'form' && (
                <LineTo
                  from={legend.id}
                  to={`point-${legend.id}`}
                  fromAnchor="right"
                  toAnchor="center"
                  borderWidth={1}
                  borderColor="#1890ff"
                  delay={true}
                  className="lines"
                />
              )}
            </div>
          ))}
        </div>
        <div className="legend-img-container">
          <img
            src={imagePreview || placeholder}
            className="legend-img "
            alt="image légendée"
            id="legend__img"
            onClick={!loading && newPoint}
          />
          {legends?.map((legend) => (
            <span
              className={`point-${legend.id}`}
              style={{
                position: 'absolute',
                top: legend.coords[1],
                left: legend.coords[0],
                border: '5px solid #1890ff',
                borderRadius: '5px',
              }}
              key={legend.id}
            />
          ))}
        </div>
        <div style={{ flex: 1 }}>
          {rightSide.map((legend) => (
            <div style={{ marginBottom: 24 }} className={legend.id} key={legend.id}>
              <LegendsBox
                index={legend.originIndex}
                side="right"
                loading={loading}
                removeColumn={() => removeColumn(legend.id)}
              />
              {currentTab === 'form' && (
                <LineTo
                  from={legend.id}
                  to={`point-${legend.id}`}
                  fromAnchor="left"
                  toAnchor="center"
                  borderWidth={1}
                  borderColor="#1890ff"
                  delay={true}
                  className="lines"
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {errors?.legends && <p style={{ color: '#ff4d4f' }}>{errors?.legends?.message}</p>}
      <LegendLabels loading={loading} />
    </>
  );
};

export default Legend;
