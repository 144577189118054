import React, { useEffect, useState } from 'react';
import { Form, Card, Input, Button, message, Upload, Row, Col, Typography } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Link, useHistory, useParams, Prompt } from 'react-router-dom';
import useSWR from 'swr';
import useFetch from '../../../hooks/useFetch';
import { trainingRoutes } from '../../../lib/routes';
import Editor from '../../../ui/Editor';
import './editor.css';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import chunkUpload from '../../../lib/chunk-upload';
import useQuery from '../../../hooks/useQuery';
import getImageSize from '../../../lib/get-image-size';

// TODO : SEO
const TrainingMoreCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingBase, setIsLoadingBase] = useState(false);
  const [horizontalImageUploading, setHorizontalImageUploading] = useState(false);
  const [verticalImageUploading, setVerticalImageUploading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const { post, get } = useFetch();
  const { handleSubmit, control, setError, formState, setValue } = useForm();
  const { errors } = useFormState({ control });
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery();
  const { data: training, isValidating } = useSWR(trainingRoutes.findOne + '/id/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    const baseId = query.get('base');

    if (!!baseId) {
      (async () => {
        setIsLoadingBase(true);

        const results = await get(trainingRoutes.findOneLearnMore + '/' + baseId);

        if (results.status === 200) {
          setValue('content', results.data.content);
        }

        setIsLoadingBase(false);
      })();
    }
  }, []);

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(trainingRoutes.createLearnMore + '/' + id, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/arinfo/formations');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="import-form">
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Typography.Title level={4}>
        Nouvelle page « En savoir plus »{training?.data ? ` pour la formation ${training?.data?.title}` : ''}
      </Typography.Title>
      <Form noValidate layout="vertical">
        <Row gutter={[16, 16]}>
          <Col sm={24} lg={12}>
            <Form.Item
              label="Image de couverture horizontale"
              required
              validateStatus={errors?.image?.message && 'error'}
              help={errors?.image?.message}
            >
              <Controller
                control={control}
                name="horizontalImage"
                render={({ field }) => (
                  <Upload.Dragger
                    name={field.name}
                    maxCount={1}
                    accept="image/*"
                    disabled={isProcessing || horizontalImageUploading || isValidating}
                    multiple={false}
                    style={{ width: '100%' }}
                    showUploadList={false}
                    action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`}
                    customRequest={chunkUpload}
                    data={async (file) => {
                      // Récupère une signature d'upload
                      const url = `${process.env.REACT_APP_BASE_URL_API_URL}/v2/medias/videos/signature`;
                      const response = await post(url);

                      if (response.status === 200) {
                        const { timestamp, signature } = response.data;

                        return {
                          file: file,
                          api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
                          timestamp,
                          signature,
                        };
                      }
                    }}
                    beforeUpload={async (file) => {
                      if (!file.type.startsWith('image/')) {
                        return Upload.LIST_IGNORE;
                      }

                      const fileSize = file.size / 1024 / 1024;

                      if (fileSize > 2) {
                        message.error('Téléversement limité à 2 Mo');
                        return Upload.LIST_IGNORE;
                      }

                      // const { height, width } = await getImageSize(file);

                      // if (height < 1080 || width < 1920) {
                      //   message.error("L'image ne respecte pas la taille minimale de 1920x1080px");
                      //   return Upload.LIST_IGNORE;
                      // }
                    }}
                    onChange={({ fileList }) => {
                      const file = fileList[0];
                      const { status } = file;

                      if (status === 'uploading') {
                        setHorizontalImageUploading(true);
                      } else if (status === 'error') {
                        setHorizontalImageUploading(false);
                      } else if (status === 'done') {
                        field.onChange(file.response.public_id);
                        setHorizontalImageUploading(false);
                      } else {
                        setHorizontalImageUploading(false);
                      }
                    }}
                  >
                    {field?.value && !horizontalImageUploading ? (
                      <div style={{ height: 100, width: 100, margin: '0 auto' }}>
                        <img
                          src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${field.value}`}
                          alt=""
                          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                        />
                      </div>
                    ) : (
                      <p className="ant-upload-drag-icon">
                        {horizontalImageUploading ? <LoadingOutlined /> : <UploadOutlined />}
                      </p>
                    )}
                    <p className="ant-upload-text">
                      Cliquez ou faites glisser le fichier vers cette zone pour le téléverser
                    </p>
                  </Upload.Dragger>
                )}
              />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item
              label="Image de couverture verticale"
              required
              validateStatus={errors?.image?.message && 'error'}
              help={errors?.image?.message}
            >
              <Controller
                control={control}
                name="verticalImage"
                render={({ field }) => (
                  <Upload.Dragger
                    name={field.name}
                    maxCount={1}
                    accept="image/*"
                    disabled={isProcessing || verticalImageUploading || isValidating}
                    multiple={false}
                    style={{ width: '100%' }}
                    showUploadList={false}
                    action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`}
                    customRequest={chunkUpload}
                    data={async (file) => {
                      // Récupère une signature d'upload
                      const url = `${process.env.REACT_APP_BASE_URL_API_URL}/v2/medias/videos/signature`;
                      const response = await post(url);

                      if (response.status === 200) {
                        const { timestamp, signature } = response.data;

                        return {
                          file: file,
                          api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
                          timestamp,
                          signature,
                        };
                      }
                    }}
                    beforeUpload={async (file) => {
                      if (!file.type.startsWith('image/')) {
                        return Upload.LIST_IGNORE;
                      }

                      const fileSize = file.size / 1024 / 1024;

                      if (fileSize > 2) {
                        message.error('Téléversement limité à 2 Mo');
                        return Upload.LIST_IGNORE;
                      }

                      // const { height, width } = await getImageSize(file);

                      // if (height < 1080 || width < 1920) {
                      //   message.error("L'image ne respecte pas la taille minimale de 1920x1080px");
                      //   return Upload.LIST_IGNORE;
                      // }
                    }}
                    onChange={({ fileList }) => {
                      const file = fileList[0];
                      const { status } = file;

                      if (status === 'uploading') {
                        setVerticalImageUploading(true);
                      } else if (status === 'error') {
                        setVerticalImageUploading(false);
                      } else if (status === 'done') {
                        field.onChange(file.response.public_id);
                        setVerticalImageUploading(false);
                      } else {
                        setVerticalImageUploading(false);
                      }
                    }}
                  >
                    {field?.value && !verticalImageUploading ? (
                      <div style={{ height: 100, width: 100, margin: '0 auto' }}>
                        <img
                          src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${field.value}`}
                          alt=""
                          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                        />
                      </div>
                    ) : (
                      <p className="ant-upload-drag-icon">
                        {verticalImageUploading ? <LoadingOutlined /> : <UploadOutlined />}
                      </p>
                    )}
                    <p className="ant-upload-text">
                      Cliquez ou faites glisser le fichier vers cette zone pour le téléverser
                    </p>
                  </Upload.Dragger>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Intitulé"
          required
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
        >
          <Controller
            name="title"
            control={control}
            render={({ field }) => <Input {...field} disabled={isProcessing || isValidating} />}
          />
        </Form.Item>
        <Form.Item
          label="Contenu"
          required
          validateStatus={errors?.content?.message && 'error'}
          help={errors?.content?.message}
        >
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <Editor
                {...field}
                minHeight={300}
                className="editor-arinfo-training-more"
                disabled={isProcessing || isLoadingBase || isValidating}
                options={{
                  heading: {
                    levels: [3, 4],
                    labels: {
                      3: 'Titre de bloc',
                      4: 'Sous-titre',
                    },
                  },
                  color: {
                    colors: ['#57586E', '#282931'],
                  },
                  code: false,
                  codeBlock: false,
                  file: false,
                  strike: false,
                  underline: false,
                  important: false,
                  highlight: false,
                }}
              />
            )}
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={isProcessing}>
            <Link to="/arinfo/formations">Annuler</Link>
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isProcessing}
            disabled={isValidating}
            onClick={handleSubmit(onSubmit)}
          >
            Enregistrer
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TrainingMoreCreate;
