import { Button, Table, Upload, Form, Popover, Divider, Alert } from 'antd';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';
import parseExcel from '../../../../lib/parseExcel';
import { sort, sortNum } from '../../../../shared/utils';
import tablePagination from '../../../../lib/tablePagination';
import { DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { accountValidator } from './validation';
import useFetch from '../../../../hooks/useFetch';
import useColumnSearch from '../../../../hooks/useColumnSearch';
import { useState } from 'react';

const { Item } = Form;

const StudentImportUpload = ({ onNext }) => {
  const [loading, setLoading] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { control } = useFormContext();
  const { fields, replace, remove, update } = useFieldArray({ control, name: 'students' });
  const students = fields.map((item, index) => ({ ...item, index }));
  const errors = fields.filter((item) => Object.keys(item?.errors).length !== 0).length !== 0;
  const { post } = useFetch();

  const onFileChange = ({ file }) => {
    setLoading(true);

    parseExcel(file, { includeId: true }).then((json) => {
      const data = json.map((item) => ({
        last_name: item['Nom'] || '',
        first_name: item['Prénom'] || '',
        email: item['Email'] || '',
      }));

      const filter = data.filter((item) => !(!item.last_name && !item.first_name && !item.email));

      accountValidator(filter, post).then((fields) => {
        replace(fields);
        setLoading(false);
      });
    });
  };

  const handleDelete = (index) => {
    const item = fields[index];

    if (item?.errors?.duplicates) {
      for (let i = 0; i < item?.errors?.duplicates.length; i++) {
        const duplicateIndex = fields.findIndex((field) => field._id === item.errors.duplicates[i]);

        if (duplicateIndex !== -1) {
          let newDuplicates = fields[duplicateIndex].errors.duplicates;
          newDuplicates.splice(newDuplicates.indexOf(item._id), 1);

          const newErrors = { ...fields[duplicateIndex].errors, duplicates: newDuplicates };

          if (newErrors.duplicates.length === 0) {
            delete newErrors.duplicates;
          }

          update(duplicateIndex, { ...fields[duplicateIndex], errors: newErrors });
        }
      }
    }

    remove(index);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      width: 20,
      align: 'center',
      sorter: (a, b) => sortNum(a, b, '_id'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('_id'),
    },
    {
      title: 'Statut',
      dataIndex: 'errors',
      key: 'status',
      width: 50,
      align: 'center',
      sorter: (a, b) => (Object.keys(a.errors)?.length !== 0 ? -1 : 1),
      sortDirections: ['ascend', 'descend'],
      render: (value) => {
        if (!value) {
          return null;
        }

        return Object.keys(value).length !== 0 ? (
          <Popover
            content={
              <ul style={{ margin: 0, listStylePosition: 'inside', padding: 0 }}>
                {!!value?.last_name && <li>Le nom est manquant</li>}
                {!!value?.first_name && <li>Le prénom est manquant</li>}
                {!!value?.email && <li>L'email est manquant</li>}
                {!!value?.exist && <li>Un compte apprenant avec cet email existe déjà</li>}
                {!!value?.duplicates && (
                  <li>
                    L'email est identique
                    {value?.duplicates.length === 1
                      ? ` à l'ID ${value?.duplicates[0]}`
                      : ` aux ID ${value?.duplicates.slice(0, -1).join(', ') + ' et ' + value?.duplicates.slice(-1)}`}
                  </li>
                )}
              </ul>
            }
          >
            <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
          </Popover>
        ) : (
          <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />
        );
      },
    },
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 300,
      sorter: (a, b) => sort(a, b, 'last_name', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
      render: (value, record) => {
        if (!!record?.errors.last_name) {
          return (
            <Popover
              content={
                <ul style={{ margin: 0, listStylePosition: 'inside', padding: 0 }}>
                  {!!record?.errors.last_name && <li>Le nom est manquant</li>}
                </ul>
              }
            >
              <QuestionCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
            </Popover>
          );
        }

        return value;
      },
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 300,
      sorter: (a, b) => sort(a, b, 'first_name', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('first_name'),
      render: (value, record) => {
        if (!!record?.errors.first_name) {
          return (
            <Popover
              content={
                <ul style={{ margin: 0, listStylePosition: 'inside', padding: 0 }}>
                  {!!record?.errors.first_name && <li>Le prénom est manquant</li>}
                </ul>
              }
            >
              <QuestionCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
            </Popover>
          );
        }

        return value;
      },
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
      key: 'email',
      width: 500,
      sorter: (a, b) => sort(a, b, 'email', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('email'),
      render: (value, record) => {
        if (!!record?.errors.email || !!record?.errors.duplicates || !!record?.errors.exist) {
          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Popover
                content={
                  <ul style={{ margin: 0, listStylePosition: 'inside', padding: 0 }}>
                    {!!record?.errors.email && <li>L'email est manquant</li>}
                    {!!record?.errors.exist && <li>Un compte apprenant avec cet email existe déjà</li>}
                    {!!record?.errors.duplicates && (
                      <li>
                        Cet email est identique
                        {record?.errors?.duplicates.length === 1
                          ? ` à l'ID ${record?.errors?.duplicates[0]}`
                          : ` aux ID ${
                              record?.errors?.duplicates.slice(0, -1).join(', ') +
                              ' et ' +
                              record?.errors?.duplicates.slice(-1)
                            }`}
                      </li>
                    )}
                  </ul>
                }
              >
                <QuestionCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
              </Popover>
              <Divider type="vertical" />
              {value}
            </span>
          );
        }

        return value;
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.index)} disabled={loading} />
      ),
    },
  ];

  return (
    <>
      <Item label="Fichier EXCEL (.xlsx)" required>
        <Upload
          beforeUpload={() => false}
          onChange={onFileChange}
          maxCount={1}
          showUploadList={false}
          accept=".xlsx"
          disabled={loading}
        >
          <Button loading={loading}>Téléverser un fichier</Button>
        </Upload>
      </Item>
      <Item label="Récapitulatif de l'importation">
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={students}
          rowKey={(row) => row.id}
          loading={loading}
          pagination={tablePagination(students)}
        />
      </Item>
      {errors && (
        <Alert
          message="Erreur d'importation"
          description="Des erreurs on été détectées, veuillez les corriger avant de continuer."
          type="error"
          showIcon
        />
      )}
      <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/formao/apprenants">
          <Button htmlType="button">Annuler</Button>
        </Link>
        <Button htmlType="button" onClick={onNext} disabled={fields.length === 0 || errors}>
          Suivant
        </Button>
      </div>
    </>
  );
};

export default StudentImportUpload;
