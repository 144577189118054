import { Input, Form, Select, Row, Col, Upload, Switch } from 'antd';
import { useMemo, useState } from 'react';
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import { trainingRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const clearData = (data) => {
  // data = data.filter((item) => item.type === 'metier');

  data = data.map((item) => ({ label: item.title, value: item._id }));

  data = data.sort((a, b) => sort(a, b, 'label'));

  return data;
};

const WorkshopCreateInfos = ({ setBlocking, loading }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const { data: trainings, isValidating } = useSWR(trainingRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const data = useMemo(() => clearData(trainings?.data || []), [trainings]);

  const onImageChange = ({ file }) => {
    setBlocking();
    setValue('image', file.originFileObj);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagePreview(reader.result));
    reader.readAsDataURL(file.originFileObj);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          label="Titre de l'atelier"
          required
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
        >
          <Controller name="title" control={control} render={({ field }) => <Input {...field} disabled={loading} />} />
        </Form.Item>
        <Form.Item
          label="Formation liée"
          required
          validateStatus={errors?.training?.message && 'error'}
          help={errors?.training?.message}
        >
          <Controller
            name="training"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={data}
                placeholder="Sélectionnez dans la liste"
                loading={isValidating}
                disabled={loading}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Description (max 250 caractères)"
          required
          validateStatus={errors?.description?.message && 'error'}
          help={errors?.description?.message}
        >
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                autoSize
                maxLength={250}
                {...field}
                disabled={loading}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.code === 'Enter') {
                    event.preventDefault();
                  }
                }}
              />
            )}
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Durée"
              validateStatus={errors?.duration?.message && 'error'}
              help="Utiliser les formats suivants : 1h30 ou 90mins"
            >
              <Controller
                name="duration"
                control={control}
                render={({ field }) => <Input placeholder="1h30" {...field} disabled={loading} />}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Visibilité">
              <Controller
                name="visible"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checkedChildren="Visible"
                    unCheckedChildren="Invisible"
                    checked={field.value}
                    disabled={loading}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Image"
          required
          validateStatus={errors?.image?.message && 'error'}
          help={errors?.image?.message}
        >
          <Controller
            control={control}
            name="image"
            render={({ field }) => (
              <Upload.Dragger
                name={field.name}
                maxCount={1}
                showUploadList={false}
                height={150}
                accept="image/*"
                disabled={loading}
                style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                onChange={(img) => onImageChange(img)}
              >
                {imagePreview ? (
                  <img
                    style={{
                      maxWidth: 'calc(100% - 32px)',
                      maxHeight: 100,
                      display: 'block',
                      margin: '0 auto',
                    }}
                    src={imagePreview}
                    alt=""
                  />
                ) : (
                  <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                )}
              </Upload.Dragger>
            )}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default WorkshopCreateInfos;
