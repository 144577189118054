import { useState, useEffect } from 'react';
import { Link, useParams, Prompt } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, Form, Tabs, Space, Button } from 'antd';
import { questionRoutes } from '../../../../lib/routes';
import useSWR from 'swr';
import Meta from '../components/Meta';
import Completion from './Completion';
import { Preview, CompletionPreview } from '../components/Preview/index';

const { TabPane } = Tabs;

const UpdateCompletionQuestion = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: question } = useSWR(questionRoutes.findOne + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const methods = useForm({
    defaultValues: {
      time: 30,
    },
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (question && question.data) {
      methods.setValue('pro_title', question.data.trainings);
      methods.setValue('time', question.data.duration);
      methods.setValue('difficulty', question.data.difficulty);
      methods.setValue('description', question.data.description);
    }
  }, [question]);

  const onSubmit = (form) => console.log(form);

  const onSubmitAndLeave = (form) => console.log(form);

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Tabs defaultActiveKey="form">
            <TabPane tab="Formulaire" key="form">
              <Meta
                loading={isProcessing}
                withImage={false}
                withQuestion={false}
                //prettier-ignore
                instructions={['Remplir la zone de texte et sélectionner les parties de textes que vous souhaitez transformer en trou une par une puis cliquez sur le bouton remplacer. Si vous avez fais une erreur, survolez le texte surligner puis cliquez sur supprimer.']}
              />
              {/* <Completion loading={isProcessing} /> */}
            </TabPane>
            <TabPane tab="Prévisualisation" key="preview">
              <Preview>
                <CompletionPreview />
              </Preview>
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/posiao/banque-de-question">Annuler</Link>
            </Button>
            <Space>
              <Button loading={isProcessing} onClick={methods.handleSubmit(onSubmit)}>
                Enregistrer
              </Button>
              <Button type="primary" loading={isProcessing} onClick={methods.handleSubmit(onSubmitAndLeave)}>
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default UpdateCompletionQuestion;
