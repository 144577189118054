import { Select } from 'antd';
import { useState } from 'react';

const options = [
  { label: 'Titre 1', value: 1 },
  { label: 'Titre 2', value: 2 },
  { label: 'Titre 3', value: 3 },
  { label: 'Titre 4', value: 4 },
  { label: 'Titre 5', value: 5 },
  { label: 'Titre 6', value: 6 },
];

const Heading = ({ editor, disabled }) => {
  const levels = editor.extensionManager.extensions.filter((el) => el.name === 'heading')[0]?.options?.levels;
  const labels = editor.extensionManager.extensions.filter((el) => el.name === 'heading')[0]?.options?.labels;
  const activeLevel = options.find((opt) => editor.isActive('heading', { level: opt.value }))?.value ?? 'p';

  const handleChange = (level) => {
    if (level === 'p') {
      return editor.commands.setParagraph();
    }

    return editor.chain().focus().setHeading({ level }).run();
  };

  return (
    <Select value={activeLevel} onChange={handleChange} disabled={disabled} style={{ minWidth: 150 }}>
      {options
        .filter((opt) => levels.includes(opt.value))
        .map((opt, i) => (
          <Select.Option key={i} value={opt.value} className="editor-select-option">
            {opt.value === 1 ? labels[opt.value] || opt.label : null}
            {opt.value === 2 ? labels[opt.value] || opt.label : null}
            {opt.value === 3 ? labels[opt.value] || opt.label : null}
            {opt.value === 4 ? labels[opt.value] || opt.label : null}
            {opt.value === 5 ? labels[opt.value] || opt.label : null}
            {opt.value === 6 ? labels[opt.value] || opt.label : null}
          </Select.Option>
        ))}
      <Select.Option key="p" value="p">
        Paragraphe
      </Select.Option>
    </Select>
  );
};

export default Heading;
