import { useState, useLayoutEffect } from 'react';
import { Button, Input, Space, Popover } from 'antd';
// import Popup from './Popup';

const getTextWidth = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

const mockData = [
  {
    _id: '0',
    value: 'Lorem ipsum dolor sit amet, consectetur ',
  },
  {
    _id: '1',
    value: 'adipiscing elit. Quisque',
    selected: true,
  },
  {
    _id: '2',
    value:
      ' adipiscing elit. Quisque ante erat, tincidunt eu metus in, laoreet pharetra tortor. Nullam neque arcu, mollis ut libero ut, vulputate efficitur dolor. Curabitur sit amet massa ex. Donec aliquet consectetur nisi sed volutpat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam sit amet odio mauris. Nunc eros lacus, facilisis convallis ipsum nec, rhoncus condimentum elit. Duis risus dolor, fringilla sed pretium et, facilisis sed lacus. Duis vel nibh quis urna fringilla rhoncus sed quis quam. Duis efficitur sed turpis ut fringilla. Aliquam faucibus metus vitae semper ultrices. Sed id sem at libero ultrices fermentum. Pellentesque cursus, magna et porttitor dignissim, ex tellus egestas mi, id maximus ante metus sit amet elit.',
  },
];

const Completion = ({ loading }) => {
  const [selectionTooLong, setSelectionTooLong] = useState(false);
  const [content, setContent] = useState([{ _id: '0', value: 'Texte à compléter' }]);

  useLayoutEffect(() => {
    const onSelectionChange = () => {
      const selection = window.getSelection();
      const selectionLength = selection.toString().length;

      if (selectionLength > 30) {
        setSelectionTooLong(true);
      } else {
        setSelectionTooLong(false);
      }
    };

    document.addEventListener('selectionchange', onSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange);
    };
  }, []);

  const handleChange = (event) => {
    const values = event.target.getElementsByTagName('span');

    for (let i = 0; i < values.length; i++) {
      const { id, textContent } = values[i];
      const contentTarget = content.find(({ _id }) => _id === id);

      if (contentTarget.value !== textContent) {
        const newContent = [...content];
        const targetIndex = newContent.indexOf(contentTarget);

        const newItem = { ...contentTarget, value: textContent };

        newContent.splice(targetIndex, 1, newItem);

        setContent(newContent);
      }
    }
  };

  const handleSelect = () => {
    const selection = window.getSelection();
    const el = selection.anchorNode.parentElement;
    const { startOffset, endOffset } = selection.getRangeAt(0);
    const selectionLength = selection.toString().length;

    if (selectionTooLong) {
      return;
    }

    if (endOffset - startOffset < selectionLength) {
      return;
    }

    const contentTarget = content.find(({ _id }) => _id === el.id);

    if (contentTarget) {
      const targetIndex = content.indexOf(contentTarget);

      const beforeSelection = contentTarget.value.substring(0, startOffset);
      const cut = contentTarget.value.substring(startOffset, endOffset);
      const afterSelection = contentTarget.value.substring(endOffset, contentTarget.value.length);

      let newContent = [...content];

      newContent.splice(targetIndex, 1, {
        _id: Math.floor(Math.random() * 99999999).toString(),
        value: beforeSelection,
      });

      newContent.splice(targetIndex + 1, 0, {
        _id: Math.floor(Math.random() * 99999999).toString(),
        value: cut,
        selected: true,
        width: getTextWidth(selection.toString()),
      });

      newContent.splice(targetIndex + 2, 0, {
        _id: Math.floor(Math.random() * 99999999).toString(),
        value: afterSelection,
      });

      newContent = newContent.filter(({ value }) => value !== '');

      setContent(newContent);
    }
  };

  const handleDelete = (id) => {
    const newContent = [...content];
    const target = newContent.find(({ _id }) => _id === id);
    const targetIndex = newContent.indexOf(target);

    if (targetIndex === -1) {
      return;
    }

    const beforeIndex = targetIndex - 1;
    const beforeSelection = newContent[beforeIndex];
    const afterIndex = targetIndex + 1;
    const afterSelection = newContent[afterIndex];

    // Remove selection
    if (beforeSelection.selected && afterSelection.selected) {
      const newItem = { ...target };
      delete newItem.selected;
      delete newItem.width;

      newContent.splice(targetIndex, 1, newItem);

      return setContent(newContent);
    }

    // Merge with text before selection
    if (!beforeSelection.selected) {
      const newItem = {
        ...beforeSelection,
        value: beforeSelection.value + target.value,
      };

      newContent.splice(beforeIndex, 2, newItem);

      return setContent(newContent);
    }

    // Merge with text after selection
    if (!afterSelection.selected) {
      const newItem = {
        ...afterSelection,
        value: target.value + afterSelection.value,
      };

      newContent.splice(targetIndex, 2, newItem);

      return setContent(newContent);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={handleSelect}>Sélectionner</Button>
      <div
        onInput={handleChange}
        contentEditable="true"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        className="completion-editor"
      >
        {content.map(({ _id, value, selected }) => (
          <>
            {selected ? (
              <Popover content={<Button onClick={() => handleDelete(_id)}>Supprimer</Button>} title={null}>
                <span
                  key={_id}
                  id={_id}
                  className="selected-text"
                  unselectable="on"
                  style={{ backgroundColor: 'coral' }}
                >
                  {value}
                </span>
              </Popover>
            ) : (
              <span key={_id} id={_id}>
                {value}
              </span>
            )}
          </>
        ))}
      </div>
    </Space>
  );
};

export default Completion;
