import { mergeAttributes, Node } from '@tiptap/core';

export const Caption = Node.create({
  name: 'figureCaption',
  content: 'text*',
  selectable: false,
  isolating: true,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  parseHTML() {
    return [{ tag: 'figcaption' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['figcaption', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
