import { figure } from './figure';
import { table } from './table';

const menus = { figure, table };

export const getMenu = (key, editor) => {
  if (menus[key]) {
    return menus[key].map((item) => ({
      ...item,
      onClick: (event) => item.onClick(event, editor),
    }));
  }

  return [];
};
