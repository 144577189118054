import { Steps, Space, Button, message } from 'antd';
import { reportRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import { useState } from 'react';

const ReportState = ({ data, mutate, canAct }) => {
  const [resendLoading, setResendLoading] = useState(false);
  const { step } = data;
  const currentStep = step?.split('_')[3] - 1;
  const { patch } = useFetch();

  const resendMail = async () => {
    if (canAct) {
      setResendLoading(true);

      const results = await patch(reportRoutes.resend + '/' + data._id);

      if (results.status === 200) {
        mutate();
        setResendLoading(false);
      } else {
        if (results.message) {
          message.error(results.message);
        }
        setResendLoading(false);
      }
    }
  };

  const getDescription = (stepIndex) => {
    if (stepIndex < currentStep) {
      return 'Terminé';
    } else if (stepIndex > currentStep) {
      return 'En attente';
    } else if (stepIndex === currentStep) {
      if (step.endsWith('ENVOYÉE')) {
        return 'Envoyé';
      } else if (step.endsWith('RELANCE_1')) {
        return 'Relancé';
      } else if (step.endsWith('RELANCE_2')) {
        return 'Relancé une seconde fois';
      } else if (step.endsWith('INTERVENTION_REQUISE')) {
        const mail =
          currentStep === 0
            ? data.student.contact.email
            : currentStep === 1 &&
              (data.reportsType === 'FORMATION' ? data.student.former.email : data.company.tutor.contact.email);

        return (
          <Space direction="vertical">
            Intervention requise
            {canAct && (
              <Space>
                <Button size="small" onClick={resendMail} loading={resendLoading}>
                  Relancer
                </Button>
                <Button size="small">
                  <a href={`mailto:${mail}`}>Contacter</a>
                </Button>
              </Space>
            )}
          </Space>
        );
      } else if (step.endsWith('RELANCE_MANUELLE')) {
        return 'Relancé manuellement';
      } else if (step.endsWith('CONTACTÉE')) {
        return 'Contacté';
      }
    }
  };

  const getStatus = (stepIndex) => {
    if (stepIndex < currentStep) {
      return 'finish';
    } else if (stepIndex > currentStep) {
      return 'wait';
    } else if (stepIndex === currentStep) {
      if (step.endsWith('INTERVENTION_REQUISE')) {
        return 'error';
      } else {
        return 'process';
      }
    }
  };

  return (
    <Steps size="small" className="formation-reports-steps" current={currentStep}>
      <Steps.Step title="Apprenant" status={getStatus(0)} description={getDescription(0)} />
      <Steps.Step
        title={data.reportsType === 'FORMATION' ? 'Formateur' : 'Tuteur'}
        status={getStatus(1)}
        description={getDescription(1)}
      />
    </Steps>
  );
};

export default ReportState;
