import { useState, memo, useEffect } from 'react';
import { useForm, FormProvider, useFormState, useFormContext } from 'react-hook-form';
import { Card, Form, Tabs, Typography, Space, Button, message, Badge } from 'antd';
import { Link, useHistory, useParams, Prompt } from 'react-router-dom';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { groupRoutes, studentRoutes } from '../../../../lib/routes';
import GroupEditStudents from './GroupEditStudents';
import GroupEditDocuments from './GroupEditDocuments';

const { TabPane } = Tabs;

const TabPaneTitle = memo(({ children, errorFields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const tabErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

  return (
    <Badge
      dot={tabErrors.length !== 0}
      status="error"
      title={`${tabErrors.length} erreur${tabErrors.length > 1 ? 's' : ''}`}
    >
      {children}
    </Badge>
  );
});

const GroupEdit = () => {
  const { id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm();
  const { put } = useFetch();
  const history = useHistory();
  const { data: group, isValidating } = useSWR(groupRoutes.default + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: students } = useSWR(studentRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (group && group.data && students && students.data) {
      let groupStudents = [];

      for (let i = 0; i < group.data.students.length; i++) {
        const studentMatch = students.data.find((stud) => stud._id.toString() === group.data.students[i].toString());

        if (studentMatch) {
          const formationMatch = studentMatch.formations.find(
            (formation) => formation.group?.toString() === group.data._id.toString(),
          );

          if (formationMatch) {
            groupStudents.push({ studentId: studentMatch._id, formationId: formationMatch._id });
          }
        }
      }

      methods.setValue('students', groupStudents || []);
      methods.setValue('documents', group.data.documents || []);
    }
  }, [group]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(groupRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(groupRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/formao/sessions');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Tabs defaultActiveKey="students">
            <TabPane tab={<TabPaneTitle errorFields={[]}>Apprenants</TabPaneTitle>} key="students">
              <GroupEditStudents
                disabled={!group || isValidating || isProcessing}
                groupFormation={group?.data.formation}
                groupId={id}
              />
            </TabPane>
            <TabPane tab="Documents" key="documents">
              <GroupEditDocuments disabled={!group || isValidating || isProcessing} />
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={!group || isProcessing || isValidating}>
              <Link to="/formao/sessions">Annuler</Link>
            </Button>
            <Space>
              <Button onClick={methods.handleSubmit(onSubmit)} loading={isProcessing} disabled={!group || isValidating}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                loading={isProcessing}
                disabled={!group || isValidating}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default GroupEdit;
