import { useState } from 'react';
import { Modal, Button, Form, Typography, Input, message } from 'antd';
import { useForm, useFormState, Controller, FormProvider } from 'react-hook-form';
import { externalInterventionRoutes } from '../../../../../lib/routes';
import { sort } from '../../../../../shared/utils';
import useFetch from '../../../../../hooks/useFetch';
import DebounceSelectType from './DebounceSelectType';

const AddExternalFormerModal = ({ disabled, onFinish }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [typeOptions, setTypeOptions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { post, get } = useFetch();

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(externalInterventionRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsOpen(false);
      onFinish(results.data._doc._id);
      setTypeOptions([]);
      methods.reset();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const fetchTypesList = async (value) => {
    const results = await get(externalInterventionRoutes.default + '/types/?search=' + value);

    if (results.status !== 200) {
      return [];
    } else {
      const formatted = results.data.map((result) => ({
        label: result,
        value: result,
      }));
      const sorted = formatted.sort((a, b) => sort(a, b, 'label'));

      return sorted;
    }
  };

  return (
    <FormProvider {...methods}>
      <Button onClick={() => setIsOpen(true)} className="gray-text" disabled={disabled}>
        Ajouter un formateur externe
      </Button>
      <Modal
        title="Ajouter un formateur externe"
        open={isOpen}
        onOk={methods.handleSubmit(onSubmit)}
        onCancel={() => {
          setIsOpen(false);
          setTypeOptions([]);
          methods.reset();
          methods.clearErrors();
        }}
      >
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label="Type d'intervention"
            required
            validateStatus={errors?.type?.message && 'error'}
            help={errors?.type?.message}
          >
            <DebounceSelectType
              fieldName="type"
              fetchOptions={fetchTypesList}
              options={typeOptions}
              setOptions={setTypeOptions}
              disabled={isProcessing}
            />
          </Form.Item>
          <Form.Item
            label="Nom"
            required
            validateStatus={errors?.last_name?.message && 'error'}
            help={errors?.last_name?.message}
          >
            <Controller
              control={methods.control}
              name="last_name"
              render={({ field }) => <Input {...field} disabled={isProcessing} placeholder="Exemple : DOE" />}
            />
          </Form.Item>
          <Form.Item
            label="Prénom"
            required
            validateStatus={errors?.first_name?.message && 'error'}
            help={errors?.first_name?.message}
          >
            <Controller
              control={methods.control}
              name="first_name"
              render={({ field }) => <Input {...field} disabled={isProcessing} placeholder="Exemple : John" />}
            />
          </Form.Item>
          <Form.Item
            label="Adresse mail"
            required
            validateStatus={errors?.email?.message && 'error'}
            help={errors?.email?.message}
          >
            <Controller
              control={methods.control}
              name="email"
              render={({ field }) => (
                <Input {...field} disabled={isProcessing} placeholder="Exemple : john.doe@mail.com" />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </FormProvider>
  );
};

export default AddExternalFormerModal;
