const tablePagination = (data = []) => ({
  showSizeChanger: true,
  showQuickJumper: true,
  total: data.length,
  showTotal: (total, range) => {
    return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
  },
});

export default tablePagination;
