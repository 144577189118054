import { useFormContext, useFormState, useFieldArray } from 'react-hook-form';
import { Input, Button, List, message, Form, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TagDrag from './TagDrag';

const { Group } = Input;
const { Item } = Form;

const Ordering = ({ loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: ordering, remove, append, move } = useFieldArray({ name: `ordering`, control });

  const appendItem = () => {
    if (ordering.length >= 6) message.error('Vous ne pouvez pas avoir plus de 6 éléments');
    else append({ label: 'Nouvel élément' });
  };

  const removeItem = (index) => {
    if (ordering.length <= 3) message.error('Vous ne pouvez pas avoir moins de 3 éléments');
    else remove(index);
  };

  return (
    <Row>
      <Col span={12}>
        <Item
          label="Liste à ordonner"
          validateStatus={errors?.answers?.message && 'error'}
          help={errors?.answers?.message}
        >
          <List bordered size="small">
            {ordering.map((label, index) => (
              <TagDrag
                key={label.id}
                data={{ ...label, index }}
                loading={loading}
                disabled={ordering.length <= 3}
                onMove={move}
                onDelete={removeItem}
              />
            ))}
          </List>
          <Button
            icon={<PlusOutlined />}
            disabled={loading || ordering.length >= 6}
            block
            onClick={appendItem}
            style={{ marginTop: 24 }}
          >
            Nouvel élément
          </Button>
        </Item>
      </Col>
    </Row>
  );
};

export default Ordering;
