import { mergeAttributes, Node } from '@tiptap/core';

const times = (n, fn) => Array.from({ length: n }, (_, i) => fn(i));

const buildNode = ({ type, content, attrs }) => ({
  type,
  ...(content ? { content } : {}),
  ...(attrs ? { attrs } : {}),
});

const buildParagraph = ({ content }) => buildNode({ type: 'paragraph', content });

const buildGrid = ({ content, attrs }) => buildNode({ type: 'grid', content, attrs });

const build = (cells, options) => {
  const buildNColumns = (cellIndex) => {
    const content = [buildParagraph({})];

    return buildNode({
      type: 'gridCell',
      content,
      attrs: {
        'data-desktop-size': cells[cellIndex].desktop.size,
        'data-desktop-order': cellIndex + 1,
        'data-tablet-size': cells[cellIndex].tablet.size,
        'data-tablet-order': cells[cellIndex].tablet.order,
        'data-tablet-hidden': cells[cellIndex].tablet.hidden,
        'data-mobile-size': cells[cellIndex].mobile.size,
        'data-mobile-order': cells[cellIndex].mobile.order,
        'data-mobile-hidden': cells[cellIndex].mobile.hidden,
      },
    });
  };

  return buildGrid({
    content: times(cells.length, buildNColumns),
    attrs: {
      'data-gap-x': options?.gapX || 0,
      'data-gap-y': options?.gapY || 0,
    },
  });
};

export const Grid = Node.create({
  name: 'grid',
  group: 'block',
  content: 'gridCell{1,}',
  isolating: true,
  selectable: true,
  addAttributes() {
    return {
      'data-gap-x': {
        parseHTML: (el) => el.getAttribute('data-gap-x'),
      },
      'data-gap-y': {
        parseHTML: (el) => el.getAttribute('data-gap-y'),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div.grid',
        priority: 150,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'grid' }), 0];
  },
  addCommands() {
    return {
      setGrid:
        (cells, options) =>
        ({ tr, dispatch }) => {
          try {
            const { doc, selection } = tr;

            if (!dispatch) {
              return;
            }

            const grid = build(cells, options);
            const newNode = doc.type.schema.nodeFromJSON(grid);

            if (newNode === null) {
              return;
            }

            tr = tr.replaceSelectionWith(newNode, false);

            return dispatch(tr);
          } catch (error) {
            console.error(error);
          }
        },
    };
  },
});
