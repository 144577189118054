import { useState, useEffect } from 'react';
import { Card, Button, Form, Input, Row, Col, Space, Typography, Empty, Alert, message } from 'antd';
import { CheckCircleOutlined, CheckSquareOutlined, SlidersOutlined } from '@ant-design/icons';
import { Controller, FormProvider, useForm, useFieldArray, useFormState } from 'react-hook-form';
import { Link, useHistory, Prompt } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { evaluationsRoutes } from '../../../lib/routes';
import CheckboxItem from './components/CheckboxItem';
import LevelItem from './components/LevelItem';
import RadioItem from './components/RadioItem';

const EvaluationCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const methods = useForm();
  const { fields, append, remove } = useFieldArray({ name: 'form', control: methods.control });
  const { errors } = useFormState({ control: methods.control });
  const history = useHistory();
  const { post } = useFetch();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.register('form');
  }, []);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(evaluationsRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/posiao/auto-evaluations');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onLevelAdd = () => {
    append({
      title: '',
      description: '',
      format: 'LEVEL',
      levels: 4,
      labels: {
        from: '',
        to: '',
      },
    });
  };

  const onRadioAdd = () => {
    append({
      title: '',
      description: '',
      format: 'RADIO',
      fields: [
        { text: '', custom: false },
        { text: '', custom: false },
      ],
    });
  };

  const onCheckboxAdd = () => {
    append({
      title: '',
      description: '',
      format: 'CHECKBOX',
      fields: [
        { text: '', custom: false },
        { text: '', custom: false },
      ],
    });
  };

  return (
    <Card className="evaluations">
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form layout="vertical" noValidate onFinish={methods.handleSubmit(onSubmit)}>
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label="Intitulé de l'auto-évaluation"
            required
            validateStatus={errors?.name?.message && 'error'}
            help={errors?.name?.message}
          >
            <Controller
              name="name"
              control={methods.control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Row gutter={[24, 24]} wrap={false}>
            <Col flex="auto">
              {fields.length === 0 ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Aucune donnée, commencez par ajouter un élément depuis la colonne de droite"
                />
              ) : (
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  {fields.map((item, index) => {
                    if (item.format === 'LEVEL')
                      return (
                        <LevelItem key={item.id} index={index} onRemove={() => remove(index)} disabled={isProcessing} />
                      );
                    else if (item.format === 'RADIO')
                      return (
                        <RadioItem key={item.id} index={index} onRemove={() => remove(index)} disabled={isProcessing} />
                      );
                    else if (item.format === 'CHECKBOX')
                      return (
                        <CheckboxItem
                          key={item.id}
                          index={index}
                          onRemove={() => remove(index)}
                          disabled={isProcessing}
                        />
                      );
                    else return null;
                  })}
                </Space>
              )}
              {errors?.form?.message && (
                <Alert message="Vous n'avez ajouté aucun champ" type="error" style={{ marginTop: 24 }} showIcon />
              )}
            </Col>
            <Col flex="none">
              <Space direction="vertical" style={{ position: 'sticky', top: 60 }}>
                <Typography.Text>Éléments disponibles</Typography.Text>
                <Button
                  block
                  icon={<CheckSquareOutlined />}
                  style={{ textAlign: 'left' }}
                  onClick={onCheckboxAdd}
                  disabled={isProcessing}
                >
                  Question à choix multiples
                </Button>
                <Button
                  block
                  icon={<CheckCircleOutlined />}
                  style={{ textAlign: 'left' }}
                  onClick={onRadioAdd}
                  disabled={isProcessing}
                >
                  Question à choix unique
                </Button>
                <Button
                  block
                  icon={<SlidersOutlined />}
                  style={{ textAlign: 'left' }}
                  onClick={onLevelAdd}
                  disabled={isProcessing}
                >
                  Question à échelle
                </Button>
              </Space>
            </Col>
          </Row>
          <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/posiao/auto-evaluations">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" disabled={isProcessing} loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default EvaluationCreate;
