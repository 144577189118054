import { useState, memo, useMemo } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Button, DatePicker, Form, Modal, Popconfirm, Space, Typography, message } from 'antd';
import { reportRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const getDisclaimer = (reportType) => {
  switch (reportType) {
    case 'MENSUEL':
      return 'Pensez également à résilier le suivi de formation en continu ou en alternance.';
    case 'STAGE':
      return 'Pensez également à résilier le suivi de formation en continu ainsi que le suivi mensuel.';
    case 'FORMATION':
      return 'Pensez également à résilier le suivi mensuel ainsi que le bilan de stage.';
    case 'COMPANY':
      return 'Pensez également à résilier le suivi mensuel.';
    default:
      return '';
  }
};

const AbortModal = memo(({ isOpen, onClose, onFinish, reportType }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { patch } = useFetch();
  const disclaimer = useMemo(() => getDisclaimer(reportType), [reportType]);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onOk = async (form) => {
    setIsProcessing(true);

    const results = await patch(reportRoutes.abort + '/' + isOpen, JSON.stringify({ date: form?.date }));

    if (results.status === 200) {
      methods.reset();
      onClose();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onCancel = () => {
    methods.reset();
    onClose();
  };

  return (
    <Form noValidate>
      <Modal
        okButtonProps={{ loading: isProcessing }}
        cancelButtonProps={{ disabled: isProcessing }}
        title="Résilier un suivi"
        open={isOpen}
        onCancel={onCancel}
        footer={
          <Space>
            <Button onClick={onCancel}>Annuler</Button>
            <Popconfirm {...popconfirmProps} onConfirm={methods.handleSubmit(onOk)}>
              <Button type="primary">OK</Button>
            </Popconfirm>
          </Space>
        }
      >
        <Typography.Paragraph>
          Résilier ce suivi mettra un terme à l'envoi automatique des prochains bilans.&nbsp;
          <b>Cette action est irréversible.</b>
        </Typography.Paragraph>
        <Typography.Paragraph>{disclaimer}</Typography.Paragraph>

        <Controller
          name="date"
          control={methods.control}
          render={({ field }) => (
            <DatePicker {...field} format="DD/MM/YYYY" status={errors?.date && 'error'} allowClear={false} />
          )}
        />
        {errors?.date?.message && <small style={{ color: 'red' }}>{errors?.date?.message}</small>}
      </Modal>
    </Form>
  );
});

export default AbortModal;
