import { useFormContext, useWatch } from 'react-hook-form';

const CheckboxPreview = ({ imagePreview }) => {
  const { control } = useFormContext();
  const values = useWatch({ control });

  return (
    <>
      {imagePreview ? (
        <img className="preview-question-image" src={imagePreview} alt="Prévisualisation de la question" />
      ) : null}

      <h3 className="preview-question">{values?.question}</h3>

      {values?.checkbox?.map((item, index) => (
        <div key={index} className="checkbox-container">
          <label className="checkbox-label">
            <input type="checkbox" className="checkbox-input" />
            <div className="checkbox-box" />
            <p className="checkbox-text">{item.label}</p>
          </label>
        </div>
      ))}
    </>
  );
};

export default CheckboxPreview;
