import { useEffect, useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Form, Modal, Typography, Input, Switch } from 'antd';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const SectionCreateModal = ({ disabled, isOpen, onClose, onFinish }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });

  useEffect(() => {
    if (isOpen) {
      methods.reset();
    }
  }, [isOpen]);

  const onSubmit = (form) => {
    setIsProcessing(true);

    if (form?.title) {
      onFinish(form);
    } else {
      return methods.setError('title', { type: 'manual', message: 'Ce champ est requis' });
    }

    setIsProcessing(false);
  };

  return (
    <Modal
      title="Ajouter une section"
      visible={isOpen}
      onOk={methods.handleSubmit(onSubmit)}
      onCancel={onClose}
      destroyOnClose
      okButtonProps={{ disabled: disabled, loading: isProcessing }}
      cancelButtonProps={{ disabled: disabled || isProcessing }}
    >
      <Form noValidate layout="vertical">
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Form.Item
          label="Nom"
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
          required
        >
          <Controller
            name="title"
            control={methods.control}
            render={({ field }) => <Input {...field} disabled={disabled || isProcessing} />}
          />
        </Form.Item>

        {(checkAuthorization(user, 'arinfo', 'faq', 'enable-section') ||
          checkAuthorization(user, 'arinfo', 'faq', 'disable-section')) && (
          <Form.Item
            label="Activer la section"
            validateStatus={errors?.disabled?.message && 'error'}
            help={errors?.disabled?.message}
            required
          >
            <Controller
              name="disabled"
              control={methods.control}
              render={({ field }) => (
                <Switch
                  onChange={(value) => {
                    const canAccess = field.value
                      ? checkAuthorization(user, 'arinfo', 'faq', 'enable-section')
                      : checkAuthorization(user, 'arinfo', 'faq', 'disable-section');

                    if (canAccess) {
                      field.onChange(!value);
                    }
                  }}
                  checked={!field.value}
                  disabled={
                    disabled ||
                    isProcessing ||
                    (field.value
                      ? !checkAuthorization(user, 'arinfo', 'faq', 'enable-section')
                      : !checkAuthorization(user, 'arinfo', 'faq', 'disable-section'))
                  }
                />
              )}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default SectionCreateModal;
