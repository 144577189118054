import { memo, useMemo } from 'react';

import { Typography, Segmented, Space } from 'antd';
import classnames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';

import { colors } from './helpers';
import { DesktopOutlined, MobileOutlined, TabletOutlined } from '@ant-design/icons';

const PreviewCell = memo(({ config, id, useColors, index }) => {
  const style = {
    backgroundColor: useColors ? colors[index] : '#e6f4ff',
  };

  return (
    <div className="preview-cell" data-size={config.size} data-order={config.order}>
      <div {...{ style }} className="preview-cell-content">
        {id}
      </div>
    </div>
  );
});

PreviewCell.displayName = 'PreviewCell';

const filterModes = (cells) => {
  const desktop = [...cells].map((cell, index) => ({ ...cell, originIndex: index }));
  let tablet = [...desktop].filter((item) => !item.tablet.hidden);
  let mobile = [...desktop].filter((item) => !item.mobile.hidden);

  return { desktop, tablet, mobile };
};

const BeforePlaceholder = () => (
  <div className="placeholder-text">
    <div />
    <div />
    <div />
    <div />
  </div>
);

const AfterPlaceholder = () => (
  <>
    <div className="placeholder-image" />
    <div className="placeholder-text">
      <div />
      <div />
      <div />
      <div />
    </div>
  </>
);

const Render = ({ mode, children }) => {
  const { control } = useFormContext();
  const gapX = useWatch({ control, name: 'gapX' });
  const gapY = useWatch({ control, name: 'gapY' });

  if (mode === 'mobile') {
    return (
      <div className="mobile-preview">
        <div className="mobile-preview-screen">
          <BeforePlaceholder />
          <div className="preview-row" data-gap-y={gapY} data-gap-x={gapX}>
            {children}
          </div>
          <AfterPlaceholder />
        </div>
        <div className="mobile-preview-buttons">
          <div className="mobile-preview-home-button" />
        </div>
      </div>
    );
  }

  if (mode === 'tablet') {
    return (
      <div className="tablet-preview">
        <div className="tablet-preview-screen">
          <BeforePlaceholder />
          <div className="preview-row" data-gap-y={gapY} data-gap-x={gapX}>
            {children}
          </div>
          <AfterPlaceholder />
        </div>
        <div className="tablet-preview-buttons">
          <div className="tablet-preview-home-button" />
        </div>
      </div>
    );
  }

  if (mode === 'desktop') {
    return (
      <div className="desktop-preview">
        <div className="desktop-preview-screen">
          <BeforePlaceholder />
          <div className="preview-row" data-gap-y={gapY} data-gap-x={gapX}>
            {children}
          </div>
          <AfterPlaceholder />
        </div>
        <div className="desktop-preview-screen-stand" />
        <div className="desktop-preview-screen-stand-base" />
      </div>
    );
  }

  return null;
};

const Preview = ({ mode, onChange, useColors }) => {
  const { control } = useFormContext();
  const cells = useWatch({ control, name: 'cells' });
  const renderCells = useMemo(() => filterModes(cells), [cells]);

  return (
    <div className={classnames('preview', { 'preview-variant': useColors })}>
      <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
        <Typography.Text strong>Prévisualisation</Typography.Text>
        <Segmented
          {...{ onChange }}
          value={mode}
          block
          size="small"
          options={[
            {
              value: 'desktop',
              label: 'Ordinateur',
              icon: <DesktopOutlined />,
            },
            {
              value: 'tablet',
              label: 'Tablette',
              icon: <TabletOutlined />,
            },
            {
              value: 'mobile',
              label: 'Mobile',
              icon: <MobileOutlined />,
            },
          ]}
        />
        <div className="preview-container">
          <Render {...{ mode }}>
            {renderCells[mode].map((item, index) => (
              <PreviewCell
                {...{ useColors }}
                key={`preview_${mode}_cell_${index}`}
                index={item.originIndex}
                id={item.cellId}
                config={item[mode]}
              />
            ))}
          </Render>
        </div>
      </Space>
    </div>
  );
};

export default Preview;
