import { Card, List } from 'antd';
import useSWR from 'swr';
import SystemListItem from './components/SystemListItem';
import { aiRoutes } from '../../../lib/routes';

const SystemsList = () => {
  const { data: aiSystems, isValidating } = useSWR(aiRoutes.default);

  return (
    <Card>
      <List
        dataSource={aiSystems?.data}
        loading={isValidating}
        renderItem={(item) => <SystemListItem key={item.id} {...{ item }} />}
      />
    </Card>
  );
};

export default SystemsList;
