import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Radio, Divider, Input, Space, Tooltip, message } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useFormContext, Controller, useFieldArray, useFormState } from 'react-hook-form';

const RadioItem = memo(({ index, onRemove, disabled }) => {
  const [customField, setCustomField] = useState(false);
  const methods = useFormContext();
  const { fields, append, remove, insert } = useFieldArray({ name: `form.${index}.fields`, control: methods.control });
  const { errors } = useFormState({ control: methods.control });

  useEffect(() => {
    const index = fields.findIndex((item) => item.custom);

    if (index !== -1) setCustomField(true);
  }, []);

  const onFieldAdd = () => {
    if ((fields.length >= 11 && customField) || (fields.length >= 10 && !customField)) {
      message.error('10 options au maximum peuvent être ajoutées');
    } else {
      if (customField) {
        insert(fields.length - 1, { text: '', custom: false });
      } else {
        append({ text: '', custom: false });
      }
    }
  };

  const onCustomFieldAdd = () => {
    if (customField) {
      message.error('1 seul champ personnalisé peut-être ajouté');
    } else {
      append({ text: 'Autre :', custom: true });
      setCustomField(true);
    }
  };

  const onFieldRemove = (itemIndex) => {
    if (fields.length <= 2) {
      message.error('2 options au minimum sont requises');
    } else {
      const isCustom = methods.getValues(`form.${index}.fields.${itemIndex}.custom`);

      remove(itemIndex);
      if (isCustom) setCustomField(false);
    }
  };

  return (
    <Card
      bordered
      size="small"
      title="Question à choix unique"
      headStyle={{ background: '#f0f0f0' }}
      extra={<Button key="delete" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />}
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Controller
          name={`form.${index}.title`}
          control={methods.control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Question"
              style={{ fontWeight: 700 }}
              disabled={disabled}
              status={errors?.form?.[index]?.title?.message && 'error'}
            />
          )}
        />
        <Controller
          name={`form.${index}.description`}
          control={methods.control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Description (optionnelle)"
              disabled={disabled}
              status={errors?.form?.[index]?.description?.message && 'error'}
            />
          )}
        />
      </Space>
      <Divider />
      <Space direction="vertical" style={{ display: 'flex' }}>
        {fields.map((item, itemIndex) => (
          <Radio key={item.id} checked={false} style={{ width: '100%' }} disabled={disabled}>
            {item.custom ? (
              <>
                <Input.Group compact style={{ width: '100%', display: 'flex' }} disabled={disabled}>
                  <Tooltip title="Ce champ n'est pas modifiable, il fera apparaître un champ à compléter pour l'utilisateur">
                    <Input
                      value={item.text}
                      placeholder={`Option ${itemIndex + 1}`}
                      style={{ width: 'calc(100% - 32px)', height: 32 }}
                      disabled={disabled}
                    />
                  </Tooltip>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => onFieldRemove(itemIndex)}
                    disabled={fields.length <= 2 || disabled}
                  />
                </Input.Group>
              </>
            ) : (
              <Controller
                name={`form.${index}.fields.${itemIndex}.text`}
                control={methods.control}
                render={({ field }) => (
                  <Input.Group compact style={{ width: '100%', display: 'flex' }} disabled={disabled}>
                    <Input
                      {...field}
                      placeholder={`Option ${itemIndex + 1}`}
                      style={{ width: 'calc(100% - 32px)', height: 32 }}
                      disabled={disabled}
                      status={errors?.form?.[index]?.fields?.[itemIndex]?.text?.message && 'error'}
                    />
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => onFieldRemove(itemIndex)}
                      disabled={fields.length <= 2 || disabled}
                    />
                  </Input.Group>
                )}
              />
            )}
          </Radio>
        ))}
        <Space>
          <Button
            icon={<PlusOutlined />}
            type="link"
            onClick={onFieldAdd}
            disabled={(fields.length >= 7 && customField) || (fields.length >= 10 && !customField) || disabled}
          >
            Ajouter une option
          </Button>
          <Button icon={<PlusOutlined />} type="link" onClick={onCustomFieldAdd} disabled={customField || disabled}>
            Ajouter une option "Autre :"
          </Button>
        </Space>
      </Space>
    </Card>
  );
});

RadioItem.displayName = 'RadioItem';
export default RadioItem;
