import React from 'react';

const Hard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.98 59.95" {...props}>
    <path
      d="M90.34 5.65L97.08 26.39 118.89 26.39 101.25 39.21 107.98 59.95 90.34 47.13 72.7 59.95 79.44 39.21 61.8 26.39 83.6 26.39 90.34 5.65z"
      style={{ fill: '#2b5597', opacity: '0.5' }}
    />
    <path
      d="M69.8 57.92a1.83 1.83 0 01-1.8-1.83 2.34 2.34 0 01.09-.54l6.33-19.49L57.84 24a1.81 1.81 0 011.06-3.27h20.49l6.33-19.48a1.82 1.82 0 013.45 0l6.33 19.49H116a1.81 1.81 0 011.06 3.26l-16.58 12.06 6.33 19.49a1.81 1.81 0 01-2.79 2l-16.57-12-16.58 12a1.87 1.87 0 01-1.07.37zm17.65-16.44a1.83 1.83 0 011.06.34l13.13 9.55-5-15.44a1.8 1.8 0 01.66-2l13.13-9.54H94.19a1.82 1.82 0 01-1.73-1.25l-5-15.44-5 15.44a1.8 1.8 0 01-1.72 1.25H64.47l13.14 9.51a1.84 1.84 0 01.66 2l-5 15.44 13.13-9.55a1.8 1.8 0 011.05-.31z"
      style={{ fill: '#2b5597' }}
    />
    <path
      d="M147.43 5.65L154.17 26.39 175.98 26.39 158.34 39.21 165.07 59.95 147.43 47.13 129.79 59.95 136.53 39.21 118.89 26.39 140.69 26.39 147.43 5.65z"
      style={{ fill: '#2b5597', opacity: '0.5' }}
    />
    <path
      d="M126.9 57.92a1.82 1.82 0 01-1.82-1.82 2 2 0 01.09-.55l6.34-19.49L114.93 24a1.81 1.81 0 01-.37-2.53 1.79 1.79 0 011.44-.73h20.49l6.33-19.49a1.82 1.82 0 013.45 0l6.33 19.49h20.49a1.81 1.81 0 011.06 3.26l-16.58 12.06 6.33 19.49a1.81 1.81 0 01-1.21 2.26 1.83 1.83 0 01-1.57-.26l-16.58-12L128 57.57a1.82 1.82 0 01-1.1.35zm17.64-16.44a1.83 1.83 0 011.06.34l13.14 9.55-5-15.44a1.8 1.8 0 01.66-2l13.13-9.54h-16.25a1.82 1.82 0 01-1.73-1.25l-5-15.44-5 15.44a1.8 1.8 0 01-1.72 1.25h-16.27l13.14 9.51a1.84 1.84 0 01.66 2l-5 15.44 13.13-9.55a1.8 1.8 0 011-.31z"
      style={{ fill: '#2b5597' }}
    />
    <path
      d="M33.25 5.65L39.99 26.39 61.8 26.39 44.16 39.21 50.89 59.95 33.25 47.13 15.61 59.95 22.35 39.21 4.71 26.39 26.51 26.39 33.25 5.65z"
      style={{ fill: '#2b5597', opacity: '0.5' }}
    />
    <path
      d="M12.71 57.92a1.81 1.81 0 01-1.79-1.84 1.89 1.89 0 01.08-.53l6.33-19.49L.75 24a1.81 1.81 0 011.06-3.27H22.3l6.33-19.48a1.82 1.82 0 013.45 0l6.33 19.49H58.9a1.81 1.81 0 011.83 1.79A1.84 1.84 0 0160 24L43.39 36.06l6.33 19.49a1.81 1.81 0 01-2.79 2l-16.57-12-16.58 12a1.87 1.87 0 01-1.07.37zm17.65-16.44a1.83 1.83 0 011.06.34l13.13 9.55-5-15.44a1.8 1.8 0 01.66-2l13.13-9.54H37.09a1.8 1.8 0 01-1.72-1.25l-5-15.44-5 15.44a1.8 1.8 0 01-1.72 1.25H7.38l13.14 9.51a1.82 1.82 0 01.65 2l-5 15.44 13.13-9.55a1.8 1.8 0 011.06-.31z"
      style={{ fill: '#2b5597' }}
    />
  </svg>
);

export default Hard;
