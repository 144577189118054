import { useState } from 'react';
import useSWR from 'swr';
import { Card, Table, Select, message, Typography, Button, Popconfirm, Space, Tooltip } from 'antd';
import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { sort, checkAuthorization, sortDate } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import tablePagination from '../../../lib/tablePagination';
import { imaginelabBookingsRoutes } from '../../../lib/routes';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import WorkshopModal from './components/WorkshopModal';

const BookingsList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const { data: bookings, isValidating, mutate } = useSWR(imaginelabBookingsRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { user } = useAuth();
  const { patch, remove, get } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(imaginelabBookingsRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const statusChange = async (id) => {
    setIsProcessing(true);

    const results = await patch(imaginelabBookingsRoutes.status + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const generatePdf = async (record) => {
    setIsProcessing(true);

    const results = await get(imaginelabBookingsRoutes.pdf + '/' + record._id);

    if (results.status === 200) {
      const blob = new Blob([Buffer.from(results.data)], {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      let date = format(new Date(record.createdAt), 'dd-MM-yyyy-HH:mm');
      date = date.replace(':', 'h');
      const lastName = record.identity.lastName.toUpperCase();
      // prettier-ignore
      const firstName = record.identity.firstName.charAt(0).toUpperCase() + record.identity.firstName.slice(1)

      link.download = `reservation-${lastName}-${firstName}_${date}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: ['identity', 'lastName'],
      key: 'lastName',
      sorter: (a, b) => sort(a, b, ['identity', 'lastName']),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('identity.lastName'),
      render: (value) => value.toUpperCase(),
    },
    {
      title: 'Prénom',
      dataIndex: ['identity', 'firstName'],
      key: 'firstName',
      sorter: (a, b) => sort(a, b, ['identity', 'firstName']),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('identity.firstName'),
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1),
    },
    {
      title: 'Atelier',
      dataIndex: ['workshop'],
      key: 'workshop',
      sorter: (a, b) => sort(a, b, ['workshop', 'title'], 'Atelier introuvable'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('workshop.title'),
      render: (values, record) => {
        let title = values?.title;
        let date = values?.dates?.find((date) => date._id === record.workshopDate);

        if (title && date) {
          return (
            <Typography.Link onClick={() => setIsOpen({ ...values, date })}>
              {title.charAt(0).toUpperCase() + title.slice(1)} -{' '}
              {format(new Date(date?.date), 'dd/MM/yyyy HH:mm')?.replace(':', 'h')}
            </Typography.Link>
          );
        } else {
          return <Typography.Text type="danger">Atelier et/ou date introuvable</Typography.Text>;
        }
      },
    },
    {
      title: 'Date de réservation',
      dataIndex: 'createdAt',
      key: 'booking',
      align: 'center',
      width: 160,
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    ...(checkAuthorization(user, 'imaginelab', 'bookings', 'update-status')
      ? [
          {
            title: 'Statut',
            dataIndex: 'state',
            key: 'state',
            width: 150,
            align: 'center',
            filters: [
              { text: 'Traitée', value: 'Traitée' },
              { text: 'Non traitée', value: 'Non traitée' },
            ],
            onFilter: (value, record) => record.state === value,
            render: (value, record) => (
              <Select
                style={{ width: '100%' }}
                value={value}
                options={[
                  { value: 'Non traitée', label: 'Non traitée' },
                  { value: 'Traitée', label: 'Traitée' },
                ]}
                onChange={() => statusChange(record._id)}
                disabled={isProcessing}
              />
            ),
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'imaginelab', 'bookings', 'read-pdf') && (
            <Tooltip placement="top" title="Consulter le PDF">
              <Button
                className="gray-text"
                icon={<FilePdfOutlined />}
                onClick={() => generatePdf(record)}
                disabled={isProcessing}
              />
            </Tooltip>
          )}
          {checkAuthorization(user, 'imaginelab', 'bookings', 'delete-booking') && (
            <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
              <Button className="gray-text" icon={<DeleteOutlined />} disabled={isProcessing} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      <WorkshopModal isOpen={isOpen} onClose={() => setIsOpen(null)} />
      <Table
        loading={isValidating}
        dataSource={bookings?.data?.sort((a, b) => sortDate(b, a, 'createdAt')) || []}
        size="small"
        columns={columns}
        pagination={tablePagination(bookings?.data)}
        rowKey={(record) => record?._id}
        bordered
        rowClassName={(record) => {
          if (record.state === 'Traitée') return 'ant-table-row-success';
          return 'ant-table-row-warning';
        }}
      />
    </Card>
  );
};
export default BookingsList;
