import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Tag,
  message,
  Popconfirm,
  Tabs,
  Menu,
  Dropdown,
  Card,
  Space,
  Tooltip,
  Modal,
  Select,
} from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { sort, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { trainingRoutes } from '../../../lib/routes';

const CopyModal = ({ id, data }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (open) {
      setValue(null);
    }
  }, [open]);

  const onOk = () => {
    history.push(`/arinfo/formations/nouvelle-page-en-savoir-plus/${value}?base=${id}`);
  };

  return (
    <>
      <Tooltip title="Dupliquer la page">
        <Button icon={<CopyOutlined />} onClick={() => setOpen(true)} style={{ width: '25%' }} />
      </Tooltip>
      <Modal title="Dupliquer la page" open={open} onCancel={() => setOpen(false)} onOk={onOk}>
        <Select
          style={{ width: '100%' }}
          placeholder="Sélectionnez dans la liste"
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          value={value}
          onChange={setValue}
          options={data
            .sort((a, b) => a.title.localeCompare(b.title, 'fr'))
            .map((item) => ({
              label: !!item?.learn_more ? `${item.title} (Page déjà existante)` : item.title,
              value: item._id,
              disabled: !!item?.learn_more,
            }))}
        />
      </Modal>
    </>
  );
};

const TrainingsList = () => {
  const { data: trainings, isValidating, mutate } = useSWR(trainingRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('enabled');
  const { user } = useAuth();
  const { remove, put, patch } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const disableTraining = async (id) => {
    setIsProcessing(true);

    const results = await put(trainingRoutes.disable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const enableTraining = async (id) => {
    setIsProcessing(true);

    const results = await put(trainingRoutes.enable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(trainingRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableLearnMore = async (id) => {
    setIsProcessing(true);

    const results = await patch(trainingRoutes.disableLearnMore + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const enableLearnMore = async (id) => {
    setIsProcessing(true);

    const results = await patch(trainingRoutes.enableLearnMore + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDeleteLearnMore = async (id) => {
    setIsProcessing(true);

    const results = await remove(trainingRoutes.deleteLearnMore + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = ({ record }) => (
    <Menu>
      {checkAuthorization(user, 'arinfo', 'trainings', 'update-training') && (
        <Menu.Item className="gray-text">
          <Link to={`/arinfo/formations/modifier-une-formation/${record._id}`}>Modifier</Link>
        </Menu.Item>
      )}
      {activeTab === 'disabled'
        ? checkAuthorization(user, 'arinfo', 'trainings', 'enable-training') && (
            <Menu.Item className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => enableTraining(record._id)}>
                Activer
              </Popconfirm>
            </Menu.Item>
          )
        : checkAuthorization(user, 'arinfo', 'trainings', 'disable-training') && (
            <Menu.Item className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => disableTraining(record._id)}>
                Désactiver
              </Popconfirm>
            </Menu.Item>
          )}
      {checkAuthorization(user, 'arinfo', 'trainings', 'delete-training') && (
        <Menu.Item className="gray-text">
          <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
            Supprimer
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Code d'identification",
      dataIndex: '_id',
      key: '_id',
      width: 230,
      align: 'center',
      ...getColumnSearchProps('_id'),
    },
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      sorter: (a, b) => sort(a, b, 'type'),
      sortDirections: ['ascend', 'descend'],
      filters: [
        { value: 'metier', text: 'Formation métier' },
        { value: 'outil', text: 'Formation outil' },
      ],
      onFilter: (value, record) => record.type === value,
      render: (value) => (
        <Tag key="role" color={value.color}>
          {value.name}
        </Tag>
      ),
      render: (value) => (value === 'metier' ? 'Formation métier' : 'Formation outil'),
    },
    {
      title: 'Page « En savoir plus »',
      dataIndex: 'learn_more',
      key: 'learn_more',
      filters: [
        { value: true, text: 'Existante' },
        { value: false, text: 'Inexistante' },
      ],
      align: 'center',
      width: 180,
      onFilter: (value, record) => (value ? !!record?.learn_more : !record?.learn_more),
      render: (value, record) => {
        if (value) {
          if (
            !checkAuthorization(user, 'arinfo', 'trainings', 'enable-training-learn-more') &&
            !checkAuthorization(user, 'arinfo', 'trainings', 'disable-training-learn-more') &&
            !checkAuthorization(user, 'arinfo', 'trainings', 'update-training-learn-more') &&
            !checkAuthorization(user, 'arinfo', 'trainings', 'delete-training-learn-more') &&
            !checkAuthorization(user, 'arinfo', 'trainings', 'create-training-learn-more')
          ) {
            return <Button block>Consulter</Button>;
          }

          return (
            <Space.Compact block>
              {checkAuthorization(user, 'arinfo', 'trainings', 'enable-training-learn-more') && !!value?.disabled ? (
                <Tooltip title="Activer la page">
                  <Button
                    icon={<EyeInvisibleOutlined />}
                    onClick={() => enableLearnMore(value._id)}
                    style={{ width: '25%' }}
                  />
                </Tooltip>
              ) : null}
              {checkAuthorization(user, 'arinfo', 'trainings', 'disable-training-learn-more') && !value?.disabled ? (
                <Tooltip title="Désactiver la page">
                  <Button icon={<EyeOutlined />} onClick={() => disableLearnMore(value._id)} style={{ width: '25%' }} />
                </Tooltip>
              ) : null}
              {checkAuthorization(user, 'arinfo', 'trainings', 'update-training-learn-more') && (
                <Tooltip title="Modifier la page">
                  <Link to={`/arinfo/formations/modifier-page-en-savoir-plus/${value._id}`} style={{ width: '25%' }}>
                    <Button icon={<EditOutlined />} block />
                  </Link>
                </Tooltip>
              )}
              {checkAuthorization(user, 'arinfo', 'trainings', 'create-training-learn-more') && (
                <CopyModal id={value._id} data={trainings?.data || []} />
              )}
              {checkAuthorization(user, 'arinfo', 'trainings', 'delete-training-learn-more') && (
                <Popconfirm {...popconfirmProps} onConfirm={() => onDeleteLearnMore(value._id)}>
                  <Tooltip title="Supprimer la page">
                    <Button icon={<DeleteOutlined />} style={{ width: '25%' }} />
                  </Tooltip>
                </Popconfirm>
              )}
            </Space.Compact>
          );
        }

        if (checkAuthorization(user, 'arinfo', 'trainings', 'create-training-learn-more')) {
          return (
            <Link to={`/arinfo/formations/nouvelle-page-en-savoir-plus/${record._id}`}>
              <Button block icon={<PlusOutlined />} className="gray-text" type="dashed">
                Ajouter
              </Button>
            </Link>
          );
        }

        return 'Inexistante';
      },
    },
    {
      title: 'Créée le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    {
      title: 'MAJ le',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'updatedAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (record.createdAt === record.updatedAt ? '/' : format(new Date(value), 'dd/MM/yyyy')),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
          <Button className="gray-text" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'trainings', 'create-training') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/arinfo/formations/nouvelle-formation">Nouvelle formation</Link>
          </Button>
        </div>
      )}
      <Tabs defaultActiveKey="enabled" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <Tabs.TabPane tab={`Activées (${trainings?.data.filter((i) => !i.disabled).length || 0})`} key="enabled">
          <Table
            loading={!trainings || isValidating || isProcessing}
            dataSource={trainings?.data.filter((i) => !i.disabled)}
            columns={columns}
            bordered
            size="small"
            scroll={{ x: 320 }}
            rowKey={(row) => row._id}
            pagination={tablePagination(trainings?.data)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Désactivées (${trainings?.data.filter((i) => i.disabled).length || 0})`} key="disabled">
          <Table
            loading={!trainings || isValidating || isProcessing}
            dataSource={trainings?.data.filter((i) => i.disabled)}
            columns={columns}
            bordered
            size="small"
            scroll={{ x: 320 }}
            rowKey={(row) => row._id}
            pagination={tablePagination(trainings?.data)}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default TrainingsList;
