import { useEffect, useState } from 'react';

/**
 * @description Génère un ID unique comportant uniquement des lettres (A, B, C, ..., Z, AA, AB, AC...)
 * @param {string} startId Un ID à partir duquel commencer la génération
 */
const useLetterId = (startId) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const [nextId, setNextId] = useState([0]);

  useEffect(() => {
    if (startId) {
      if (typeof startId !== 'string') {
        throw Error("La valeur de l'id par défaut doit être une chaîne de caractères");
      }

      const id = [];

      for (const char of startId) {
        const match = chars.indexOf(char);

        if (match !== -1) {
          id.push(match);
        } else {
          throw Error(`La valeur de l'id par défaut comporte un caractère invalide : ${char}`);
        }
      }

      setNextId(id.reverse());
    }
  }, []);

  const increment = () => {
    for (let i = 0; i < nextId.length; i++) {
      const value = ++nextId[i];

      if (value >= chars.length) {
        setNextId((curr) => {
          curr[i] = 0;
          return curr;
        });
      } else {
        return;
      }
    }

    setNextId((curr) => {
      curr.push(0);
      return curr;
    });
  };

  const getId = () => {
    const id = [];

    for (const char of nextId) {
      id.unshift(chars[char]);
    }

    increment();
    return id.join('');
  };

  return { getId };
};

export default useLetterId;
