import { useState, useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Form, Row, Col, Upload, Button, message, Card } from 'antd';
import { Prompt } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { serialize } from 'object-to-formdata';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import { userRoutes } from '../../../lib/routes';
import placeholder from '../../../assets/images/placeholder-image.jpg';

const { Item } = Form;

const AvatarEdit = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  const { user } = useAuth();
  const { handleSubmit, formState, control, setError, setValue } = useForm();
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    if (user.avatar) {
      setImagePreview(user.avatar?.secure_url);
    }
  }, [user]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(userRoutes.avatar + '/' + user._id, formData, 'multipart/form-data');

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onAvatarChange = (img, onChange) => {
    const fileSize = img.size / 1024 / 1024;

    if (fileSize > 2) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 2Mo');
    } else {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
        onChange(img);
      });

      reader.readAsDataURL(img);
    }
  };

  const onAvatarDelete = () => {
    setImagePreview(null);
    setValue('avatar', null);
  };

  return (
    <>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications d'avatar, voulez-vous vraiment quitter cette page ?"
      />
      <Form layout="vertical" noValidate onFinish={handleSubmit(onSubmit)}>
        <Row>
          <Col span={12}>
            <Item label="Avatar" validateStatus={errors?.avatar && 'error'} help={errors?.avatar?.message} required>
              <Controller
                name="avatar"
                control={control}
                render={({ field }) => (
                  <Card
                    className="avatar-card"
                    bodyStyle={{ padding: 0 }}
                    cover={
                      imagePreview ? (
                        <img className="avatar-card-img" src={imagePreview} alt="Avatar" />
                      ) : (
                        <img className="avatar-card-placeholder" src={placeholder} alt="Avatar" />
                      )
                    }
                    actions={[
                      ...(imagePreview
                        ? [
                            <Button type="link" disabled={isProcessing} onClick={onAvatarDelete}>
                              Supprimer
                            </Button>,
                          ]
                        : []),
                      <ImgCrop
                        aspect={1 / 1}
                        modalTitle="Définir la zone de l'avatar"
                        zoom
                        modalOk="Confirmer"
                        modalCancel="Annuler"
                        onModalOk={(img) => onAvatarChange(img, field.onChange)}
                      >
                        <Upload
                          height={232}
                          name={field.name}
                          maxCount={1}
                          showUploadList={false}
                          accept="image/*"
                          disabled={isProcessing}
                          style={{
                            width: 232,
                          }}
                        >
                          <Button type="link" disabled={isProcessing}>
                            {imagePreview ? 'Remplacer' : 'Ajouter'}
                          </Button>
                        </Upload>
                      </ImgCrop>,
                    ]}
                  />
                )}
              />
            </Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" loading={isProcessing}>
          Enregistrer
        </Button>
      </Form>
    </>
  );
};
export default AvatarEdit;
