import { Button, Popover, Form, Tooltip, InputNumber, Space, Checkbox } from 'antd';
import { useState } from 'react';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined, TableOutlined } from '@ant-design/icons';

const TablePopover = ({ editor, disabled }) => {
  const [values, setValues] = useState({ rows: 1, cols: 1, withHeaderRow: true });
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = () => {
    editor.chain().focus().insertTable(values).run();

    setIsOpen(false);
  };

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={setIsOpen}
      trigger="click"
      title="Tableau"
      destroyTooltipOnHide
      placement="bottom"
      className="editor-upload-video"
      content={
        <Form layout="vertical" style={{ width: 250 }}>
          <Form.Item
            label="Colonnes"
            tooltip="Des colonnes supplémentaires peuvent être ajoutées une fois le tableau créé"
          >
            <InputNumber
              style={{ width: '100%' }}
              value={values.cols}
              onChange={(value) => setValues((rest) => ({ ...rest, cols: value }))}
              min={1}
            />
          </Form.Item>
          <Form.Item
            label="Rangés"
            tooltip="Des rangées supplémentaires peuvent être ajoutées une fois le tableau créé"
          >
            <InputNumber
              style={{ width: '100%' }}
              value={values.rows}
              onChange={(value) => setValues((rest) => ({ ...rest, rows: value }))}
              min={1}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={values.withHeaderRow}
              onChange={(event) => setValues((rest) => ({ ...rest, withHeaderRow: event.target.checked }))}
            >
              En-tête&nbsp;
              <Tooltip title="La première rangée sera une en-tête avec un style distinct">
                <QuestionCircleOutlined />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          <Space>
            <Button type="primary" icon={<CheckOutlined />} onClick={handleSubmit} />
            <Button icon={<CloseOutlined />} onClick={() => setIsOpen(false)} />
          </Space>
        </Form>
      }
    >
      <Tooltip key="table" title="Tableau">
        <Button
          icon={<TableOutlined />}
          disabled={disabled}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        />
      </Tooltip>
    </Popover>
  );
};

export default TablePopover;
