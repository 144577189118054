import { useState, useEffect } from 'react';
import { useFormContext, useFieldArray, useFormState } from 'react-hook-form';
import { Row, Col } from 'antd';
import ConnectLines from './ConnectLines';
import ConnectFrom from './ConnectFrom';
import ConnectTo from './ConnectTo';

const Connect = ({ loading, currentTab }) => {
  const [line, setLine] = useState({ from: null, to: null });
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: lines, append, replace } = useFieldArray({ name: 'lines', control });

  useEffect(() => {
    if (line.from !== null && line.to !== null) {
      let fromIndex = lines.findIndex(({ from }) => from === line.from);
      let toIndex = lines.findIndex(({ to }) => to === line.to);

      if (fromIndex === -1 && toIndex === -1) {
        append({ from: line.from, to: line.to });
      } else {
        if (fromIndex !== toIndex) {
          const newLines = [...lines];

          if (fromIndex !== -1) {
            fromIndex = newLines.findIndex(({ from }) => from === line.from);
            newLines.splice(fromIndex, 1);
          }

          if (toIndex !== -1) {
            toIndex = newLines.findIndex(({ to }) => to === line.to);
            newLines.splice(toIndex, 1);
          }

          newLines.push({ from: line.from, to: line.to });

          replace(newLines);
        }
      }

      setLine({ from: null, to: null });
    }
  }, [line, lines]);

  return (
    <>
      <Row>
        <Col span={11}>
          <ConnectFrom
            loading={loading}
            selected={line.from}
            onSelect={(id) => setLine((rest) => ({ ...rest, from: id }))}
          />
        </Col>
        <Col span={2} />
        <Col span={11}>
          <ConnectTo loading={loading} selected={line.to} onSelect={(id) => setLine((rest) => ({ ...rest, to: id }))} />
        </Col>
        {currentTab === 'form' && <ConnectLines loading={loading} />}
      </Row>
      {errors?.lines && <Row style={{ color: '#ff4d4f' }}>{errors?.lines?.message}</Row>}
    </>
  );
};

export default Connect;
