import { memo, useState } from 'react';
import { Card, Button, Table, Menu, Dropdown, Tabs, Popconfirm, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { formationRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { sort, checkAuthorization } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';

const { Item } = Menu;
const { TabPane } = Tabs;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const FormationsList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const { put } = useFetch();
  const { getColumnSearchProps } = useColumnSearch();
  const { data: formations, isValidating, mutate } = useSWR(formationRoutes.default);
  const enabledFormations = formations?.data.filter((formation) => !formation.disabled) || [];
  const disabledFormations = formations?.data.filter((formation) => formation.disabled) || [];

  const enableFormation = async (id) => {
    setIsProcessing(true);

    const results = await put(formationRoutes.enable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableFormation = async (id) => {
    setIsProcessing(true);

    const results = await put(formationRoutes.disable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ record }) => (
    <Menu key={`menu-${record._id}`}>
      {checkAuthorization(user, 'formao', 'formations', 'update-formation') && (
        <Item key="edit">
          <Link to={`/formao/formations/modifier-une-formation/${record._id}`}>Modifier</Link>
        </Item>
      )}
      {record.disabled
        ? checkAuthorization(user, 'formao', 'formations', 'enable-formation') && (
            <Item key="enable" className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => enableFormation(record._id)}>
                Activer
              </Popconfirm>
            </Item>
          )
        : checkAuthorization(user, 'formao', 'formations', 'disable-formation') && (
            <Item key="disable" className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => disableFormation(record._id)}>
                Désactiver
              </Popconfirm>
            </Item>
          )}
    </Menu>
  ));

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Acronyme',
      dataIndex: 'acronym',
      key: 'acronym',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'acronym'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('acronym'),
      render: (value) => value.toUpperCase(),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'formao', 'formations', 'create-formation') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/formao/formations/nouvelle-formation">Nouvelle formation</Link>
          </Button>
        </div>
      )}
      <Tabs>
        <TabPane key="enabled" tab={`Actives (${enabledFormations.length})`}>
          <Table
            loading={!formations || isValidating || isProcessing}
            dataSource={enabledFormations}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(enabledFormations)}
          />
        </TabPane>
        <TabPane key="disabled" tab={`Inactives (${disabledFormations.length})`}>
          <Table
            loading={!formations || isValidating || isProcessing}
            dataSource={disabledFormations}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(disabledFormations)}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default FormationsList;
