import { Card, Button, Form, Input, message } from 'antd';
import { useFormContext, useFormState, useFieldArray, Controller } from 'react-hook-form';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Item } = Form;
const { Group } = Input;

const LegendLabels = ({ loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: labels, remove, append } = useFieldArray({ name: 'labels', control });

  return (
    <Item validateStatus={errors?.labels?.message && 'error'} help={errors?.labels?.message}>
      <Card
        size="small"
        title="Éléments pièges"
        extra={[
          <Button
            key="new"
            icon={<PlusOutlined />}
            disabled={loading || labels.length >= 6}
            onClick={() => {
              if (labels.length >= 6) {
                message.error('Vous ne pouvez pas avoir plus de 6 éléments pièges');
              } else {
                append({ content: 'Nouvel élément' });
              }
            }}
          >
            Nouvel élément
          </Button>,
        ]}
        style={{ overflow: 'hidden' }}
        bodyStyle={{ padding: 0 }}
      >
        <div className="legend-label-container">
          {labels.map((label, index) => (
            <Item
              key={label.id}
              className="legend-label-item"
              validateStatus={errors?.labels?.[index]?.content?.message && 'error'}
              help={errors?.labels?.[index]?.content?.message}
            >
              <Group compact>
                <Controller
                  name={`labels.[${index}].content`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} disabled={loading} style={{ width: 'calc(100% - 32px)', height: 32 }} />
                  )}
                />
                <Button icon={<DeleteOutlined />} disabled={loading} onClick={() => remove(index)} />
              </Group>
            </Item>
          ))}
        </div>
      </Card>
    </Item>
  );
};

export default LegendLabels;
