import OrganizeLists from './OrganizeLists';
import OrganizeLabels from './OrganizeLabels';

const Organize = ({ loading }) => (
  <>
    <OrganizeLists loading={loading} />
    <OrganizeLabels loading={loading} />
  </>
);

export default Organize;
