import { Card, Tabs, Form, Space, Button, Typography, message } from 'antd';
import { Link, useHistory, useParams, Prompt } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { formationRoutes } from '../../../../lib/routes';
import FormationEditMeta from './FormationEditMeta';
import FormationEditStudents from './FormationEditStudents';
import FormationEditGroups from './FormationEditGroups';
import FormationEditUnits from './FormationEditUnits';
import FormationEditDocuments from './FormationEditDocuments';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const FormationEdit = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const { put } = useFetch();
  const methods = useForm();
  const { data: module, isValidating } = useSWR(formationRoutes.default + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (module && module.data) {
      methods.setValue('title', module.data.title);
      methods.setValue('acronym', module.data.acronym);
      methods.setValue('units', module.data.units);
      methods.setValue('documents', module.data.documents);
    }
  }, [module]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(formationRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(formationRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/formao/formations');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="program-edit">
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Tabs defaultActiveKey="account">
            <TabPane tab="Informations" key="account">
              <FormationEditMeta disabled={isProcessing || isValidating} />
            </TabPane>
            <TabPane tab="Unités" key="units">
              <FormationEditUnits disabled={isProcessing || isValidating} />
            </TabPane>
            <TabPane tab="Documents" key="documents">
              <FormationEditDocuments disabled={isProcessing || isValidating} />
            </TabPane>
            <TabPane tab="Apprenants" key="students">
              <FormationEditStudents formationId={id} />
            </TabPane>
            <TabPane tab="Sessions" key="groups">
              <FormationEditGroups formationId={id} />
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing || isValidating}>
              <Link to="/formao/formations">Annuler</Link>
            </Button>
            <Space>
              <Button onClick={methods.handleSubmit(onSubmit)} loading={isProcessing} disabled={isValidating}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                loading={isProcessing}
                disabled={isValidating}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default FormationEdit;
