import { memo, useMemo } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

import { colors, getSizeFromTotal } from '../helpers';

const Cell = memo(({ index, desktop, onDelete, disabled, cellId, useColors }) => {
  const width = useMemo(() => getSizeFromTotal(desktop.size).percentage, [desktop]);

  return (
    <div
      className="editor-grid-cell"
      style={{
        flex: `${width} 1 0px`,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: useColors ? colors[index] : '#ffffff',
      }}
    >
      <Typography.Text strong>{cellId}</Typography.Text>
      <Typography.Text type="secondary" strong>
        ({desktop.size})
      </Typography.Text>
      <Button
        size="small"
        icon={<DeleteOutlined />}
        onClick={(event) => {
          event.stopPropagation();
          return onDelete();
        }}
        disabled={disabled}
        style={{ marginTop: 6 }}
      />
    </div>
  );
});

Cell.displayName = 'Cell';
export default Cell;
