import { Prompt, useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm, Controller, useFormState, FormProvider } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { capitalize } from 'lodash';
import useSWR from 'swr';
import {
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Input,
  DatePicker,
  Button,
  message,
  Typography,
  Alert,
  Radio,
  Space,
} from 'antd';
import { agencyRoutes, userRoutes, satisfactionReportRoutes, externalInterventionRoutes } from '../../../../lib/routes';
import { checkAuthorization, sort } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import AddExternalFormerModal from './components/AddExternalFormerModal';
import DebounceSelectFormer from './components/DebounceSelectFormer';
import useFetch from '../../../../hooks/useFetch';

const SatisfactionReportCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [formerOptions, setFormerOptions] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const { user } = useAuth();
  const { data: users, isValidating: isUsersValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const formattedUsers = useMemo(
    () =>
      users?.data.map((user) => ({
        label: `${user.last_name.toUpperCase()} ${user.first_name}`,
        value: user._id,
      })),
    [users],
  );
  const formattedAgencies = useMemo(
    () =>
      agencies?.data.map((agency) => ({
        label: agency.city,
        value: agency._id,
      })),
    [agencies],
  );
  const methods = useForm({
    defaultValues: {
      referent: user._id,
    },
  });
  const { errors } = useFormState({ control: methods.control });
  const history = useHistory();
  const { post, get } = useFetch();
  const selectedType = methods.watch('type');
  const formerType = methods.watch('studentFormerType');

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.setValue('studentFormer', null);
    setFormerOptions([]);
  }, [formerType]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    let [studentStartAt, studentEndAt] = [,];

    if (form?.studentFormationDates) {
      [studentStartAt, studentEndAt] = form?.studentFormationDates;
    }

    const results = await post(
      satisfactionReportRoutes.default,
      JSON.stringify({ ...form, studentStartAt, studentEndAt, studentFormer: form?.studentFormer?.value }),
    );

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/formao/bilans');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          if (key === 'studentStartAt' || key === 'studentEndAt') {
            methods.setError('studentFormationDates', { type: 'manual', message: value });
          } else {
            methods.setError(key, { type: 'manual', message: value });
          }
        });
      }
    }

    setIsProcessing(false);
  };

  const fetchFormerList = async (value) => {
    const results = await get(externalInterventionRoutes.default + '?search=' + value);

    if (results.status !== 200) {
      return [];
    } else {
      const formatted = results.data.map((result) => ({
        label: result.last_name.toUpperCase() + ' ' + capitalize(result.first_name),
        value: result._id,
        extra: result.type,
      }));
      const sorted = formatted.sort((a, b) => sort(a, b, 'label'));

      return sorted;
    }
  };

  const fetchUserList = async (value) => {
    const results = await get(userRoutes.default + '?search=' + value);

    if (results.status !== 200) {
      return [];
    } else {
      const formatted = results.data.map((result) => ({
        label: result.last_name.toUpperCase() + ' ' + capitalize(result.first_name),
        value: result._id,
        extra: result.role?.name,
      }));
      const sorted = formatted.sort((a, b) => sort(a, b, 'label'));

      return sorted;
    }
  };

  const onExternalFormerCreate = (id) => {
    methods.setValue('studentFormer', id);
    mutateInterventions();
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Typography.Title level={2}>Création d'un bilan de satisfaction</Typography.Title>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
          </ul>
        </blockquote>
      </Typography.Paragraph>
      <Alert
        message="Les bilans des utilisateurs formao sont envoyés automatiquement. Il n'est pas nécessaire de les créer sous risque de doublon."
        type="info"
        showIcon
      />
      <FormProvider {...methods}>
        <Form layout="vertical" noValidate onFinish={methods.handleSubmit(onSubmit)}>
          <Divider>Informations générales</Divider>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Type de bilan"
                validateStatus={errors?.type?.message && 'error'}
                help={errors?.type?.message}
                required
              >
                <Controller
                  name="type"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        { label: 'Individuel', value: 'INDIVIDUAL' },
                        { label: 'Entreprise', value: 'COMPANY' },
                      ]}
                      placeholder="Sélectionnez dans la liste"
                      disabled={isProcessing}
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Référent"
                validateStatus={errors?.referent?.message && 'error'}
                help={errors?.referent?.message}
                required
              >
                <Controller
                  name="referent"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={formattedUsers}
                      disabled={isProcessing || !formattedUsers}
                      placeholder="Sélectionnez dans la liste"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Nom de l'entreprise"
                validateStatus={errors?.companyName?.message && 'error'}
                help={errors?.companyName?.message}
                required={selectedType === 'COMPANY'}
              >
                <Controller
                  name="companyName"
                  control={methods.control}
                  render={({ field }) => (
                    <Input {...field} disabled={isProcessing || selectedType !== 'COMPANY'} allowClear />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Centre gestionnaire"
                validateStatus={errors?.studentAgency?.message && 'error'}
                help={errors?.studentAgency?.message}
                required
              >
                <Controller
                  name="studentAgency"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={formattedAgencies}
                      disabled={isProcessing || !formattedAgencies}
                      placeholder="Sélectionnez dans la liste"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Informations de l'apprenant</Divider>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Nom"
                validateStatus={errors?.studentLastName?.message && 'error'}
                help={errors?.studentLastName?.message}
                required
              >
                <Controller
                  name="studentLastName"
                  control={methods.control}
                  render={({ field }) => (
                    <Input {...field} disabled={isProcessing} allowClear placeholder="Exemple : Doe" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Prénom"
                validateStatus={errors?.studentFirstName?.message && 'error'}
                help={errors?.studentFirstName?.message}
                required
              >
                <Controller
                  name="studentFirstName"
                  control={methods.control}
                  render={({ field }) => (
                    <Input {...field} disabled={isProcessing} allowClear placeholder="Exemple : John" />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Email"
                validateStatus={errors?.studentEmail?.message && 'error'}
                help={errors?.studentEmail?.message}
                required
              >
                <Controller
                  name="studentEmail"
                  control={methods.control}
                  render={({ field }) => (
                    <Input {...field} disabled={isProcessing} allowClear placeholder="Exemple : john.doe@mail.com" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Téléphone"
                validateStatus={errors?.studentPhone?.message && 'error'}
                help={errors?.studentPhone?.message}
              >
                <Controller
                  name="studentPhone"
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(event) => field.onChange(event.target.value.replace(/[^0-9]/g, ''))}
                      maxLength={10}
                      disabled={isProcessing}
                      allowClear
                      placeholder="Exemple : 0607080910"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Informations de la formation</Divider>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Formation"
                validateStatus={errors?.studentFormation?.message && 'error'}
                help={errors?.studentFormation?.message}
                required
              >
                <Controller
                  name="studentFormation"
                  control={methods.control}
                  render={({ field }) => (
                    <Input {...field} disabled={isProcessing} placeholder="Exemple : InDesign et Photoshop" />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Dates de la formation"
                validateStatus={errors?.studentFormationDates?.message && 'error'}
                help={errors?.studentFormationDates?.message}
                required
              >
                <Controller
                  name="studentFormationDates"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker.RangePicker {...field} format="DD/MM/YYYY" disabled={isProcessing} allowClear={false} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Domaine de formation"
                validateStatus={errors?.formationDomain?.message && 'error'}
                help={errors?.formationDomain?.message}
                required
              >
                <Controller
                  name="formationDomain"
                  control={methods.control}
                  render={({ field }) => (
                    <Radio.Group {...field}>
                      <Space direction="vertical">
                        <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="BATIMENT">
                          Bâtiment, modélisation (NSF 230)
                        </Radio>
                        <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="IMPRESSION">
                          Impression, édition, graphisme (NSF 322)
                        </Radio>
                        <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="INFORMATIQUE">
                          Informatique, développement (NSF 326)
                        </Radio>
                        <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="COMMUNICATION">
                          Communication digitale et web (NSF 320)
                        </Radio>
                      </Space>
                    </Radio.Group>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Modalité de réalisation"
                validateStatus={errors?.realizationMethod?.message && 'error'}
                help={errors?.realizationMethod?.message}
                required
              >
                <Controller
                  name="realizationMethod"
                  control={methods.control}
                  render={({ field }) => (
                    <Radio.Group {...field}>
                      <Space direction="vertical">
                        <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="PRESENTIEL">
                          En présentiel
                        </Radio>
                        <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="DISTANCIEL">
                          En télé-présentiel
                        </Radio>
                        <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="MIXTE">
                          Mixte
                        </Radio>
                      </Space>
                    </Radio.Group>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Informations du formateur</Divider>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="Type de formateur"
                validateStatus={errors?.studentFormerType?.message && 'error'}
                help={errors?.studentFormerType?.message}
                required
              >
                <Controller
                  name="studentFormerType"
                  control={methods.control}
                  render={({ field }) => (
                    <Radio.Group {...field}>
                      <Radio className={errors?.studentFormerType?.message && 'radio-danger'} value="INTERNE">
                        Interne à Arinfo
                      </Radio>
                      <Radio className={errors?.studentFormerType?.message && 'radio-danger'} value="EXTERNE">
                        Externe à Arinfo
                      </Radio>
                    </Radio.Group>
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Formateur"
                validateStatus={errors?.studentFormer?.message && 'error'}
                help={errors?.studentFormer?.message}
                required
              >
                <DebounceSelectFormer
                  fieldName="studentFormer"
                  fetchOptions={formerType === 'EXTERNE' ? fetchFormerList : fetchUserList}
                  options={formerOptions}
                  setOptions={setFormerOptions}
                  disabled={isProcessing || !formerType}
                />
              </Form.Item>
              {checkAuthorization(user, 'global', 'externalInterventions', 'create') && (
                <AddExternalFormerModal onFinish={onExternalFormerCreate} disabled={formerType !== 'EXTERNE'} />
              )}
            </Col>
          </Row>
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/formao/bilans">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default SatisfactionReportCreate;
