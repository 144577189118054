import { Card, Form, Button, Input, Typography, message, Alert, Row, Col } from 'antd';
import { Link, Prompt } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { formationRoutes } from '../../../../lib/routes';

const { Item } = Form;
const { Paragraph } = Typography;

const FormationCreate = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleSubmit, control, setError, formState } = useForm();
  const { errors } = useFormState({ control });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(formationRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push(`/formao/formations/modifier-une-formation/${results.data._id}`);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            </ul>
          </blockquote>
        </Paragraph>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              validateStatus={errors?.title?.message && 'error'}
              help={errors?.title?.message}
              label="Intitulé"
              required
            >
              <Controller
                control={control}
                name="title"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              validateStatus={errors?.acronym?.message && 'error'}
              help={errors?.acronym?.message}
              label="Acronyme"
              required
            >
              <Controller
                control={control}
                name="acronym"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Item>
          </Col>
        </Row>
        <Alert
          style={{ marginBottom: 20 }}
          showIcon
          type="info"
          message="Vous pourrez accéder à la sélection des modules une fois cette étape terminée."
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={isProcessing}>
            <Link to="/formao/formations">Annuler</Link>
          </Button>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default FormationCreate;
