import { useState, useEffect, useMemo } from 'react';
import { Button, Card, message, Form, Tabs, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import useSWR from 'swr';
import { FAQRoutes } from '../../../lib/routes';
import { useAuth } from '../../../authContext';
import { checkAuthorization } from '../../../shared/utils';
import useFetch from '../../../hooks/useFetch';
import FAQSection from './components/FAQSection';
import SectionCreateModal from './components/SectionCreateModal';

const PageContent = ({
  isEditing,
  data,
  disabled,
  stopEditing,
  onFinish,
  tab,
  category,
  isProcessing,
  setIsProcessing,
  setIsBlocking,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const methods = useForm({ defaultValues: { sections: data, toDelete: [] } });
  const { fields: sections, append, remove, swap } = useFieldArray({ control: methods.control, name: 'sections' });
  const { fields: toDelete, append: appendToDelete } = useFieldArray({ control: methods.control, name: 'toDelete' });
  const { put } = useFetch();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.reset();
  }, [tab, isEditing]);

  useEffect(() => methods.setValue('sections', data), [data]);

  const moveSection = (sourceIndex, destinationIndex) => {
    if (sections[sourceIndex] && sections[destinationIndex]) {
      const orders = { source: sections[sourceIndex].order, destination: sections[destinationIndex].order };

      methods.setValue(`sections.${sourceIndex}.order`, orders.destination);
      methods.setValue(`sections.${destinationIndex}.order`, orders.source);

      swap(sourceIndex, destinationIndex);
    }
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(FAQRoutes.update + `/${tab}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      stopEditing();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const handleNewSection = (newSection) => {
    append({
      title: newSection.title,
      questions: [],
      category,
      order: sections[sections?.length - 1]?.order + 1 || 1,
      disabled: !!newSection.disabled ? true : false,
    });

    setIsOpen(false);
  };

  const handleRemove = (index) => {
    if (sections[index]?._id) {
      appendToDelete(sections[index]._id);
    }

    remove(index);
  };

  return (
    <FormProvider {...methods}>
      <Form layout="vertical" noValidate>
        <SectionCreateModal
          onFinish={handleNewSection}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          disabled={disabled || isProcessing}
        />
        {sections?.length !== 0 ? (
          sections.map((item, index) => (
            <FAQSection
              key={item._id || item.id}
              index={index}
              data={item}
              isEditing={isEditing}
              onFinish={onFinish}
              onDelete={handleRemove}
              move={moveSection}
              disabled={disabled || isProcessing}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucune question dans cette catégorie." />
        )}
        {isEditing && checkAuthorization(user, 'arinfo', 'faq', 'update-faq') && (
          <Button
            type="primary"
            disabled={disabled || isProcessing}
            onClick={() => setIsOpen(true)}
            className="arinfo-faq-add-section-button"
          >
            Ajouter une section
          </Button>
        )}

        <div style={{ textAlign: 'right' }}>
          {isEditing && (
            <Button type="primary" loading={isProcessing} disabled={disabled} onClick={methods.handleSubmit(onSubmit)}>
              Enregistrer les modifications
            </Button>
          )}
        </div>
      </Form>
    </FormProvider>
  );
};

const filterSections = (data) => {
  const sections = {
    before: [],
    during: [],
    end: [],
  };

  if (!!data && Array.isArray(data) && data.length) {
    for (let i = 0; i < data.length; i++) {
      switch (data[i].category) {
        case 'Avant votre formation':
          sections.before.push(data[i]);
          continue;
        case 'Pendant votre formation':
          sections.during.push(data[i]);
          continue;
        case 'Fin de formation':
          sections.end.push(data[i]);
          continue;
        default:
          continue;
      }
    }
  }

  return sections;
};

const FAQList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [tab, setTab] = useState('before');
  const { user } = useAuth();
  const {
    data: sections,
    mutate,
    isValidating,
  } = useSWR(`${FAQRoutes.default}`, {
    revalidateOnFocus: !isEditing,
    revalidateOnReconnect: !isEditing,
  });
  const { before, during, end } = useMemo(() => filterSections(sections?.data), [sections?.data]);

  const commonTabsProps = {
    isEditing,
    disabled: !sections || isValidating,
    isProcessing,
    setIsProcessing,
    setIsBlocking,
    onFinish: () => mutate(`${FAQRoutes.default}`),
    stopEditing: () => setIsEditing(false),
    tab,
  };

  const onTabChange = (value) => {
    if (isBlocking) {
      let choice = confirm(
        "Vous n'avez pas sauvegardé vos modifications de la FAQ, voulez-vous vraiment changer d'onglet ?",
      );

      if (choice === true) {
        setIsBlocking(false);
        setIsEditing(false);
        setTab(value);
      }
    } else {
      setIsEditing(false);
      setTab(value);
    }
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications de la FAQ, voulez-vous vraiment quitter cette page ?"
      />

      {(checkAuthorization(user, 'arinfo', 'faq', 'update-faq') ||
        checkAuthorization(user, 'arinfo', 'faq', 'enable-section') ||
        checkAuthorization(user, 'arinfo', 'faq', 'disable-section') ||
        checkAuthorization(user, 'arinfo', 'faq', 'enable-question') ||
        checkAuthorization(user, 'arinfo', 'faq', 'disable-question')) && (
        <div style={{ marginBottom: 20, textAlign: 'right' }}>
          <Button disabled={!sections || isValidating || isProcessing} onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Annuler les modifications' : 'Activer la modification'}
          </Button>
        </div>
      )}
      <Tabs defaultActiveKey="before" activeKey={tab} onChange={onTabChange}>
        <Tabs.TabPane tab="Avant votre formation" key="before">
          {(!sections || (sections && !sections?.data?.length)) && isValidating ? (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 20px 0px' }}>
              <LoadingOutlined />
            </div>
          ) : (
            <PageContent {...commonTabsProps} data={before} category="Avant votre formation" />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pendant votre formation" key="during">
          {(!sections || (sections && !sections?.data?.length)) && isValidating ? (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 20px 0px' }}>
              <LoadingOutlined />
            </div>
          ) : (
            <PageContent {...commonTabsProps} data={during} category="Pendant votre formation" />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Fin de formation" key="end">
          {(!sections || (sections && !sections?.data?.length)) && isValidating ? (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 20px 0px' }}>
              <LoadingOutlined />
            </div>
          ) : (
            <PageContent {...commonTabsProps} data={end} category="Fin de formation" />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default FAQList;
