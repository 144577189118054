import { Form, Tabs, Card, Space, Button, message } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { Prompt, Link, useParams, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { cpfRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import CPFUpdateInformations from './CPFUpdateInformations';
import CPFUpdateTable from './CPFUpdateTable';
import CPFUpdateAgencies from './CPFUpdateAgencies';

const { TabPane } = Tabs;

const CPFUpdate = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm();
  const { id } = useParams();
  const { put } = useFetch();
  const history = useHistory();
  const { data: cpf, isValidating } = useSWR(cpfRoutes.default + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (cpf?.data) {
      methods.setValue('title', cpf?.data.title);
      methods.setValue('description', cpf?.data.description);
      methods.setValue('format', cpf?.data.format);
      methods.setValue('prices', cpf?.data.prices);
      methods.setValue('agencies', cpf?.data.agencies);
    }
  }, [cpf]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(cpfRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(cpfRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/arinfo/simulateur-cpf');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="cpf-update">
      <FormProvider {...methods}>
        <Prompt
          when={isBlocking}
          message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
        />
        <Form noValidate layout="vertical">
          <Tabs defaultActiveKey="meta">
            <TabPane tab="Informations" key="meta">
              <CPFUpdateInformations disabled={!cpf || isValidating || isProcessing} />
            </TabPane>
            <TabPane tab="Grille tarifaire" key="grid">
              <CPFUpdateTable disabled={!cpf || isValidating || isProcessing} />
            </TabPane>
            <TabPane tab="Centres et formations" key="agencies">
              <CPFUpdateAgencies disabled={!cpf || isValidating || isProcessing} />
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={!cpf || isValidating || isProcessing}>
              <Link to="/arinfo/simulateur-cpf">Annuler</Link>
            </Button>
            <Space>
              <Button onClick={methods.handleSubmit(onSubmit)} loading={!cpf || isValidating || isProcessing}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                loading={!cpf || isValidating || isProcessing}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default CPFUpdate;
