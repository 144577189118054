import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import FigureView from './FigureView';

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const Figure = Node.create({
  name: 'figure',
  group: 'block',
  content: 'figureImage figureCaption',
  draggable: true,
  isolating: true,
  allowGapCursor: false,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      'data-desktop-hidden': {
        default: false,
        parseHTML: (el) => el.getAttribute('data-desktop-hidden'),
      },
      'data-desktop-fit': {
        default: 'cover',
        parseHTML: (el) => el.getAttribute('data-desktop-fit'),
      },
      'data-desktop-width': {
        default: 'auto',
        parseHTML: (el) => el.getAttribute('data-desktop-width'),
      },
      'data-desktop-height': {
        default: 'auto',
        parseHTML: (el) => el.getAttribute('data-desktop-height'),
      },
      'data-tablet-hidden': {
        default: false,
        parseHTML: (el) => el.getAttribute('data-tablet-hidden'),
      },
      'data-tablet-fit': {
        default: 'cover',
        parseHTML: (el) => el.getAttribute('data-tablet-fit'),
      },
      'data-tablet-width': {
        default: 'auto',
        parseHTML: (el) => el.getAttribute('data-tablet-width'),
      },
      'data-tablet-height': {
        default: 'auto',
        parseHTML: (el) => el.getAttribute('data-tablet-height'),
      },
      'data-mobile-hidden': {
        default: false,
        parseHTML: (el) => el.getAttribute('data-mobile-hidden'),
      },
      'data-mobile-fit': {
        default: 'cover',
        parseHTML: (el) => el.getAttribute('data-mobile-fit'),
      },
      'data-mobile-width': {
        default: 'auto',
        parseHTML: (el) => el.getAttribute('data-mobile-width'),
      },
      'data-mobile-height': {
        default: 'auto',
        parseHTML: (el) => el.getAttribute('data-mobile-height'),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'figure',
        getAttrs: (el) => !el.hasAttribute('data-video'),
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['figure', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(FigureView);
  },
  addCommands() {
    return {
      setFigure:
        ({ image, caption }) =>
        ({ tr, dispatch }) => {
          try {
            const { doc } = tr;

            if (!dispatch) {
              return;
            }

            const figure = {
              type: this.name,
              content: [
                {
                  type: 'figureImage',
                  attrs: image,
                },
                {
                  type: 'figureCaption',
                  content: caption ? [{ type: 'text', text: caption }] : [],
                },
              ],
            };

            const newNode = doc.type.schema.nodeFromJSON(figure);

            if (newNode === null) {
              return;
            }

            tr = tr.replaceSelectionWith(newNode, false);

            return dispatch(tr);
          } catch (error) {
            console.error(error);
          }
        },
    };
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, src, alt, title] = match;

          return { src, alt, title };
        },
      }),
    ];
  },
});
