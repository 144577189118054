import { mergeAttributes, Node } from '@tiptap/core';

export const CloudinaryVideo = Node.create({
  name: 'cloudinaryVideo',
  group: 'block',
  content: 'inline*',
  draggable: true,
  isolating: true,
  addOptions() {
    return {
      allow: 'fullscreen',
      allowfullscreen: true,
      frameborder: '0',
    };
  },
  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (el) => el.querySelector('iframe')?.getAttribute('src'),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'figure[data-video]',
        contentElement: 'figcaption',
      },
    ];
  },
  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      { 'data-video': '' },
      [
        'iframe',
        mergeAttributes(
          this.options.HTMLAttributes,
          {
            allow: this.options.allow,
            allowfullscreen: this.options.allowfullscreen,
            frameborder: this.options.frameborder,
          },
          HTMLAttributes,
        ),
      ],
      ['figcaption', 0],
    ];
  },
});
