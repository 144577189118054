import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { Select } from 'antd';

const CodeBlock = ({
  node: {
    attrs: { language: defaultLanguage },
  },
  updateAttributes,
  extension,
}) => (
  <NodeViewWrapper className="code-block">
    <Select
      style={{ minWidth: 200 }}
      defaultValue={defaultLanguage}
      onChange={(value) => updateAttributes({ language: value })}
      className="code-bock-select"
      size="small"
      showSearch
    >
      <Option value={null}>auto</Option>
      {extension.options.lowlight
        .listLanguages()
        .sort((a, b) => a.localeCompare(b))
        .map((lang, index) => (
          <Option key={index} value={lang}>
            {lang}
          </Option>
        ))}
    </Select>
    <pre spellCheck={false} autoCorrect={false} autoCapitalize={false}>
      <NodeViewContent as="code" />
      {/* <NodeViewContent as="code" className={defaultLanguage && `hljs language-${defaultLanguage}`} /> */}
    </pre>
  </NodeViewWrapper>
);

export default CodeBlock;
