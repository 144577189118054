import { memo, useRef } from 'react';
import { Typography, List, Space, Button, Popconfirm, Select, InputNumber, Tooltip } from 'antd';
import { useFormContext, useFieldArray, Controller, useWatch } from 'react-hook-form';
import { PlusOutlined, DragOutlined, DeleteOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { useDrop, useDrag } from 'react-dnd';
import { moduleRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const { Link } = Typography;
const { Item } = List;

const getTitle = (record) => {
  let title = record.title;

  if (record.level) {
    if (record.level === 'easy') {
      title = `${title} - Rookie (Débutant)`;
    }

    if (record.level === 'medium') {
      title = `${title} - Aventurier (Avancé)`;
    }

    if (record.level === 'hard') {
      title = `${title} - Explorateur (Expert)`;
    }
  }

  return title;
};

const getModulesInFormation = (units) => {
  return units
    .map((unit) => {
      if (unit.modules === []) {
        return [];
      } else {
        let ids = [];

        for (let i = 0; i < unit.modules.length; i++) {
          if (unit.modules[i].module) {
            ids.push(unit.modules[i].module);
          }
        }

        return ids;
      }
    })
    .flat();
};

const FormationEditModule = ({ unitIndex, onUpdate, disabled, toAppend, onToAppendChange }) => {
  const { control, setValue, getValues } = useFormContext();
  const { data: modules, isValidating } = useSWR(moduleRoutes.default);
  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: `units[${unitIndex}].modules`,
  });
  const units = useWatch({ control, name: 'units' });
  const modulesInFormation = getModulesInFormation(units);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const handleFilter = (inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase());

  const handleAppend = () => {
    const newModules = [];

    for (let i = 0; i !== toAppend; i++) {
      newModules.push({ module: null });
    }

    append(newModules);
  };

  const MemoizedItem = memo(({ field, index }) => {
    const { control } = useFormContext();
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [, drop] = useDrop({
      accept: 'COURSE',
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        const hoverItem = getValues(`units[${unitIndex}].modules[${hoverIndex}]`);

        if (item.id === hoverItem.id) {
          return;
        }

        if (item.unitIndex === unitIndex) {
          move(dragIndex, hoverIndex);
          onUpdate();
          return;
        }

        const dragArray = getValues(`units[${item.unitIndex}].modules`);
        dragArray.splice(item.index, 1);

        const itemCopy = { ...item };
        delete itemCopy.id;
        delete itemCopy.index;
        delete itemCopy.unitIndex;

        insert(hoverIndex, itemCopy);
        setValue(`units[${item.unitIndex}].modules`, dragArray);
        onUpdate();
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'COURSE',
      item: { ...field, index, unitIndex: unitIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <Item className="course-list-item">
        <div ref={previewRef} style={{ opacity, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Controller
            name={`units[${unitIndex}].modules[${index}].module`}
            control={control}
            render={({ field }) => {
              let isSelectedDisabled = false;

              if (!!field.value) {
                const match = modules?.data.find((item) => item._id === field.value);

                if (!!match) {
                  isSelectedDisabled = match?.disabled || false;
                }
              }

              return (
                <Tooltip
                  destroyTooltipOnHide
                  title={
                    isSelectedDisabled
                      ? "Ce cours est désactivé, il ne sera pas disponible sur l'interface apprenant de Formao."
                      : null
                  }
                >
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    status={isSelectedDisabled && 'warning'}
                    style={{ width: '100%', marginRight: 8 }}
                    placeholder="Sélectionnez dans la liste"
                    loading={isValidating}
                    disabled={!modules || isValidating || disabled}
                    options={modules?.data
                      .sort((a, b) => sort(a, b, 'title'))
                      .map((module) => {
                        let title = getTitle(module);
                        const isDisabled = !!module?.disabled;
                        const isAlreadyIn = modulesInFormation.includes(module._id) && field.value !== module._id;

                        if (isAlreadyIn) {
                          title += ' (Déjà présent dans la formation)';
                        }

                        if (isDisabled) {
                          title += ' (Cours désactivé)';
                        }

                        return {
                          label: title,
                          value: module._id,
                          disabled: isDisabled || isAlreadyIn,
                        };
                      })}
                  />
                </Tooltip>
              );
            }}
          />
          <Space>
            <Button ref={ref} icon={<DragOutlined />} disabled={disabled} />
            <Popconfirm
              {...popconfirmProps}
              onConfirm={() => {
                remove(index);
              }}
            >
              <Button icon={<DeleteOutlined />} disabled={disabled} />
            </Popconfirm>
          </Space>
        </div>
      </Item>
    );
  });

  MemoizedItem.displayName = 'MemoizedItem';

  const [, drop] = useDrop({
    accept: 'COURSE',
    drop: (item, monitor) => {
      if (fields.length > 0) {
        return;
      }

      const dragArray = getValues(`units[${item.unitIndex}].modules`);
      dragArray.splice(item.index, 1);

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.unitIndex;

      append(itemCopy);
      setValue(`units[${item.unitIndex}].modules`, dragArray);
      onUpdate();
    },
  });

  return (
    <List size="small">
      <div ref={drop} style={{ minHeight: 48.8 }}>
        {fields.map((field, index) => (
          <MemoizedItem key={field._id || field.id} field={field} index={index} unitIndex={unitIndex} />
        ))}
      </div>
      <Item style={{ borderTop: '1px solid #f0f0f0', justifyContent: 'flex-start' }}>
        <InputNumber value={toAppend} onChange={onToAppendChange} min={1} max={10} style={{ marginRight: 5 }} />
        <Link disabled={disabled} onClick={handleAppend}>
          <PlusOutlined /> Ajouter {toAppend > 1 ? 'des' : 'un'} cours
        </Link>
      </Item>
    </List>
  );
};

export default FormationEditModule;
