import { Extension as TipTapExtension } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Blockquote from '@tiptap/extension-blockquote';
import Code from '@tiptap/extension-code';
import Placeholder from '@tiptap/extension-placeholder';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import History from '@tiptap/extension-history';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Typography from '@tiptap/extension-typography';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import HardBreak from '@tiptap/extension-hard-break';
import Youtube from '@tiptap/extension-youtube';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Save from './Save';
import { Important } from './Important';
import { CloudinaryVideo } from './CloudinaryVideo';
import { lowlight } from 'lowlight';
import CodeBlock from './CodeBlock';
import CloudinaryVideoView from './CloudinaryVideoView';
import { ContentEditorFigureExtension } from './ContentEditorFigureExtension';
import { ContentEditorDetailsExtension } from './ContentEditorDetailsExtension';
import { ContentEditorGridExtension } from './ContentEditorGridExtension';
import { Vimeo } from './VimeoExtension';
import { File } from './File';
import hljsBlade from 'highlightjs-blade';
import hljsTwig from 'highlight.js/lib/languages/twig';
import hljsXml from 'highlight.js/lib/languages/xml';

lowlight.registerLanguage('blade', hljsBlade);
lowlight.registerLanguage('twig', hljsTwig);
lowlight.registerLanguage('xml', hljsXml);
lowlight.registerLanguage('html', hljsXml);

const options = {
  save: Save.options || false,
  bold: Bold.options || false,
  italic: Italic.options || false,
  strike: Strike.options || false,
  underline: Underline.options || false,
  link: Link.options || false,
  heading: Heading.options || false,
  orderedList: OrderedList.options || false,
  bulletList: BulletList.options || false,
  listItem: ListItem.options || false,
  blockquote: Blockquote.options || false,
  code: Code.options || false,
  codeBlock: { ...CodeBlockLowlight.options, lowlight } || false,
  placeholder: Placeholder.options || false,
  image: Image.options || false,
  figure: ContentEditorFigureExtension.options || false,
  dropcursor: Dropcursor.options || false,
  gapcursor: Gapcursor.options || false,
  typography: Typography.options || false,
  history: History.options || false,
  highlight: Highlight.options || false,
  important: Important.options || false,
  color: Color.options || false,
  hardBreak: HardBreak.options || false,
  textAlign: TextAlign.options || false,
  youtube: Youtube.options || false,
  vimeo: Vimeo.options || false,
  cloudinaryVideo: CloudinaryVideo.options || false,
  file: File.options || false,
  table: Table.options || false,
  details: ContentEditorDetailsExtension.options || false,
  grid: ContentEditorGridExtension.options || false,
};

const Extension = TipTapExtension.create({
  name: 'extensions',
  addOptions: () => options,
  addExtensions() {
    const extensions = [Document, Text, Paragraph, TextStyle];

    if (this.options.save !== false) {
      extensions.push(Save.configure(this.options?.save));
    }

    if (this.options.bold !== false) {
      extensions.push(Bold.configure(this.options?.bold));
    }

    if (this.options.italic !== false) {
      extensions.push(Italic.configure(this.options?.italic));
    }

    if (this.options.strike !== false) {
      extensions.push(Strike.configure(this.options?.strike));
    }

    if (this.options.underline !== false) {
      extensions.push(Underline.configure(this.options?.underline));
    }

    if (this.options.orderedList !== false) {
      extensions.push(OrderedList.configure(this.options?.orderedList));
    }

    if (this.options.bulletList !== false) {
      extensions.push(BulletList.configure(this.options?.bulletList));
    }

    if (this.options.listItem !== false) {
      extensions.push(ListItem.configure(this.options?.listItem));
    }

    if (this.options.heading !== false) {
      extensions.push(Heading.configure(this.options?.heading));
    }

    if (this.options.blockquote !== false) {
      extensions.push(Blockquote.configure(this.options?.blockquote));
    }

    if (this.options.code !== false) {
      extensions.push(Code.configure(this.options?.code));
    }

    if (this.options.codeBlock !== false) {
      extensions.push(
        CodeBlockLowlight.extend({
          addNodeView() {
            return ReactNodeViewRenderer(CodeBlock);
          },
        }).configure(this.options?.codeBlock),
      );
    }

    if (this.options.link !== false) {
      extensions.push(Link.configure(this.options?.link));
    }

    if (this.options.placeholder !== false) {
      extensions.push(
        Placeholder.configure({
          includeChildren: true,
          showOnlyCurrent: false,
          showOnlyWhenEditable: false,
          placeholder: ({ node }) => {
            switch (node.type.name) {
              case 'figureCaption':
                return 'Légende (optionnelle)';
              case 'detailsSummary':
                return 'Sommaire';
              case 'detailsContent':
                return 'Contenu';
              default:
                return null;
            }
          },
        }),
      );
    }

    if (this.options.image !== false) {
      extensions.push(Image.configure(this.options?.image));
    }

    if (this.options.figure !== false) {
      extensions.push(ContentEditorFigureExtension.configure(this.options?.figure));
    }

    if (this.options.dropcursor !== false) {
      extensions.push(Dropcursor.configure(this.options?.dropcursor));
    }

    if (this.options.gapcursor !== false) {
      extensions.push(Gapcursor.configure(this.options?.gapcursor));
    }

    if (this.options.history !== false) {
      extensions.push(History.configure(this.options?.history));
    }

    if (this.options.typography !== false) {
      extensions.push(Typography.configure(this.options?.typography));
    }

    if (this.options.highlight !== false) {
      extensions.push(Highlight.configure(this.options?.highlight));
    }

    if (this.options.important !== false) {
      extensions.push(Important.configure(this.options?.important));
    }

    if (this.options.color !== false) {
      extensions.push(Color.configure(this.options?.color));
    }

    if (this.options.hardBreak !== false) {
      extensions.push(HardBreak.configure(this.options?.hardBreak));
    }

    if (this.options.textAlign !== false) {
      extensions.push(TextAlign.configure(this.options?.textAlign));
    }

    if (this.options.details !== false) {
      extensions.push(ContentEditorDetailsExtension.configure(this.options?.details));
    }

    if (this.options.grid !== false) {
      extensions.push(ContentEditorGridExtension.configure(this.options?.grid));
    }

    if (this.options.youtube !== false) {
      extensions.push(Vimeo.configure(this.options?.youtube));
    }

    if (this.options.vimeo !== false) {
      extensions.push(Youtube.configure(this.options?.vimeo));
    }

    if (this.options.cloudinaryVideo !== false) {
      extensions.push(
        CloudinaryVideo.extend({
          addNodeView() {
            return ReactNodeViewRenderer(CloudinaryVideoView);
          },
        }).configure(this.options?.cloudinaryVideo),
      );
    }

    if (this.options.file !== false) {
      extensions.push(File.configure(this.options?.file));
    }

    if (this.options.table !== false) {
      extensions.push(Table.configure({ ...this.options?.table }));
      extensions.push(TableCell);
      extensions.push(TableHeader);
      extensions.push(TableRow);
    }

    return extensions;
  },
});

export default Extension;
