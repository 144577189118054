import { useState } from 'react';
import { Card, Button, Table, Popconfirm, Dropdown, Menu, message } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { evaluationsRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import { MoreOutlined } from '@ant-design/icons';
import { checkAuthorization } from '../../../shared/utils';

const EvaluationsList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: evaluations, isValidating, mutate } = useSWR(evaluationsRoutes.default);
  const { user } = useAuth();
  const { remove } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const deleteEvaluation = async (id) => {
    setIsProcessing(true);

    const results = await remove(evaluationsRoutes.default + '/' + id, JSON.stringify({ ids: [id] }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = ({ record }) => (
    <Menu>
      {checkAuthorization(user, 'posiao', 'evaluations', 'update') && (
        <Menu.Item key="update" className="gray-text">
          <Link to={`/posiao/auto-evaluations/modifier-une-auto-evaluation/${record._id}`}>Modifier</Link>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'posiao', 'evaluations', 'delete') && (
        <Menu.Item key="delete" className="gray-text">
          <Popconfirm {...popconfirmProps} onConfirm={() => deleteEvaluation(record._id)}>
            Supprimer
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Intitulé de l'auto-évaluation",
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (record) => {
        if (
          checkAuthorization(user, 'posiao', 'evaluations', 'update') &&
          checkAuthorization(user, 'posiao', 'evaluations', 'delete')
        ) {
          return (
            <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
              <Button className="gray-text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'posiao', 'evaluations', 'create') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button>
            <Link to="/posiao/auto-evaluations/nouvelle-auto-evaluation">Nouvelle auto-évaluation</Link>
          </Button>
        </div>
      )}
      <Table
        loading={!evaluations || isValidating || isProcessing}
        dataSource={evaluations?.data || []}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination(evaluations?.data)}
      />
    </Card>
  );
};

export default EvaluationsList;
