import { useState, useRef, useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Button, Space, Collapse, Popconfirm } from 'antd';
import { DragOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDrop, useDrag } from 'react-dnd';
import StudentCreateModules from './StudentCreateModules';
import StudentCreateTitle from './StudentCreateTitle';

const { Panel } = Collapse;

const StudentCreateUnits = ({ disabled }) => {
  const { control } = useFormContext();
  const { fields: units, append, remove, move } = useFieldArray({ control, name: 'units' });
  const [keysOpen, setKeysOpen] = useState([]);
  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const DraggableCard = ({ unit, index }) => {
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: 'CHAPTER',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        move(dragIndex, hoverIndex);
        forceUpdate();
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'CHAPTER',
      item: { id: unit.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <div ref={previewRef} style={{ opacity }}>
        <Collapse activeKey={keysOpen} onChange={setKeysOpen} className="student-collapse">
          <Panel
            key={unit._id || unit.id}
            header={
              <div
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                <StudentCreateTitle fieldName={`units.${index}.title`} />
              </div>
            }
            style={{ marginBottom: 20, padding: 0 }}
            className="drag-collapse"
            extra={
              <Space>
                <div onClick={(e) => e.stopPropagation()}>
                  <Button ref={ref} data-handler-id={handlerId} icon={<DragOutlined />} />
                </div>
                <div onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    {...popconfirmProps}
                    onConfirm={() => {
                      remove(index);
                    }}
                  >
                    <Button onClick={(e) => e.stopPropagation()} icon={<DeleteOutlined />} />
                  </Popconfirm>
                </div>
              </Space>
            }
          >
            <StudentCreateModules disabled={disabled} unitIndex={index} onUpdate={forceUpdate} />
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
      {units.map((unit, index) => (
        <DraggableCard key={unit.id} unit={unit} index={index} />
      ))}
      <Button block disabled={disabled} onClick={() => append({ title: 'Nouvelle unité', modules: [] })} type="primary">
        Ajouter une unité
      </Button>
    </Space>
  );
};

export default StudentCreateUnits;
