import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import View from './View';

export const Details = Node.create({
  name: 'details',
  defining: true,
  allowGapCursor: false,
  group: 'block',
  content: 'detailsSummary detailsContent',
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      open: {
        default: true,
        rendered: false,
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(View);
  },
  parseHTML() {
    return [{ tag: 'details' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['details', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      setDetails:
        () =>
        ({ state, tr, editor, dispatch }) => {
          try {
            if (!dispatch || editor.isActive('details')) {
              return;
            }

            const { $from, $to } = state.selection;
            const range = $from.blockRange($to);

            tr = tr.insert(range.start, editor.schema.nodes.details.createAndFill()).scrollIntoView();

            return dispatch(tr);
          } catch (error) {
            console.error(error);
          }
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      // Quitte le bloc lors de l'utilisation de la flèche du bas à l'intérieur du bloc dans les cas suivants :
      // 1. Si le bloc est fermé et que le curseur est à la fin du sommaire
      // 2. Si le bloc est ouvert et que le curseur est à la fin du contenu
      ArrowDown: ({ editor }) => {
        const { state } = editor;
        const { selection, doc } = state;
        const { $from, empty } = selection;

        // Si la sélection n'est pas vide ou que le bloc n'est pas actif, l'action est annulée
        if (!empty || !editor.isActive('details')) {
          return false;
        }

        let depth = $from.depth;
        let parent;
        let currentNodeInParent;

        // Recherche le premier parent de type "details" ainsi que la position du curseur à l'intérieur (sommaire || contenu)
        do {
          parent = $from.node(depth);

          // Si un parent est présent
          if (parent) {
            // Si le parent est de type "details"
            if (parent.type === this.type) {
              break;
            }

            // Si le curseur est dans le sommaire ou le contenu
            if (['detailsSummary', 'detailsContent'].includes(parent.type.name)) {
              currentNodeInParent = parent;
            }

            // Met à jour la sélection pour récupérer le prochain parent
            editor.commands.selectParentNode();
            // Remonte d'un niveau dans l'arborescence
            depth--;
          }
        } while (depth > 0 && parent);

        // Si le curseur est à la fin du sommaire ou du contenu
        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (['detailsSummary', 'detailsContent'].includes(currentNodeInParent.type.name)) {
          if (isAtEnd) {
            // Si le curseur est dans le sommaire mais que le bloc est ouvert, l'action est annulée
            if (currentNodeInParent.type.name === 'detailsSummary' && !!parent.attrs.open) {
              return false;
            }

            // Calcul la position du prochain bloc
            const after = editor.state.selection.$from.after();

            // Si une erreur survient pendant le calcul de la position, l'action est annulée
            if (after === undefined) {
              return false;
            }

            // Récupère le bloc suivant à partir de la position
            const nodeAfter = doc.nodeAt(after);

            // Si un bloc est trouvé la sélection est mise à jour pour se centrer sur ce bloc
            if (nodeAfter) {
              return editor.commands.setTextSelection(after);
            }

            // Un nouveau bloc de texte est créé et la sélection est mise à jour sur ce bloc
            return editor.commands.insertContentAt(after, '<p></p>', {
              updateSelection: true,
              parseOptions: {
                preserveWhitespace: 'full',
              },
            });
          }
        }
      },
    };
  },
});
