import { useRef, memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { Typography, List, Button, Space } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';

const { Text } = Typography;
const { Item } = List;

const TagDrag = memo(({ data, onMove, onDelete, disabled, loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const ref = useRef(null);
  const previewRef = useRef(null);
  const [, drop] = useDrop({
    accept: 'CARD',
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = data.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      return onMove(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'CARD',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;

  drag(ref);
  drop(preview(previewRef));

  return (
    <Item className="editable-fix">
      <div className="ordering-tag-container" ref={previewRef} style={{ opacity }}>
        <Controller
          name={`ordering.[${data.index}].label`}
          control={control}
          render={({ field }) => (
            <Text
              ellipsis
              editable={loading ? false : { onChange: field.onChange, tooltip: 'Renommer' }}
              disabled={loading}
              style={{ width: 'calc(100% - 80px)' }}
            >
              {field.value}
            </Text>
          )}
        />
        <Space>
          <Button icon={<DragOutlined />} ref={ref} disabled={loading} />
          <Button onClick={() => onDelete(data.index)} icon={<DeleteOutlined />} disabled={loading} />
        </Space>
      </div>
      {errors?.ordering?.[data?.index]?.label && (
        <span style={{ color: '#ff4d4f' }}>{errors?.ordering?.[data?.index]?.label?.message}</span>
      )}
    </Item>
  );
});

TagDrag.displayName = 'TagDrag';
export default TagDrag;
