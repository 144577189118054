import { useFormContext, useWatch } from 'react-hook-form';
import Clock from '../../../../../assets/posiao/clock.svg';

const instructions = {
  checkbox: 'Cocher la ou les bonnes réponses, plusieurs réponses possibles.',
  illustrated: 'Cocher la ou les bonnes réponses, plusieurs réponses possibles.',
  connect: 'Relier les éléments de gauche avec les éléments de droite. Des pièges peuvent être présents.',
  legend: 'Déplacer les éléments dans les bonnes légendes. Des pièges peuvent être présents.',
  ordering: 'Déplacer les éléments pour classer correctement la liste.',
  organize: 'Déplacer les éléments dans les bonnes colonnes, des pièges peuvent être présents.',
  trueFalse: 'Sélectionner vrai ou faux.',
};

const Preview = ({ children, type }) => {
  const { control } = useFormContext();
  const duration = useWatch({ control, name: 'duration' });

  return (
    <div className="preview-container">
      <div className="home-container">
        <div className="header-container">
          <p className="header-title">Question 1 /30</p>
          <div className="clock-container">
            <p className="clock-count">{duration}s</p>
            <img src={Clock} alt="clock" style={{ maxWidth: 30, marginLeft: 10 }} />
          </div>
        </div>
        <div className="home-content">
          <p className="preview-instructions">{instructions[type]}</p>
          {children}
          <button className="next-button">Question suivante</button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
