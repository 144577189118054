import { Extension } from '@tiptap/core';

const Save = Extension.create({
  name: 'save',
  addOptions() {
    return {
      callback: () => {},
    };
  },
  addKeyboardShortcuts() {
    return {
      'Mod-s': () => this.options.callback(),
    };
  },
});

export default Save;
