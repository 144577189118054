import { useFormContext, useFieldArray } from 'react-hook-form';
import { Upload, Form, message, Alert, List, Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { serialize } from 'object-to-formdata';
import useFetch from '../../../../hooks/useFetch';
import { uploadRoutes } from '../../../../lib/routes';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const { Item } = Form;
const { Dragger } = Upload;

const FormationEditDocuments = ({ disabled }) => {
  const [loading, setLoading] = useState(false);
  const { control } = useFormContext();
  const { fields: documents, append, remove } = useFieldArray({ control, name: 'documents' });
  const { post, remove: fetchRemove } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const handleUpload = async ({ file }) => {
    if (!checkAuthorization(user, 'formao', 'documents', 'upload-document')) {
      message.error("Vous ne disposez pas de l'autorisation requise pour téléverser un fichier");
      return false;
    }

    setLoading(true);

    if (file.size / 1024 / 1024 > 10) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 10Mo');

      setLoading(false);

      return false;
    }

    const formData = serialize({ file }, { indices: true });
    const results = await post(uploadRoutes.file, formData, 'multipart/form-data');

    if (results.status === 201) {
      append(results.data);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    setLoading(false);
  };

  const handleDelete = async (id, index) => {
    if (!checkAuthorization(user, 'formao', 'documents', 'upload-document')) {
      message.error("Vous ne disposez pas de l'autorisation requise pour téléverser un fichier");
      return false;
    }

    setLoading(true);

    const results = await fetchRemove(uploadRoutes.deleteFile + '/' + id);

    if (results.status === 200) {
      remove(index);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        message.error(results.errors);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Alert
        type="info"
        showIcon
        message="Les documents déposés dans cet espace ne seront accessible par tous les apprenants inscrits à cette formation."
        style={{ marginBottom: 20 }}
      />
      <Item required>
        <Dragger
          name="file"
          maxCount={1}
          showUploadList={false}
          height={150}
          disabled={loading || disabled}
          beforeUpload={() => false}
          onChange={handleUpload}
        >
          <p className="ant-upload-hint">Sélectionnez ou déposez un fichier</p>
        </Dragger>
      </Item>
      <List
        size="small"
        bordered
        style={{ marginBottom: 20 }}
        dataSource={documents}
        renderItem={(item, index) => (
          <List.Item
            key={item._id}
            actions={[
              <a
                href={`${process.env.REACT_APP_BASE_URL_API_URL}/download/formao/${item.name}${item.extension}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button icon={<DownloadOutlined />} />
              </a>,
              <Tooltip title="Supprimer le document" key="delete" placement="right">
                <Popconfirm
                  {...popconfirmProps}
                  onConfirm={() => {
                    handleDelete(item._id, index);
                  }}
                >
                  <Button icon={<DeleteOutlined />} disabled={loading || disabled} />
                </Popconfirm>
              </Tooltip>,
            ]}
          >
            <span>{item.originalName}</span>
          </List.Item>
        )}
      />
    </>
  );
};

export default FormationEditDocuments;
