import { Button, Popover, Space, Form, Tooltip, Input } from 'antd';
import { useState, useEffect } from 'react';
import { CloseOutlined, CheckOutlined, YoutubeOutlined } from '@ant-design/icons';

const checkUrl = (value) => {
  const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(.+)?$/;

  if (!value) {
    return false;
  }

  return value.match(YOUTUBE_REGEX);
};

const Youtube = ({ editor, disabled }) => {
  const [url, setUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setError(null);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    if (checkUrl(url)) {
      editor.commands.setYoutubeVideo({ src: url });

      setUrl('');
      setIsOpen(false);
    } else {
      setError('URL invalide');
    }
  };

  return (
    <Popover
      visible={isOpen}
      onVisibleChange={() => setIsOpen(!isOpen)}
      trigger="click"
      title="Intégrer une vidéo Youtube"
      overlayStyle={{ width: 350 }}
      placement="bottom"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item validateStatus={error && 'error'} help={error} style={{ margin: 0 }}>
            <Input
              name="url"
              placeholder="URL de la vidéo"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              disabled={disabled}
            />
          </Form.Item>
          <Space style={{ marginTop: 10 }}>
            <Button icon={<CheckOutlined />} type="primary" disabled={disabled} onClick={handleSubmit} />
            <Button icon={<CloseOutlined />} onClick={() => setIsOpen(false)} disabled={disabled} />
          </Space>
        </Space>
      }
    >
      <Tooltip key="youtube" title="Intégrer une vidéo Youtube">
        <Button
          icon={<YoutubeOutlined />}
          disabled={disabled}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        />
      </Tooltip>
    </Popover>
  );
};

export default Youtube;
