import { useFormContext, useWatch } from 'react-hook-form';
import Move from '../../../../../assets/posiao/move.svg';

const OrganizePreview = () => {
  const { control } = useFormContext();
  const values = useWatch({ control });

  return (
    <>
      <h3 className="preview-question">{values?.question}</h3>
      <div className="columns-container">
        {values.lists.map((list, index) => (
          <div key={`list-${index}`} className="column">
            <div className="labels-box-title">{list.title}</div>
            <div className="column-labels" />
          </div>
        ))}
      </div>

      <div className="labels-box">
        <div className="labels-box-title">Éléments à trier</div>
        <div className="labels-box-drop">
          {values.labels.map((label, index) => (
            <div key={`labelbox-label${index}`} className="item">
              {label.content}
              <img src={Move} alt="move icon" style={{ maxWidth: '20px' }} />
            </div>
          ))}
          {values.lists.map((list, index) =>
            list.labels.map((label, labelIndex) => (
              <div key={`list${index}-label${labelIndex}`} className="item">
                {label.content}
                <img src={Move} alt="move icon" style={{ maxWidth: '20px' }} />
              </div>
            )),
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizePreview;
