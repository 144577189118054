import { useState } from 'react';
import { Modal, Button, Upload, Form, Input, Typography, message, Switch } from 'antd';
import { Controller, useFormState, useForm } from 'react-hook-form';
import { UploadOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';
import { imaginelabDocumentsRoutes } from '../../../lib/routes';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';

const CreateModal = ({ loading, onFinish }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm({ defaultValues: { disabled: false } });
  const { errors } = useFormState({ control: methods.control });
  const { user } = useAuth();
  const { post } = useFetch();

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await post(imaginelabDocumentsRoutes.create, formData, 'multipart/form-data');

    if (results.status === 201) {
      methods.reset();
      setIsOpen(false);
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onFileChange = ({ file }, onChange) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 10) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 10Mo');
    } else {
      onChange(file);
    }
  };

  const onCancel = () => {
    methods.reset();
    setIsOpen(false);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button className="gray-text" onClick={() => setIsOpen(true)} disabled={loading || isProcessing}>
          Nouveau document
        </Button>
      </div>
      <Modal
        title="Ajouter un document"
        visible={isOpen}
        onCancel={onCancel}
        onOk={methods.handleSubmit(onSubmit)}
        okButtonProps={{ disabled: loading, loading: isProcessing }}
        cancelButtonProps={{ disabled: loading || isProcessing }}
        destroyOnClose
      >
        <Form noValidate layout="vertical">
          <Form.Item
            label="Document"
            className="imaginelab-document-upload"
            validateStatus={errors?.file?.message && 'error'}
            help={errors?.file?.message}
            required
          >
            <Controller
              name="file"
              control={methods.control}
              render={({ field }) => (
                <Upload
                  name={field.name}
                  disabled={loading || isProcessing}
                  maxCount={1}
                  showUploadList={false}
                  accept=".pdf"
                  beforeUpload={() => false}
                  onChange={(img) => onFileChange(img, field.onChange)}
                >
                  <Button
                    block
                    style={{
                      textAlign: 'left',
                      borderColor: errors?.file?.message && '#ff4d4f',
                    }}
                    disabled={loading || isProcessing}
                  >
                    <Typography.Text
                      style={{
                        color: errors?.file?.message && '#ff4d4f',
                      }}
                      ellipsis
                    >
                      <UploadOutlined /> {field?.value ? field.value.name : 'Téléverser'}
                    </Typography.Text>
                  </Button>
                </Upload>
              )}
            />
          </Form.Item>
          <Form.Item
            label="Titre"
            validateStatus={errors?.title?.message && 'error'}
            help={errors?.title?.message}
            required
          >
            <Controller
              name="title"
              control={methods.control}
              render={({ field }) => <Input {...field} disabled={loading || isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={errors?.description?.message && 'error'}
            help={errors?.description?.message}
            required
          >
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => <Input {...field} maxLength={50} disabled={loading || isProcessing} />}
            />
          </Form.Item>

          {(checkAuthorization(user, 'imaginelab', 'documents', 'enable-document') ||
            checkAuthorization(user, 'imaginelab', 'documents', 'disable-document')) && (
            <Form.Item label="Actif" required>
              <Controller
                name="disabled"
                control={methods.control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={!field.value}
                    maxLength={50}
                    onChange={(value) => {
                      const canAccess = field.value
                        ? checkAuthorization(user, 'imaginelab', 'documents', 'enable-document')
                        : checkAuthorization(user, 'imaginelab', 'documents', 'disable-document');

                      if (canAccess) {
                        field.onChange(!value);
                      }
                    }}
                    disabled={
                      isProcessing ||
                      loading ||
                      (field.value
                        ? !checkAuthorization(user, 'imaginelab', 'documents', 'enable-document')
                        : !checkAuthorization(user, 'imaginelab', 'documents', 'disable-document'))
                    }
                  />
                )}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
export default CreateModal;
