import React from "react";
import { Result } from "antd";
import "./NoMatch.css";

const NoMatch = () => (
  <div
    className="container error-page"
    style={{ minHeight: "calc(-64px + 100vh - 48px)" }}
  >
    <Result
      status="404"
      title="404"
      subTitle="Désolé la page que vous recherchez est introuvable"
    />
  </div>
);

export default NoMatch;
