import { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Input, Space, Tooltip } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';

const getFieldErrors = (errors, fieldName) => {
  switch (fieldName) {
    case 'forms.former':
      return errors?.forms?.former;
    case 'forms.tutor':
      return errors?.forms?.tutor;
    case 'forms.student':
      return errors?.forms?.student;
    default:
      return null;
  }
};

const TextAreaItem = memo(({ data, onMove, onRemove, disabled, fieldName }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const fieldErrors = getFieldErrors(errors, fieldName);
  const ref = useRef(null);
  const previewRef = useRef(null);
  const [, drop] = useDrop({
    accept: 'FIELD',
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = data.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      return onMove(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'FIELD',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;

  drag(ref);
  drop(preview(previewRef));

  return (
    <div ref={previewRef} style={{ opacity }}>
      <Card
        bordered
        size="small"
        title="Champ de texte"
        headStyle={{ background: '#f0f0f0' }}
        extra={
          <Space>
            <Button icon={<DragOutlined />} ref={ref} disabled={disabled} />
            <Button key="delete" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />
          </Space>
        }
      >
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Controller
            name={`${fieldName}.${data.index}.title`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Question *"
                style={{ fontWeight: 700 }}
                disabled={disabled}
                status={fieldErrors?.[data.index]?.title?.message && 'error'}
              />
            )}
          />
          {fieldErrors?.[data.index]?.title?.message && (
            <small style={{ color: 'red' }}>{fieldErrors?.[data.index]?.title?.message}</small>
          )}
          <Controller
            name={`${fieldName}.${data.index}.description`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Description de la question (optionnel)"
                disabled={disabled}
                status={fieldErrors?.[data.index]?.description?.message && 'error'}
              />
            )}
          />
          {fieldErrors?.[data.index]?.description?.message && (
            <small style={{ color: 'red' }}>{fieldErrors?.[data.index]?.description?.message}</small>
          )}
          <Divider />
          <Tooltip title="Ce champ n'est pas modifiable, il fera apparaître un champ à compléter pour l'utilisateur">
            <Input.TextArea
              placeholder="Champ à compléter par l'utilisateur"
              readOnly
              autoSize={{ minRows: 2, maxRows: 2 }}
              disabled={disabled}
            />
          </Tooltip>
        </Space>
      </Card>
    </div>
  );
});

TextAreaItem.displayName = 'TextAreaItem';
export default TextAreaItem;
