import { useState, memo, useEffect } from 'react';
import { useForm, FormProvider, useFormState, useFormContext } from 'react-hook-form';
import { Card, Form, Tabs, Typography, Space, Button, message, Badge } from 'antd';
import { Link, useHistory, useParams, Prompt } from 'react-router-dom';
import moment from 'moment';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { studentRoutes } from '../../../../lib/routes';
import StudentEditAccount from './StudentEditAccount';
import StudentEditFormations from './StudentEditFormations';
import StudentEditDocuments from './StudentEditDocuments';
import { useAuth } from '../../../../authContext';

const { TabPane } = Tabs;

const TabPaneTitle = memo(({ children, errorFields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const tabErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

  return (
    <Badge
      dot={tabErrors.length !== 0}
      status="error"
      title={`${tabErrors.length} erreur${tabErrors.length > 1 ? 's' : ''}`}
    >
      {children}
    </Badge>
  );
});

const StudentEdit = () => {
  const { id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: student, isValidating } = useSWR(studentRoutes.default + '/id/' + id);
  const { user } = useAuth();
  const methods = useForm({
    defaultValues: {
      referent: user._id,
    },
  });
  const { put } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (student && student.data) {
      methods.setValue('last_name', student.data.last_name);
      methods.setValue('first_name', student.data.first_name);
      methods.setValue('email', student.data.email);
      methods.setValue('referent', student.data.referent);
      methods.setValue('agency', student.data.agency);
      methods.setValue('documents', student.data.documents);
      methods.setValue(
        'formations',
        student.data.formations.map((formation) => {
          if (formation.formation === null) {
            return {};
          } else {
            return {
              ...formation,
              formation: formation.formation?._id,
              ...(formation.custom && typeof formation.formation === 'object'
                ? { units: formation.formation.units }
                : {}),
              startAt: moment(formation.startAt),
              endAt: moment(formation.endAt),
              extra: formation.extra || null,
              ...(formation.group ? { group: formation.group } : {}),
            };
          }
        }),
      );
    }
  }, [student]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(studentRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              Object.entries(item).forEach(([key, value]) => {
                methods.setError(`formations[${index}].${key}`, { type: 'manual', message: value });
              });
            });
          } else {
            methods.setError(key, { type: 'manual', message: value });
          }
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(studentRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/formao/apprenants');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              Object.entries(item).forEach(([key, value]) => {
                methods.setError(`formations[${index}].${key}`, { type: 'manual', message: value });
              });
            });
          } else {
            methods.setError(key, { type: 'manual', message: value });
          }
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="student-create">
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Tabs defaultActiveKey="account">
            <TabPane
              tab={<TabPaneTitle errorFields={['last_name', 'first_name', 'email']}>Compte</TabPaneTitle>}
              key="account"
            >
              <StudentEditAccount disabled={!student || isValidating || isProcessing} />
            </TabPane>
            <TabPane
              tab={
                <TabPaneTitle errorFields={['training', 'referent', 'startAt', 'endAt', 'formations']}>
                  Formations
                </TabPaneTitle>
              }
              key="formations"
            >
              <StudentEditFormations disabled={!student || isValidating || isProcessing} />
            </TabPane>
            <TabPane tab="Documents" key="documents">
              <StudentEditDocuments />
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={!student || isValidating || isProcessing}>
              <Link to="/formao/apprenants">Annuler</Link>
            </Button>
            <Space>
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                loading={isProcessing}
                disabled={!student || isValidating}
              >
                Enregistrer
              </Button>
              <Button
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                type="primary"
                loading={isProcessing}
                disabled={!student || isValidating}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default StudentEdit;
