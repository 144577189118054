import { useState, useEffect } from 'react';
import { Link, useHistory, Prompt, useParams } from 'react-router-dom';
import { Controller, FormProvider, useForm, useFieldArray, useFormState, useFormContext } from 'react-hook-form';
import { CheckCircleOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Card,
  Form,
  Typography,
  Row,
  Col,
  Empty,
  Space,
  Alert,
  Button,
  message,
  Tabs,
  Select,
  Tooltip,
  Badge,
} from 'antd';
import useSWR from 'swr';
import { formationRoutes, reportFormRoutes } from '../../../lib/routes';
import { searchInString } from '../../../lib/helpers';
import TextAreaItem from './components/TextAreaItem';
import TableItem from './components/TableItem';
import useFetch from '../../../hooks/useFetch';

const FormContent = ({ error, disabled, fieldName }) => {
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({ name: fieldName, control: control });

  const getRecipient = () => {
    switch (fieldName) {
      case 'forms.former':
        return 'FORMER';
      case 'forms.tutor':
        return 'TUTOR';
      case 'forms.student':
        return 'STUDENT';
      default:
        return null;
    }
  };

  const onTextAdd = () => {
    const newText = {
      title: '',
      description: '',
      format: 'TEXTAREA',
      recipient: getRecipient(),
    };

    append(newText);
  };

  const onTableAdd = () => {
    const newTable = {
      title: '',
      description: '',
      format: 'TABLE',
      recipient: getRecipient(),
      fields: [{ title: '', description: '' }],
    };

    append(newTable);
  };

  return (
    <Row gutter={[24, 24]} wrap={false}>
      <Col flex="auto">
        {fields.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Aucune donnée, commencez par ajouter un élément depuis la colonne de droite"
          />
        ) : (
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            {fields.map((item, index) => {
              if (item.format === 'TEXTAREA') {
                return (
                  <TextAreaItem
                    key={item.id}
                    data={{ ...item, index }}
                    onMove={move}
                    onRemove={() => remove(index)}
                    fieldName={fieldName}
                    disabled={disabled}
                  />
                );
              } else if (item.format === 'TABLE') {
                return (
                  <TableItem
                    key={item.id}
                    data={{ ...item, index }}
                    onMove={move}
                    onRemove={() => remove(index)}
                    disabled={disabled}
                    fieldName={fieldName}
                  />
                );
              } else {
                return null;
              }
            })}
          </Space>
        )}

        {error?.message && (
          <Alert message="Vous n'avez ajouté aucun champ" type="error" style={{ marginTop: 24 }} showIcon />
        )}
      </Col>

      <Col flex="none">
        <Space direction="vertical" style={{ position: 'sticky', top: 60 }}>
          <Typography.Text>Éléments disponibles</Typography.Text>

          <Button block icon={<FormOutlined />} style={{ textAlign: 'left' }} onClick={onTextAdd} disabled={disabled}>
            Champ de texte
          </Button>

          <Button
            block
            icon={<CheckCircleOutlined />}
            style={{ textAlign: 'left' }}
            onClick={onTableAdd}
            disabled={disabled}
          >
            Tableau d'évaluation
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

const FormUpdate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formattedFormations, setFormattedFormations] = useState([]);
  const { id } = useParams();
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: reportForm, isValidating } = useSWR(reportFormRoutes.default + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: existingForms } = useSWR(reportFormRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const methods = useForm({ defaultValues: { forms: { student: [], former: [], tutor: [] } } });
  const { errors } = useFormState({ control: methods.control });
  const history = useHistory();
  const { put } = useFetch();

  useEffect(() => {
    if (reportForm && reportForm.data) {
      methods.setValue('formations', reportForm.data.formations);
      methods.setValue('formType', reportForm.data.formType);
      methods.setValue('reportType', reportForm.data.reportType);
      methods.setValue('forms.student', reportForm.data.forms.student);

      if (
        reportForm.data.formType === 'MENSUEL' ||
        (reportForm.data.formType === 'FORMATION' && reportForm.data.reportType !== 'STAGE')
      ) {
        methods.setValue('forms.former', reportForm.data.forms.former);
      } else {
        methods.setValue('forms.tutor', reportForm.data.forms.tutor);
      }

      let formatted = [];

      formatted = formations?.data?.map((formation) => {
        const isAlreadyUsed = existingForms?.data?.find(
          (form) =>
            form.formType === reportForm.data.formType &&
            form.formations.includes(formation._id) &&
            form._id.toString() !== id.toString() &&
            form.reportType === reportForm.data.reportType,
        );
        return {
          id: formation._id,
          label: `${formation.title}${isAlreadyUsed ? ' (déjà existant)' : ''}`,
          value: formation._id,
          isAlreadyUsed: !!isAlreadyUsed,
        };
      });

      setFormattedFormations(formatted);
    }
  }, [reportForm]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.register('forms');
  }, []);

  const handleFormations = (ids, values, onChange) => {
    if (values.some((item) => item.isAlreadyUsed === true)) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }

    onChange(ids);
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(reportFormRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(reportFormRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/formao/formulaires-de-bilans');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form layout="vertical" noValidate onFinish={methods.handleSubmit(onSubmit)}>
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label={
              <>
                Formation.s liée.s&nbsp;
                <Tooltip title="Formations auxquelles sera destiné ce formulaire.">
                  <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                </Tooltip>
              </>
            }
            validateStatus={errors?.formations?.message && 'error'}
            help={errors?.formations?.message}
            required
          >
            <Controller
              name="formations"
              control={methods.control}
              render={({ field }) => (
                <Select
                  {...field}
                  mode="multiple"
                  onChange={(ids, values) => handleFormations(ids, values, field.onChange)}
                  filterOption={(input, option) => searchInString(input, option.label)}
                  options={formattedFormations || []}
                  disabled={!formations || isFormationsValidating || isProcessing}
                  allowClear
                  placeholder="Sélectionnez dans la liste"
                />
              )}
            />
          </Form.Item>

          {showAlert && (
            <Alert
              type="warning"
              message="Une ou plusieurs des formations sélectionnées ont déjà un formulaire défini pour ce même type de bilan. Enregistrer cette modification écrasera les formulaires existants."
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}

          <Alert
            type="info"
            message="Pour éviter des conflits avec les bilans déjà envoyés, il n'est pas possible de modifier le type de formulaire et le type de bilan."
            showIcon
          />
          <Tabs>
            <Tabs.TabPane
              tab={<Badge dot={errors?.forms?.student}>Stagiaire</Badge>}
              key="student"
              disabled={isProcessing || isValidating}
            >
              <FormContent
                error={errors?.forms?.student}
                disabled={isProcessing || isValidating}
                fieldName="forms.student"
              />
            </Tabs.TabPane>

            {(reportForm?.data?.formType === 'MENSUEL' ||
              (reportForm?.data?.formType === 'FORMATION' &&
                ['MI_PARCOURS', 'FINAL'].includes(reportForm?.data?.reportType))) && (
              <Tabs.TabPane
                tab={<Badge dot={errors?.forms?.former}>Formateur</Badge>}
                key="former"
                disabled={
                  isProcessing ||
                  !reportForm?.data?.formType ||
                  (!['MENSUEL', 'STAGE'].includes(reportForm?.data?.formType) && !reportForm?.data?.reportType) ||
                  isValidating
                }
              >
                <FormContent
                  error={errors?.forms?.former}
                  disabled={isProcessing || !reportForm?.data?.formType || isValidating}
                  fieldName="forms.former"
                />
              </Tabs.TabPane>
            )}

            {(reportForm?.data?.formType === 'STAGE' ||
              (reportForm?.data?.formType === 'COMPANY' && reportForm?.data?.reportType) ||
              (reportForm?.data?.formType === 'FORMATION' && reportForm?.data?.reportType === 'STAGE')) && (
              <Tabs.TabPane
                tab={<Badge dot={errors?.forms?.tutor}>Tuteur</Badge>}
                key="tutor"
                disabled={
                  isProcessing ||
                  !reportForm?.data?.formType ||
                  (!['MENSUEL', 'STAGE'].includes(reportForm?.data?.formType) && !reportForm?.data?.reportType) ||
                  isValidating
                }
              >
                <FormContent
                  error={errors?.forms?.tutor}
                  disabled={isProcessing || !reportForm?.data?.formType || isValidating}
                  fieldName="forms.tutor"
                />
              </Tabs.TabPane>
            )}
          </Tabs>
          <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/formao/formulaires-de-bilans/">Annuler</Link>
            </Button>
            <Space>
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                disabled={!reportForm || isValidating || isProcessing}
                loading={isProcessing}
              >
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                disabled={!reportForm || isValidating || isProcessing}
                loading={isProcessing}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default FormUpdate;
