import { isBefore, isToday } from 'date-fns';
import { RedoOutlined } from '@ant-design/icons';
import { Controller, useFormState, useFormContext, useFieldArray } from 'react-hook-form';
import { Row, Col, Divider, Form, Select, Typography, DatePicker, Space, Switch, Button, Input, Checkbox } from 'antd';
import moment from 'moment';
import RenderMonthlyReports from './RenderMonthlyReports';
import { sort } from '../../../../shared/utils';

const ReportsStep = ({
  disabled,
  showAllForms,
  formattedForms,
  forms,
  isFormsValidating,
  formsOptions,
  setFormsOptions,
  toggleShowAllForms,
  reportsType,
  reloadForms,
  isEditing,
  step,
}) => {
  const { control, watch, getValues } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields, append, remove, replace } = useFieldArray({ control, name: 'reports' });
  const selectedFormation = watch('studentFormation');

  const onFormChange = (value, onChange, reportIndex) => {
    if (selectedFormation) {
      const selectedForms = getValues('reports')?.map((item, index) => {
        if (index === reportIndex) {
          return value?.toString();
        } else {
          return item?.form?.toString();
        }
      });

      const newFormsOptions = forms?.data?.filter(
        (form) =>
          form?.formations.length === 0 ||
          form?.formations?.includes(selectedFormation.toString()) ||
          selectedForms.includes(form?._id),
      );

      if (newFormsOptions.length !== 0) {
        const formatted = newFormsOptions?.map((form) => ({
          label: form.name,
          value: form._id,
          formType: form.formType,
        }));

        setFormsOptions(formatted);
      } else {
        setFormsOptions([]);
        toggleShowAllForms(true);
      }
    } else {
      setFormsOptions([]);
      toggleShowAllForms(true);
    }

    onChange(value || null);
  };

  const disabledDate = (current) => {
    const date = new Date(current);
    const day = date.getDay();

    return day !== 1;
  };

  const toggleOptionalReport = (value) => {
    if (value && fields.length === 3) {
      append({ form: null, sendAt: null, ignored: false });
    }
    if (!value && fields.length === 4) {
      remove(fields.length - 1);
    }
  };

  const handleIgnored = (value, fieldIndex, onChange) => {
    let oldValues = getValues('reports');
    let newValues = oldValues.map((item, index) => ({
      ...item,
      ignored: value ? index <= fieldIndex : index < fieldIndex,
    }));

    replace(newValues);
  };

  const onRefresh = () => {
    reloadForms();

    if (selectedFormation) {
      const newFormsOptions = forms?.data?.filter(
        (form) => form?.formations.length === 0 || form?.formations?.includes(selectedFormation.toString()),
      );

      if (newFormsOptions.length !== 0) {
        const formatted = newFormsOptions?.map((form) => ({
          label: form.name,
          value: form._id,
          formType: form.formType,
        }));

        setFormsOptions(formatted);
      } else {
        setFormsOptions([]);
        toggleShowAllForms(true);
      }
    } else {
      setFormsOptions([]);
      toggleShowAllForms(true);
    }
  };

  return (
    <>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
          </ul>
        </blockquote>
      </Typography.Paragraph>
      {['COMPANY', 'STAGE', 'FORMATION'].includes(reportsType) &&
        fields.map((item, index) => {
          let label = '';
          let isOutDated = false;

          if (isEditing && item.sendAt && step) {
            if (step.startsWith('BILAN_1') && index === 0) {
              isOutDated = true;
            }
            if (step.startsWith('BILAN_2') && index <= 1) {
              isOutDated = true;
            }
            if (step.startsWith('BILAN_3') && index <= 2) {
              isOutDated = true;
            }
            if (step.startsWith('BILAN_4')) {
              isOutDated = true;
            }
          }

          if (['COMPANY', 'FORMATION'].includes(reportsType)) {
            switch (index) {
              case 0:
                label = reportsType === 'COMPANY' ? "Bilan d'intégration" : 'Bilan mi parcours';
                break;
              case 1:
                label = reportsType === 'COMPANY' ? 'Bilan mi parcours' : 'Bilan final';
                break;
              case 2:
                label = 'Bilan final';
                break;
              case 3:
                label = (
                  <Space>
                    Bilan supplémentaire {<Switch checked={fields.length === 4} onChange={toggleOptionalReport} />}
                  </Space>
                );
                break;
              default:
                break;
            }
          }

          if (reportsType === 'STAGE') {
            label = 'Bilan de stage';
          }

          return (
            <Form.Item
              label={label}
              validateStatus={errors?.reports?.[index]?.message && 'error'}
              help={errors?.reports?.[index]?.message}
              key={item._id || item.id}
              required
            >
              <Row justify="space-between" style={{ width: '100%' }}>
                <Col span={5}>
                  <Form.Item
                    validateStatus={errors?.reports?.[index]?.sendAt?.message && 'error'}
                    help={errors?.reports?.[index]?.sendAt?.message}
                  >
                    <Controller
                      name={`reports.${index}.sendAt`}
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          value={field.value ? moment(field.value) : null}
                          format="DD/MM/YYYY"
                          disabledDate={disabledDate}
                          disabled={
                            disabled || isOutDated || item.results || item.finishedAt || item.token || item.ignored
                          }
                          showToday={false}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    validateStatus={errors?.reports?.[index]?.form?.message && 'error'}
                    help={errors?.reports?.[index]?.form?.message}
                  >
                    <Controller
                      name={`reports.${index}.form`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e) => onFormChange(e, field.onChange, index)}
                          filterSort={(a, b) => sort(a, b, 'label')}
                          options={
                            showAllForms ? formattedForms : formsOptions.filter((form) => form.formType === reportsType)
                          }
                          disabled={
                            disabled ||
                            !forms ||
                            (!forms && isFormsValidating) ||
                            isOutDated ||
                            item.results ||
                            item.finishedAt ||
                            item.token ||
                            item.ignored
                          }
                          placeholder="Formulaire"
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              {selectedFormation && (
                                <>
                                  <Divider style={{ margin: '8px 0' }} />
                                  <div
                                    style={{
                                      padding: '0 8px 8px 8px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Space style={{ padding: '0 8px 8px 8px' }}>
                                      <Switch
                                        checked={showAllForms}
                                        onChange={toggleShowAllForms}
                                        disabled={!selectedFormation || !formsOptions?.length || item.ignored}
                                      />
                                      Afficher tous les formulaires
                                    </Space>
                                    <Button onClick={onRefresh} icon={<RedoOutlined />} loading={isFormsValidating} />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={13}>
                  {['FORMATION', 'COMPANY'].includes(reportsType) && (
                    <Form.Item
                      validateStatus={errors?.reports?.[index]?.ignored?.message && 'error'}
                      help={errors?.reports?.[index]?.ignored?.message}
                    >
                      <Controller
                        name={`reports.${index}.ignored`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={() => handleIgnored(!field.value, index, field.onChange)}
                            disabled={
                              disabled ||
                              !forms ||
                              (!forms && isFormsValidating) ||
                              isOutDated ||
                              item.results ||
                              item.finishedAt ||
                              item.token ||
                              isEditing
                            }
                          >
                            Ignorer ce bilan
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form.Item>
          );
        })}
      {reportsType === 'COMPANY' && fields.length !== 4 && (
        <Form.Item
          label={
            <Space>
              Bilan supplémentaire {<Switch checked={fields.length === 4} onChange={toggleOptionalReport} />}
            </Space>
          }
          required
        />
      )}

      {reportsType === 'MENSUEL' && (
        <RenderMonthlyReports
          reports={fields}
          showAllForms={showAllForms}
          toggleShowAllForms={toggleShowAllForms}
          formsOptions={formsOptions}
          formattedForms={formattedForms}
          disabled={disabled}
          onRefresh={onRefresh}
          isFormsValidating={isFormsValidating}
        />
      )}
    </>
  );
};

export default ReportsStep;
