import { useState, useEffect, useMemo } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import useSWR from 'swr';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import FullCalendar from '@fullcalendar/react';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Col, Row } from 'antd';
import CreateDateModal from './CreateDateModal';
import EditDateModal from './EditDateModal';
import { agencyRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const fetcher = (url) => fetch(url).then((res) => res.json());

const clearData = (array) => {
  array = array.map((item) => ({ label: item.city, value: item._id }));

  array = array.sort((a, b) => sort(a, b, 'label'));

  return array;
};

const formats = [
  {
    label: 'Découverte',
    color: '#1890FF',
  },
  {
    label: 'Stage vacances',
    color: '#52C41A',
  },
  {
    label: 'Hebdomadaire',
    color: '#FF4D4F',
  },
  {
    label: 'Intergénérationnel',
    color: '#722ed1',
  },
];

const DatesCalendar = ({ loading }) => {
  const [createEventModalVisible, setCreateEventModalVisible] = useState(false);
  const [editEventModalVisible, setEditEventModalVisible] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [lockedDays, setLockedDays] = useState([]);
  const { control } = useFormContext();
  const { fields: dates, remove, append, update } = useFieldArray({ name: 'dates', control });
  const { data: holidaysData } = useSWR('https://calendrier.api.gouv.fr/jours-feries/metropole.json', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const agenciesData = useMemo(() => clearData(agencies?.data.filter((agency) => agency.imaginelab) || []), [agencies]);

  useEffect(() => {
    if (typeof holidaysData !== 'undefined') {
      setLockedDays(Object.keys(holidaysData).map((key) => key));
      setHolidays(
        Object.entries(holidaysData).map(([key, value]) => ({
          title: `${value} - Férié`,
          start: key,
          description: 'Jour férié',
          display: 'background',
          groupId: 'holidays',
        })),
      );
    }
  }, [holidaysData]);

  useEffect(() => {
    setWorkshops(
      dates &&
        dates.map((item) => {
          const agency = agencies?.data?.find((itm) => itm._id === item.agency)?.city;
          const title = agency + ' - ' + format(new Date(item.date), 'p', { locale: fr });
          const color = formats.find((format) => format.label === item.format).color;

          return {
            ...item,
            title,
            date: item.date,
            color,
          };
        }),
    );
  }, [dates, agencies]);

  const handleDateSelect = (info) => {
    if (!lockedDays.includes(info.dateStr) && !loading) {
      setSelectedDate(info.date);
      setCreateEventModalVisible(true);
    }
  };

  const handleEventSelect = (info) => {
    if (info.event._def.groupId !== 'holidays' && !loading) {
      const event = workshops.find((item) => item.id === info.event._def.publicId);

      setSelectedEvent(event);
      setEditEventModalVisible(true);
    }
  };

  const onEditDate = (values) => {
    const index = dates.findIndex((item) => item.id === values.id);

    if (index !== -1) {
      update(index, values);
    }
  };

  const onRemove = (id) => {
    const index = dates.findIndex((item) => item.id === id);

    remove(index);
  };

  return (
    <div className="imaginelab-workshop-calendar">
      <CreateDateModal
        agencies={agenciesData}
        agenciesLoading={isValidating}
        visible={createEventModalVisible}
        onClose={() => setCreateEventModalVisible(false)}
        selectedDate={selectedDate}
        onFinish={append}
        loading={loading}
      />
      <EditDateModal
        agencies={agenciesData}
        agenciesLoading={isValidating}
        visible={editEventModalVisible}
        selectedEvent={selectedEvent}
        remove={onRemove}
        onClose={() => {
          setEditEventModalVisible(false);
          setSelectedEvent(null);
        }}
        onFinish={onEditDate}
        loading={loading}
      />
      <Row gutter={[20, 20]}>
        {formats.map((format) => (
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div className="imaginelab-workshop-calendar-dots" style={{ border: `4px solid ${format.color}` }} />
            {format.label}
          </Col>
        ))}
      </Row>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        locale={frLocale}
        initialView="dayGridWeek"
        events={[...holidays, ...(workshops ? workshops : [])]}
        displayEventTime={false}
        dateClick={handleDateSelect}
        eventClick={handleEventSelect}
        height={300}
        hiddenDays={[0, 6]}
      />
    </div>
  );
};

export default DatesCalendar;
