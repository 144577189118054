import {
  DeleteOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Skeleton, Space, Tag, Typography, message } from 'antd';
import Item, { Meta } from 'antd/lib/list/Item';
import useSWR from 'swr';
import { format } from 'date-fns';
import axios from 'axios';

import { packageRoutes } from '../../../../lib/routes';
import { memo, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

const SWROptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  revalidateOnRefresh: false,
  refreshInterval: 5000,
};

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const statusTags = {
  UPLOADING_PACKAGE: {
    label: 'Téléversement du package',
    color: 'gold',
    icon: <SyncOutlined spin />,
  },
  FORMATTING: {
    label: 'Formattage du cours',
    color: 'orange',
    icon: <SyncOutlined spin />,
  },
  UPLOADING_FILES: {
    label: 'Téléversement des fichiers',
    color: 'yellow',
    icon: <SyncOutlined spin />,
  },
  EXPORT_REQUESTED: {
    label: 'Export demandé',
    color: 'gold',
    icon: <SyncOutlined spin />,
  },
  COPY_FILES: {
    label: 'Récupération des fichiers',
    color: 'orange',
    icon: <SyncOutlined spin />,
  },
  GENERATE_FILES: {
    label: 'Création des fichiers de cours',
    color: 'yellow',
    icon: <SyncOutlined spin />,
  },
  BUILD_PACKAGE: {
    label: 'Création des fichiers de cours',
    color: 'lime',
    icon: <SyncOutlined spin />,
  },
  QUEUED: {
    label: "En file d'attente",
    color: 'default',
    icon: <ClockCircleOutlined />,
  },
  DONE: {
    label: 'Export terminé',
    color: 'success',
    icon: <CheckCircleOutlined />,
  },
  ERRORED: {
    label: 'Export échoué',
    color: 'error',
    icon: <CloseCircleOutlined />,
  },
};

const TaskCard = memo(({ item, onDelete }) => {
  const [reload, setReload] = useState(true);
  const { token, user } = useAuth();
  const { data: packageData, isValidating } = useSWR(`${packageRoutes.default}/${item.item}`, {
    ...SWROptions,
    refreshInterval: reload ? 5000 : 0,
  });
  const createdAt = useMemo(
    () => (packageData?.data ? format(new Date(packageData?.data?.createdAt), 'dd/MM/yyyy à hh:mm') : null),
    [packageData?.data],
  );
  const statusTag = useMemo(
    () => (packageData?.data ? statusTags[packageData?.data?.status] : null),
    [packageData?.data],
  );

  useEffect(() => {
    if (!isValidating && !packageData?.data) {
      setReload(false);
    }
  }, [isValidating, packageData?.data]);

  const deleteTask = async () => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/queue/${item.item}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        message.error(
          response?.data?.message ||
            "Une erreur est survenue, la tâche ne peut pas être supprimée de la file d'attente",
        );
      }
      onDelete();
      message.success("Tâche retirée de la file d'attente");
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la tâche ne peut pas être supprimée de la file d'attente",
      );
    }
  };

  if (!isValidating && !packageData?.data) {
    return (
      <Item>
        <Meta
          avatar={<WarningOutlined style={{ fontSize: '32px', color: '#ED6D63' }} />}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em' }}>
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                Supprimer la tâche de la file d'attente
              </Typography.Title>
              {checkAuthorization(user, 'devTools', 'importExportService', 'dropItemFromQueue') && (
                <Popconfirm {...popconfirmProps} onConfirm={deleteTask}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              )}
            </div>
          }
        />
      </Item>
    );
  }

  return (
    <Item className={packageData?.data?.status === 'QUEUED' && 'queuedItemDisabled'}>
      <Meta
        avatar={
          isValidating && !packageData?.data ? (
            <Skeleton.Avatar shape="square" active style={{ width: 32, height: 32 }} />
          ) : packageData?.data?.type?.includes('_IMPORT') ? (
            <DownloadOutlined style={{ fontSize: '32px', color: '#40a9ff' }} />
          ) : (
            <UploadOutlined style={{ fontSize: '32px', color: '#40a9ff' }} />
          )
        }
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em' }}>
            <Skeleton
              paragraph={{ rows: 1, width: 150 }}
              title={false}
              active
              loading={isValidating && !packageData?.data}
              style={{ marginBottom: 0 }}
            >
              {packageData?.data?.type.includes('_IMPORT')
                ? `IMPORT : ${packageData?.data?.package}`
                : `EXPORT : ${packageData?.data?.course.title}`}
            </Skeleton>
            <Space>
              {isValidating && !packageData?.data ? (
                <Skeleton.Button size="small" active style={{ width: 100 }} />
              ) : packageData?.data?.type.includes('MOODLE_') ? (
                <Tag color="blue">Moodle</Tag>
              ) : (
                <Tag color="purple">SCORM</Tag>
              )}
              {isValidating && !packageData?.data ? (
                <Skeleton.Button size="small" active style={{ width: 100 }} />
              ) : statusTag ? (
                <Tag color={statusTag.color} icon={isValidating ? <SyncOutlined spin /> : statusTag.icon}>
                  {statusTag.label}
                </Tag>
              ) : null}
              {checkAuthorization(user, 'devTools', 'importExportService', 'dropItemFromQueue') && (
                <Popconfirm {...popconfirmProps} onConfirm={deleteTask}>
                  <Button
                    icon={<DeleteOutlined />}
                    disabled={
                      (isValidating && !packageData?.data) || !['QUEUED', 'DONE'].includes(packageData?.data?.status)
                    }
                    size="small"
                  />
                </Popconfirm>
              )}
            </Space>
          </div>
        }
        description={
          <div style={{ marginTop: 'auto' }}>
            <Skeleton
              paragraph={{ rows: 2, width: [350, 250] }}
              title={false}
              active
              loading={isValidating && !packageData?.data}
            >
              {packageData?.data?.type.includes('_IMPORT') && (
                <Typography.Text type="secondary" style={{ marginBottom: 0, display: 'block' }}>
                  Fichier temporaire : {packageData?.data?.tempFile}
                </Typography.Text>
              )}
              <Typography.Text type="secondary" style={{ marginBottom: 0, display: 'block' }}>
                Tâche soumise le {createdAt} par {packageData?.data?.user?.first_name}{' '}
                {packageData?.data?.user?.last_name}
              </Typography.Text>
            </Skeleton>
            {packageData?.data?.type.includes('_IMPORT') && (
              <Space style={{ marginTop: 16 }}>
                {isValidating && !packageData?.data ? (
                  <Skeleton.Button size="small" active style={{ width: 250 }} />
                ) : (
                  <Checkbox checked={'upload' in item ? !item.upload : true}>
                    Désactiver le téléversement des fichiers
                  </Checkbox>
                )}
                {isValidating && !packageData?.data ? (
                  <Skeleton.Button size="small" active style={{ width: 250 }} />
                ) : (
                  <Checkbox checked={'import2Formao' in item ? !item.import2Formao : true}>
                    Désactiver l'ajout du cours dans Formao
                  </Checkbox>
                )}
              </Space>
            )}
          </div>
        }
      />
    </Item>
  );
});

TaskCard.displayName = 'TaskCard';
export default TaskCard;
