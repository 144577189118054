import { useState, useEffect } from 'react';
import { Card, Button, Tabs, Form, message } from 'antd';
import { Link, useHistory, Prompt } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { FastAverageColor } from 'fast-average-color';
import { serialize } from 'object-to-formdata';
import Meta from '../components/Meta';
import Legend from './Legend';
import { Preview, LegendPreview } from '../components/Preview/index';
import { questionRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const fac = new FastAverageColor();

const { TabPane } = Tabs;

const CreateLegendQuestion = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [tab, setTab] = useState('form');
  const methods = useForm({
    defaultValues: {
      duration: 30,
      labels: [{ content: 'Élément A' }, { content: 'Élément B' }, { content: 'Élément C' }],
    },
  });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.register('legends');
    methods.register('labels');
  }, []);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    let linesColor = 'default';

    if (imagePreview) {
      const color = await fac.getColorAsync(imagePreview);

      if ((!color?.isDark && color?.isLight) || (color?.isDark && !color?.isLight)) {
        if (color.isDark) linesColor = 'light';
        if (color.isLight) linesColor = 'dark';
      }
    }

    const formData = serialize({ ...form, linesColor }, { indices: true });
    const results = await post(questionRoutes.create + '/legend', formData, 'multipart/form-data');

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/posiao/banque-de-question');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Tabs defaultActiveKey="form" activeKey={tab} onChange={setTab}>
            <TabPane tab="Formulaire" key="form">
              <Meta
                loading={isProcessing}
                withImage={false}
                withQuestion={false}
                instructions={[
                  "Cliquez sur l'image afin d'ajouter une légende et la nommer",
                  'Ajouter des éléments et les nommer',
                  'Les éléments présents dans la partie "Éléments pièges" seront des pièges',
                ]}
              />
              <Legend
                loading={isProcessing}
                currentTab={tab}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
              />
            </TabPane>
            <TabPane tab="Prévisualisation" key="preview">
              <Preview type="legend">
                <LegendPreview currentTab={tab} imagePreview={imagePreview} />
              </Preview>
            </TabPane>
          </Tabs>
          {tab === 'form' && (
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
              <Button className="gray-text" disabled={isProcessing}>
                <Link to="/posiao/banque-de-question">Annuler</Link>
              </Button>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            </div>
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default CreateLegendQuestion;
