import { useMemo } from 'react';
import { Form, Select, Row, Col, Radio, Divider } from 'antd';
import { useFormContext, Controller, useWatch, useFieldArray } from 'react-hook-form';
import useSWR from 'swr';
import { agencyRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const { Item } = Form;
const { Group } = Radio;

const swrOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const generateAgencies = (data) => {
  const additional = [{ label: 'Distanciel', value: 'DISTANCIEL' }];
  let array = [...data];

  array = array.map((item) => ({
    label: item.city,
    value: item._id,
  }));

  return [array.sort((a, b) => sort(a, b, 'label')), additional.concat(array).sort((a, b) => sort(a, b, 'label'))];
};

const StudentImportFormationAgency = ({ errors }) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({ control, name: 'students' });
  const [groupMode, agencyMode] = useWatch({ control, name: ['groupMode', 'agencyMode'] });
  const { data: agenciesData, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, swrOptions);
  const [agencies, agenciesWithAdditional] = useMemo(() => generateAgencies(agenciesData?.data || []), [agenciesData]);

  const handleFilter = (inputValue, option) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <>
      <Divider>Centre</Divider>
      <Row>
        <Col span={12}>
          <Item required>
            <Controller
              control={control}
              name="agencyMode"
              render={({ field }) => (
                <Group {...field}>
                  <Radio value="global">Sélection globale</Radio>
                  <Radio value="unique">Sélection individuelle</Radio>
                </Group>
              )}
            />
          </Item>
          {agencyMode === 'global' && (
            <>
              {groupMode === 'new' && (
                <Item
                  label="Centre rattaché a la session"
                  required
                  validateStatus={errors?.agency && 'error'}
                  help={errors?.agency}
                >
                  <Controller
                    control={control}
                    name="formation.agency"
                    render={({ field }) => (
                      <Select
                        {...field}
                        filterOption={handleFilter}
                        showSearch
                        placeholder="Sélectionnez dans la liste"
                        disabled={!agencies || isAgenciesValidating}
                        loading={isAgenciesValidating}
                        options={agenciesWithAdditional}
                      />
                    )}
                  />
                </Item>
              )}
              <Item
                label="Centre rattaché aux stagiaires"
                required
                validateStatus={errors?.studentsAgency && 'error'}
                help={errors?.studentsAgency}
                tooltip="Pour sélectionner un centre différent pour chaque stagiaires, veuillez utiliser le mode « Sélection individuelle »"
              >
                <Controller
                  control={control}
                  name="formation.studentsAgency"
                  render={({ field }) => (
                    <Select
                      {...field}
                      filterOption={handleFilter}
                      showSearch
                      placeholder="Sélectionnez dans la liste"
                      disabled={!agencies || isAgenciesValidating}
                      loading={isAgenciesValidating}
                      options={agencies}
                    />
                  )}
                />
              </Item>
            </>
          )}
          {agencyMode === 'unique' && (
            <>
              {groupMode === 'new' && (
                <Item
                  label="Centre rattaché à la session"
                  required
                  validateStatus={errors?.agency && 'error'}
                  help={errors?.agency}
                >
                  <Controller
                    control={control}
                    name="formation.agency"
                    render={({ field }) => (
                      <Select
                        {...field}
                        filterOption={handleFilter}
                        showSearch
                        placeholder="Sélectionnez dans la liste"
                        disabled={!agencies || isAgenciesValidating}
                        loading={isAgenciesValidating}
                        options={agenciesWithAdditional}
                      />
                    )}
                  />
                </Item>
              )}
              {fields.map((item, index) => (
                <Item
                  key={item?.id || index}
                  label={`Centre rattaché à ${item.last_name} ${item.first_name}`}
                  required
                  validateStatus={errors?.[`students.${index}.agency`] && 'error'}
                  help={errors?.[`students.${index}.agency`]}
                >
                  <Controller
                    control={control}
                    name={`students.${index}.agency`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        filterOption={handleFilter}
                        showSearch
                        placeholder="Sélectionnez dans la liste"
                        disabled={!agencies || isAgenciesValidating}
                        loading={isAgenciesValidating}
                        options={agencies}
                      />
                    )}
                  />
                </Item>
              ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default StudentImportFormationAgency;
