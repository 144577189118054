import React, { useState } from 'react';
import { Drawer, Form, Button, Input, Select, Typography, message, Upload, Divider, Checkbox } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { serialize } from 'object-to-formdata';
import useSWR from 'swr';
import streetIndicators from '../../../../datasets/indices.json';
import streetTypes from '../../../../datasets/types_voie.json';
import states from '../../../../datasets/regions.json';
import useFetch from '../../../../hooks/useFetch';
import { agencyRoutes } from '../../../../lib/routes';

const CreateAgencyDrawer = ({ onFinish }) => {
  const { handleSubmit, control, setError, setValue, reset, clearErrors } = useForm();
  const { errors } = useFormState({ control });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const { post } = useFetch();

  const onCancel = () => {
    clearErrors();
    reset();
    setImagePreview(null);
    setIsOpen(false);
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form);
    const results = await post(agencyRoutes.create, formData, 'multipart/form-data');

    if (results.status === 201) {
      setIsOpen(false);
      onFinish();
      onCancel();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onImageChange = ({ file }) => {
    setValue('image', file.originFileObj);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagePreview(reader.result));
    reader.readAsDataURL(file.originFileObj);
  };

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button onClick={() => setIsOpen(true)} className="gray-text">
          Nouveau centre
        </Button>
      </div>
      <Drawer
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        maskClosable
        closable={false}
        width={310}
        title="Nouveau centre"
        footer={
          <div>
            <Button type="primary" onClick={handleSubmit(onSubmit)} loading={isProcessing}>
              Enregistrer
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={onCancel} disabled={isProcessing}>
              Annuler
            </Button>
          </div>
        }
      >
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item label="Image" validateStatus={errors?.image?.message && 'error'} help={errors?.image?.message}>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <Upload.Dragger
                  name={field.name}
                  maxCount={1}
                  showUploadList={false}
                  height={150}
                  disabled={isProcessing}
                  accept="image/*"
                  style={{ border: errors.image && '1px solid #ff4d4f' }}
                  onChange={(img) => onImageChange(img)}
                >
                  {imagePreview ? (
                    <img
                      style={{
                        maxWidth: 'calc(100% - 32px)',
                        maxHeight: 118,
                        display: 'block',
                        margin: '0 auto',
                      }}
                      src={imagePreview}
                      alt=""
                    />
                  ) : (
                    <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                  )}
                </Upload.Dragger>
              )}
            />
          </Form.Item>
          <Form.Item required validateStatus={errors?.imaginelab?.message && 'error'} help={errors.imaginelab?.message}>
            <Controller
              name="imaginelab"
              defaultValue={false}
              control={control}
              render={({ field }) => (
                <Checkbox {...field} disabled={isProcessing}>
                  Ce centre possède un pôle I-magineLab
                </Checkbox>
              )}
            />
          </Form.Item>
          <Divider>Adresse</Divider>
          <Form.Item
            label="Région"
            required
            validateStatus={errors?.state?.message && 'error'}
            help={errors.state?.message}
          >
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={states.map((state) => ({
                    label: state.name,
                    value: state.name,
                  }))}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Code postal"
            required
            validateStatus={errors?.postal_code?.message && 'error'}
            help={errors.postal_code?.message}
          >
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} maxLength={5} />}
            />
          </Form.Item>
          <Form.Item
            label="Commune"
            required
            validateStatus={errors?.city?.message && 'error'}
            help={errors.city?.message}
          >
            <Controller
              name="city"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Acronyme"
            required
            validateStatus={errors?.acronym?.message && 'error'}
            help={errors.acronym?.message}
          >
            <Controller
              name="acronym"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item label="Cedex" validateStatus={errors?.cedex?.message && 'error'} help={errors.cedex?.message}>
            <Controller
              name="cedex"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Numéro de voie"
            validateStatus={errors?.street_number?.message && 'error'}
            help={errors.street_number?.message}
          >
            <Controller
              name="street_number"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} maxLength={5} />}
            />
          </Form.Item>
          <Form.Item
            label="Indice de répétition"
            validateStatus={errors?.street_indicator?.message && 'error'}
            help={errors.street_indicator?.message}
          >
            <Controller
              name="street_indicator"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  allowClear
                  options={streetIndicators.map((indicator) => ({
                    label: indicator.name,
                    value: indicator.name,
                  }))}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Type de voie"
            required
            validateStatus={errors?.street_type?.message && 'error'}
            help={errors.street_type?.message}
          >
            <Controller
              name="street_type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={streetTypes.map((type) => ({
                    label: type.name,
                    value: type.name,
                  }))}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Libellé de voie"
            required
            validateStatus={errors?.street?.message && 'error'}
            help={errors.street?.message}
          >
            <Controller
              name="street"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Divider>Contact</Divider>
          <Form.Item
            label="Téléphone"
            required
            validateStatus={errors?.phone?.message && 'error'}
            help={errors.phone?.message}
          >
            <Controller
              name="phone"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} maxLength={10} />}
            />
          </Form.Item>
          <Form.Item
            label="Adresse email"
            required
            validateStatus={errors?.email?.message && 'error'}
            help={errors.email?.message}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} type="email" />}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateAgencyDrawer;
