import { Form, Drawer, Button, Space, message, Tabs, Typography, Badge } from 'antd';
import { useState, useEffect, memo } from 'react';
import { useForm, FormProvider, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { eventRoutes } from '../../../../../lib/routes';
import useFetch from '../../../../../hooks/useFetch';
import CreateDrawerMeta from './CreateDrawerMeta';
import CreateDrawerConstraint from './CreateDrawerConstraint';

const { TabPane } = Tabs;

const TabPaneTitle = memo(({ children, errorFields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const tabErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

  return (
    <Badge
      dot={tabErrors.length !== 0}
      status="error"
      title={`${tabErrors.length} erreur${tabErrors.length > 1 ? 's' : ''}`}
    >
      {children}
    </Badge>
  );
});

const CreateDrawer = ({ visible, date, onClose, onCreate }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const title = format(new Date(date), 'PPP', { locale: fr });
  const methods = useForm({ defaultValues: { should_return: false, description: '' } });
  const { post } = useFetch();

  useEffect(() => {
    if (visible) {
      methods.setValue('description', '');
      methods.setValue('start', date);
      methods.setValue('end', date);
    }
  }, [visible]);

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(eventRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      handleClose();
      onCreate();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Drawer
      size="large"
      placement="right"
      title={title}
      destroyOnClose
      visible={visible}
      onClose={handleClose}
      footer={
        <Space>
          <Button type="primary" onClick={methods.handleSubmit(onSubmit)} loading={isProcessing}>
            Enregistrer
          </Button>
          <Button onClick={handleClose} disabled={isProcessing}>
            Annuler
          </Button>
        </Space>
      }
    >
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Tabs defaultActiveKey="students">
            <TabPane tab={<TabPaneTitle errorFields={['title', 'start', 'end']}>Évènement</TabPaneTitle>} key="event">
              <CreateDrawerMeta disabled={isProcessing} />
            </TabPane>
            <TabPane tab="Restrictions" key="contraint">
              <CreateDrawerConstraint disabled={isProcessing} />
            </TabPane>
          </Tabs>
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export default CreateDrawer;
