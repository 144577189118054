import { memo } from 'react';

import { Checkbox, Col, Form, InputNumber, Row, Select } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const View = memo(({ mode, loading, dimensions }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const [maxWidth, maxHeight] = dimensions;

  return (
    <>
      <Form.Item validateStatus={!!errors?.[mode]?.hidden?.message && 'error'} help={errors?.[mode]?.hidden?.message}>
        <Controller
          {...{ control }}
          name={`${mode}.hidden`}
          render={({ field }) => (
            <Checkbox {...field} checked={field.value} disabled={loading}>
              Cacher l'image
            </Checkbox>
          )}
        />
      </Form.Item>
      <Form.Item
        label={maxWidth ? `Largeur (max : ${maxWidth}px)` : 'Largeur'}
        required
        validateStatus={!!errors?.[mode]?.width?.message && 'error'}
        help={errors?.[mode]?.width?.message}
      >
        <Row gutter={[16, 16]}>
          <Col flex="none">
            <Controller
              {...{ control }}
              name={`${mode}.width`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value === 'auto'}
                  onChange={(event) => (event.target.checked ? field.onChange('auto') : field.onChange(''))}
                  disabled={loading}
                >
                  Auto
                </Checkbox>
              )}
            />
          </Col>
          <Col flex="auto">
            <Controller
              {...{ control }}
              name={`${mode}.width`}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  value={typeof field.value === 'number' ? field.value : ''}
                  style={{ width: '100%' }}
                  disabled={field.value === 'auto' || loading}
                  min={0}
                  max={maxWidth ?? Number.MAX_SAFE_INTEGER}
                  addonAfter="px"
                />
              )}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label={maxHeight ? `Hauteur (max : ${maxHeight}px)` : 'Hauteur'}
        required
        validateStatus={!!errors?.[mode]?.height?.message && 'error'}
        help={errors?.[mode]?.height?.message}
      >
        <Row gutter={[16, 16]}>
          <Col flex="none">
            <Controller
              {...{ control }}
              name={`${mode}.height`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value === 'auto'}
                  onChange={(event) => (event.target.checked ? field.onChange('auto') : field.onChange(''))}
                  disabled={loading}
                >
                  Auto
                </Checkbox>
              )}
            />
          </Col>
          <Col flex="auto">
            <Controller
              {...{ control }}
              name={`${mode}.height`}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  value={typeof field.value === 'number' ? field.value : ''}
                  style={{ width: '100%' }}
                  disabled={field.value === 'auto' || loading}
                  min={0}
                  max={maxHeight ?? Number.MAX_SAFE_INTEGER}
                  addonAfter="px"
                />
              )}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label="Ajustement"
        required
        validateStatus={!!errors?.[mode]?.fit?.message && 'error'}
        help={errors?.[mode]?.fit?.message}
      >
        <Controller
          {...{ control }}
          name={`${mode}.fit`}
          render={({ field }) => (
            <Select
              {...field}
              size="small"
              disabled={loading}
              options={[
                { label: 'Ajusté', value: 'contain' },
                { label: 'Étiré', value: 'fill' },
                { label: 'Remplissage', value: 'cover' },
              ]}
            />
          )}
        />
      </Form.Item>
    </>
  );
});

View.displayName = 'View';
export default View;
