import { useFormContext, useFormState, useFieldArray, Controller } from 'react-hook-form';
import { Form, Checkbox, Button, message, Upload, Image, Col, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Item } = Form;

const Illustrated = ({ loading }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: illustrated, remove, append } = useFieldArray({ name: 'illustrated', control });

  const appendItem = ({ file }) => {
    const fileSize = file.size / 1024 / 1024;

    if (illustrated.length === 6) {
      message.error('Vous ne pouvez pas avoir plus de 6 choix possibles');
    } else if (fileSize > 2) {
      message.error('Ce fichier est trop lourd, le poids maximum est de 2Mo');
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => append({ image: file, valid: false, preview: reader.result }));
      reader.readAsDataURL(file);
    }
  };

  const removeItem = (index) => {
    if (illustrated.length <= 3) message.error('Vous ne pouvez pas avoir moins de 3 choix possibles');
    else remove(index);
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: 20,
        }}
      >
        {illustrated.map((illustration, index) => (
          <Col key={illustration.id}>
            <Item
              required={index <= 2}
              style={{
                width: '100%',
                padding: 10,
                border: errors?.illustrated?.[index]?.image ? '1px solid red' : '1px solid #f0f0f0',
                borderRadius: 4,
              }}
            >
              <Image
                height={200}
                width="100%"
                style={{ objectFit: 'cover' }}
                src={illustration.preview}
                alt={illustration.id}
              />
              <Row justify="space-between" align="middle" style={{ marginTop: 10 }}>
                <Controller
                  name={`illustrated.${index}.valid`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      disabled={loading}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    >
                      Bonne réponse
                    </Checkbox>
                  )}
                />
                <Button disabled={loading} icon={<DeleteOutlined />} onClick={() => removeItem(index)} />
              </Row>
              {errors?.illustrated?.[index]?.image && (
                <div style={{ color: '#ff4d4f' }}>{errors?.illustrated?.[index]?.image?.message}</div>
              )}
            </Item>
          </Col>
        ))}
        {illustrated.length < 6 && (
          <Dragger
            height={264}
            style={{ width: '100%' }}
            disabled={loading}
            showUploadList={false}
            maxCount={1}
            accept="image/*"
            beforeUpload={() => false}
            onChange={appendItem}
          >
            <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
          </Dragger>
        )}
      </div>
      {errors?.illustrated && <p style={{ color: '#ff4d4f' }}>{errors?.illustrated?.message}</p>}
    </>
  );
};

export default Illustrated;
