/**
 * @function replaceDiacritics
 * @description Supprime les accents d'une chaîne de caractères
 * @param {string} str La chaîne de caractères à convertir
 * @returns {string}
 */
export const replaceDiacritics = (str) => {
  if (typeof str !== 'string') {
    throw Error('La valeur passée doit être une chaîne de caractères');
  }

  return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};

/**
 * @function searchInString
 * @description
 * @param {*} input La chaîne de caractères à trouver
 * @param {*} text La chaîne de caractères à analyser
 * @returns {boolean} La chaîne de caractère à analyser content la valeur à trouver
 */
export const searchInString = (input, text) => {
  if (typeof input !== 'string') {
    throw Error('La valeur passée doit être une chaîne de caractères');
  }

  if (typeof text !== 'string') {
    throw Error('La valeur passée doit être une chaîne de caractères');
  }

  input = replaceDiacritics(input);
  text = replaceDiacritics(text);

  return new RegExp(input, 'i').test(text);
};
