import { Form, Select, Alert, Transfer } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import { studentRoutes, groupRoutes, agencyRoutes } from '../../../../../lib/routes';
import { useAuth } from '../../../../../authContext';
import { sort } from '../../../../../shared/utils';

const { Item } = Form;

const CreateDrawerConstraint = ({ disabled }) => {
  const { user } = useAuth();
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: studentsData, isValidating: isStudentsValidating } = useSWR(studentRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const students = [
    {
      label: 'Mes stagiaires',
      options: studentsData?.data
        ? studentsData?.data
            // .filter((item) => item.referent._id === user._id)
            .sort((a, b) => sort(a, b, 'last_name'))
            .map((item) => ({
              value: item._id,
              label: `${item.last_name.toUpperCase()} ${
                item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1)
              }`,
            }))
        : [],
    },
    {
      label: 'Autres stagiaires',
      options: studentsData?.data
        ? studentsData?.data
            // .filter((item) => item.referent._id !== user._id)
            .sort((a, b) => sort(a, b, 'last_name'))
            .map((item) => ({
              value: item._id,
              label: `${item.last_name.toUpperCase()} ${
                item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1)
              }`,
            }))
        : [],
    },
  ];
  const { data: groupsData, isValidating: isGroupsValidating } = useSWR(groupRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const groups = [
    {
      label: 'Mes sessions',
      options: groupsData?.data
        ? groupsData?.data
            .filter((item) => item.referent._id === user._id)
            .sort((a, b) => sort(a, b, 'name'))
            .map((item) => ({
              value: item._id,
              label: item.name,
            }))
        : [],
    },
    {
      label: 'Autres sessions',
      options: groupsData?.data
        ? groupsData?.data
            .filter((item) => item.referent._id !== user._id)
            .sort((a, b) => sort(a, b, 'name'))
            .map((item) => ({
              value: item._id,
              label: item.name,
            }))
        : [],
    },
  ];

  return (
    <>
      <Alert
        type="info"
        showIcon
        message="Seulement les apprenants, sessions et centres sélectionnés auront accès à cet évènement, laisser vide pour ne pas appliquer de restriction."
        style={{ marginBottom: 20 }}
      />
      <Item
        validateStatus={errors?.students?.message && 'error'}
        help={errors?.students?.message}
        label="Apprenants"
        extra="Seulement les apprenants sélectionnés auront accès à cet évènement"
      >
        <Controller
          control={control}
          name="students"
          render={({ field }) => (
            <Select
              {...field}
              disabled={!students || disabled}
              placeholder="Sélectionnez dans la liste"
              allowClear
              mode="multiple"
              showSearch={false}
              loading={isStudentsValidating}
              options={students}
            />
          )}
        />
      </Item>
      <Item
        validateStatus={errors?.groups?.message && 'error'}
        help={errors?.groups?.message}
        label="Sessions"
        extra="Seulement les sessions sélectionnées auront accès à cet évènement"
      >
        <Controller
          control={control}
          name="groups"
          render={({ field }) => (
            <Select
              {...field}
              disabled={!groups || disabled}
              placeholder="Sélectionnez dans la liste"
              allowClear
              mode="multiple"
              showSearch={false}
              loading={isGroupsValidating}
              options={groups}
            />
          )}
        />
      </Item>
      <Item
        validateStatus={errors?.agencies?.message && 'error'}
        help={errors?.agencies?.message}
        label="Centres"
        extra="Seulement les centres sélectionnés auront accès à cet évènement"
      >
        <Controller
          control={control}
          name="agencies"
          render={({ field }) => (
            <Select
              {...field}
              disabled={!agencies || disabled}
              placeholder="Sélectionnez dans la liste"
              allowClear
              mode="multiple"
              showSearch={false}
              loading={isAgenciesValidating}
              options={
                agencies?.data.map((agency) => ({
                  label: agency.city,
                  value: agency._id,
                })) || []
              }
            />
          )}
        />
      </Item>
    </>
  );
};

export default CreateDrawerConstraint;
