import { useEffect, useState } from 'react';
import { Tooltip, Modal, Button, Form, Select, message, notification } from 'antd';
import { MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { useForm, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import { userRoutes, quizRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import { sort } from '../../../../shared/utils';
import { searchInString } from '../../../../lib/helpers';

const { Item } = Form;

const SendResultsModal = ({ quizId }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });
  const { data: users, isValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { post } = useFetch();

  useEffect(() => {
    if (!isOpen) {
      methods.reset();
    }
  }, [isOpen]);

  const onCancel = () => {
    methods.clearErrors();
    methods.reset();
    setIsOpen(false);
  };

  const sendMails = async (form) => {
    const key = Math.floor(Math.random() * 99999999);

    notification.open({
      message: `Envoi du PDF à ${form.recipients.length} personne(s) en cours...`,
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await post(quizRoutes.sendResults + `/${quizId}`, JSON.stringify(form));

    if (results.status === 200) {
      methods.reset();
      setIsOpen(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    notification.close(key);
  };

  return (
    <>
      <Tooltip title="Envoyer le PDF" destroyTooltipOnHide={{ keepParent: false }}>
        <Button className="gray-text" onClick={() => setIsOpen(true)} icon={<MailOutlined />} />
      </Tooltip>

      <Modal
        title="Envoyer le PDF"
        visible={isOpen}
        footer={
          <>
            <Button style={{ marginLeft: 8 }} onClick={() => setIsOpen(false)} disabled={isProcessing}>
              Annuler
            </Button>
            <Button type="primary" onClick={methods.handleSubmit(sendMails)} loading={isProcessing}>
              Envoyer
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <Form noValidate layout="vertical">
          <Item validateStatus={errors?.recipients?.message && 'error'} help={errors?.recipients?.message}>
            <Controller
              name="recipients"
              control={methods.control}
              render={({ field }) => (
                <Select
                  {...field}
                  mode="tags"
                  disabled={!users || isValidating || isProcessing}
                  style={{ width: '100%' }}
                  placeholder="Sélectionnez dans la liste"
                  filterOption={(input, option) => searchInString(input, option.label)}
                  options={
                    users &&
                    users.data
                      .sort((a, b) => sort(a, b, 'last_name', ''))
                      .map((user) => ({
                        label: `${user.last_name.toUpperCase()} ${
                          user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                        }`,
                        value: user._id,
                      }))
                  }
                />
              )}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default SendResultsModal;
