import React from 'react';
import { Row, Col, Card, Typography, Button, Space } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useAuth } from '../authContext';

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Typography.Title level={3} className="gray-text" style={{ margin: 0, marginBottom: 20 }}>
              Bon retour parmi nous {user.first_name} !
            </Typography.Title>
            <Row gutter={[24, 24]}>
              <Col span={6}>
                <Card
                  size="small"
                  title="Arinfo"
                  style={{ height: '100%' }}
                  extra={
                    <a href="https://arinfo.fr/" target="_blank" rel="noopener noreferrer">
                      <Button icon={<LinkOutlined />} />
                    </a>
                  }
                >
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Typography.Link
                      href="https://www.facebook.com/centre.formation.arinfo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.instagram.com/arinfo_centre/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.linkedin.com/school/arinfo/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.youtube.com/channel/UCJg7IePNBH-GKb8DtIQG2XQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      YouTube
                    </Typography.Link>
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  size="small"
                  title="Formao"
                  style={{ height: '100%' }}
                  extra={
                    <a href="https://formao.fr/" target="_blank" rel="noopener noreferrer">
                      <Button icon={<LinkOutlined />} />
                    </a>
                  }
                >
                  <Typography.Text type="secondary">Aucun réseau</Typography.Text>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  size="small"
                  title="OpenSpace3D"
                  style={{ height: '100%' }}
                  extra={
                    <a href="https://www.openspace3d.com/" target="_blank" rel="noopener noreferrer">
                      <Button icon={<LinkOutlined />} />
                    </a>
                  }
                >
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Typography.Link
                      href="https://www.facebook.com/openspace3dfr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.instagram.com/openspace3d/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </Typography.Link>
                    <Typography.Link href="https://twitter.com/OpenSpace3D" target="_blank" rel="noopener noreferrer">
                      X
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.youtube.com/user/Openspace3D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      YouTube
                    </Typography.Link>
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  size="small"
                  title="I-magineLAB"
                  style={{ height: '100%' }}
                  extra={
                    <a href="https://i-maginelab.com/" target="_blank" rel="noopener noreferrer">
                      <Button icon={<LinkOutlined />} />
                    </a>
                  }
                >
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Typography.Link
                      href="https://www.facebook.com/ImagineLabParis"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </Typography.Link>
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  size="small"
                  title="I-magineXR"
                  style={{ height: '100%' }}
                  extra={
                    <a href="https://i-maginexr.com/" target="_blank" rel="noopener noreferrer">
                      <Button icon={<LinkOutlined />} />
                    </a>
                  }
                >
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Typography.Link
                      href="https://www.facebook.com/profile.php?id=61559538406667"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.instagram.com/i_maginexr_"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.linkedin.com/company/i-maginexr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </Typography.Link>
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  size="small"
                  title="I-maginer"
                  style={{ height: '100%' }}
                  extra={
                    <a href="https://www.i-maginer.com/" target="_blank" rel="noopener noreferrer">
                      <Button icon={<LinkOutlined />} />
                    </a>
                  }
                >
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Typography.Link
                      href="https://www.facebook.com/ImaginerParis/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </Typography.Link>
                    <Typography.Link
                      href="https://www.linkedin.com/company/i-maginer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </Typography.Link>
                    <Typography.Link href="https://x.com/i_maginer" target="_blank" rel="noopener noreferrer">
                      X
                    </Typography.Link>
                  </Space>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
