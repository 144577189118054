import { Button, Modal, Space, Table, notification, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { format, subWeeks } from 'date-fns';
import useSWR from 'swr';
import { satisfactionReportRoutes, studentRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const SatisfactionReportModal = ({ data, onClose }) => {
  const { data: students, isValidating } = useSWR(studentRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: satisfactionReports } = useSWR(satisfactionReportRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  // prettier-ignore
  const filteredStudents = students?.data?.filter((student) => data?.students?.includes(student?._id)) || [];
  const { user } = useAuth();
  const { get } = useFetch();

  const handleDownload = async () => {
    const ids = data?.students?.join(',');

    if (ids) {
      notification.open({
        key: 'download',
        message: 'Téléchargement des bilans de satisfaction',
        description: 'Cela peu prendre quelques minutes.',
        icon: <LoadingOutlined />,
        closeIcon: <></>,
        duration: null,
      });

      const results = await get(satisfactionReportRoutes.downloadAll + '/' + ids);

      if (results.status === 201) {
        const blob = new Blob([Buffer.from(results.data.data)], { type: 'application/zip' });
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = `${data.name}.zip`;
        link.click();

        notification.success({
          key: 'download',
          message: 'Téléchargement des bilans de satisfaction',
          description: 'Téléchargement terminé.',
          duration: 3,
        });
      } else {
        if (results.message) {
          notification.error({
            key: 'download',
            message: 'Téléversement du fichier',
            description: results.message,
            duration: 3,
          });
        }
      }
    }
  };

  const columns = [
    {
      title: 'Nom',
      key: 'last_name',
      dataIndex: 'last_name',
      render: (record) => record.toUpperCase(),
    },
    {
      title: 'Prénom',
      key: 'first_name',
      dataIndex: 'first_name',
      render: (record) => record.charAt(0).toUpperCase() + record.slice(1),
    },
    {
      title: 'Status',
      key: 'state',
      width: 150,
      render: (record) => {
        const formation = record?.formations?.find(({ group }) => group?.toString() === data?._id?.toString());
        const match = satisfactionReports?.data?.find(
          (report) =>
            report.studentId?._id?.toString() === record._id?.toString() &&
            report.student?.formationId?.toString() === formation._id.toString(),
        );

        if (match) {
          if (match?.report?.results?.length !== 0) {
            return <Tag color="success">Complété</Tag>;
          } else {
            return <Tag color="processing">En attente</Tag>;
          }
        } else {
          let sendDate = new Date();

          if (formation.custom) {
            sendDate = new Date(formation.endAt);
          } else {
            sendDate = subWeeks(new Date(formation.endAt), 3);
          }

          return <Tag>Envoi le : {format(sendDate, 'dd/MM/yyyy')}</Tag>;
        }
      },
    },
  ];

  return (
    <Modal width={700} title={`Bilans de satisfaction - ${data?.name}`} open={data} onCancel={onClose} onOk={onClose}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {checkAuthorization(user, 'formao', 'satisfactionReports', 'download-set') && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleDownload}>Télécharger les bilans</Button>
          </div>
        )}
        <Table
          size="small"
          bordered
          rowKey={(row) => row._id}
          columns={columns}
          dataSource={filteredStudents || []}
          loading={(!students, isValidating)}
          pagination={false}
        />
      </Space>
    </Modal>
  );
};

export default SatisfactionReportModal;
