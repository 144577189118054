import { useEffect, useState, forwardRef } from 'react';
import { Modal, Form, Input, Typography, message, Menu } from 'antd';
import { Controller, useForm, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import { typeRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const { Item } = Form;
const { Paragraph } = Typography;

const EditModal = forwardRef(({ onFinish, typeId }, ref) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { data: type, isValidating } = useSWR(isVisible && typeId ? typeRoutes.default + '/' + typeId : null);
  const { control, handleSubmit, setError, setValue } = useForm();
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (type?.data) {
      setValue('title', type?.data?.title);
      setValue('acronym', type?.data?.acronym);
    }
  }, [type]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(typeRoutes.default + '/' + typeId, JSON.stringify(form));

    if (results.status === 200) {
      onFinish();
      setIsVisible(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Menu.Item ref={ref} key="edit" onClick={() => setIsVisible(true)}>
        Modifier
      </Menu.Item>
      <Modal
        title="Modifier la catégorie"
        visible={isVisible}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{ loading: isProcessing, disabled: !type || isProcessing || isValidating }}
        onCancel={() => setIsVisible(false)}
        cancelButtonProps={{ disabled: !type || isProcessing || isValidating }}
        destroyOnClose
      >
        <Form noValidate layout="vertical">
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Item
            label="Intitulé"
            validateStatus={errors?.title?.message && 'error'}
            help={errors?.title?.message}
            required
          >
            <Controller
              name="title"
              control={control}
              render={({ field }) => <Input {...field} disabled={!type || isProcessing || isValidating} />}
            />
          </Item>
          <Item
            label="Acronyme"
            validateStatus={errors?.acronym?.message && 'error'}
            help={errors?.acronym?.message}
            required
          >
            <Controller
              name="acronym"
              control={control}
              render={({ field }) => <Input {...field} disabled={!type || isProcessing || isValidating} />}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
});

EditModal.displayName = 'EditModal';
export default EditModal;
