import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { Button, Upload } from 'antd';
import useFetch from '../../hooks/useFetch';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';

const CloudinaryVideoView = ({ node, updateAttributes }) => {
  const [uploading, setUploading] = useState(false);
  const { post } = useFetch();

  return (
    <NodeViewWrapper className="cloudinary-video">
      <figure data-video="">
        <iframe src={node.attrs.src} />
        <div className="cloudinary-video-footer">
          <Upload
            multiple={false}
            maxCount={1}
            accept=".vtt"
            showUploadList={false}
            action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload`}
            data={async (file) => {
              // Récupère une signature d'upload
              const url = `${process.env.REACT_APP_BASE_URL_API_URL}/v2/medias/raw/signature`;
              const response = await post(url);

              if (response.status === 200) {
                const { timestamp, signature } = response.data;

                return {
                  file: file,
                  api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
                  timestamp,
                  signature,
                };
              }
            }}
            beforeUpload={(file) => {
              console.log(file);

              if (file.type !== 'text/vtt' && !file.name.endsWith('vtt')) {
                return Upload.LIST_IGNORE;
              }

              const fileSize = file.size / 1024 / 1024;

              if (fileSize > 10) {
                message.error('Téléversement limité à 10 Mo');
                return Upload.LIST_IGNORE;
              }
            }}
            onChange={({ fileList }) => {
              console.log('upload');
              setUploading(true);
              const awaiting = fileList.filter((item) => item.status !== 'done');

              if (awaiting.length === 0) {
                const src = node.attrs.src.split('&source');
                const url = `${src}&source[textTracks][captions][label]=French&source[textTracks][captions][language]=fr&source[textTracks][captions][url]=https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload/${fileList[0].response.public_id}&source[textTracks][captions][default]=true`;

                console.log('upload ok');
                updateAttributes({ src: url });
                setUploading(false);
              }
            }}
          >
            <Button
              icon={<UploadOutlined />}
              type={node.attrs.src.endsWith('&source[textTracks][captions][default]=true') ? 'default' : 'primary'}
              loading={uploading}
            >
              {node.attrs.src.endsWith('&source[textTracks][captions][default]=true')
                ? 'Remplacer les sous-titres (.vtt)'
                : 'Ajouter des sous-titres (.vtt)'}
            </Button>
          </Upload>
          <NodeViewContent as="figcaption" className="cloudinary-video-caption" />
        </div>
      </figure>
    </NodeViewWrapper>
  );
};

export default CloudinaryVideoView;
