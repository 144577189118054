import React, { useState, useEffect, useCallback, memo } from 'react';
import { Button, Tag, Card, List, Image, Input, Tooltip, Popconfirm, Select, Space, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined, EyeOutlined, EyeInvisibleOutlined, SearchOutlined } from '@ant-design/icons';
import qs from 'qs';
import { debounce } from 'lodash';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import CreateQuestionModal from './components/CreateQuestionModal';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { questionRoutes, typeRoutes } from '../../../lib/routes';
import Easy from '../../../components/icons/Easy';
import Medium from '../../../components/icons/Medium';
import Hard from '../../../components/icons/Hard';
import CheckboxIllu from '../../../assets/posiao/checkbox.svg';
import IllustratedIllu from '../../../assets/posiao/illustrated.svg';
import CompletionIllu from '../../../assets/posiao/completion.svg';
import ConnectIllu from '../../../assets/posiao/connect.svg';
import OrderingIllu from '../../../assets/posiao/ordering.svg';
import OrganizeIllu from '../../../assets/posiao/organize.svg';
import LegendIllu from '../../../assets/posiao/legend.svg';
import TrueFalseIllu from '../../../assets/posiao/trueFalse.svg';
import { sort } from '../../../shared/utils';

const { Item } = List;
const { TabPane } = Tabs;

const questionTypes = [
  {
    acronym: 'checkbox',
    label: 'Cases à cocher',
    image: CheckboxIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/cases-a-cocher',
  },
  {
    acronym: 'illustrated',
    label: 'Cases à cocher illustrées',
    image: IllustratedIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/cases-a-cocher-illustrees',
  },
  // {
  //   acronym: 'completion',
  //   label: 'Texte à compléter',
  //   image: CompletionIllu,
  //   updateUrl: '/posiao/banque-de-question/modifier-une-question/texte-a-completer',
  // },
  {
    acronym: 'legend',
    label: 'Légendes à compléter',
    image: LegendIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/legendes-a-completer',
  },
  {
    acronym: 'organize',
    label: 'Éléments à trier',
    image: OrganizeIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/elements-a-trier',
  },
  {
    acronym: 'ordering',
    label: 'Liste à ordonner',
    image: OrderingIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/liste-a-ordonner',
  },
  {
    acronym: 'trueFalse',
    label: 'Vrai ou faux',
    image: TrueFalseIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/vrai-ou-faux',
  },
  {
    acronym: 'connect',
    label: 'Points à relier',
    image: ConnectIllu,
    updateUrl: '/posiao/banque-de-question/modifier-une-question/points-a-reliers',
  },
];

const difficulties = [
  { label: 'Facile', value: 'easy' },
  { label: 'Moyen', value: 'medium' },
  { label: 'Difficile', value: 'hard' },
];

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const PageContent = memo(
  ({
    loading,
    questions,
    types,
    user,
    onDelete,
    values,
    onEnable,
    onDisable,
    onChange,
    searchValue,
    onSearchChange,
    isTypesValidating,
  }) => (
    <div style={{ border: '1px solid rgba(0,0,0,.06)', borderRadius: '2px', overflow: 'hidden' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#FAFAFA',
          padding: '16px',
        }}
      >
        <Input
          placeholder="Rechercher"
          style={{ width: 200 }}
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
          suffix={<SearchOutlined />}
          allowClear={true}
          disabled={loading}
        />
        <Space>
          <Select
            disabled={loading}
            value={values.type}
            placeholder="Types de questions"
            style={{ width: 200 }}
            options={questionTypes.map((type) => ({ label: type.label, value: type.acronym }))}
            allowClear
            onChange={(value) => onChange('type', value)}
          />
          <Select
            disabled={loading}
            value={values.difficulty}
            placeholder="Difficultés"
            style={{ width: 200 }}
            options={difficulties.map((difficulty) => ({ label: difficulty.label, value: difficulty.value }))}
            allowClear
            onChange={(value) => onChange('difficulty', value)}
          />
          <Select
            disabled={loading || !types || isTypesValidating}
            value={values.pro_title}
            placeholder="Catégories de quiz"
            style={{ width: 200 }}
            options={types?.data
              .sort((a, b) => sort(a, b, 'title', ''))
              .map((type) => ({ label: type.title, value: type._id }))}
            allowClear
            onChange={(value) => onChange('pro_title', value)}
          />
        </Space>
      </div>
      <List
        loading={loading}
        dataSource={questions || []}
        pagination={{ ...tablePagination(questions), size: 'small', style: { marginBottom: 16, marginRight: 16 } }}
        renderItem={(item) => (
          <Item
            style={{ padding: '16px' }}
            actions={[
              item.disabled
                ? checkAuthorization(user, 'posiao', 'questions', 'enable') && (
                    <Tooltip title="Activer" destroyTooltipOnHide={{ keepParent: false }}>
                      <Popconfirm
                        {...popconfirmProps}
                        cancelButtonProps={{ disabled: loading }}
                        onConfirm={() => onEnable(item._id)}
                      >
                        <Button key="enable" icon={<EyeInvisibleOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                  )
                : checkAuthorization(user, 'posiao', 'questions', 'disable') && (
                    <Tooltip title="Désactiver" destroyTooltipOnHide={{ keepParent: false }}>
                      <Popconfirm
                        {...popconfirmProps}
                        cancelButtonProps={{ disabled: loading }}
                        onConfirm={() => onDisable(item._id)}
                      >
                        <Button key="disable" icon={<EyeOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                  ),
              checkAuthorization(user, 'posiao', 'questions', 'update') && (
                <Tooltip title="Modifier" destroyTooltipOnHide={{ keepParent: false }}>
                  <Link to={questionTypes?.find((type) => type.acronym === item.type)?.updateUrl + '/' + item._id}>
                    <Button key="edit" icon={<EditOutlined />} />
                  </Link>
                </Tooltip>
              ),
              checkAuthorization(user, 'posiao', 'questions', 'delete') && (
                <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm
                    {...popconfirmProps}
                    cancelButtonProps={{ disabled: loading }}
                    onConfirm={() => onDelete(item._id)}
                  >
                    <Button key="delete" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              ),
            ]}
          >
            <div style={{ width: '100%' }} className="question-list-item">
              <Item.Meta
                avatar={
                  <Image
                    preview={false}
                    width={120}
                    src={questionTypes?.find((type) => type.acronym === item.type)?.image}
                  />
                }
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.difficulty === 'hard' ? (
                      <Hard width={35} style={{ marginRight: 10 }} />
                    ) : item.difficulty === 'medium' ? (
                      <Medium width={35} style={{ marginRight: 10 }} />
                    ) : (
                      <Easy width={35} style={{ marginRight: 10 }} />
                    )}

                    {questionTypes?.find((type) => type.acronym === item.type)?.label}
                  </div>
                }
                description={
                  <>
                    <p>{item?.question || item?.description}</p>
                    <div className="question-item-tags">
                      {item?.trainings?.map((training) => (
                        <Tag key={training._id}>{training?.acronym}</Tag>
                      ))}
                    </div>
                  </>
                }
              />
            </div>
          </Item>
        )}
      />
    </div>
  ),
);

PageContent.displayName = 'PageContent';

const QuestionsList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [values, setValues] = useState({
    query: null,
    difficulty: null,
    pro_title: null,
    type: null,
    string: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: questions, isValidating, mutate } = useSWR(`${questionRoutes.default}${values.string}`);
  const { data: types, isValidating: isTypesValidating } = useSWR(typeRoutes.default);
  const enabledQuestions = questions?.data?.filter((question) => !question.disabled) || [];
  const disabledQuestions = questions?.data?.filter((question) => question.disabled) || [];
  const { user } = useAuth();
  const { remove, put } = useFetch();

  const setSearch = (value) => {
    setValues((rest) => ({ ...rest, query: value === '' ? null : value }));
  };

  const debounceSearch = useCallback(debounce(setSearch, 300), []);

  const handleSetSearch = (value) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  useEffect(() => {
    const string = qs.stringify(
      {
        query: values.query,
        difficulty: values.difficulty,
        pro_title: values.pro_title,
        type: values.type,
      },
      { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true },
    );

    setValues((rest) => ({ ...rest, string }));
  }, [values.query, values.difficulty, values.pro_title, values.type]);

  const onEnable = async (id) => {
    setIsProcessing(true);

    const results = await put(questionRoutes.enable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDisable = async (id) => {
    setIsProcessing(true);

    const results = await put(questionRoutes.disable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(questionRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onChange = (name, value) => {
    setValues((rest) => ({ ...rest, [name]: value }));
  };

  return (
    <Card>
      {checkAuthorization(user, 'posiao', 'questions', 'create') && (
        <div style={{ marginBottom: 20, textAlign: 'right' }}>
          <CreateQuestionModal />
        </div>
      )}
      <Tabs defaultActiveKey="enabled">
        <TabPane tab={`Actives (${enabledQuestions.length})`} key="enabled">
          <PageContent
            questions={enabledQuestions}
            loading={!questions || isValidating || isProcessing}
            values={values}
            onDelete={onDelete}
            onEnable={onEnable}
            onDisable={onDisable}
            onChange={onChange}
            searchValue={searchValue}
            onSearchChange={handleSetSearch}
            types={types}
            isTypesValidating={isTypesValidating}
            user={user}
          />
        </TabPane>
        <TabPane tab={`Inactives (${disabledQuestions.length})`} key="disabled">
          <PageContent
            questions={disabledQuestions}
            loading={!questions || isValidating || isProcessing}
            user={user}
            values={values}
            onDelete={onDelete}
            onEnable={onEnable}
            onDisable={onDisable}
            onChange={onChange}
            searchValue={searchValue}
            onSearchChange={handleSetSearch}
            types={types}
            isTypesValidating={isTypesValidating}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default QuestionsList;
