import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Form, Button, Space, Typography, message, Input, Divider, Select, Upload, Row, Col, Card } from 'antd';
import ImgCrop from 'antd-img-crop';
import { serialize } from 'object-to-formdata';
import { Link, useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import types from '../../../datasets/project_types.json';
import { projectRoutes, trainingRoutes, agencyRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const ProjectUpdate = () => {
  const { id } = useParams();
  const { data: project, isValidating } = useSWR(projectRoutes.findOne + '/id/' + id);
  const { data: agencies } = useSWR(agencyRoutes.default);
  const { data: trainings } = useSWR(trainingRoutes.default);
  const { handleSubmit, setError, control, setValue, register } = useForm();
  const { errors } = useFormState({ control });
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagesPreview, setImagesPreview] = useState({
    image: null,
    gallery_1: null,
    gallery_2: null,
    gallery_3: null,
    gallery_4: null,
  });
  const history = useHistory();
  const { put } = useFetch();

  useEffect(() => {
    register('gallery');
  }, [register]);

  useEffect(() => {
    if (project && project.data) {
      setValue('title', project.data.title);
      setValue('description', project.data.description);
      setValue('type', project.data.type);
      setValue('agency', project.data.agency ? project.data.agency._id : null);
      setValue('training', project.data.training ? project.data.training._id : null);
      setValue('author', project.data.author);
      setValue('seo_description', project.data.seo.description);
      setValue('seo_tags', project.data.seo.tags);

      if (project.data.image) {
        setImagesPreview((rest) => ({
          ...rest,
          image: project.data.image.secure_url,
        }));
      }

      if (project.data.gallery.gallery_1) {
        setImagesPreview((rest) => ({
          ...rest,
          gallery_1: project.data.gallery.gallery_1.secure_url,
        }));
      }

      if (project.data.gallery.gallery_2) {
        setImagesPreview((rest) => ({
          ...rest,
          gallery_2: project.data.gallery.gallery_2.secure_url,
        }));
      }

      if (project.data.gallery.gallery_3) {
        setImagesPreview((rest) => ({
          ...rest,
          gallery_3: project.data.gallery.gallery_3.secure_url,
        }));
      }

      if (project.data.gallery.gallery_4) {
        setImagesPreview((rest) => ({
          ...rest,
          gallery_4: project.data.gallery.gallery_4.secure_url,
        }));
      }
    }
  }, [project]);

  const onCropImageChange = (file) => {
    setValue('image', file);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagesPreview((rest) => ({ ...rest, image: reader.result })));
    reader.readAsDataURL(file);
  };

  const onImageChange = (name, { file }) => {
    setValue(name, file.originFileObj);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagesPreview((rest) => ({ ...rest, [name]: reader.result })));
    reader.readAsDataURL(file.originFileObj);
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(projectRoutes.update + '/' + id, formData, 'multipart/form-data');

    if (results.status === 200) {
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await put(projectRoutes.update + '/' + id, formData, 'multipart/form-data');

    if (results.status === 200) {
      history.push('/arinfo/galerie');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Form noValidate layout="vertical">
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Form.Item
          label="Image de couverture"
          required
          validateStatus={errors?.image?.message && 'error'}
          help={errors?.image?.message}
        >
          <Controller
            control={control}
            name="image"
            render={({ field }) => (
              <ImgCrop
                aspect={270 / 230}
                rotate
                zoom
                modalTitle="Modifier l'image"
                modalCancel="Annuler"
                modalOk="Confirmer"
                onModalOk={onCropImageChange}
              >
                <Upload.Dragger
                  name={field.name}
                  maxCount={1}
                  showUploadList={false}
                  height={150}
                  disabled={!project || isValidating || isProcessing}
                  accept="image/*"
                  style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                >
                  {imagesPreview.image ? (
                    <img
                      style={{
                        maxWidth: 'calc(100% - 32px)',
                        maxHeight: 118,
                        display: 'block',
                        margin: '0 auto',
                      }}
                      src={imagesPreview.image}
                      alt=""
                    />
                  ) : (
                    <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                  )}
                </Upload.Dragger>
              </ImgCrop>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Titre"
          required
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
        >
          <Controller
            control={control}
            name="title"
            render={({ field }) => <Input {...field} disabled={!project || isValidating || isProcessing} />}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          required
          validateStatus={errors?.description?.message && 'error'}
          help={errors?.description?.message}
          extra="Un paragraphe décrivant le projet dans tous ses détails"
        >
          <Controller
            control={control}
            name="description"
            render={({ field }) => <Input.TextArea {...field} disabled={!project || isValidating || isProcessing} />}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Type de projet"
              required
              validateStatus={errors?.type?.message && 'error'}
              help={errors?.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!project || isValidating || isProcessing}
                    placeholder="Sélectionnez dans la liste"
                    allowClear
                    options={types.map((type) => ({
                      label: type.label,
                      value: type.key,
                    }))}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Centre"
              required
              validateStatus={errors?.agency?.message && 'error'}
              help={errors?.agency?.message}
            >
              <Controller
                name="agency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!agencies || isProcessing}
                    placeholder="Sélectionnez dans la liste"
                    allowClear
                    options={
                      agencies?.data.map((agency) => ({
                        label: agency.city,
                        value: agency._id,
                      })) || []
                    }
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Formation"
              required
              validateStatus={errors?.training?.message && 'error'}
              help={errors?.training?.message}
            >
              <Controller
                name="training"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!trainings || isProcessing}
                    placeholder="Sélectionnez dans la liste"
                    allowClear
                    options={
                      trainings?.data.map((training) => ({
                        label: training.title,
                        value: training._id,
                      })) || []
                    }
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Auteur"
              validateStatus={errors?.author?.message && 'error'}
              help={errors?.author?.message}
            >
              <Controller
                control={control}
                name="author"
                render={({ field }) => <Input {...field} disabled={!project || isValidating || isProcessing} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Galerie"
          required
          validateStatus={errors?.[Object.keys(errors).find((i) => i.startsWith('gallery'))]?.message && 'error'}
          help={errors?.[Object.keys(errors).find((i) => i.startsWith('gallery'))]?.message}
          extra="1 image minimum est requise, 4 images max peuvent être insérées"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <Controller
                control={control}
                name="gallery_1"
                render={({ field }) => (
                  <Upload.Dragger
                    name={field.name}
                    maxCount={1}
                    showUploadList={false}
                    height={150}
                    disabled={!project || isValidating || isProcessing}
                    accept="image/*"
                    style={{
                      border: errors?.gallery?.message || errors?.gallery_1?.message ? '1px solid #ff4d4f' : null,
                    }}
                    onChange={(img) => onImageChange('gallery_1', img)}
                  >
                    {imagesPreview.gallery_1 ? (
                      <img
                        style={{
                          maxWidth: 'calc(100% - 32px)',
                          maxHeight: 118,
                          display: 'block',
                          margin: '0 auto',
                        }}
                        src={imagesPreview.gallery_1}
                        alt=""
                      />
                    ) : (
                      <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                    )}
                  </Upload.Dragger>
                )}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Controller
                control={control}
                name="gallery_2"
                render={({ field }) => (
                  <Upload.Dragger
                    name={field.name}
                    maxCount={1}
                    showUploadList={false}
                    height={150}
                    disabled={!project || isValidating || isProcessing}
                    accept="image/*"
                    style={{
                      border: errors?.gallery?.message || errors?.gallery_2?.message ? '1px solid #ff4d4f' : null,
                    }}
                    onChange={(img) => onImageChange('gallery_2', img)}
                  >
                    {imagesPreview.gallery_2 ? (
                      <img
                        style={{
                          maxWidth: 'calc(100% - 32px)',
                          maxHeight: 118,
                          display: 'block',
                          margin: '0 auto',
                        }}
                        src={imagesPreview.gallery_2}
                        alt=""
                      />
                    ) : (
                      <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                    )}
                  </Upload.Dragger>
                )}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Controller
                control={control}
                name="gallery_3"
                render={({ field }) => (
                  <Upload.Dragger
                    name={field.name}
                    maxCount={1}
                    showUploadList={false}
                    height={150}
                    disabled={!project || isValidating || isProcessing}
                    accept="image/*"
                    style={{
                      border: errors?.gallery?.message || errors?.gallery_3?.message ? '1px solid #ff4d4f' : null,
                    }}
                    onChange={(img) => onImageChange('gallery_3', img)}
                  >
                    {imagesPreview.gallery_3 ? (
                      <img
                        style={{
                          maxWidth: 'calc(100% - 32px)',
                          maxHeight: 118,
                          display: 'block',
                          margin: '0 auto',
                        }}
                        src={imagesPreview.gallery_3}
                        alt=""
                      />
                    ) : (
                      <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                    )}
                  </Upload.Dragger>
                )}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Controller
                control={control}
                name="gallery_4"
                render={({ field }) => (
                  <Upload.Dragger
                    name={field.name}
                    maxCount={1}
                    showUploadList={false}
                    height={150}
                    disabled={!project || isValidating || isProcessing}
                    accept="image/*"
                    style={{
                      border: errors?.gallery?.message || errors?.gallery_4?.message ? '1px solid #ff4d4f' : null,
                    }}
                    onChange={(img) => onImageChange('gallery_4', img)}
                  >
                    {imagesPreview.gallery_4 ? (
                      <img
                        style={{
                          maxWidth: 'calc(100% - 32px)',
                          maxHeight: 118,
                          display: 'block',
                          margin: '0 auto',
                        }}
                        src={imagesPreview.gallery_4}
                        alt=""
                      />
                    ) : (
                      <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                    )}
                  </Upload.Dragger>
                )}
              />
            </Col>
          </Row>
        </Form.Item>
        <Divider>Référencement (SEO)</Divider>
        <Form.Item
          label="Description"
          required
          validateStatus={errors?.seo_description?.message && 'error'}
          help={errors?.seo_description?.message}
          extra="Une description rapide du projet (longueur max : 160 caractères)"
        >
          <Controller
            control={control}
            name="seo_description"
            render={({ field }) => (
              <Input {...field} disabled={!project || isValidating || isProcessing} maxLength={160} />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Mots-clés"
          validateStatus={errors?.seo_tags?.message && 'error'}
          help={errors?.seo_tags?.message}
          extra="Utilisez la touche Entrée pour ajouter plusieurs mots-clés"
        >
          <Controller
            control={control}
            name="seo_tags"
            render={({ field }) => (
              <Select
                {...field}
                mode="tags"
                disabled={!project || isValidating || isProcessing}
                dropdownStyle={{ display: 'none' }}
              />
            )}
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={!project || isValidating || isProcessing}>
            <Link to="/arinfo/galerie">Annuler</Link>
          </Button>
          <Space>
            <Button onClick={handleSubmit(onSubmit)} loading={!project || isValidating || isProcessing}>
              Enregistrer
            </Button>
            <Button
              onClick={handleSubmit(onSubmitAndLeave)}
              type="primary"
              loading={!project || isValidating || isProcessing}
            >
              Enregistrer et quitter
            </Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default ProjectUpdate;
