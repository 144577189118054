import { useFormContext, Controller } from 'react-hook-form';
import { Typography } from 'antd';
import { memo } from 'react';

const ProgramEditTitle = memo(({ fieldName }) => {
  const { control } = useFormContext();

  const handleClick = (event) => {
    if (event.target.className !== 'ant-typography') {
      event.stopPropagation();
    }
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <Typography.Text editable={{ onChange: field.onChange, tooltip: 'Renommer' }} onClick={handleClick}>
          {field.value}
        </Typography.Text>
      )}
    />
  );
});

ProgramEditTitle.displayName = 'ProgramEditTitle';
export default ProgramEditTitle;
