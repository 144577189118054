import { useState, useRef, memo } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { DeleteOutlined, DragOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Space, Typography, InputNumber, Popconfirm, Switch, Tag, Tooltip } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import FAQQuestion from './FAQQuestion';
import EditableTitle from './EditableTitle';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const EmptySection = ({ onDrop }) => {
  const [, drop] = useDrop({
    accept: 'QUESTION',
    drop: (item, monitor) => {
      onDrop(item);
    },
  });

  return (
    <div ref={drop} style={{ height: 56, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography.Text type="secondary">Aucune question</Typography.Text>
    </div>
  );
};

const FAQSection = memo(
  ({ data, isEditing, onFinish, moveQuestion, move, index, onDelete, onMoveQuestionInSection, disabled, ...props }) => {
    const [addCount, setAddCount] = useState(1);
    const [openKeys, setOpenKeys] = useState([]);
    const { control, getValues, setValue } = useFormContext();
    const { user } = useAuth();
    const {
      fields: questions,
      append,
      swap,
      remove,
      insert,
    } = useFieldArray({ control, name: `sections.${index}.questions` });
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [, drop] = useDrop({
      accept: 'SECTION',
      drop: (item, monitor) => {
        if (item.index === index) return;

        move(item.index, index);
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'SECTION',
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const popconfirmProps = {
      title: 'Êtes-vous sûr ?',
      cancelText: 'Non',
      okText: 'Oui',
      placement: 'left',
    };

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    const handleSwapQuestions = (source, destination) => {
      swap(source.question, destination.question);
    };

    const handleMoveQuestion = (source, destination) => {
      const sourceQuestionsArray = [...getValues(`sections.${source.section}.questions`)];
      const sourceQuestion = sourceQuestionsArray[source.question];

      sourceQuestionsArray.splice(source.question, 1);

      setValue(`sections.${source.section}.questions`, sourceQuestionsArray);
      insert(destination.question, sourceQuestion);
    };

    const handleDropInEmpty = (source) => {
      const sourceQuestionsArray = [...getValues(`sections.${source.sectionIndex}.questions`)];
      const sourceQuestion = sourceQuestionsArray[source.index];

      sourceQuestionsArray.splice(source.index, 1);

      setValue(`sections.${source.sectionIndex}.questions`, sourceQuestionsArray);
      append(sourceQuestion);
    };

    const closeQuestionPanel = (key) => {
      if (openKeys) {
        const newOpenKeys = [...openKeys];
        const index = newOpenKeys.indexOf(key);

        newOpenKeys.splice(index, 1);

        setOpenKeys(newOpenKeys);
      }
    };

    const appendQuestions = () => {
      let toAppend = [];

      const defaultQuestion = {
        question: 'Question par défaut',
        answer: '<p>Réponse par défaut</p>',
        disabled: false,
      };

      for (let i = 0; i < addCount; i++) {
        toAppend.push(defaultQuestion);
      }

      append(toAppend);
    };

    const getSectionState = () => {
      if (data.disabled) {
        return <Tag color="red">Désactivé</Tag>;
      } else {
        if (!data.questions.length) {
          return (
            <Tooltip title="Aucune question dans cette section" overlayInnerStyle={{ textAlign: 'center' }}>
              <Tag>Activé mais invisible</Tag>
            </Tooltip>
          );
        } else {
          if (data.questions.findIndex((question) => !question.disabled) !== -1) {
            return <Tag color="green">Activé</Tag>;
          } else {
            return (
              <Tooltip title="Aucune question active dans cette section" overlayInnerStyle={{ textAlign: 'center' }}>
                <Tag>Activé mais invisible</Tag>
              </Tooltip>
            );
          }
        }
      }
    };

    return (
      <div ref={previewRef} style={{ opacity, marginBottom: 20 }}>
        <Collapse>
          <Collapse.Panel
            collapsible={disabled && 'disabled'}
            {...props}
            className="arinfo-faq-collapse"
            header={
              <div
                onClick={(e) => isEditing && e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                <EditableTitle
                  fieldName={`sections.${index}.title`}
                  isEditing={isEditing}
                  disabled={disabled}
                  authorized={checkAuthorization(user, 'arinfo', 'faq', 'update-faq')}
                />
              </div>
            }
            extra={
              <>
                {isEditing ? (
                  <Space onClick={(e) => isEditing && e.stopPropagation()}>
                    {(checkAuthorization(user, 'arinfo', 'faq', 'enable-section') ||
                      checkAuthorization(user, 'arinfo', 'faq', 'disable-section')) && (
                      <Controller
                        control={control}
                        name={`sections.${index}.disabled`}
                        render={({ field }) => (
                          <Switch
                            onChange={(value) => {
                              const canAccess = field.value
                                ? checkAuthorization(user, 'arinfo', 'faq', 'enable-section')
                                : checkAuthorization(user, 'arinfo', 'faq', 'disable-section');

                              if (canAccess) {
                                field.onChange(!value);
                              }
                            }}
                            checked={!field.value}
                            checkedChildren="Activé"
                            unCheckedChildren="Désactivé"
                            disabled={
                              disabled ||
                              (field.value
                                ? !checkAuthorization(user, 'arinfo', 'faq', 'enable-section')
                                : !checkAuthorization(user, 'arinfo', 'faq', 'disable-section'))
                            }
                          />
                        )}
                      />
                    )}
                    {checkAuthorization(user, 'arinfo', 'faq', 'update-faq') && (
                      <>
                        <Button ref={ref} icon={<DragOutlined />} disabled={disabled} />
                        <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(index)} disabled={disabled}>
                          <Button className="gray-text" icon={<DeleteOutlined />} disabled={disabled} />
                        </Popconfirm>
                      </>
                    )}
                  </Space>
                ) : (
                  getSectionState()
                )}
              </>
            }
          >
            <Collapse
              className="arinfo-faq-nested-collapse"
              ghost
              accordion
              activeKey={openKeys}
              onChange={setOpenKeys}
            >
              {questions.length === 0 ? (
                <EmptySection onDrop={handleDropInEmpty} />
              ) : (
                questions.map((question, questIndex) => (
                  <FAQQuestion
                    key={question._id || question.id}
                    sectionIndex={index}
                    data={question}
                    sectionId={data._id}
                    questionIndex={questIndex}
                    isEditing={isEditing}
                    removeQuestion={remove}
                    onMove={handleMoveQuestion}
                    onSwap={handleSwapQuestions}
                    whileDragging={() => closeQuestionPanel(question.id || questIndex)}
                    disabled={disabled}
                    sectionState={data.disabled}
                  />
                ))
              )}
            </Collapse>
            {isEditing && checkAuthorization(user, 'arinfo', 'faq', 'update-faq') && (
              <div className="arinfo-faq-add-question">
                <Space>
                  <InputNumber value={addCount} onChange={setAddCount} min={1} disabled={disabled} />
                  <Typography.Link onClick={appendQuestions} disabled={disabled}>
                    <PlusOutlined /> Ajouter {addCount === 1 ? 'une question' : 'des questions'}
                  </Typography.Link>
                </Space>
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  },
);

FAQSection.displayName = 'FAQSection';
export default FAQSection;
