import { useState, useEffect } from 'react';
import { Modal, Select, Form, message, Typography } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { userRoutes, roleRoutes } from '../../../../lib/routes';

const UpdateRoleModal = ({ user, onFinish }) => {
  const { handleSubmit, setValue, control, setError } = useForm();
  const { errors } = useFormState({ control });
  const { data: roles, isValidating } = useSWR(roleRoutes.default);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { put } = useFetch();

  useEffect(() => {
    if (user?.role?._id) {
      setValue('role', user.role._id);
    }
  }, [user, isOpen]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(userRoutes.updateRole + '/' + user._id, JSON.stringify(form));

    if (results.status === 200) {
      onFinish();
      setIsOpen(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <span onClick={() => setIsOpen(true)}>Changer le rôle</span>
      <Modal
        width={500}
        title="Change le rôle"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        cancelText="Annuler"
        okText="Enregistrer"
        onOk={handleSubmit(onSubmit)}
        confirmLoading={isProcessing}
        destroyOnClose
      >
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Form.Item
            label="Rôle"
            required
            validateStatus={errors?.role?.message && 'error'}
            help={errors?.role?.message}
          >
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  disabled={isProcessing}
                  loading={!roles || isValidating}
                  options={
                    roles?.data.map((role) => ({
                      label: role.name,
                      value: role._id,
                    })) || []
                  }
                />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateRoleModal;
