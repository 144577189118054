import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { Form, Input, Select, Upload } from 'antd';

const { Item } = Form;
const { TextArea } = Input;
const { Dragger } = Upload;

const ModuleCreateMeta = ({ disabled, preview, setPreview }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Item
        label="Image"
        validateStatus={errors?.image && 'error'}
        help={errors?.image?.message}
        extra="La taille recommandée est de 650x175px"
      >
        <Controller
          control={control}
          name="image"
          render={({ field }) => (
            <Dragger
              name={field.name}
              maxCount={1}
              showUploadList={false}
              height={150}
              disabled={disabled}
              accept="image/*"
              style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
              onChange={(img) => setPreview(img)}
            >
              {preview ? (
                <img
                  style={{
                    maxWidth: 'calc(100% - 32px)',
                    maxHeight: 100,
                    display: 'block',
                    margin: '0 auto',
                  }}
                  src={preview}
                  alt=""
                />
              ) : (
                <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
              )}
            </Dragger>
          )}
        />
      </Item>
      <Item validateStatus={errors?.title?.message && 'error'} help={errors?.title?.message} label="Intitulé" required>
        <Controller control={control} name="title" render={({ field }) => <Input {...field} disabled={disabled} />} />
      </Item>
      <Item validateStatus={errors?.goals?.message && 'error'} help={errors?.goals?.message} label="Objectifs" required>
        <Controller
          control={control}
          name="goals"
          render={({ field }) => <TextArea {...field} disabled={disabled} />}
        />
      </Item>
      <Item label="Difficulté" validateStatus={errors?.level?.message && 'error'} help={errors?.level?.message}>
        <Controller
          name="level"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              disabled={disabled}
              placeholder="Sélectionnez dans la liste"
              allowClear
              options={[
                { label: 'Rookie (Débutant)', value: 'easy' },
                { label: 'Aventurier (Avancé)', value: 'medium' },
                { label: 'Explorateur (Expert)', value: 'hard' },
              ]}
            />
          )}
        />
      </Item>
    </>
  );
};

export default ModuleCreateMeta;
