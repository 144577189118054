import { Col, Form, List, Modal, Row, Select, Space, Typography } from 'antd';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const EditStudentsModalContent = ({ isOpen, setIsOpen }) => {
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const { control } = useFormContext();
  const { append } = useFieldArray({ control, name: 'students' });

  useEffect(() => {
    if (isOpen?.length > 0) {
      setData(isOpen);
    }
  }, [isOpen]);

  const onChange = (value, studentId) => {
    const matchIndex = data.findIndex((item) => item?.student?._id?.toString() === studentId.toString());
    const clone = [...data];

    clone[matchIndex] = { ...clone[matchIndex], formationId: value };
    setData(clone);

    if (errors.includes(studentId)) {
      const errorsClone = [...errors];
      const index = errorsClone.findIndex((item) => item.toString() === studentId.toString());

      if (index !== -1) {
        errorsClone.splice(index, 1);
        setErrors(errorsClone);
      }
    }
  };

  const onSubmit = () => {
    let errorsCount = 0;

    setErrors([]);

    for (let i = 0; i < data.length; i++) {
      if (!data[i]?.formationId) {
        setErrors((rest) => [...rest, data[i].student._id]);
        errorsCount++;
      }
    }

    if (errorsCount === 0) {
      const formattedData = data.map((item) => ({ studentId: item.student._id, formationId: item.formationId }));

      append(formattedData);
      setData([]);
      setIsOpen(null);
    }
  };

  return (
    <Modal
      open={isOpen}
      onOk={onSubmit}
      okText="Continuer"
      onCancel={() => {
        setData([]);
        setErrors([]);
        setIsOpen(null);
      }}
      closable={false}
    >
      <Space direction="vertical">
        <Typography.Paragraph>
          Un ou plusieurs apprenant possèdes plusieurs formations qui correspondent aux critères. Veuillez sélectionner
          les bonnes dates de formation.
        </Typography.Paragraph>
        <Form noValidate layout="vertical">
          <List
            size="small"
            dataSource={data || []}
            bordered
            renderItem={(item) => (
              <List.Item>
                <Form.Item
                  style={{ width: '100%', marginBottom: 0 }}
                  required
                  validateStatus={errors?.includes(item.student._id) && 'error'}
                  help={errors?.includes(item.student._id) && 'Ce champ est requis'}
                >
                  <Row justify="space-between">
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography.Text>
                        {item.student.last_name.toUpperCase()} {capitalize(item.student.first_name)}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Select
                        style={{ width: 200 }}
                        onChange={(value) => onChange(value, item.student._id)}
                        value={item?.formationId ? item.formationId : null}
                        size="small"
                      >
                        {item.matchingFormations.map((formation) => (
                          <Select.Option value={formation._id}>
                            {format(new Date(formation.startAt), 'dd/MM/yyyy') +
                              ' - ' +
                              format(new Date(formation.endAt), 'dd/MM/yyyy')}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
              </List.Item>
            )}
          />
        </Form>
      </Space>
    </Modal>
  );
};

export default EditStudentsModalContent;
