import { useState } from 'react';

import { Dropdown } from 'antd';

import { useContentEditorContext } from '../contentEditorContext';

import { getMenu } from './menus';

const ContextMenu = ({ children, editor }) => {
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const editorContext = useContentEditorContext();

  const onOpenChange = (open) => {
    if (open) {
      const options = { ...editor, commands: editor.commands, chain: editor.chain, context: editorContext };

      if (editor?.isActive('figure')) {
        setMenu(getMenu('figure', options));
        setOpen(true);
        return;
      }

      if (editor?.isActive('table')) {
        setMenu(getMenu('table', options));
        setOpen(true);
        return;
      }
    }

    setOpen(false);
    return;
  };

  const onItemClick = () => {
    setOpen(false);
    return;
  };

  return (
    <Dropdown
      {...{ open, onOpenChange }}
      menu={{ items: menu, onClick: onItemClick }}
      trigger={['contextMenu']}
      destroyPopupOnHide
    >
      <div>{children}</div>
    </Dropdown>
  );
};

export default ContextMenu;
