import { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Typography, message } from 'antd';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { typeRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const { Item } = Form;
const { Paragraph } = Typography;

const CreateModal = ({ onFinish }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { control, handleSubmit, setError, reset } = useForm();
  const { errors } = useFormState({ control });
  const { post } = useFetch();

  useEffect(() => {
    if (isVisible) {
      reset();
    }
  }, [isVisible]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(typeRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      onFinish();
      setIsVisible(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button onClick={() => setIsVisible(true)}>Nouvelle catégorie</Button>
      </div>
      <Modal
        title="Nouvelle catégorie"
        visible={isVisible}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{ loading: isProcessing }}
        onCancel={() => setIsVisible(false)}
        cancelButtonProps={{ disabled: isProcessing }}
      >
        <Form noValidate layout="vertical">
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Item
            label="Intitulé"
            validateStatus={errors?.title?.message && 'error'}
            help={errors?.title?.message}
            required
          >
            <Controller
              name="title"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Item>
          <Item
            label="Acronyme"
            validateStatus={errors?.acronym?.message && 'error'}
            help={errors?.acronym?.message}
            required
          >
            <Controller
              name="acronym"
              control={control}
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateModal;
