import { Extension } from '@tiptap/core';

import { Content } from './Content';
import { Details } from './Details';
import { Summary } from './Summary';

export const ContentEditorDetailsExtension = Extension.create({
  name: 'detailsExtension',
  addExtensions() {
    return [Details, Summary, Content];
  },
});
