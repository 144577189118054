import { isAfter, isBefore, startOfDay, endOfDay } from 'date-fns';
import { CalendarFilled } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import { get } from 'lodash';

const useColumnDateRange = () => {
  const getColumnDateRangeProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div>
        <div style={{ padding: '5px 12px' }}>
          <DatePicker.RangePicker value={selectedKeys[0]} onChange={(e) => setSelectedKeys([e])} allowClear={false} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '7px 12px 7px 5px',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          <Button onClick={() => clearFilters()} type="link" size="small">
            Réinitialiser
          </Button>
          <Button onClick={() => confirm()} type="primary" size="small">
            OK
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => <CalendarFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (
        isAfter(new Date(get(record, dataIndex)), startOfDay(new Date(value[0]))) &&
        isBefore(new Date(get(record, dataIndex)), endOfDay(new Date(value[1])))
      ) {
        return true;
      } else {
        return false;
      }
    },
  });

  return { getColumnDateRangeProps };
};

export default useColumnDateRange;
