import { useState, useEffect, useCallback } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import LineTo from 'react-lineto';

const ConnectLines = ({ loading }) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { control } = useFormContext();
  const lines = useWatch({ name: 'lines', control });
  const fromList = useWatch({ name: 'fromList', control });
  const toList = useWatch({ name: 'toList', control });

  useEffect(() => {
    window.addEventListener('scroll', forceUpdate);
    window.addEventListener('resize', forceUpdate);

    return () => {
      window.removeEventListener('resize', forceUpdate);
      window.removeEventListener('scroll', forceUpdate);
    };
  }, [forceUpdate]);

  useEffect(forceUpdate, [forceUpdate, fromList, toList]);

  return lines.map((item, index) => (
    <LineTo
      className="lines"
      key={index}
      from={`from-square-${item.from}`}
      fromAnchor="right"
      toAnchor="left"
      to={`to-square-${item.to}`}
      borderWidth={1}
      borderColor={loading ? '#d9d9d9' : '#1890ff'}
    />
  ));
};

export default ConnectLines;
