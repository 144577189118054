import { Fragment, useEffect, useState } from 'react';

import {
  CheckOutlined,
  CloseOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  LayoutOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, InputNumber, Modal, Row, Space, Tooltip, Typography } from 'antd';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import DesktopGrid from './DesktopGrid';
import MobileAndTabletGrid from './MobileAndTabletGrid';
import Preview from './Preview';

const defaultValues = {
  gapX: 0,
  gapY: 0,
  cells: [
    {
      cellId: 'A',
      desktop: { size: 12, order: 1 },
      tablet: { size: 12, order: 1, hidden: false },
      mobile: { size: 12, order: 1, hidden: false },
    },
    {
      cellId: 'B',
      desktop: { size: 12, order: 2 },
      tablet: { size: 12, order: 2, hidden: false },
      mobile: { size: 12, order: 2, hidden: false },
    },
  ],
};

const ContentEditorMenuGrid = ({ editor }) => {
  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [preview, setPreview] = useState('desktop');
  const [useColors, setUseColors] = useState(false);
  const [gapLinked, setGapLinked] = useState(true);
  const methods = useForm({ defaultValues });

  useEffect(() => {
    if (open) {
      methods.reset(defaultValues);
      setPreview('desktop');
    }
  }, [open]);

  const onSubmit = (form) => {
    editor.chain().focus().setGrid(form.cells, { gapX: form.gapX, gapY: form.gapY }).run();
    setOpen(false);
  };

  const handleLinkedGapChange = (newValue) => {
    methods.setValue('gapX', newValue);
    methods.setValue('gapY', newValue);
  };

  return (
    <>
      <Modal
        open={open}
        width={1440}
        title="Insérer une grille"
        onCancel={() => setOpen(false)}
        transitionName=""
        destroyOnClose
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setOpen(false)} icon={<CloseOutlined />}>
              Annuler
            </Button>
            <Button type="primary" onClick={methods.handleSubmit(onSubmit)} icon={<CheckOutlined />}>
              OK
            </Button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <Row gutter={[12, 12]} className="editor-grid">
            <Col span={showPreview ? 16 : 24}>
              <Form layout="vertical" noValidate>
                <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
                  <Typography.Paragraph type="secondary">
                    <blockquote style={{ marginTop: 0 }}>
                      <ul>
                        <li>La grille est composée de 24 colonnes, le total des cellules doit être égal à ce nombre</li>
                        <li>Vous pouvez redimensionner les cellules à l'aide des séparateurs</li>
                        <li>Les cellules peuvent être ajoutées et supprimées uniquement depuis la vue ordinateur</li>
                        <li>
                          <Checkbox
                            checked={showPreview}
                            onChange={(event) => setShowPreview(event.target.checked)}
                            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                          >
                            Afficher la prévisualisation
                          </Checkbox>
                        </li>
                        <li>
                          <Checkbox
                            checked={useColors}
                            onChange={(event) => setUseColors(event.target.checked)}
                            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                          >
                            Affiner l'affichage avec des couleurs (Permet d'identifier plus facilement les colonnes)
                          </Checkbox>
                        </li>
                      </ul>
                    </blockquote>
                  </Typography.Paragraph>
                  <Form.Item
                    label="Gouttières"
                    tooltip="Les gouttières définissent l'espacement entre les cellules et les rangées"
                  >
                    <Space.Compact block size="small">
                      <Controller
                        name="gapX"
                        control={methods.control}
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            max={24}
                            onChange={(newValue) => {
                              if (gapLinked) {
                                handleLinkedGapChange(newValue === null ? 0 : newValue);
                              } else {
                                field.onChange(newValue === null ? 0 : newValue);
                              }
                            }}
                            style={{ maxWidth: 100 }}
                            prefix={<ColumnWidthOutlined />}
                          />
                        )}
                      />
                      <Button
                        icon={<LinkOutlined />}
                        type={gapLinked ? 'primary' : 'default'}
                        onClick={() => setGapLinked(!gapLinked)}
                      />
                      <Controller
                        name="gapY"
                        control={methods.control}
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            max={24}
                            onChange={(newValue) => {
                              if (gapLinked) {
                                handleLinkedGapChange(newValue === null ? 0 : newValue);
                              } else {
                                field.onChange(newValue === null ? 0 : newValue);
                              }
                            }}
                            style={{ maxWidth: 100 }}
                            suffix={<ColumnHeightOutlined />}
                          />
                        )}
                      />
                    </Space.Compact>
                  </Form.Item>
                  <Typography.Text strong>Affichage sur ordinateur</Typography.Text>
                  <DesktopGrid {...{ useColors }} mode="desktop" onUpdate={() => setPreview('desktop')} />
                  <Divider>Configuration du responsive</Divider>
                  <Typography.Text strong>Affichage sur tablette</Typography.Text>
                  <MobileAndTabletGrid {...{ useColors }} mode="tablet" onUpdate={() => setPreview('tablet')} />
                  <Typography.Text strong>Affichage sur mobile</Typography.Text>
                  <MobileAndTabletGrid {...{ useColors }} mode="mobile" onUpdate={() => setPreview('mobile')} />
                </Space>
              </Form>
            </Col>
            {showPreview && (
              <Fragment>
                <Col flex="none">
                  <Divider type="vertical" style={{ height: '100%' }} />
                </Col>
                <Col span={7}>
                  <Preview {...{ useColors }} mode={preview} onChange={setPreview} />
                </Col>
              </Fragment>
            )}
          </Row>
        </FormProvider>
      </Modal>
      <Tooltip key="grid" title="Insérer une grille">
        <Button icon={<LayoutOutlined />} onClick={() => setOpen(true)} />
      </Tooltip>
    </>
  );
};

export default ContentEditorMenuGrid;
