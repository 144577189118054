import { Card, Button, Form, Tabs, message } from 'antd';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { serialize } from 'object-to-formdata';
import WorkshopCreateInfos from './WorkshopCreateInfos';
import WorkshopCreateDates from './WorkshopCreateDates';
import { workshopRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const WorkshopCreate = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const methods = useForm({ defaultValues: { visible: true } });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await post(workshopRoutes.default, formData, 'multipart/form-data');

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/arinfo/ateliers');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Tabs>
            <Tabs.TabPane tab="Informations" key="infos">
              <WorkshopCreateInfos setBlocking={() => setIsBlocking(true)} loading={isProcessing} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Dates" key="dates">
              <WorkshopCreateDates loading={isProcessing} />
            </Tabs.TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/arinfo/ateliers">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default WorkshopCreate;
