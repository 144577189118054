import React, { useState } from 'react';
import {
  Table,
  Button,
  Space,
  Tooltip,
  message,
  Popconfirm,
  Tabs,
  Tag,
  Descriptions,
  Typography,
  Card,
  notification,
} from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import { format } from 'date-fns';
import { DeleteOutlined, FilePdfOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { sort, sortNum, sortDate, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import CreateQuizDrawer from './components/CreateQuizDrawer';
import SendResultsModal from './components/SendResultsModal';
import ExportPending from './components/ExportPending';
import Archives from './components/Archives';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { quizRoutes, agencyRoutes, typeRoutes, fileRoutes } from '../../../lib/routes';

// prettier-ignore
const Data = (props) => (
  <Table
    {...props}
    bordered
    size="small"
    // scroll={{ x: 1024 }}
    rowKey={(row) => row._id}
    expandable={{
      expandedRowRender: (record) => {
        const Address = () => {
          if (record.address) {
            const {
              city = '',
              postal_code = '',
              street_type = '',
              street = '',
              street_indicator = '',
              street_number = '',
            } = record.address;
        
            const indicator = street_indicator ? `${street_indicator} ` : "";

            return record.address ? `${street_number} ${indicator}${street_type} ${street}, ${postal_code} ${city}` : null
          }

          return null;
        }

        return (
          <Descriptions>
            {record.state !== "Test envoyé" && (
              <Descriptions.Item label="Date de naissance" span={3}>
                {record.identity.birth_date ? format(new Date(record.identity.birth_date), "dd/MM/yyyy") : null}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Adresse email" span={3}>
              {record.contact.email ? record.contact.email : null}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone" span={3}>
              {record.contact.phone ? record.contact.phone.match(/.{2}/g).join(" ") : 'Non précisé'}
            </Descriptions.Item>
            {record.state !== "Test envoyé" && (
              <Descriptions.Item label="Adresse complète" span={3}>
                <Address />
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Personne à notifier" span={3}>
              {record.referrer ? `${record.referrer.last_name.toUpperCase()} ${
                record.referrer.first_name.charAt(0).toUpperCase() + record.referrer.first_name.slice(1)
              }` : 'Non précisé'}
            </Descriptions.Item>
            {record.state === "Test envoyé" && (
              <Descriptions.Item label="Lien du test" span={3}>
                <Typography.Paragraph copyable style={{ color: '#666666' }}>
                  {`https://positionnement.arinfo.fr?t=${record.token}`}
                </Typography.Paragraph>
              </Descriptions.Item>
            )}
          </Descriptions>
        );
      },
    }}
    pagination={tablePagination(props?.dataSource)}
  />
);

const QuizzesList = () => {
  const { data: quizzes, isValidating, mutate } = useSWR(quizRoutes.default);
  const { data: types } = useSWR(typeRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const completedQuizzes = quizzes?.data?.filter((quiz) => quiz.state === 'Test terminé') || [];
  const sentQuizzes = quizzes?.data?.filter((quiz) => quiz.state === 'Test envoyé') || [];
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { getColumnSearchProps } = useColumnSearch();
  const [activeTab, setActiveTab] = useState('completed');
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const { remove, get, post, patch } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
    cancelButtonProps: { disabled: isProcessing },
  };

  const onDeleteComplete = async (id) => {
    setIsProcessing(true);

    const results = await remove(quizRoutes.deleteComplete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDeletePending = async (id) => {
    setIsProcessing(true);

    const results = await remove(quizRoutes.deletePending + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const createPDF = async (record, cb) => {
    const key = record._id;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await patch(quizRoutes.pdf + '/' + record._id);

    if (results.status === 200) {
      const blob = new Blob([Buffer.from(results.data.buffer)], {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      let date = moment(record.results.finishedAt).format('DD-MM-YYYY-HH:mm');
      date = date.replace(':', 'h');
      const lastName = record.identity.last_name.toUpperCase();
      // prettier-ignore
      const firstName = record.identity.first_name.charAt(0).toUpperCase() + record.identity.first_name.slice(1)

      link.download = `test-posiao-${lastName}-${firstName}_${record.type.acronym}_${date}.pdf`;
      link.click();
      if (cb) cb();
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  const downloadPdf = async (fileId) => {
    const file = await get(fileRoutes.default + '/' + fileId);

    const blob = new Blob([Buffer.from(file.data.buffer.data)], {
      type: 'application/pdf',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = file.data.name;
    link.click();
  };

  const sendEmail = async (record) => {
    setIsProcessing(true);

    const results = await post(quizRoutes.email + '/' + record._id);

    if (results.status !== 200) {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Catégorie',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      filters:
        types?.data
          .filter((type) => type.acronym !== 'Commun')
          .sort((a, b) => sort(a, b, 'title', ''))
          .map((type) => ({
            text: type.title,
            value: type.acronym,
          })) || [],
      onFilter: (value, record) => record.type.acronym === value,
      sorter: (a, b) => sort(a, b, 'type.acronym', ''),
      sortDirections: ['ascend', 'descend'],
      width: 150,
      render: (value) => {
        return value?.acronym ? (
          <Tag style={{ width: '100%' }}>{value?.acronym}</Tag>
        ) : (
          <Typography.Text type="danger">Catégorie introuvable</Typography.Text>
        );
      },
    },
    {
      title: 'Nom',
      key: 'last_name',
      sorter: (a, b) => sort(a, b, 'identity.last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('identity.last_name'),
      render: (record) => record.identity.last_name.toUpperCase(),
    },
    {
      title: 'Prénom',
      key: 'first_name',
      sorter: (a, b) => sort(a, b, 'identity.first_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('identity.first_name'),
      render: (record) => record.identity.first_name.charAt(0).toUpperCase() + record.identity.first_name.slice(1),
    },
    {
      title: 'Centre',
      key: 'agency',
      dataIndex: 'agency',
      sorter: (a, b) => sort(a, b, 'agency.city', 'Centre introuvable'),
      sortDirections: ['ascend', 'descend'],
      filters: agencies?.data.map((agency) => ({ text: agency.city, value: agency._id })) || [],
      onFilter: (value, record) => record.agency?._id === value,
      render: (value) => value?.city || <Typography.Text type="danger">Centre introuvable</Typography.Text>,
    },
    {
      title: "Date d'envoi",
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    ...(activeTab === 'completed'
      ? [
          {
            title: 'Date de complétion',
            key: 'finishedAt',
            width: 170,
            align: 'center',
            sorter: (a, b) => sortDate(a, b, 'results.finishedAt'),
            sortDirections: ['ascend', 'descend'],
            render: (record) => format(new Date(record.results.finishedAt), 'dd/MM/yyyy'),
          },
          {
            title: 'Score',
            key: 'score',
            width: 120,
            align: 'center',
            sorter: (a, b) => sortNum(a, b, 'results.score'),
            sortDirections: ['ascend', 'descend'],
            render: (record) => {
              const { score, percentage } = record.results;
              return `${score}/30 (${percentage}%)`;
            },
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {activeTab === 'completed' && (
            <>
              {checkAuthorization(user, 'posiao', 'quizzes', 'read-results') && (
                <Tooltip title="Consulter le PDF" destroyTooltipOnHide={{ keepParent: false }}>
                  <Button
                    className="gray-text"
                    onClick={() => {
                      if (record?.results?.pdf) {
                        downloadPdf(record?.results?.pdf);
                      } else {
                        createPDF(record);
                      }
                    }}
                    icon={<FilePdfOutlined />}
                  />
                </Tooltip>
              )}
              {checkAuthorization(user, 'posiao', 'quizzes', 'share') && <SendResultsModal quizId={record._id} />}
              {checkAuthorization(user, 'posiao', 'quizzes', 'delete-complete') && (
                <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={() => onDeleteComplete(record._id)}>
                    <Button className="gray-text" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              )}
            </>
          )}
          {activeTab === 'sent' && (
            <>
              {checkAuthorization(user, 'posiao', 'quizzes', 'resend') && (
                <Tooltip title="Renvoyer un email" destroyTooltipOnHide={{ keepParent: false }}>
                  <Button className="gray-text" onClick={() => sendEmail(record)} icon={<MailOutlined />} />
                </Tooltip>
              )}
              {checkAuthorization(user, 'posiao', 'quizzes', 'delete-pending') && (
                <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={() => onDeletePending(record._id)}>
                    <Button className="gray-text" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];

  const handleTabChange = (key) => setActiveTab(key);

  // prettier-ignore
  const tableProps = {
    loading: !quizzes || isValidating || isProcessing,
    dataSource: activeTab === "sent" ? sentQuizzes : completedQuizzes,
    columns: columns,
    rowClassName: record => {
      if (activeTab === 'completed') {
        if (record.results.percentage >= 70) return 'ant-table-row-success'
        return 'ant-table-row-warning'
      }
    }
  };

  return (
    <Card>
      {checkAuthorization(user, 'posiao', 'quizzes', 'create') ||
      (checkAuthorization(user, 'posiao', 'quizzes', 'export-pending') && activeTab === 'sent') ? (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Space>
            {checkAuthorization(user, 'posiao', 'quizzes', 'export-pending') && activeTab === 'sent' ? (
              <ExportPending onFinish={mutate} agencies={agencies?.data || []} />
            ) : null}
            {checkAuthorization(user, 'posiao', 'quizzes', 'create') && <CreateQuizDrawer onFinish={mutate} />}
          </Space>
        </div>
      ) : null}
      <Tabs defaultActiveKey="completed" activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane tab={`Terminés (${completedQuizzes.length})`} key="completed">
          <Data {...tableProps} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`En attente (${sentQuizzes.length})`} key="sent">
          <Data {...tableProps} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Archivés`} key="archives">
          <Archives downloadPdf={downloadPdf} createPDF={createPDF} onDelete={onDeleteComplete} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default QuizzesList;
