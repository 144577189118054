import { Button, Form, Modal, Upload, Table, Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import parseExcel from '../../../../lib/parseExcel';
import tablePagination from '../../../../lib/tablePagination';
import useFetch from '../../../../hooks/useFetch';
import { moduleRoutes } from '../../../../lib/routes';

const levels = {
  Facile: 'easy',
  Moyen: 'medium',
  Difficile: 'hard',
};

const columns = [
  {
    title: 'Index',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Intitulé',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Objectifs',
    dataIndex: 'goals',
    key: 'goals',
  },
  {
    title: 'Difficulté',
    dataIndex: 'level',
    key: 'level',
    width: 200,
    render: (value) => {
      if (value === 'easy') return 'Rookie (Débutant)';
      if (value === 'medium') return 'Aventurier (Avancé)';
      if (value === 'hard') return 'Explorateur (Expert)';
      return null;
    },
  },
];

const ModuleImportExcelModal = ({ onFinish, isOpen, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const { post } = useFetch();

  const onSubmit = async () => {
    setIsProcessing(true);

    const results = await post(moduleRoutes.import, JSON.stringify({ data }));

    if (results.status === 200) {
      {onClose};
      setData([]);
      setErrors({});
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        setErrors(results.errors);
      }
    }

    setIsProcessing(false);
  };

  const onFileChange = ({ file }) => {
    parseExcel(file, { includeId: true }).then((json) => {
      setFile(file.name);

      const newData = json.map((item, index) => {
        return {
          index: index + 1,
          id: item.id,
          title: item['Intitulé'] || '',
          goals: item['Objectifs'] || '',
          ...(levels[item['Difficulté']] ? { level: levels[item['Difficulté']] } : {}),
        };
      });

      setData(newData);
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setErrors({});
      setData([]);
      setFile(null);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        visible={isOpen}
        onCancel={onClose}
        confirmLoading={isProcessing}
        onOk={onSubmit}
        cancelButtonProps={{ disabled: isProcessing }}
        width={1000}
      >
        <Form layout="vertical">
          <Form.Item label="Fichier EXCEL (.xlsx)" required>
            <Upload
              beforeUpload={() => false}
              onChange={onFileChange}
              maxCount={1}
              showUploadList={false}
              accept=".xlsx"
            >
              <Button>{file ? file : 'Téléverser un fichier'}</Button>
            </Upload>
          </Form.Item>
        </Form>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(row) => row.id}
          pagination={tablePagination(data)}
        />
        {Object.keys(errors).length !== 0 && (
          <Alert
            showIcon
            type="error"
            message={
              <ul style={{ margin: 0 }}>
                {errors?.titleMissing && errors?.titleMissing?.length !== 0 && (
                  <li>
                    Un ou plusieurs intitulés sont manquants
                    <ul>
                      <li>Aux index : {errors?.titleMissing.join(', ').replace(/, ([^,]*)$/, ' et $1')}</li>
                    </ul>
                  </li>
                )}
                {errors?.arrayDuplicates && Object.keys(errors?.arrayDuplicates?.length !== 0) && (
                  <li>
                    Certains intitulés possèdent la même difficulté
                    <ul>
                      {Object.entries(errors.arrayDuplicates).map(([key, value], index) => (
                        <li key={index}>
                          {key} : {value.join(', ').replace(/, ([^,]*)$/, ' et $1')}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
                {errors?.dbDuplicates && Object.keys(errors?.dbDuplicates?.length !== 0) && (
                  <li>
                    Certains intitulés sont déjà enregistrés dans la DB avec cette difficulté
                    <ul>
                      {Object.entries(errors.dbDuplicates).map(([key, value], index) => (
                        <li key={index}>
                          {key} : {value.join(', ').replace(/, ([^,]*)$/, ' et $1')}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
                {errors?.goalsMissing && errors?.goalsMissing?.length !== 0 && (
                  <li>
                    Une ou plusieurs description des objectifs sont manquantes
                    <ul>
                      <li>Aux index : {errors?.goalsMissing.join(', ').replace(/, ([^,]*)$/, ' et $1')}</li>
                    </ul>
                  </li>
                )}
              </ul>
            }
          />
        )}
      </Modal>
    </>
  );
};

export default ModuleImportExcelModal;
