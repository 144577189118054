import { useState } from 'react';
import { Button, DatePicker, Form, Modal, Select, message } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { utils, writeFile } from 'xlsx';
import useFetch from '../../../../hooks/useFetch';
import { quizRoutes } from '../../../../lib/routes';
import { format } from 'date-fns';
import { sort } from '../../../../shared/utils';

const options = [
  { label: '7 derniers jours', value: '7' },
  { label: '14 derniers jours', value: '14' },
  { label: '30 derniers jours', value: '30' },
  { label: 'Personnalisée', value: 'custom' },
];

const ExportPending = ({ onFinish, agencies }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleSubmit, control, setError, reset, clearErrors, watch } = useForm({
    defaultValues: { period: '7' },
  });
  const period = watch('period');
  const { errors } = useFormState({ control });
  const { post } = useFetch();

  const onCancel = () => {
    clearErrors();
    reset();
    setIsOpen(false);
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(quizRoutes.export, JSON.stringify(form));

    if (results.status === 200) {
      const headers = ['last_name', 'first_name', 'category', 'agency', 'email', 'createdAt'];
      const titles = ['Nom', 'Prénom', 'Catégorie', 'Centre', 'Email', "Date d'envoi"];
      const data = results.data.map((item) => ({
        last_name: item.identity.last_name.toUpperCase(),
        first_name: item.identity.first_name.charAt(0).toUpperCase() + item.identity.first_name.slice(1),
        category: (item?.type?.acronym || '').toUpperCase(),
        agency: item?.agency?.city || '',
        email: item.contact.email.toLowerCase(),
        createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
      }));

      var ws = utils.json_to_sheet(data, { header: headers });

      var wb = utils.book_new();

      utils.sheet_add_aoa(ws, [titles], { origin: 'A1' });
      utils.book_append_sheet(wb, ws, 'tests-de-positionnement');

      writeFile(wb, `tests-de-positionnement.xlsx`);

      onFinish();
      onCancel();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={() => setIsOpen(true)} className="gray-text">
          Exporter les données
        </Button>
      </div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        cancelButtonProps={{ disabled: isProcessing }}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{ loading: isProcessing }}
        title="Exporter les données"
      >
        <Form layout="vertical">
          <Form.Item
            label="Centres"
            help={errors?.agencies?.message}
            validateStatus={errors?.agencies?.message && 'error'}
          >
            <Controller
              control={control}
              name="agencies"
              render={({ field }) => (
                <Select
                  {...field}
                  allowClear
                  mode="multiple"
                  placeholder="Sélectionnez dans la liste"
                  options={agencies
                    .sort((a, b) => sort(a, b, 'city'))
                    .map((agency) => ({ label: agency.city, value: agency._id }))}
                  disabled={isProcessing}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Période"
            required
            help={errors?.period?.message}
            validateStatus={errors?.period?.message && 'error'}
          >
            <Controller
              control={control}
              name="period"
              render={({ field }) => <Select {...field} options={options} disabled={isProcessing} />}
            />
          </Form.Item>
          {period === 'custom' && (
            <Form.Item
              label="Période personnalisée"
              required
              help={errors?.custom_period?.message}
              validateStatus={errors?.custom_period?.message && 'error'}
            >
              <Controller
                control={control}
                name="custom_period"
                render={({ field }) => (
                  <DatePicker.RangePicker {...field} format="DD/MM/YYYY" disabled={isProcessing} />
                )}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ExportPending;
