import { Button, Card, Form, Alert, Upload, Select, Steps, Space, message } from 'antd';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import parseExcelTest from '../../../../lib/parseExcelTest';
import CPFImportPrices from './CPFImportPrices';
import CPFImportPreview from './CPFImportPreview';
import { getRows, validatePrices } from './utils';
import useFetch from '../../../../hooks/useFetch';
import { cpfRoutes } from '../../../../lib/routes';

const { Item } = Form;
const { Step } = Steps;

const CPFImport = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [sheets, setSheets] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [gridsSheet, setGridsSheet] = useState(null);
  const history = useHistory();
  const { post } = useFetch();
  const gridsSheetData = useMemo(() => sheets[gridsSheet], [gridsSheet]);
  const methods = useForm({
    defaultValues: { grids: [{ title: 'Grille de tarif n°1' }] },
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const onFileChange = ({ file }) => {
    setLoading(true);

    parseExcelTest(file, { includeId: true }).then((json) => {
      setFile(file.name);
      setSheets(json);
      setLoading(false);
    });
  };

  const getValue = (value, options = { format: false, decimal: 2 }) => {
    if (!value || Array.isArray(value) || isNaN(value)) {
      return null;
    }

    if (options.format) {
      if (options.decimal) {
        return (Math.round((Number(value) + Number.EPSILON) * 100) / 100).toFixed(options.decimal);
      }

      return Math.round(Number(value));
    }

    return Number(value);
  };

  const onNext = (form) => {
    const formErrors = {};
    let grids = form.grids;

    for (let i = 0; i < grids.length; i++) {
      const gridErrors = validatePrices(grids[i]);

      if (Object.keys(gridErrors).length !== 0) {
        formErrors[i] = gridErrors;
      } else {
        const { costPerDay, costPerHour, days, hours, pricesWithoutTaxes, pricesWithTaxes } = grids[i];
        const rows = [];

        const costPerDayData = getRows(costPerDay, gridsSheetData);
        const costPerHourData = getRows(costPerHour, gridsSheetData);
        const daysData = getRows(days, gridsSheetData);
        const hoursData = getRows(hours, gridsSheetData);
        const pricesWithoutTaxesData = getRows(pricesWithoutTaxes, gridsSheetData);
        const pricesWithTaxesData = getRows(pricesWithTaxes, gridsSheetData);

        const obj = {
          costPerDayData,
          costPerHourData,
          daysData,
          hoursData,
          pricesWithoutTaxesData,
          pricesWithTaxesData,
        };

        // prettier-ignore
        const [key, value] = Object.entries(obj).sort((a, b) => a[1].length - b[1].length).reverse()[0];

        for (let i = 0; i < obj[key].length; i++) {
          const row = {
            costPerHour: null,
            costPerDay: null,
            priceWithoutTaxes: null,
            priceWithTaxes: null,
            days: null,
            hours: null,
          };

          row.costPerHour = getValue(costPerHourData[i], { format: true, decimal: 2 });
          row.costPerDay = getValue(costPerDayData[i], { format: true, decimal: 2 });
          row.priceWithoutTaxes = getValue(pricesWithoutTaxesData[i], { format: true });
          row.priceWithTaxes = getValue(pricesWithTaxesData[i], { format: true });
          row.days = getValue(daysData[i]);
          row.hours = getValue(hoursData[i]);

          rows.push(row);
        }

        methods.setValue(`grids.${i}.data`, rows);
      }
    }

    if (Object.keys(formErrors).length !== 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      setCurrentStep(1);
    }
  };

  const removeErrors = (index) => {
    const newErrors = { ...errors };

    delete newErrors[index];

    setErrors(newErrors);
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    for (let i = 0; i < form.grids.length; i++) {
      delete form.grids[i].hours;
      delete form.grids[i].days;
      delete form.grids[i].costPerHour;
      delete form.grids[i].costPerDay;
      delete form.grids[i].pricesWithoutTaxes;
      delete form.grids[i].pricesWithTaxes;
    }

    const results = await post(cpfRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/arinfo/simulateur-cpf');
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Alert
        type="warning"
        message="En important un nouveau fichier, les anciennes données seront écrasées."
        showIcon
        style={{ marginBottom: 24 }}
      />
      <Steps size="small" current={currentStep} style={{ marginBottom: 24 }}>
        <Step title="Importation" />
        <Step title="Vérification" />
      </Steps>
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          {currentStep === 0 && (
            <>
              <Item label="Fichier EXCEL (.xlsx)" required>
                <Upload
                  beforeUpload={() => false}
                  onChange={onFileChange}
                  maxCount={1}
                  showUploadList={false}
                  accept=".xlsx"
                >
                  <Button loading={loading}>{file ? file : 'Téléverser un fichier'}</Button>
                </Upload>
              </Item>
              <Item label="Sélectionnez la feuille des tarifs" required>
                <Select
                  placeholder="Sélectionnez dans la liste"
                  value={gridsSheet}
                  onChange={setGridsSheet}
                  options={Object.keys(sheets).map((key) => ({ label: key, value: key }))}
                  disabled={Object.keys(sheets).length === 0 || loading}
                />
              </Item>
              <CPFImportPrices
                errors={errors}
                onDelete={removeErrors}
                disabled={Object.keys(sheets).length === 0 || loading || !gridsSheet}
              />
            </>
          )}
          {currentStep === 1 && <CPFImportPreview />}
          {currentStep === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to="/arinfo/simulateur-cpf">
                <Button disabled={loading}>Annuler</Button>
              </Link>
              <Button
                type="primary"
                disabled={Object.keys(sheets).length === 0 || !gridsSheet || loading}
                onClick={methods.handleSubmit(onNext)}
              >
                Suivant
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space>
                <Link to="/arinfo/simulateur-cpf">
                  <Button disabled={isProcessing || loading}>Annuler</Button>
                </Link>
                <Button disabled={isProcessing} onClick={() => setCurrentStep(0)}>
                  Précédent
                </Button>
              </Space>
              <Button type="primary" loading={isProcessing} onClick={methods.handleSubmit(onSubmit)}>
                Enregistrer
              </Button>
            </div>
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default CPFImport;
