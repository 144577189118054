import { Descriptions, Modal } from 'antd';
import { format } from 'date-fns';

const WorkshopModal = ({ isOpen, onClose }) => (
  <Modal
    visible={isOpen}
    onCancel={onClose}
    footer={null}
    title={isOpen?.title.charAt(0).toUpperCase() + isOpen?.title.slice(1)}
    bodyStyle={{ padding: 0 }}
  >
    <Descriptions size="small" column={3} bordered layout="vertical">
      <Descriptions.Item label="Format" span={3}>
        {isOpen?.date?.format}
      </Descriptions.Item>
      <Descriptions.Item label="Date" span={3}>
        {isOpen?.date?.date && format(new Date(isOpen?.date?.date), 'dd/MM/yyyy HH:mm')?.replace(':', 'h')}
      </Descriptions.Item>
      <Descriptions.Item label="Lieu" span={3}>
        {isOpen?.date?.agency?.city}
      </Descriptions.Item>
      <Descriptions.Item label="Durée">{isOpen?.duration}</Descriptions.Item>
      <Descriptions.Item label="Prix">{isOpen?.price} €/personne</Descriptions.Item>
      <Descriptions.Item label="Age">
        {Number(isOpen?.date?.age[0]) === Number(isOpen?.date?.age[1])
          ? Number(isOpen?.date?.age[0]) === 18
            ? `Adultes`
            : `${isOpen?.date?.age[0]} ans`
          : `${isOpen?.date?.age.join('-')} ans`}
      </Descriptions.Item>
    </Descriptions>
  </Modal>
);

export default WorkshopModal;
