import { useFormContext, Controller } from 'react-hook-form';
import { useState, useRef, useMemo } from 'react';
import { AutoComplete, Spin } from 'antd';
import { debounce } from 'lodash';

const DebounceAutoComplete = ({ fetchOptions, debounceTimeout = 800, options, setOptions, disabled, fieldName }) => {
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);
  const { control } = useFormContext;

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <AutoComplete
          value={field?.value?.label}
          onChange={(value, option) => field.onChange(option || null)}
          filterOption={false}
          options={options}
          onSearch={debounceFetcher}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          disabled={disabled}
          placeholder="Commencez à taper pour charger la liste..."
        />
      )}
    />
  );
};

export default DebounceAutoComplete;
