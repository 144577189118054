import { Row, Col, Input, DatePicker, Form, Checkbox } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import moment from 'moment';
import Editor from '../../../../../ui/Editor';

const { Item } = Form;

const CreateDrawerMeta = ({ disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Item validateStatus={errors?.title?.message && 'error'} help={errors?.title?.message} label="Intitulé" required>
        <Controller control={control} name="title" render={({ field }) => <Input {...field} />} />
      </Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item validateStatus={errors?.start?.message && 'error'} help={errors?.start?.message} label="Début" required>
            <Controller
              control={control}
              name="start"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  format={['DD/MM/YYYY']}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(date)}
                  disabled={disabled}
                />
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item validateStatus={errors?.end?.message && 'error'} help={errors?.end?.message} label="Fin" required>
            <Controller
              control={control}
              name="end"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  allowClear={false}
                  format={['DD/MM/YYYY']}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(date)}
                  disabled={disabled}
                />
              )}
            />
          </Item>
        </Col>
      </Row>
      {/* <Item extra="En cochant cette case vous pourrez définir une date de rendu pour un exercice">
        <Controller
          control={control}
          name="should_return"
          defaultValue={true}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={disabled}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              Évènement lié à une formation
            </Checkbox>
          )}
        />
      </Item> */}
      <Item
        validateStatus={errors?.description?.message && 'error'}
        help={errors?.description?.message}
        label="Description"
      >
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Editor
              {...field}
              minHeight={200}
              disabled={disabled}
              options={{
                heading: false,
                code: false,
                codeBlock: false,
                image: false,
                quote: false,
                history: false,
                figure: false,
                important: false,
                blockquote: false,
                color: false,
                file: false,
                table: false,
                details: false,
                textAlign: false,
                grid: false,
                cloudinaryVideo: false,
                youtube: false,
                vimeo: false,
              }}
            />
          )}
        />
      </Item>
    </>
  );
};

export default CreateDrawerMeta;
