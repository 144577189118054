import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import useFetch from '../../../../hooks/useFetch';
import { aiRoutes } from '../../../../lib/routes';

const NewVersionModal = memo(({ id, ai, onFinish }) => {
  const [visible, setVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues: { name: '', value: '' } });
  const { errors } = useFormState({ control });
  const { post } = useFetch();

  useEffect(() => {
    if (visible) {
      reset();
      setIsProcessing(false);
    }
  }, [visible]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(aiRoutes.versions, JSON.stringify({ ...form, ai: id.toString() }));

    if (results.status === 201) {
      setVisible(false);
      reset();
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Button size="small" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        Nouvelle version
      </Button>
      <Modal
        visible={visible}
        title={`Nouvelle version pour ${ai}`}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{ loading: isProcessing }}
        cancelButtonProps={{ disabled: isProcessing }}
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item
            label="Intitulé"
            required
            validateStatus={errors?.name?.message && 'error'}
            help={errors?.name?.message}
          >
            <Controller
              {...{ control }}
              name="name"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Valeur"
            required
            validateStatus={errors?.value?.message && 'error'}
            help={errors?.value?.message}
            tooltip="Cette valeur est utilisée dans les requêtes sur l'API de l'IA."
          >
            <Controller
              {...{ control }}
              name="value"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

NewVersionModal.displayName = 'NewVersionModal';
export default NewVersionModal;
