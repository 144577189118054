import { useFormContext, useFieldArray, useFormState } from 'react-hook-form';
import { Form, Card, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';
import OrganizeLabel from './OrganizeLabel';

const { Item } = Form;

const OrganizeLabels = ({ loading }) => {
  const ref = useRef(null);
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: labels, remove, append } = useFieldArray({ name: 'labels', control });
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'LABEL',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (item.listIndex === 'none') {
        return;
      }

      if (labels.length >= 6) {
        message.error('Vous ne pouvez pas avoir plus de 6 éléments pièges');
        return;
      }

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.listIndex;

      item.remove();
      append(itemCopy);
    },
  });

  drop(ref);

  return (
    <Item validateStatus={errors?.labels?.message && 'error'} help={errors?.labels?.message}>
      <Card
        size="small"
        title="Éléments pièges"
        extra={[
          <Button
            key="new"
            icon={<PlusOutlined />}
            disabled={loading || labels.length >= 6}
            onClick={() => {
              if (labels.length >= 6) {
                message.error('Vous ne pouvez pas avoir plus de 6 éléments pièges');
              } else {
                append({ content: 'Nouvel élément' });
              }
            }}
          >
            Nouvel élément
          </Button>,
        ]}
        style={{ overflow: 'hidden' }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div
          ref={ref}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: 20,
            padding: 12,
            backgroundColor: isOver ? '#bae7ff' : canDrop ? '#e6f7ff' : '#fafafa',
          }}
        >
          {labels.map((item, index) => (
            <Item
              className="organize-label"
              key={item.id}
              validateStatus={errors?.labels?.[index]?.content?.message && 'error'}
              help={errors?.labels?.[index]?.content?.message}
            >
              <OrganizeLabel
                data={{ ...item, remove: () => remove(index) }}
                fieldName={`labels.${index}.content`}
                loading={loading}
              />
            </Item>
          ))}
        </div>
      </Card>
    </Item>
  );
};

export default OrganizeLabels;
