import { Dropdown, Card, Tabs, Button, Menu, Tooltip, Space } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DownOutlined, QuestionCircleOutlined, UpOutlined } from '@ant-design/icons';
import { useAuth } from '../../../authContext';
import { checkAuthorization } from '../../../shared/utils';
import SatisfactionReportList from './satisfaction/SatisfactionReportList';
import FormationReportList from './formation/FormationReportList';
import CompanyReportList from './company/CompanyReportList';
import MonthlyReportList from './mensuel/MonthlyReportList';
import TrainingReportList from './stage/TrainingReportList';
import Search from './search/Search';

const ReportsList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  const canCreate = () =>
    checkAuthorization(user, 'formao', 'satisfactionReports', 'create') ||
    checkAuthorization(user, 'formao', 'formationReports', 'create') ||
    checkAuthorization(user, 'formao', 'companyReports', 'create') ||
    checkAuthorization(user, 'formao', 'trainingReports', 'create') ||
    checkAuthorization(user, 'formao', 'monthlyReports', 'create');

  const canSearch = () =>
    checkAuthorization(user, 'formao', 'satisfactionReports', 'search') ||
    checkAuthorization(user, 'formao', 'formationReports', 'search') ||
    checkAuthorization(user, 'formao', 'companyReports', 'search') ||
    checkAuthorization(user, 'formao', 'trainingReports', 'search') ||
    checkAuthorization(user, 'formao', 'monthlyReports', 'search');

  const Actions = () => (
    <Menu>
      {checkAuthorization(user, 'formao', 'satisfactionReports', 'create') && (
        <Menu.Item key="satisfaction" className="gray-text">
          <Link to="/formao/bilans/bilans-de-satisfaction/nouveau-bilan">Bilan de satisfaction</Link>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'formao', 'companyReports', 'create') && (
        <Menu.Item key="company" className="gray-text">
          <Link to="/formao/bilans/bilans-entreprise/nouveau-bilan">Bilan de formation en alternance</Link>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'formao', 'formationReports', 'create') && (
        <Menu.Item key="formation" className="gray-text">
          <Link to="/formao/bilans/bilans-de-formation/nouveau-bilan">Bilan de formation en continu</Link>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'formao', 'trainingReports', 'create') && (
        <Menu.Item key="training" className="gray-text">
          <Link to="/formao/bilans/bilans-de-stage/nouveau-bilan">Bilan de stage</Link>
        </Menu.Item>
      )}
      {checkAuthorization(user, 'formao', 'monthlyReports', 'create') && (
        <Menu.SubMenu key="monthly" title="Suivi mensuel">
          <Menu.Item key="monthly-alt" className="gray-text">
            <Link to="/formao/bilans/suivis-mensuels/nouveau-bilan-alt">Formation en alternance</Link>
          </Menu.Item>
          <Menu.Item key="monthly-tc" className="gray-text">
            <Link to="/formao/bilans/suivis-mensuels/nouveau-bilan-tc">Formation en continu</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}
    </Menu>
  );

  return (
    <Card>
      {canCreate() && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Dropdown overlay={<Actions />} trigger={['click']} open={isOpen} onOpenChange={setIsOpen}>
            <Button className="gray-text">
              Créer un bilan
              {isOpen ? <UpOutlined style={{ fontSize: 11 }} /> : <DownOutlined style={{ fontSize: 11 }} />}
            </Button>
          </Dropdown>
        </div>
      )}
      {canSearch() && (
        <Tabs>
          {checkAuthorization(user, 'formao', 'formationReports', 'search') && (
            <Tabs.TabPane
              tab={
                <Space>
                  Formation en continu
                  <Tooltip
                    title={
                      <>
                        Liste des suivis des formations en continu pour accéder aux bilans :
                        <ul>
                          <li>Mi-parcours</li>
                          <li>Final</li>
                        </ul>
                      </>
                    }
                  >
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </Space>
              }
              key="FORMATION"
            >
              <FormationReportList />
            </Tabs.TabPane>
          )}
          {checkAuthorization(user, 'formao', 'companyReports', 'search') && (
            <Tabs.TabPane
              tab={
                <Space>
                  Formation en alternance
                  <Tooltip
                    title={
                      <>
                        Liste des suivis des formations en alternance pour accéder aux bilans :
                        <ul>
                          <li>Intégration</li>
                          <li>Mi-parcours</li>
                          <li>Final</li>
                          <li>Supplémentaire</li>
                        </ul>
                      </>
                    }
                  >
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </Space>
              }
              key="COMPANY"
            >
              <CompanyReportList />
            </Tabs.TabPane>
          )}
          {checkAuthorization(user, 'formao', 'trainingReports', 'search') && (
            <Tabs.TabPane
              tab={
                <Space>
                  Stage
                  <Tooltip title="Liste des bilans de stage pour les formation en continu.">
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </Space>
              }
              key="STAGE"
            >
              <TrainingReportList />
            </Tabs.TabPane>
          )}
          {checkAuthorization(user, 'formao', 'monthlyReports', 'search') && (
            <Tabs.TabPane
              tab={
                <Space>
                  Mensuel
                  <Tooltip title="Liste des suivis mensuels pour les formations en continu et en alternance.">
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </Space>
              }
              key="MONTHLY"
            >
              <MonthlyReportList />
            </Tabs.TabPane>
          )}
          {checkAuthorization(user, 'formao', 'satisfactionReports', 'search') && (
            <Tabs.TabPane
              tab={
                <Space>
                  Satisfaction
                  <Tooltip title="Liste des bilans de satisfaction pour tous les types de formations.">
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </Space>
              }
              key="SATISFACTION"
            >
              <SatisfactionReportList />
            </Tabs.TabPane>
          )}
          {canSearch() && (
            <Tabs.TabPane
              tab={
                <Space>
                  Recherche
                  <Tooltip title="Moteur de recherche de bilans parmi les actifs et archivés.">
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </Space>
              }
              key="SEARCH"
            >
              <Search />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </Card>
  );
};

export default ReportsList;
