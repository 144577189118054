import { Card, Table, Space, Button, message, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import useSWR from 'swr';
import { externalInterventionRoutes } from '../../../lib/routes';
import CreateInterventionModal from './components/CreateInterventionModal';
import useColumnSearch from '../../../hooks/useColumnSearch';
import tablePagination from '../../../lib/tablePagination';
import UpdateInterventionModal from './components/UpdateInterventionModal';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';
import { checkAuthorization } from '../../../shared/utils';

const InterventionsList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const { data: interventions, isValidating, mutate } = useSWR(externalInterventionRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { remove } = useFetch();
  const { user } = useAuth();

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(externalInterventionRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Nom',
      key: 'last_name',
      sorter: (a, b) => sort(a, b, 'last_name'),
      dataIndex: 'last_name',
      ...getColumnSearchProps('last_name'),
      render: (record) => record.toUpperCase(),
    },
    {
      title: 'Prénom',
      key: 'first_name',
      sorter: (a, b) => sort(a, b, 'first_name'),
      dataIndex: 'first_name',
      ...getColumnSearchProps('first_name'),
      render: (record) => record.charAt(0).toUpperCase() + record.slice(1),
    },
    {
      title: 'Adresse mail',
      key: 'email',
      sorter: (a, b) => sort(a, b, 'email'),
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: "Type d'intervention",
      key: 'type',
      sorter: (a, b) => sort(a, b, 'type'),
      dataIndex: 'type',
      ...getColumnSearchProps('type'),
    },
    ...(checkAuthorization(user, 'global', 'externalInterventions', 'update') ||
    checkAuthorization(user, 'global', 'externalInterventions', 'delete')
      ? [
          {
            title: 'Actions',
            key: 'actions',
            width: 50,
            render: (record) => (
              <Space>
                {checkAuthorization(user, 'global', 'externalInterventions', 'update') && (
                  <Tooltip title="Modifier">
                    <Button icon={<EditOutlined />} onClick={() => setIsUpdateModalOpen(record)} />
                  </Tooltip>
                )}
                {checkAuthorization(user, 'global', 'externalInterventions', 'delete') && (
                  <Tooltip title="Supprimer">
                    <Popconfirm
                      title="Êtes-vous sûr ?"
                      cancelText="Non"
                      okText="Oui"
                      placement="left"
                      onConfirm={() => onDelete(record._id)}
                    >
                      <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                )}
              </Space>
            ),
          },
        ]
      : []),
  ];

  return (
    <Card>
      {checkAuthorization(user, 'global', 'externalInterventions', 'create') && (
        <CreateInterventionModal onFinish={mutate} />
      )}
      {checkAuthorization(user, 'global', 'externalInterventions', 'update') && (
        <UpdateInterventionModal isOpen={isUpdateModalOpen} setIsOpen={setIsUpdateModalOpen} onFinish={mutate} />
      )}
      <Table
        size="small"
        dataSource={interventions?.data || []}
        columns={columns}
        loading={!interventions || isValidating || isProcessing}
        bordered
        rowKey={(row) => row._id}
        pagination={tablePagination(interventions?.data)}
      />
    </Card>
  );
};

export default InterventionsList;
