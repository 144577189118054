import { memo, useRef } from 'react';
import { Typography, List, Space, Button, Popconfirm, Tooltip, InputNumber } from 'antd';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { PlusOutlined, DragOutlined, DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import { useDrop, useDrag } from 'react-dnd';
import ModuleEditTitle from './ModuleEditTitle';

const { Link } = Typography;
const { Item } = List;

const ModulesEditCourses = ({ chapterIndex, onUpdate, disabled, setActiveModal, toAppend, onToAppendChange }) => {
  const { control, setValue, getValues } = useFormContext();
  const chapterFile = useWatch({ control, name: `chapters.${chapterIndex}.file` });
  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: `chapters[${chapterIndex}].courses`,
  });

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const handleAppend = () => {
    const newModules = [];

    for (let i = 0; i !== toAppend; i++) {
      newModules.push({ title: 'Nouveau chapitre', content: '', file: { name: '', _id: '' } });
    }

    append(newModules);
  };

  const MemoizedItem = memo(({ field, index }) => {
    const ref = useRef(null);
    const previewRef = useRef(null);
    const [, drop] = useDrop({
      accept: 'COURSE',
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        const hoverItem = getValues(`chapters[${chapterIndex}].courses[${hoverIndex}]`);

        if (item.id === hoverItem.id) {
          return;
        }

        if (item.chapterIndex === chapterIndex) {
          move(dragIndex, hoverIndex);
          onUpdate();
          return;
        }

        const dragArray = getValues(`chapters[${item.chapterIndex}].courses`);
        dragArray.splice(item.index, 1);

        const itemCopy = { ...item };
        delete itemCopy.id;
        delete itemCopy.index;
        delete itemCopy.chapterIndex;

        insert(hoverIndex, itemCopy);
        setValue(`chapters[${item.chapterIndex}].courses`, dragArray);
        onUpdate();
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'COURSE',
      item: { ...field, index, chapterIndex: chapterIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.2 : 1;
    drag(ref);
    drop(preview(previewRef));

    return (
      <Item className="course-list-item">
        <div ref={previewRef} style={{ opacity, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Space>
            {!!chapterFile && (
              <Tooltip title="Tant qu'un fichier PDF est présent, les apprenants n'ont pas accès à ce chapitre">
                <WarningOutlined style={{ color: '#faad14' }} />
              </Tooltip>
            )}
            <ModuleEditTitle fieldName={`chapters.${chapterIndex}.courses.${index}.title`} />
          </Space>
          <Space>
            <Button
              icon={<EditOutlined />}
              disabled={disabled}
              onClick={() =>
                setActiveModal({ title: field.title, field: `chapters.${chapterIndex}.courses.${index}.content` })
              }
            />
            <Button ref={ref} icon={<DragOutlined />} disabled={disabled} />
            <Popconfirm {...popconfirmProps} onConfirm={() => remove(index)}>
              <Button icon={<DeleteOutlined />} disabled={disabled} />
            </Popconfirm>
          </Space>
        </div>
      </Item>
    );
  });

  MemoizedItem.displayName = 'MemoizedItem';

  const [, drop] = useDrop({
    accept: 'COURSE',
    drop: (item, monitor) => {
      if (fields.length > 0) {
        return;
      }

      const dragArray = getValues(`chapters[${item.chapterIndex}].courses`);
      dragArray.splice(item.index, 1);

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.chapterIndex;

      append(itemCopy);
      setValue(`chapters[${item.chapterIndex}].courses`, dragArray);
      onUpdate();
    },
  });

  return (
    <List size="small">
      <div ref={drop} style={{ minHeight: 48.8 }}>
        {fields.map((field, index) => {
          return <MemoizedItem key={field._id || field.id} field={field} index={index} chapterIndex={chapterIndex} />;
        })}
      </div>
      <Item style={{ borderTop: '1px solid #f0f0f0', justifyContent: 'flex-start' }}>
        <InputNumber value={toAppend} onChange={onToAppendChange} min={1} max={10} style={{ marginRight: 5 }} />
        <Link disabled={disabled} onClick={handleAppend}>
          <PlusOutlined /> Ajouter {toAppend > 1 ? 'des' : 'un'} chapitre{toAppend > 1 ? 's' : ''}
        </Link>
      </Item>
    </List>
  );
};

export default ModulesEditCourses;
