import { useFormContext, useWatch } from 'react-hook-form';
import Move from '../../../../../assets/posiao/move.svg';

const OrderingPreview = () => {
  const { control } = useFormContext();
  const values = useWatch({ control });

  function shuffle(array) {
    const newArray = [...array];
    const length = newArray.length;

    for (let start = 0; start < length; start++) {
      const randomPosition = Math.floor((newArray.length - start) * Math.random());
      const randomItem = newArray.splice(randomPosition, 1);

      newArray.push(...randomItem);
    }

    return newArray;
  }

  return (
    <>
      <h3 className="preview-question">{values?.question}</h3>
      {shuffle(values.ordering).map((label, index) => (
        <div key={index} className="card">
          {label.label}

          <img src={Move} alt="move icon" style={{ maxWidth: '20px' }} />
        </div>
      ))}
    </>
  );
};

export default OrderingPreview;
