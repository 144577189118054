import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import DnDScrollable from './DnDScrollable';
import { FilesModalProvider } from './ui/FilesModal/context';
import { AuthProvider } from './authContext';
import Router from './router';
// Pack de langue FR pour antd
import frFR from 'antd/lib/locale/fr_FR';
import 'moment/locale/fr';
import 'highlight.js/styles/github-dark.css';
// Utilisation de TinyMCE localement
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/searchreplace';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
// CSS de ANTD
import 'antd/dist/antd.css';
// CSS de nprogress
import 'nprogress/nprogress.css';
// CSS
import './index.css';
import './formao.css';

ReactDOM.render(
  <AuthProvider>
    <ConfigProvider locale={frFR}>
      <DndProvider backend={HTML5Backend}>
        <DnDScrollable>
          <FilesModalProvider>
            <Router />
          </FilesModalProvider>
        </DnDScrollable>
      </DndProvider>
    </ConfigProvider>
  </AuthProvider>,
  document.getElementById('root'),
);
