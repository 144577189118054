import { Extension } from '@tiptap/core';

import { Cell } from './Cell';
import { Grid } from './Grid';

export const ContentEditorGridExtension = Extension.create({
  name: 'gridExtension',
  addExtensions() {
    return [Grid, Cell];
  },
});
