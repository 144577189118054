import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./authContext";
import { checkAuthorization } from "./shared/utils";
import nprogress from "nprogress";

const PrivateRoute = ({
  component: Component,
  database,
  collection,
  authorization,
  ...rest
}) => {
  const { isAuth, user } = useAuth();
  nprogress.start();
  useEffect(() => nprogress.done());

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          if (!database && !collection && !authorization) {
            return <Component {...props} />;
          } else {
            if (checkAuthorization(user, database, collection, authorization)) {
              return <Component {...props} />;
            }
          }
        }

        return (
          <Redirect
            to={{ pathname: "/connexion", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
