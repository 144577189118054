import { uniqueId } from 'lodash';

export const parse = (logs, options) => {
  const ids = [];
  const logArray = [];
  const entries = Object.entries(logs);

  const generateId = () => {
    const id = uniqueId();
    ids.push(id);

    return id;
  };

  // Traite toutes les entrées de l'objet
  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];

    // Initialise le nouvel élément
    const newLogArrayItem = { label: key, id: generateId(), logs: [], children: [] };

    // Récupère les logs directement si présent sous forme de tableau
    if (Array.isArray(value) && !!value?.length) {
      newLogArrayItem.logs = newLogArrayItem.logs.concat(
        value.map((logItem) => ({ ...logItem, id: uniqueId() })).sort((a, b) => a.loggedAt - b.loggedAt),
      );
    }

    // Parse tous les objets présents dans l'entrée
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      const { items, ids: logItemIds } = parse(value);

      ids.push(...logItemIds);

      // Ajoute les logs si présents
      const pureLogItems = items.filter((e) => e.label === 'logs').map((e) => e.logs);
      newLogArrayItem.logs = newLogArrayItem.logs.concat(pureLogItems)[0];

      // Ajoute les enfants
      newLogArrayItem.children = newLogArrayItem.children.concat(items.filter((e) => e.label !== 'logs'));
    }

    // Ajoute le nouvel élément à la structure
    logArray.push(newLogArrayItem);
  }

  return { items: logArray, ids };
};

export const getLogList = (logs) => {
  let logArray = [];
  const entries = Object.entries(logs);

  // Traite toutes les entrées de l'objet
  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];

    // Traite le cas des tableaux
    if (Array.isArray(value) && !!value?.length) {
      for (let vi = 0; vi < value.length; vi++) {
        logArray.push(value[vi]);
      }
    }

    // Parse tous les objets présents dans l'entrée
    else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      const valueEntries = getLogList(value);

      if (valueEntries.length) {
        logArray = logArray.concat(valueEntries);
      }
    }
  }

  return logArray.sort((a, b) => a.loggedAt - b.loggedAt);
};
