import { get } from 'lodash';

// prettier-ignore
export const checkAuthorization = (user, database, collection, authorization) => {
  // Si un paramètre est manquant
  if (!user || !database || !collection || !authorization) return false;
  // Si les permissions ne sont pas accessibles
  if (!user.role.permissions) return false;
  // Si la base de données passée n'existe pas
  if (!user.role.permissions[database]) return false;
  // Si la collection passée n'existe pas
  if (!user.role.permissions[database][collection]) return false;
  // Si l'utilisateur ne dispose pas de l'autorisation requise
  if (!user.role.permissions[database][collection].includes(authorization)) return false;

  return true;
};

export const sort = (a, b, dataIndex, empty) => {
  const firstField = (get(a, dataIndex) || empty).toLowerCase();
  const secondField = (get(b, dataIndex) || empty).toLowerCase();

  return firstField.localeCompare(secondField, 'fr');
};

export const sortDate = (a, b, dataIndex) => {
  return new Date(get(a, dataIndex)) - new Date(get(b, dataIndex));
};

export const sortDateFormat = (a, b, dataIndex) => {
  a = get(a, dataIndex).split('/');
  b = get(b, dataIndex).split('/');

  return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
};

export const sortNum = (a, b, dataIndex) => {
  return get(a, dataIndex) - get(b, dataIndex);
};
