import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { Form, Input, Row, Col } from 'antd';

const { Item } = Form;

const FormationEditMeta = ({ disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Item
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
          label="Intitulé"
          required
        >
          <Controller control={control} name="title" render={({ field }) => <Input {...field} disabled={disabled} />} />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          validateStatus={errors?.acronym?.message && 'error'}
          help={errors?.acronym?.message}
          label="Acronyme"
          required
        >
          <Controller
            control={control}
            name="acronym"
            render={({ field }) => <Input {...field} disabled={disabled} />}
          />
        </Item>
      </Col>
    </Row>
  );
};

export default FormationEditMeta;
