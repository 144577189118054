import { Button, Col, Divider, Form, Row, Select, Space, Switch } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { format, isBefore, isSameDay, isToday } from 'date-fns';
import { RedoOutlined } from '@ant-design/icons';
import fr from 'date-fns/locale/fr';
import { useEffect, useState } from 'react';

const RenderMonthlyReports = ({
  reports,
  showAllForms,
  toggleShowAllForms,
  formsOptions,
  formattedForms,
  isFormsValidating,
  onRefresh,
  disabled,
}) => {
  const [formattedReports, setFormattedReports] = useState([]);
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const selectedFormation = watch('studentFormation');

  useEffect(() => {
    let years = [];
    let filtered = [];
    let formatted = [];

    for (let i = 0; i < reports.length; i++) {
      const date = new Date(reports[i].originalDate);
      years.push(date.getFullYear());
    }

    filtered = years.filter((year, index) => years.indexOf(year) === index);

    for (let i = 0; i < filtered.length; i++) {
      formatted.push({ title: filtered[i].toString(), months: [] });
    }

    for (let i = 0; i < formatted.length; i++) {
      let filteredMonths = reports.filter(
        (month) => month?.originalDate?.getFullYear()?.toString() === formatted[i].title,
      );

      filteredMonths.forEach((month) =>
        formatted[i].months.push({
          originalDate: month.originalDate,
          sendAt: month.sendAt,
        }),
      );
    }

    setFormattedReports(formatted);
  }, []);

  const getLabel = (month) => {
    const formattedLabel = format(new Date(month), 'MMMM', { locale: fr });

    if (['avril', 'août', 'octobre'].includes(formattedLabel)) {
      return `Formulaire d'${formattedLabel}`;
    } else {
      return `Formulaire de ${formattedLabel}`;
    }
  };

  return (
    <Row gutter={[50, 20]}>
      {formattedReports &&
        formattedReports.map((year, index) => [
          ...(index !== 0
            ? [
                <Col span={2} key={`divider-${index}`}>
                  <Divider type="vertical" style={{ height: '100%' }} />
                </Col>,
              ]
            : []),
          <Col span={6} key={`year-${index}`}>
            <Divider>{year.title}</Divider>
            {year?.months?.map((month, monthIndex) => (
              <Form.Item
                label={`${getLabel(month.originalDate)}`}
                validateStatus={
                  errors?.reports?.[
                    reports.findIndex((item) => isSameDay(new Date(item.sendAt), new Date(month.sendAt)))
                  ]?.form?.message && 'error'
                }
                help={
                  errors?.reports?.[
                    reports.findIndex((item) => isSameDay(new Date(item.sendAt), new Date(month.sendAt)))
                  ]?.form?.message
                }
                required
                key={`${index}-${monthIndex}`}
              >
                <Controller
                  name={`reports.[${reports.findIndex((item) =>
                    isSameDay(new Date(item.sendAt), new Date(month.sendAt)),
                  )}].form`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={
                        disabled || isBefore(new Date(month.sendAt), new Date()) || isToday(new Date(month.sendAt))
                      }
                      options={
                        showAllForms
                          ? formattedForms.filter((form) => form?.formType === 'MENSUEL')
                          : formsOptions.filter((form) => form?.formType === 'MENSUEL')
                      }
                      placeholder="Sélectionnez dans la liste"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {selectedFormation && (
                            <>
                              <Divider style={{ margin: '8px 0' }} />
                              <div
                                style={{
                                  padding: '0 8px 8px 8px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Space style={{ padding: '0 8px 8px 8px' }}>
                                  <Switch
                                    checked={showAllForms}
                                    onChange={toggleShowAllForms}
                                    disabled={!selectedFormation || !formsOptions?.length || disabled}
                                  />
                                  Afficher tous les formulaires
                                </Space>
                                <Button onClick={onRefresh} icon={<RedoOutlined />} loading={isFormsValidating} />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    />
                  )}
                />
              </Form.Item>
            ))}
          </Col>,
        ])}
    </Row>
  );
};

export default RenderMonthlyReports;
