import { domToReact } from 'html-react-parser';
import VideoPlayer from './VideoPlayer';

const getTextTrack = (params) => {
  let hasTextTrack = false;
  let textTrack = {};

  if (
    params.has('source[textTracks][captions][url]') &&
    params.has('source[textTracks][captions][label]') &&
    params.has('source[textTracks][captions][language]')
  ) {
    hasTextTrack = true;
    textTrack.url = params.get('source[textTracks][captions][url]');
    textTrack.label = params.get('source[textTracks][captions][label]');
    textTrack.language = params.get('source[textTracks][captions][language]');
  }

  if (params.has('source[textTracks][captions][default]')) {
    textTrack.default = params.get('source[textTracks][captions][default]') === 'true';
  }

  return { hasTextTrack, textTrack };
};

export const formatMark = (node) => {
  if ('data-color' in node.attribs) {
    return (
      <mark {...node.attribs} style={{ backgroundColor: node.attribs['data-color'] }}>
        {domToReact(node.children)}
      </mark>
    );
  }
};

export const formatFigure = (node) => {
  if (!('data-video' in node.attribs)) {
    const image = node.children.filter((child) => child.type === 'tag' && child.name === 'img');
    const figcaption = node.children.filter((child) => child.type === 'tag' && child.name === 'figcaption');

    return (
      <figure {...node.attribs}>
        {domToReact(image)}
        {domToReact(figcaption)}
      </figure>
    );
  } else {
    const video = node.children.filter((child) => child.type === 'tag' && child.name === 'iframe');
    const videoUrlParams = new URLSearchParams(new URL(video[0].attribs.src).search);
    const videoPublicId = videoUrlParams.get('public_id');
    const { hasTextTrack, textTrack } = getTextTrack(videoUrlParams);

    const figcaption = node.children.filter((child) => child.type === 'tag' && child.name === 'figcaption');

    return (
      <figure {...node.attribs}>
        <VideoPlayer publicId={videoPublicId} textTracks={hasTextTrack ? { captions: textTrack } : {}} />
        {domToReact(figcaption)}
      </figure>
    );
  }
};

export const formatTableCell = (node) => {
  if (!['th', 'td'].includes(node.name)) {
    throw Error('Invalid tag');
  }

  const Tag = node.name;
  const width = node.attribs?.colwidth;
  const colSpan = node.attribs?.colspan || 1;
  const rowSpan = node.attribs?.rowspan || 1;

  if (Array.isArray(node.children)) {
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].type === 'tag' && node.children[i].name === 'figure') {
        formatFigure(node.children[i]);
      }
    }
  }

  return (
    <Tag id={node.attribs.id} colSpan={colSpan} rowSpan={rowSpan} style={{ width: width ? `${width}px` : null }}>
      {domToReact(node.children)}
    </Tag>
  );
};
