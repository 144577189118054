import { useEffect } from 'react';
import { Result, Table, Button, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';
import { sort } from '../../../../shared/utils';
import useColumnSearch from '../../../../hooks/useColumnSearch';
import tablePagination from '../../../../lib/tablePagination';

const StudentImportEnd = ({ onMount, status, result }) => {
  const { getColumnSearchProps } = useColumnSearch();

  useEffect(() => onMount(), []);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => sort(a, b, 'last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => sort(a, b, 'first_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('first_name'),
    },
    {
      title: "Nom d'utilisateur",
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => sort(a, b, 'username'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => sort(a, b, 'email'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Mot de passe',
      dataIndex: 'decryptedPassword',
      key: 'decryptedPassword',
    },
  ];

  const handleDownload = () => {
    const headers = ['last_name', 'first_name', 'email', 'username', 'decryptedPassword'];
    const titles = ['Nom', 'Prénom', 'Adresse email', "Nom d'utilisateur", 'Mot de passe'];
    const cleanData =
      result?.data.map((item) => ({
        last_name: item.last_name,
        first_name: item.first_name,
        email: item.email,
        username: item.username,
        decryptedPassword: item.decryptedPassword,
      })) || [];

    var ws = utils.json_to_sheet(cleanData, { header: headers });

    var wb = utils.book_new();

    utils.sheet_add_aoa(ws, [titles], { origin: 'A1' });
    utils.book_append_sheet(wb, ws, 'identifiants');

    writeFile(wb, `identifiants.xlsx`);
  };

  if (status === 'loading') {
    return <Result icon={<LoadingOutlined />} status="info" title="Importation en cours" />;
  }

  if (status === 'error') {
    return (
      <Result status="error" title="Erreur lors de l'importation">
        {result?.errors?.message}
      </Result>
    );
  }

  if (status === 'success') {
    return (
      <>
        <Result
          status="success"
          title="Importation terminée"
          style={{ maxWidth: '80ch', margin: '0 auto' }}
          subTitle="Les identifiants ont été générés et envoyés par email. Pour éviter tout problème, vous pouvez télécharger une copie des identifiants générés lors de l'importation, une fois cette page quittée ils ne seront plus visibles."
          extra={
            <Button type="primary" onClick={handleDownload}>
              Télécharger les identifiants
            </Button>
          }
        />
        <Table
          bordered
          size="small"
          dataSource={result?.data}
          columns={columns}
          rowKey={(row) => row._id}
          pagination={tablePagination(result?.data)}
        />
        <Link to="/formao/apprenants">
          <Button>Retour aux apprenants</Button>
        </Link>
      </>
    );
  }

  return null;
};

export default StudentImportEnd;
