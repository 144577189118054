// Permet de désactiver le drag'n'drop à l'intérieur d'un élément possédant l'attribut data-no-dnd="true"
import { KeyboardSensor as LibKeyboardSensor, MouseSensor as LibMouseSensor, PointerSensor as LibPointerSensor } from '@dnd-kit/core';

const shouldHandleEvent = (element) => {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }

    cur = cur.parentElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown',
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

export class KeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown',
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

export class PointerSensor extends LibPointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}
