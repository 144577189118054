import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import cn from 'classnames';

const TrueFalsePreview = ({ imagePreview }) => {
  const [selected, setSelected] = useState(null);
  const { control } = useFormContext();
  const values = useWatch({ control });

  return (
    <>
      {imagePreview ? (
        <img className="preview-question-image" src={imagePreview} alt="Prévisualisation de la question" />
      ) : null}

      <h3 className="preview-question">{values?.question}</h3>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          className={cn(`true-button`, {
            selected: selected,
          })}
          onClick={() => setSelected(true)}
        >
          Vrai
        </button>
        <button
          className={cn(`false-button`, {
            selected: selected === false,
          })}
          onClick={() => setSelected(false)}
        >
          Faux
        </button>
      </div>
    </>
  );
};

export default TrueFalsePreview;
