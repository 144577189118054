import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { Button, message, notification, Popconfirm, Space, Tooltip } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { reportRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import { checkAuthorization } from '../../../../shared/utils';
import AbortModal from './AbortModal';
import { useAuth } from '../../../../authContext';

const ReportActions = ({ report, mutate, authorizationCollection, editPath }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const { user } = useAuth();
  const { get, remove, patch } = useFetch();
  const currentReportLink = useMemo(() => {
    if (
      (['COMPANY', 'FORMATION'].includes(report.reportsType) && report.step && !report.step.endsWith('COMPLÉTÉ')) ||
      report.reportsType === 'MENSUEL'
    ) {
      const currentReport = report.reports?.find((item) => !item.ignored && !item.finishedAt && item.token);

      if (currentReport) {
        return `https://bilan.arinfo.fr/?t=${currentReport.token}`;
      }
    }

    if (report.reportsType === 'STAGE' && report.reports[0]?.token && !report.reports[0]?.finishedAt) {
      return `https://bilan.arinfo.fr/?t=${report.reports[0].token}`;
    }

    return '';
  }, [report]);
  const canResendReport = useMemo(() => {
    if (['COMPANY', 'FORMATION'].includes(report.reportsType)) {
      return report.step && !report.step.endsWith('COMPLÉTÉ');
    }

    if (report.reportsType === 'STAGE') {
      return report.reports[0].token && !report.reports[0].finishedAt;
    }

    if (report.reportsType === 'MENSUEL') {
      const waitingReports = report.reports.find((item) => !item.ignored && !item.finishedAt && item.token);

      console.log(waitingReports);
    }

    return false;
  }, [report]);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const resendMail = async () => {
    if (checkAuthorization(user, 'formao', authorizationCollection, 'act')) {
      setResendLoading(true);

      const results = await patch(reportRoutes.resend + '/' + report._id);

      if (results.status === 200) {
        mutate();
        setResendLoading(false);
      } else {
        if (results.message) {
          message.error(results.message);
        }
        setResendLoading(false);
      }
    }
  };

  const generatePDF = async (report) => {
    const canAccess = report.step === `BILAN_${report.reports?.length}_COMPLÉTÉ`;

    if (canAccess) {
      const key = report._id;

      notification.open({
        message: 'Génération du PDF en cours...',
        description: 'Votre PDF sera prêt dans quelques instants',
        icon: <LoadingOutlined />,
        duration: 0,
        closeIcon: <></>,
        key,
      });

      const results = await get(reportRoutes.groupedPdf + '/' + report._id);

      if (results.status === 201) {
        const blob = new Blob([Buffer.from(results.data)], {
          type: 'application/pdf',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';

        let date = format(new Date(report?.reports[report.reports?.length - 1]?.finishedAt), 'dd-MM-yyyy');

        link.download = `Ensemble-des-bilans_${report.student.lastName?.toUpperCase()}-${capitalize(
          report.student.firstName,
        )}_${date}.pdf`;
        link.click();
      } else {
        if (results.message) {
          message.error(results.message);
        }
      }

      notification.close(key);
    } else {
      message.error('Tous les bilans ne sont pas complétés');
    }
  };

  const deleteReport = async (id) => {
    const results = await remove(reportRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  return (
    <>
      <AbortModal
        isOpen={isModalOpen}
        reportType={report.reportsType}
        onClose={() => setIsModalOpen(false)}
        onFinish={mutate}
      />
      <Space>
        {report?.step === 'ANNULÉ' ? (
          <Tooltip title={`Suivi résilié le : ${format(new Date(report.abortDate), 'dd/MM/yyyy')}`}>
            <Button type="primary" danger>
              Résilié
            </Button>
          </Tooltip>
        ) : (
          checkAuthorization(user, 'formao', authorizationCollection, 'abort') && (
            <Button danger onClick={() => setIsModalOpen(report._id)}>
              Résilier
            </Button>
          )
        )}
        {report?.step !== 'ANNULÉ' && (
          <>
            <Tooltip title="Copier le lien du bilan">
              <Button
                onClick={() => {
                  if (currentReportLink) {
                    navigator.clipboard.writeText(currentReportLink);
                  }
                }}
                icon={<CopyOutlined />}
                disabled={!currentReportLink}
              />
            </Tooltip>
            {checkAuthorization(user, 'formao', authorizationCollection, 'act') && (
              <Tooltip title="Renvoyer le bilan">
                <Button
                  onClick={resendMail}
                  icon={<MailOutlined />}
                  disabled={!canResendReport}
                  loading={resendLoading}
                />
              </Tooltip>
            )}
            {['COMPANY', 'FORMATION'].includes(report.reportsType) &&
              checkAuthorization(user, 'formao', authorizationCollection, 'download-set') && (
                <Tooltip title="Télécharger le pdf de l'ensemble des bilans">
                  <Button
                    onClick={() => generatePDF(report)}
                    icon={<FilePdfOutlined />}
                    disabled={report.step !== `BILAN_${report.reports?.length}_COMPLÉTÉ`}
                  />
                </Tooltip>
              )}
            {checkAuthorization(user, 'formao', authorizationCollection, 'update') && (
              <Link to={editPath + report._id}>
                <Button icon={<EditOutlined />} />
              </Link>
            )}
            {checkAuthorization(user, 'formao', authorizationCollection, 'delete') && (
              <Tooltip title="Supprimer">
                <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(report._id)}>
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            )}
          </>
        )}
      </Space>
    </>
  );
};

export default ReportActions;
