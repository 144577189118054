import useSWR from 'swr';
import { useState } from 'react';
import { MoreOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Card, Tabs, Table, Tag, Button, Dropdown, Menu, Popconfirm, message, Popover } from 'antd';
import { typeRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { sort, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import CreateModal from './CreateModal';
import EditModal from './EditModal';

const { TabPane } = Tabs;
const { Item } = Menu;

const TypesList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { user } = useAuth();
  const { data: types, isValidating, mutate } = useSWR(typeRoutes.default);
  const enabledTypes =
    types?.data
      .filter((type) => !type.disabled)
      .sort((a, b) => {
        return a.title === 'Commun' ? -1 : b.title === 'Commun' ? 1 : 0;
      }) || [];
  const disabledTypes = types?.data.filter((type) => type.disabled) || [];
  const { put, remove } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
    cancelButtonProps: { disabled: isProcessing },
  };

  const onEnable = async (id) => {
    setIsProcessing(true);

    const results = await put(typeRoutes.enable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDisable = async (id) => {
    setIsProcessing(true);

    const results = await put(typeRoutes.disable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(typeRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const isUsable = (type) => {
    if (type.acronym === 'Commun') {
      if (type?.totals?.easy.enabled < 3 || type?.totals?.medium.enabled < 4 || type?.totals?.hard.enabled < 3) {
        return false;
      }
    } else {
      if (type?.totals?.easy.enabled < 5 || type?.totals?.medium.enabled < 10 || type?.totals?.hard.enabled < 5) {
        return false;
      }
    }
    return true;
  };

  const Actions = ({ record }) => (
    <Menu key={`menu-${record._id}`}>
      {checkAuthorization(user, 'posiao', 'types', 'create') && <EditModal onFinish={mutate} typeId={record._id} />}
      {record.disabled
        ? checkAuthorization(user, 'posiao', 'types', 'create') && (
            <Item key="enable" className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => onEnable(record._id)}>
                Activer
              </Popconfirm>
            </Item>
          )
        : checkAuthorization(user, 'posiao', 'types', 'disable') && (
            <Item key="disable" className="gray-text">
              <Popconfirm {...popconfirmProps} onConfirm={() => onDisable(record._id)}>
                Désactiver
              </Popconfirm>
            </Item>
          )}
      {checkAuthorization(user, 'posiao', 'types', 'delete') && (
        <Item key="delete" className="gray-text">
          <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
            Supprimer
          </Popconfirm>
        </Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Utilisable',
      dataIndex: 'totals',
      key: 'status',
      width: 80,
      align: 'center',
      sorter: (a, b) => {
        const aUsable = isUsable(a);
        const bUsable = isUsable(b);

        return aUsable + bUsable;
      },
      sortDirections: ['ascend', 'descend'],
      render: (value, { acronym }) => {
        if (acronym === 'Commun') {
          if (value?.easy.enabled < 3 || value?.medium.enabled < 4 || value?.hard.enabled < 3) {
            const conditions = () => (
              <ul style={{ margin: 0, listStylePosition: 'inside', padding: 0 }}>
                {value?.easy.enabled < 3 && (
                  <li>
                    Il manque {3 - value?.easy.enabled}
                    {3 - value?.easy.enabled === 1 ? ' question facile active' : ' questions faciles actives'}
                  </li>
                )}
                {value?.medium.enabled < 4 && (
                  <li>
                    Il manque {4 - value?.medium.enabled}
                    {3 - value?.medium.enabled === 1 ? ' question moyenne active' : ' questions moyennes actives'}
                  </li>
                )}
                {value?.hard.enabled < 3 && (
                  <li>
                    Il manque {3 - value?.hard.enabled}
                    {3 - value?.hard.enabled === 1 ? ' question difficile active' : ' questions difficiles actives'}
                  </li>
                )}
              </ul>
            );

            return (
              <Popover placement="top" content={conditions}>
                <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
              </Popover>
            );
          }
        } else {
          if (value?.easy.enabled < 5 || value?.medium.enabled < 10 || value?.hard.enabled < 5) {
            const conditions = () => (
              <ul style={{ margin: 0, listStylePosition: 'inside', padding: 0 }}>
                {value?.easy.enabled < 5 && (
                  <li>
                    Il manque {5 - value?.easy.enabled}
                    {5 - value?.easy.enabled === 1 ? ' question facile active' : ' questions faciles actives'}
                  </li>
                )}
                {value?.medium.enabled < 10 && (
                  <li>
                    Il manque {10 - value?.medium.enabled}
                    {10 - value?.medium.enabled === 1 ? ' question moyenne active' : ' questions moyennes actives'}
                  </li>
                )}
                {value?.hard.enabled < 5 && (
                  <li>
                    Il manque {5 - value?.hard.enabled}
                    {5 - value?.hard.enabled === 1 ? ' question difficile active' : ' questions difficiles actives'}
                  </li>
                )}
              </ul>
            );

            return (
              <Popover placement="top" content={conditions}>
                <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
              </Popover>
            );
          }
        }

        return <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />;
      },
    },
    {
      title: 'Acronyme',
      dataIndex: 'acronym',
      key: 'acronym',
      width: 130,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'acronym'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('acronym'),
      render: (acronym) => <Tag style={{ width: '100%', textAlign: 'center' }}>{acronym}</Tag>,
    },
    {
      title: 'Intitulé',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
      render: (title) => <>{title}</>,
    },
    {
      title: 'Questions faciles',
      dataIndex: 'totals',
      key: 'easy',
      width: 150,
      render: (value) => {
        const { enabled, disabled } = value?.easy;

        return (
          <>
            <span>
              {enabled || 0} active{enabled > 1 ? 's' : ''}
            </span>
            <br />
            <span>
              {disabled || 0} inactive{disabled > 1 ? 's' : ''}
            </span>
          </>
        );
      },
    },
    {
      title: 'Questions moyennes',
      dataIndex: 'totals',
      key: 'medium',
      width: 150,
      render: (value) => {
        const { enabled, disabled } = value?.medium;

        return (
          <>
            <span>
              {enabled || 0} active{enabled > 1 ? 's' : ''}
            </span>
            <br />
            <span>
              {disabled || 0} inactive{disabled > 1 ? 's' : ''}
            </span>
          </>
        );
      },
    },
    {
      title: 'Questions difficiles',
      dataIndex: 'totals',
      key: 'hard',
      width: 150,
      render: (value) => {
        const { enabled, disabled } = value?.hard;

        return (
          <>
            <span>
              {enabled || 0} active{enabled > 1 ? 's' : ''}
            </span>
            <br />
            <span>
              {disabled || 0} inactive{disabled > 1 ? 's' : ''}
            </span>
          </>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <>
          {record.title !== 'Commun' && (
            <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
              <Button icon={<MoreOutlined />} disabled={isProcessing} />
            </Dropdown>
          )}
        </>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'posiao', 'types', 'create') && <CreateModal onFinish={mutate} />}
      <Tabs defaultActiveKey="enabled">
        <TabPane tab={`Actifs (${enabledTypes?.length})`} key="enabled">
          <Table
            loading={isProcessing || isValidating}
            bordered
            size="small"
            columns={columns}
            dataSource={enabledTypes}
            rowKey={(row) => row._id}
            pagination={tablePagination(enabledTypes)}
          />
        </TabPane>
        <TabPane tab={`Inactifs (${disabledTypes?.length})`} key="disabled">
          <Table
            loading={isProcessing || isValidating}
            bordered
            size="small"
            columns={columns}
            dataSource={disabledTypes}
            rowKey={(row) => row._id}
            pagination={tablePagination(disabledTypes)}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default TypesList;
