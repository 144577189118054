import { Modal, Tabs, List, Typography, Checkbox, Button, Upload } from 'antd';
import useSWR from 'swr';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { documentRoutes } from '../../lib/routes';
import { sort } from '../../shared/utils';

const { TabPane } = Tabs;
const { Item } = List;
const { Text } = Typography;
const { Dragger } = Upload;

const FilesModal = ({ visible, onOk, onClose, selection, handleSelection }) => {
  const { data: documents } = useSWR(documentRoutes.public, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const handleFileUpload = () => {};

  return (
    <Modal
      title={
        selection.length >= 1
          ? `${selection.length} sélectionné${selection.length > 1 ? 's' : ''}`
          : 'Gestionnaire de fichiers'
      }
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      bodyStyle={{ padding: 0 }}
      width={1000}
    >
      <Tabs tabPosition="left">
        <TabPane tab="Tous les fichiers" key="*" style={{ padding: 0 }}>
          <Dragger height={130} onChange={handleFileUpload} beforeUpload={() => false} showUploadList={false}>
            <InboxOutlined style={{ fontSize: 40, color: '#008dff' }} />
            <p className="ant-upload-text">Cliquez ou faites glisser le fichier dans cette zone pour le téléverser</p>
          </Dragger>
          <List
            size="small"
            dataSource={documents?.data.sort((a, b) => sort(a, b, 'originalName'))}
            renderItem={(item) => (
              <Item>
                <Checkbox checked={selection.includes(item._id)} onChange={() => handleSelection(item._id)} />
                <Text editable style={{ width: '100%', margin: '0 16px 0 16px', left: 0 }}>
                  {item.originalName}
                </Text>
                <Button icon={<DeleteOutlined />} />
              </Item>
            )}
          />
        </TabPane>
        <TabPane tab="Arinfo" key="arinfo"></TabPane>
        <TabPane tab="Formao" key="formao"></TabPane>
        <TabPane tab="Posiao" key="posiao"></TabPane>
      </Tabs>
    </Modal>
  );
};

export default FilesModal;
