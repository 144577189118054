import useSWR from 'swr';
import { InputNumber, Table, Button, Card, Form, Space } from 'antd';
import { Prompt, Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { trainingRoutes, cpfRoutes } from '../../../lib/routes';
import { useMemo, useState, useEffect } from 'react';
import { sort } from '../../../shared/utils';
import useFetch from '../../../hooks/useFetch';

const getTrainings = (trainingsData) => {
  let trainings = [];

  if (trainingsData?.data) {
    trainings = trainingsData?.data.filter(({ type, cpf_url }) => type === 'outil' && !!cpf_url);
  }

  trainings = trainings.sort((a, b) => sort(a, b, 'title'));

  return trainings;
};

const CPFAdvices = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { put } = useFetch();
  const history = useHistory();
  const { control, formState, handleSubmit, setValue } = useForm();
  const { data: advices, isValidating: isAdvicesValidating } = useSWR(cpfRoutes.default + '/advices/find', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: trainingsData, isValidating } = useSWR(trainingRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const trainings = useMemo(() => getTrainings(trainingsData), [trainingsData]);

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    if (advices?.data) {
      setValue('shared', advices?.data.shared);
      setValue('dedicated', advices?.data.dedicated);
    }
  }, [advices]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    await put(cpfRoutes.default + '/advices/update', JSON.stringify(form));

    setIsBlocking(false);
    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    await put(cpfRoutes.default + '/advices/update', JSON.stringify(form));

    setIsBlocking(false);
    history.push('/arinfo/simulateur-cpf');

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Formation',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Durée moyenne conseillée',
      children: [
        {
          title: 'Formateur partagé (En groupe)',
          children: [
            {
              title: 'Opérationnel',
              key: 'initiation',
              width: 170,
              align: 'center',
              render: (record) => (
                <Controller
                  control={control}
                  name={`shared.${record._id}.initiation`}
                  render={({ field }) => (
                    <InputNumber {...field} style={{ width: '100%' }} size="small" addonAfter="jours" min={0} />
                  )}
                />
              ),
            },
            {
              title: 'Expert',
              key: 'perfection',
              width: 170,
              align: 'center',
              render: (record) => (
                <Controller
                  control={control}
                  name={`shared.${record._id}.perfection`}
                  render={({ field }) => (
                    <InputNumber {...field} style={{ width: '100%' }} size="small" addonAfter="jours" min={0} />
                  )}
                />
              ),
            },
          ],
        },
        {
          title: 'Formateur dédié (100% sur mesure)',
          children: [
            {
              title: 'Opérationnel',
              key: 'initiation',
              width: 170,
              align: 'center',
              render: (record) => (
                <Controller
                  control={control}
                  name={`dedicated.${record._id}.initiation`}
                  render={({ field }) => (
                    <InputNumber {...field} style={{ width: '100%' }} size="small" addonAfter="jours" min={0} />
                  )}
                />
              ),
            },
            {
              title: 'Expert',
              key: 'perfection',
              width: 170,
              align: 'center',
              render: (record) => (
                <Controller
                  control={control}
                  name={`dedicated.${record._id}.perfection`}
                  render={({ field }) => (
                    <InputNumber {...field} style={{ width: '100%' }} size="small" addonAfter="jours" min={0} />
                  )}
                />
              ),
            },
          ],
        },
      ],
    },
  ];

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Form noValidate layout="vertical">
        <Table
          loading={!trainings || isValidating || isAdvicesValidating}
          bordered
          size="small"
          columns={columns}
          dataSource={trainings}
          pagination={false}
          style={{ marginBottom: 24 }}
          tableLayout="auto"
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={!trainings || isValidating || isProcessing}>
            <Link to="/arinfo/simulateur-cpf">Annuler</Link>
          </Button>
          <Space>
            <Button onClick={handleSubmit(onSubmit)} loading={!trainings || isValidating || isProcessing}>
              Enregistrer
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit(onSubmitAndLeave)}
              loading={!trainings || isValidating || isProcessing}
            >
              Enregistrer et quitter
            </Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default CPFAdvices;
