import { useEffect, useRef, useState } from 'react';
import { useDragDropManager } from 'react-dnd';

const DnDScrollable = ({ children }) => {
  // inside container with scrollbar
  const [dragValue, setDragValue] = useState(false);
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const timerRef = useRef();
  const unsubscribeRef = useRef();

  const setScrollIntervall = (speed, container) => {
    timerRef.current = setInterval(() => {
      container.scrollBy(0, speed);
    }, 1);
  };

  useEffect(() => {
    if (dragValue) {
      unsubscribeRef.current = monitor.subscribeToOffsetChange(() => {
        const offset = monitor.getClientOffset();
        const container = document.getElementById('dnd-scrollable-container'); // container with scrollbar

        if (!offset || !container) return;

        if (offset.y < container.clientHeight / 2 - 200) {
          if (timerRef.current) clearInterval(timerRef.current);
          setScrollIntervall(-5, container);
        } else if (offset.y > container.clientHeight / 2 + 200) {
          if (timerRef.current) clearInterval(timerRef.current);
          setScrollIntervall(5, container);
        } else if (offset.y > container.clientHeight / 2 - 200 && offset.y < container.clientHeight / 2 + 200) {
          if (timerRef.current) clearInterval(timerRef.current);
        }
      });
    } else if (unsubscribeRef.current) {
      if (timerRef.current) clearInterval(timerRef.current);
      unsubscribeRef.current();
    }
  }, [dragValue, monitor]);

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      if (monitor.isDragging()) setDragValue(() => true);
      else if (!monitor.isDragging()) setDragValue(() => false);
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  return (
    <div id="dnd-scrollable-container" style={{ maxHeight: '100vh', overflow: 'scroll' }}>
      {children}
    </div>
  );
};

export default DnDScrollable;
