import { useState, useMemo, useEffect, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { uniqueId } from 'lodash';
import LineTo from 'react-lineto';
import cn from 'classnames';

const ConnectPreview = ({ currentTab }) => {
  const [selection, setSelection] = useState({ from: null, to: null });
  const [lines, setLines] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { control } = useFormContext();
  const values = useWatch({ control });
  const fromList = useMemo(
    () => values.fromList.map((rest) => ({ ...rest, id: uniqueId('preview-') })),
    [values.fromList],
  );
  const toList = useMemo(() => values.toList.map((rest) => ({ ...rest, id: uniqueId('preview-') })), [values.toList]);

  useEffect(() => {
    if (selection.from !== null && selection.to !== null) {
      const fromIndex = lines.findIndex((line) => line[0] === selection.from);
      let toIndex = lines.findIndex((line) => line[1] === selection.to);

      if (fromIndex === -1 && toIndex === -1) {
        setLines((lines) => [...lines, [selection.from, selection.to]]);
      } else {
        if (fromIndex !== toIndex) {
          lines.splice(fromIndex, 1);
          toIndex = lines.findIndex((line) => line[1] === selection.to);
          lines.splice(toIndex, 1);
          setLines((lines) => [...lines, [selection.from, selection.to]]);
        }
      }

      setSelection({ from: null, to: null });
    }
  }, [lines, selection.from, selection.to]);

  useEffect(() => {
    if (currentTab === 'preview') {
      forceUpdate();
    }
  }, [currentTab]);

  useEffect(() => {
    const Listener = () => {
      forceUpdate();
    };
    window.addEventListener('scroll', Listener);
    window.addEventListener('resize', Listener);

    return () => {
      window.removeEventListener('resize', Listener);
      window.removeEventListener('scroll', Listener);
    };
  }, [forceUpdate]);

  return (
    <>
      <div style={{ overflow: 'hidden', clear: 'both' }}>
        <div style={{ float: 'left', width: '40%' }}>
          {fromList.map((list, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 12 }}>
              {list.content}
              <div
                onClick={() => setSelection((rest) => ({ ...rest, from: list.id }))}
                style={{ margin: 0 }}
                className={cn(`preview-square square-${list.id}`, {
                  preview_selected: selection.from === list.id && lines.findIndex((line) => line[0] === list.id) === -1,
                  preview_linked: lines.findIndex((line) => line[0] === list.id) !== -1,
                  preview_linked_selected:
                    selection.from === list.id && lines.findIndex((line) => line[0] === list.id) !== -1,
                })}
              />
            </div>
          ))}
        </div>
        <div style={{ float: 'right', width: '40%' }}>
          {toList.map((list, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 12 }}>
              <div
                onClick={() => setSelection((rest) => ({ ...rest, to: list.id }))}
                style={{ margin: 0 }}
                className={cn(`preview-square square-${list.id}`, {
                  preview_selected: selection.to === list.id && lines.findIndex((line) => line[1] === list.id) === -1,
                  preview_linked: lines.findIndex((line) => line[1] === list.id) !== -1,
                  preview_linked_selected:
                    selection.to === list.id && lines.findIndex((line) => line[1] === list.id) !== -1,
                })}
              />
              {list.content}
            </div>
          ))}
        </div>
      </div>
      {currentTab === 'preview' &&
        lines.map((line, index) => (
          <LineTo
            className="lines"
            key={index}
            from={`square-${line[0]}`}
            fromAnchor="right"
            toAnchor="left"
            to={`square-${line[1]}`}
            borderWidth={1}
            borderColor="#315397"
          />
        ))}
    </>
  );
};

export default ConnectPreview;
