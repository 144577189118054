import React, { useState } from 'react';
import { useForm, Controller, useFormState, FormProvider } from 'react-hook-form';
import { Form, Button, Typography, message, Input, Divider, Select, Upload, Card, Tabs, DatePicker, Radio } from 'antd';
import ImgCrop from 'antd-img-crop';
import { serialize } from 'object-to-formdata';
import { Link, useHistory } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import PostPreview from './PostPreview';
import { imageUpload } from '../../../shared/tinymceConfig';
import { checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import { postRoutes } from '../../../lib/routes';

const PostCreate = () => {
  const methods = useForm({ defaultValues: { disabled: true } });
  const { handleSubmit, setError, control, setValue, watch } = methods;
  const { errors } = useFormState({ control });
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [tab, setTab] = useState('form');
  const history = useHistory();
  const { post } = useFetch();
  const { token, user } = useAuth();

  const onImageChange = (file) => {
    setValue('image', file);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagePreview(reader.result));
    reader.readAsDataURL(file);
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await post(postRoutes.create, formData, 'multipart/form-data');

    if (results.status === 201) {
      history.push('/arinfo/actualites');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const disabledTime = (selected) => {
    let disabledHours = [];
    let disabledMinutes = [];
    let current = moment();

    if (
      selected &&
      selected.isSame(current, 'day') &&
      selected.isSame(current, 'month') &&
      selected.isSame(current, 'year')
    ) {
      for (let i = 0; i < 24; i++) {
        if (i < new Date(current).getHours() + 1) {
          disabledHours.push(i);
        }
      }

      if (selected.isSame(current.add(1, 'hour'), 'hour')) {
        for (let j = 0; j < 60; j += 5) {
          if (j < new Date(current).getMinutes()) {
            disabledMinutes.push(j);
          }
        }
      }
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes,
    };
  };

  const onPublish = (event, onChange) => {
    const newValue = event.target.value;
    const canAccess =
      newValue === 'disabled'
        ? checkAuthorization(user, 'arinfo', 'posts', 'disable-post')
        : checkAuthorization(user, 'arinfo', 'posts', 'enable-post');
    if (canAccess) {
      onChange(newValue);

      if (newValue !== 'programmed') {
        setValue('publishAt', null);
      }
    }
  };

  return (
    <Card>
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Tabs defaultActiveKey="form" activeKey={tab} onChange={setTab}>
            <Tabs.TabPane tab="Formulaire" key="form">
              <Typography.Paragraph type="secondary">
                <blockquote style={{ marginTop: 0 }}>
                  <ul>
                    <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
                  </ul>
                </blockquote>
              </Typography.Paragraph>
              <Form.Item
                label="Image"
                validateStatus={errors?.image?.message && 'error'}
                help={errors?.image?.message}
                extra="La taille recommandée est de 270x230px"
                required
              >
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <ImgCrop
                      aspect={270 / 230}
                      rotate
                      zoom
                      modalTitle="Modifier l'image"
                      modalCancel="Annuler"
                      modalOk="Confirmer"
                      onModalOk={onImageChange}
                    >
                      <Upload.Dragger
                        name={field.name}
                        maxCount={1}
                        showUploadList={false}
                        height={150}
                        disabled={isProcessing}
                        accept="image/*"
                        style={{ border: errors?.image && '1px solid #ff4d4f' }}
                      >
                        {imagePreview ? (
                          <img
                            style={{
                              maxWidth: 'calc(100% - 32px)',
                              maxHeight: 118,
                              display: 'block',
                              margin: '0 auto',
                            }}
                            src={imagePreview}
                            alt=""
                          />
                        ) : (
                          <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                        )}
                      </Upload.Dragger>
                    </ImgCrop>
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Titre"
                required
                validateStatus={errors?.title?.message && 'error'}
                help={errors?.title?.message}
              >
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => <Input {...field} disabled={isProcessing} />}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors?.content?.message && 'error'}
                help={errors?.content?.message}
                label="Contenu"
                required
              >
                <Controller
                  control={control}
                  name="content"
                  render={({ field }) => (
                    <Editor
                      ref={field.ref}
                      value={field.value}
                      onEditorChange={field.onChange}
                      disabled={isProcessing}
                      init={{
                        ...imageUpload(token),
                        selector: 'textarea',
                        skin: false,
                        content_css: '/tiny.css',
                        min_height: 300,
                        menubar: false,
                        object_resizing: 'true',
                        resize: 'both',
                        plugins: [
                          'autoresize link image imagetools media',
                          'table paste',
                          'lists link',
                          'fullscreen',
                          'searchreplace',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic underline forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | link image media | fullscreen searchreplace',
                        block_formats: 'Paragraphe=p; Titre 1=h2; Titre 2=h3; Titre 3=h4;',
                        link_class_list: [
                          { title: 'Par défaut', value: '' },
                          { title: 'Lien invisible', value: 'hidden-link' },
                        ],
                      }}
                    />
                  )}
                />
              </Form.Item>
              <Divider>Référencement (SEO)</Divider>
              <Form.Item
                label="Description"
                required
                validateStatus={errors?.description?.message && 'error'}
                help={errors?.description?.message}
                extra="Une description rapide de l'actualité (longueur max : 160 caractères)"
              >
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => <Input {...field} disabled={isProcessing} maxLength={160} />}
                />
              </Form.Item>
              <Form.Item
                label="Mots-clés"
                validateStatus={errors?.tags?.message && 'error'}
                help={errors?.tags?.message}
                extra="Utilisez la touche Entrée pour ajouter plusieurs mots-clés"
              >
                <Controller
                  control={control}
                  name="tags"
                  render={({ field }) => (
                    <Select {...field} mode="tags" disabled={isProcessing} dropdownStyle={{ display: 'none' }} />
                  )}
                />
              </Form.Item>
              {(checkAuthorization(user, 'arinfo', 'posts', 'enable-post') ||
                checkAuthorization(user, 'arinfo', 'posts', 'disable-post')) && (
                <Form.Item
                  label="Publication"
                  validateStatus={errors?.published?.message && 'error'}
                  help={errors?.published?.message}
                  required
                >
                  <Controller
                    control={control}
                    name="published"
                    render={({ field }) => (
                      <Radio.Group
                        {...field}
                        onChange={(e) => onPublish(e, field.onChange)}
                        buttonStyle="solid"
                        disabled={isProcessing}
                      >
                        <Radio.Button
                          value="disabled"
                          disabled={!checkAuthorization(user, 'arinfo', 'posts', 'disable-post')}
                        >
                          Non publié
                        </Radio.Button>
                        <Radio.Button
                          value="published"
                          disabled={!checkAuthorization(user, 'arinfo', 'posts', 'enable-post')}
                        >
                          Publié
                        </Radio.Button>
                        <Radio.Button
                          value="programmed"
                          disabled={!checkAuthorization(user, 'arinfo', 'posts', 'enable-post')}
                        >
                          Programmé
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Programmer la publication"
                validateStatus={errors?.publishAt?.message && 'error'}
                help={errors?.publishAt?.message}
              >
                <Controller
                  control={control}
                  name="publishAt"
                  render={({ field }) => (
                    <DatePicker
                      value={field.value ? moment(field.value) : null}
                      onSelect={(value) => setValue('publishAt', new Date(value._d))}
                      format="DD/MM/YYYY HH:mm"
                      showNow={false}
                      showTime
                      minuteStep={5}
                      allowClear={false}
                      disabled={isProcessing || watch('published') !== 'programmed'}
                      disabledDate={(selected) => {
                        const current = moment();
                        return (
                          selected.isBefore(current, 'day') &&
                          selected.isSameOrBefore(current, 'month') &&
                          selected.isSameOrBefore(current, 'year')
                        );
                      }}
                      disabledTime={disabledTime}
                    />
                  )}
                />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Prévisualisation" key="preview">
              <PostPreview />
            </Tabs.TabPane>
          </Tabs>
          {tab === 'form' && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button className="gray-text" disabled={isProcessing}>
                <Link to="/arinfo/actualites">Annuler</Link>
              </Button>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            </div>
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default PostCreate;
