import { debounce } from 'lodash';
import { useState, useRef, useMemo } from 'react';
import { AutoComplete, Row, Spin, Tag } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';

const DebounceSelectFormer = ({ fetchOptions, debounceTimeout = 800, options, setOptions, disabled, fieldName }) => {
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);
  const { control } = useFormContext;

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <AutoComplete
          {...field}
          value={field?.value?.label}
          onChange={(value, option) => field.onChange(option || null)}
          filterOption={false}
          onSearch={debounceFetcher}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          disabled={disabled}
          placeholder="Exemple: Formateur"
        >
          {options &&
            options?.map((option) => (
              <AutoComplete.Option key={option.value} value={option.value} label={option.label}>
                <Row>
                  {option?.extra && <Tag>{option?.extra}</Tag>} {option?.label}
                </Row>
              </AutoComplete.Option>
            ))}
        </AutoComplete>
      )}
    />
  );
};

export default DebounceSelectFormer;
