import React, { useState } from 'react';
import { Modal, Button, List } from 'antd';
import { Link } from 'react-router-dom';
import CheckboxIllu from '../../../../assets/posiao/checkbox.svg';
import IllustratedIllu from '../../../../assets/posiao/illustrated.svg';
import CompletionIllu from '../../../../assets/posiao/completion.svg';
import ConnectIllu from '../../../../assets/posiao/connect.svg';
import OrderingIllu from '../../../../assets/posiao/ordering.svg';
import OrganizeIllu from '../../../../assets/posiao/organize.svg';
import LegendIllu from '../../../../assets/posiao/legend.svg';
import TrueFalseIllu from '../../../../assets/posiao/trueFalse.svg';

const CreateQuestionModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const types = [
    {
      type: 'default',
      img: CheckboxIllu,
      url: '/posiao/banque-de-question/nouvelle-question/cases-a-cocher',
      name: 'Cases à cocher',
      description:
        'Une question accompagnée ou non par une image avec plusieurs choix de réponses. Plusieurs réponses peuvent être bonnes.',
    },
    {
      type: 'illustrated',
      img: IllustratedIllu,
      url: '/posiao/banque-de-question/nouvelle-question/cases-a-cocher-illustrees',
      name: 'Cases à cocher illustrées',
      description:
        'Une question suivie de plusieurs images en guise de choix de réponses. Plusieurs réponses peuvent être bonnes.',
    },
    {
      type: 'organize',
      img: OrganizeIllu,
      url: '/posiao/banque-de-question/nouvelle-question/elements-a-trier',
      name: 'Éléments à trier',
      description: "Un certain nombre d'étiquettes à placer dans les bonnes colonnes.",
    },
    {
      type: 'legend',
      img: LegendIllu,
      url: '/posiao/banque-de-question/nouvelle-question/legendes-a-completer',
      name: 'Légendes à compléter',
      description: 'Une image possédant des légendes à compléter avec les différentes étiquettes.',
    },
    {
      type: 'ordering',
      img: OrderingIllu,
      url: '/posiao/banque-de-question/nouvelle-question/liste-a-ordonner',
      name: 'Liste à ordonner',
      description: 'Une liste contenant des élément à réordonner.',
    },
    {
      type: 'connect',
      img: ConnectIllu,
      url: '/posiao/banque-de-question/nouvelle-question/points-a-reliers',
      name: 'Points à relier',
      description: 'Deux listes contenant différents éléments à relier entre eux.',
    },
    {
      type: 'completion',
      img: CompletionIllu,
      url: '/posiao/banque-de-question/nouvelle-question/texte-a-completer',
      name: 'Texte à compléter',
      description: "Texte ou phrase possédant des trou à compléter par l'apprenant.",
      disabled: true,
    },
    {
      type: 'trueFalse',
      img: TrueFalseIllu,
      url: '/posiao/banque-de-question/nouvelle-question/vrai-ou-faux',
      name: 'Vrai ou faux',
      description: 'Une affirmation/question suivi de deux choix possibles, vrai ou faux.',
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={showModal} className="gray-text">
          Nouvelle question
        </Button>
        <Modal title="Choisissez un type de question" visible={isModalVisible} onCancel={onModalClose} footer={null}>
          <List
            itemLayout="horizontal"
            dataSource={types}
            rowKey={(record) => record.type}
            renderItem={(item) => (
              <>
                {!item.disabled ? (
                  <Link to={item.url}>
                    <List.Item className="types-list-item">
                      <List.Item.Meta
                        style={{
                          padding: '0 12px',
                          height: '120px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        avatar={<img style={{ width: '100px' }} alt={item.title} src={item.img} />}
                        title={item.name}
                        description={item.description}
                      />
                    </List.Item>
                  </Link>
                ) : (
                  <List.Item className="types-list-item-disabled">
                    <List.Item.Meta
                      style={{
                        padding: '0 12px',
                        height: '120px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      avatar={<img style={{ width: '100px' }} alt={item.title} src={item.img} />}
                      title={`${item.name} - Prochainement`}
                      description={item.description}
                    />
                  </List.Item>
                )}
              </>
            )}
          />
        </Modal>
      </div>
    </>
  );
};

export default CreateQuestionModal;
