import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Descriptions, Space, Tooltip, Typography, message, notification } from 'antd';
import { format, isBefore } from 'date-fns';
import { capitalize } from 'lodash';
import { memo } from 'react';
import useFetch from '../../../../hooks/useFetch';
import { reportRoutes } from '../../../../lib/routes';

const ReportDescription = memo(({ report, data, canDownload }) => {
  const current = new Date();
  const { get } = useFetch();

  const generatePDF = async () => {
    const key = report._id;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await get(reportRoutes.pdf + '/' + report.results);

    if (results.status === 201) {
      const blob = new Blob([Buffer.from(results.data)], {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      let date = format(new Date(report?.finishedAt), 'dd-MM-yyyy');

      link.download = `Bilan-de-stage_${data.student.lastName.toUpperCase()}-${capitalize(
        data.student.firstName,
      )}_${date}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  const getStatut = () => {
    if (data?.step === 'ANNULÉ') {
      return (
        <Space>
          <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
          <Typography.Text>Suivi résilié</Typography.Text>
        </Space>
      );
    } else if (isBefore(current, new Date(report.sendAt)) && !report.token && !report.finishedAt) {
      return (
        <Space>
          <ClockCircleOutlined style={{ color: '#faad14' }} />
          <Typography.Text>Programmé</Typography.Text>
        </Space>
      );
    } else {
      if (!report.finishedAt && report.token) {
        if (!report.results) {
          return (
            <Space>
              <SyncOutlined />
              <Typography.Text> En attente de l'apprenant </Typography.Text>
            </Space>
          );
        } else {
          return (
            <Space>
              <SyncOutlined />
              <Typography.Text> En attente du tuteur </Typography.Text>
            </Space>
          );
        }
      } else {
        return (
          <Space>
            <CheckCircleOutlined style={{ color: '#52c41a' }} />
            <Typography.Text>Complété le {format(new Date(report.finishedAt), 'dd/MM/yyyy')}</Typography.Text>
          </Space>
        );
      }
    }
  };

  return (
    <>
      <Descriptions
        className="report-card-formation-alternation"
        title="Bilan de stage"
        column={1}
        size="small"
        labelStyle={{ width: 130 }}
        bordered
      >
        <Descriptions.Item label="Statut">{getStatut()}</Descriptions.Item>
        <Descriptions.Item label={isBefore(current, new Date(report?.sendAt)) ? 'Envoi le' : 'Envoyé le'}>
          {format(new Date(report?.sendAt), 'dd/MM/yyyy')}
        </Descriptions.Item>
      </Descriptions>
      {report?.finishedAt && canDownload && (
        <Tooltip placement="right" title="Télécharger le pdf de ce bilan">
          <Button type="link" onClick={generatePDF} disabled={!report?.finishedAt}>
            Télécharger
          </Button>
        </Tooltip>
      )}
    </>
  );
});

export default ReportDescription;
