import React, { useState, useEffect } from 'react';
import { Table, Descriptions, Tooltip, Popconfirm, Button, Space, message, Card } from 'antd';
import { DeleteOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { uniqBy } from 'lodash';
import { sort, checkAuthorization } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { useAuth } from '../../../authContext';
import CreateAgencyDrawer from './components/CreateAgencyDrawer';
import UpdateAgencyDrawer from './components/UpdateAgencyDrawer';
import ImageModal from './components/ImageModal';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { agencyRoutes } from '../../../lib/routes';

const AgenciesList = () => {
  const { data: agencies, isValidating, mutate } = useSWR(agencyRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [states, setStates] = useState([]);
  const { user } = useAuth();
  const { post } = useFetch();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  useEffect(() => {
    if (agencies && agencies.data) {
      // Récupère la liste des régions
      const roles = agencies.data.map((agency) => ({
        text: agency.state,
        value: agency.state,
      }));

      // Retire les duplicatas
      const cleanStates = uniqBy(roles, 'value');

      setStates(cleanStates);
    }
  }, [agencies]);

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await post(agencyRoutes.delete, JSON.stringify({ ids: [id] }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 80,
      align: 'center',
      render: (value) => {
        if (value) {
          return <ImageModal image={value} />;
        } else {
          return <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 16 }} />;
        }
      },
    },
    {
      title: 'Ville',
      dataIndex: 'city',
      key: 'city',
      width: 350,
      sorter: (a, b) => sort(a, b, 'city'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('city'),
    },
    {
      title: 'Région',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => sort(a, b, 'state'),
      sortDirections: ['ascend', 'descend'],
      filters: states,
      onFilter: (value, record) => record.state === value,
    },
    {
      title: 'Acronym',
      dataIndex: 'acronym',
      key: 'acronym',
      width: 120,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'acronym'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('acronym'),
      render: (value) => value || '',
    },
    {
      title: 'Pôle I-magineLab',
      dataIndex: 'imaginelab',
      key: 'imaginelab',
      width: 150,
      align: 'center',
      sorter: (a, b) => (a.imaginelab ? 1 : -1),
      sortDirections: ['ascend', 'descend'],
      render: (record) => {
        return record ? (
          <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 16 }} />
        );
      },
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'global', 'agencies', 'update-agency') && (
            <UpdateAgencyDrawer record={record} onFinish={mutate} />
          )}
          {checkAuthorization(user, 'global', 'agencies', 'delete-agency') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'global', 'agencies', 'create-agency') && <CreateAgencyDrawer onFinish={mutate} />}
      <Table
        loading={!agencies || isValidating || isProcessing}
        dataSource={agencies?.data || []}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 320 }}
        rowKey={(row) => row._id}
        expandable={{
          expandedRowRender: (record) => {
            const { city, postal_code, street_type, street, street_indicator, street_number } = record;
            const indicator = street_indicator ? `${street_indicator} ` : '';

            return (
              <Descriptions>
                <Descriptions.Item label="Adresse complète" span={3}>
                  {`${street_number} ${indicator}${street_type} ${street}, ${postal_code} ${city}`}
                </Descriptions.Item>
                <Descriptions.Item label="Adresse email" span={3}>
                  {record.email}
                </Descriptions.Item>
                <Descriptions.Item label="Téléphone" span={3}>
                  {record.phone.match(/.{2}/g).join(' ')}
                </Descriptions.Item>
              </Descriptions>
            );
          },
        }}
        pagination={tablePagination(agencies?.data)}
      />
    </Card>
  );
};

export default AgenciesList;
