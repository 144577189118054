import { read, utils } from 'xlsx';
import { nanoid } from 'nanoid';

const parseExcel = (file, options = { includeId: false }) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = read(e.target.result, { type: 'array' });
      // Récupère la première feuille
      const wsName = data.SheetNames[0];
      const ws = data.Sheets[wsName];
      // Converti en tableau de tableau
      const parsed = utils.sheet_to_json(ws, { header: 1, blankrows: false });
      // Récupère les champs
      const fields = parsed.splice(0, 1)[0];
      // Converti en tableau d'objet
      const json = parsed.map((values) => {
        const obj = {};

        fields.map((field, index) => {
          obj[field] = values[index];
        });

        if (options.includeId) {
          obj.id = nanoid();
        }

        return obj;
      });

      resolve(json);
    };

    reader.readAsArrayBuffer(file);
  });
};

export default parseExcel;
