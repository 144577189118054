import { Menu, Drawer, Layout } from 'antd';
import { get } from 'lodash';
import { memo, useEffect, useState } from 'react';
import hljs from 'highlight.js';

import HtmlView from './HtmlView';
import PdfView from './PdfView';

const ModuleModal = memo(({ visible, data, onClose }) => {
  const [activeCourse, setActiveCourse] = useState(null);

  const renderCourse = (path) => {
    const course = get(data, path);

    if (!!course) {
      setActiveCourse(course);
    }
  };

  useEffect(() => {
    if (visible) {
      const initialCourse = get(data, 'chapters.0');

      if (initialCourse?.file) {
        setActiveCourse(initialCourse?.file);
      } else {
        renderCourse('chapters.0.courses.0');
      }
    }
  }, [visible]);

  useEffect(() => {
    document.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el);
    });
  }, [visible, activeCourse]);

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={data?.title || ''}
      placement="top"
      height="100vh"
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      <Layout style={{ background: '#fff' }}>
        <Layout.Sider width={250} theme="light" style={{ background: '#fff' }}>
          <Menu
            style={{ height: '100%', maxHeight: 'calc(100vh - 54.8px)', overflow: 'scroll' }}
            selectedKeys={[activeCourse?._id]}
          >
            {(data?.chapters || []).map((chapter, chapterIndex) => {
              if (chapter?.file) {
                return (
                  <Menu.ItemGroup key={chapter._id} title={chapter.title}>
                    <Menu.Item
                      key={chapter.file._id}
                      title="Lecteur PDF"
                      onClick={() => renderCourse(`chapters.${chapterIndex}.file`)}
                    >
                      Lecteur PDF
                    </Menu.Item>
                  </Menu.ItemGroup>
                );
              }

              return (
                <Menu.ItemGroup key={chapter._id} title={chapter.title}>
                  {(chapter?.courses || []).map((course, courseIndex) => (
                    <Menu.Item
                      key={course._id}
                      title={course.title}
                      onClick={() => renderCourse(`chapters.${chapterIndex}.courses.${courseIndex}`)}
                    >
                      {course.title}
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              );
            })}
          </Menu>
        </Layout.Sider>
        <Layout.Content style={{ background: '#fff', height: 'calc(100vh - 54.8px)', padding: 24, overflow: 'scroll' }}>
          <div id="formao" key={activeCourse?._id}>
            {activeCourse?.size ? <PdfView data={activeCourse} /> : <HtmlView data={activeCourse} />}
          </div>
        </Layout.Content>
      </Layout>
    </Drawer>
  );
});

export default ModuleModal;
