import { Table, Typography } from 'antd';
import { isNumber } from 'lodash';
import { useMemo } from 'react';

const isEmpty = (value) => {
  if (typeof value === 'string') {
    if (Number.isNaN(value) && Number.isNaN(parseFloat(value))) {
      return true;
    }
  } else if (!isNumber(value)) {
    return true;
  }

  return false;
};

const createRows = (data, keys) => {
  const rows = [];
  const columnsKeys = [
    'satisfaction_rate',
    'real',
    'presentation',
    'success_rate',
    'break_rate',
    'insertion',
    'after_training',
  ];

  for (let i = 0; i < keys.length; i++) {
    const match = data.find((item) => item.training === keys[i]);

    if (match) {
      rows.push({
        training: keys[i],
        ...columnsKeys.reduce((acc, curr) => {
          if (!(curr in match) || isEmpty(match[curr])) {
            return { ...acc, [curr]: '/' };
          }

          return { ...acc, [curr]: match[curr].toString() };
        }, {}),
      });
    } else {
      rows.push({
        training: keys[i],
        ...columnsKeys.reduce((acc, curr) => {
          return { ...acc, [curr]: '/' };
        }, {}),
      });
    }
  }

  return rows;
};

const StatisticsExpanded = ({ values, learning }) => {
  const valuesRows = useMemo(
    () => createRows(values, ['TEBDP', 'IMEP', 'CDUI', 'DWWM', 'CDA', 'TAI', 'COBIM', 'BIMOD', 'Outils']),
    [values],
  );
  const learningRows = useMemo(
    () => createRows(learning, ['TEBDP', 'IMEP', 'CDUI', 'DWWM', 'CDA', 'TAI', 'COBIM', 'BIMOD']),
    [learning],
  );

  const valuesColumns = [
    {
      title: 'OF + CFA',
      dataIndex: 'training',
      key: 'training',
    },
    {
      title: 'Taux de satisfaction',
      dataIndex: 'satisfaction_rate',
      key: 'satisfaction_rate',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Effectif formé',
      dataIndex: 'real',
      key: 'real',
    },
    {
      title: 'Taux de présentation',
      dataIndex: 'presentation',
      key: 'presentation',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Taux de réussite',
      dataIndex: 'success_rate',
      key: 'success_rate',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Taux de rupture',
      dataIndex: 'break_rate',
      key: 'break_rate',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Insertion pro. à 6 mois',
      dataIndex: 'insertion',
      key: 'insertion',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: "Poursuite d'études",
      dataIndex: 'after_training',
      key: 'after_training',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
  ];

  const learningColumns = [
    {
      title: 'CFA',
      dataIndex: 'training',
      key: 'training',
    },
    {
      title: 'Taux de satisfaction',
      dataIndex: 'satisfaction_rate',
      key: 'satisfaction_rate',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Effectif formé',
      dataIndex: 'real',
      key: 'real',
    },
    {
      title: 'Taux de présentation',
      dataIndex: 'presentation',
      key: 'presentation',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Taux de réussite',
      dataIndex: 'success_rate',
      key: 'success_rate',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Taux de rupture',
      dataIndex: 'break_rate',
      key: 'break_rate',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: 'Insertion pro. à 6 mois',
      dataIndex: 'insertion',
      key: 'insertion',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
    {
      title: "Poursuite d'études",
      dataIndex: 'after_training',
      key: 'after_training',
      render: (value) => (value === '/' ? '/' : `${value} %`),
    },
  ];

  return (
    <div style={{ marginLeft: 48 }}>
      <Typography.Title level={5} style={{ marginTop: 4 }}>
        Indicateurs pour la Formation Professionnelle Continue (OF) et l'Apprentissage (CFA)
      </Typography.Title>
      <Table
        bordered
        size="small"
        columns={valuesColumns}
        dataSource={valuesRows}
        rowKey={(row) => row._id}
        pagination={false}
      />
      <Typography.Title level={5} style={{ marginTop: 4 }}>
        Indicateurs pour l'Apprentissage (CFA) uniquement
      </Typography.Title>
      <Table
        bordered
        size="small"
        columns={learningColumns}
        dataSource={learningRows}
        rowKey={(row) => row._id}
        pagination={false}
      />
    </div>
  );
};

export default StatisticsExpanded;
