import { Layout, Menu, Dropdown, Space, Avatar } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '../authContext';

const { Header } = Layout;

const UserMenu = ({ onLogout }) => (
  <Menu>
    <Menu.Item key="settings" className="gray-text">
      <Link to="/parametres">Paramètres</Link>
    </Menu.Item>
    <Menu.Item key="logout" className="gray-text" onClick={onLogout}>
      Déconnexion
    </Menu.Item>
  </Menu>
);

const Navbar = () => {
  const { user, logout } = useAuth();
  const history = useHistory();

  return (
    <Header
      className="navbar"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        padding: '0 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 1000,
        height: 48,
      }}
    >
      <Link to="/" style={{ color: 'white', display: 'block', height: '100%', display: 'flex', alignItems: 'center' }}>
        <img src="/logos_Arinfo_RVB_MonoBL_Horizontal.svg" alt="Accueil" style={{ height: '100%', display: 'block' }} />
      </Link>
      <Dropdown
        trigger={['click']}
        overlay={<UserMenu onLogout={() => logout(() => history.push('/connexion'))} />}
        placement="bottomRight"
      >
        <div style={{ display: 'flex', color: 'white', cursor: 'pointer', height: '100%' }}>
          <Space style={{ height: '100%', lineHeight: 'initial' }}>
            {user.first_name + ' ' + user.last_name.charAt(0) + '.'}
            <Avatar
              src={user?.avatar?.secure_url}
              style={{ textTransform: 'uppercase', fontSize: 16, backgroundColor: '#9BA1CB' }}
              size={32}
            >
              {user.first_name.charAt(0) + user.last_name.charAt(0)}
            </Avatar>
          </Space>
        </div>
      </Dropdown>
    </Header>
  );
};

export default Navbar;
