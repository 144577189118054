import { Card, Form, Button, Input, Upload, Select, Typography, Alert, message } from 'antd';
import { Link, Prompt } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { serialize } from 'object-to-formdata';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { moduleRoutes } from '../../../../lib/routes';

const { Item } = Form;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Paragraph } = Typography;

const ModuleCreate = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const { handleSubmit, control, setValue, setError, formState } = useForm();
  const { errors } = useFormState({ control });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await post(moduleRoutes.default, formData, 'multipart/form-data');

    if (results.status === 201) {
      setIsBlocking(false);
      history.push(`/formao/cours/modifier-un-cours/${results.data._id}`);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onImageChange = ({ file }) => {
    setIsBlocking(true);
    setValue('image', file.originFileObj);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagePreview(reader.result));
    reader.readAsDataURL(file.originFileObj);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            </ul>
          </blockquote>
        </Paragraph>
        <Item
          label="Image"
          validateStatus={errors?.image && 'error'}
          help={errors?.image?.message}
          extra="La taille recommandée est de 650x175px"
        >
          <Controller
            control={control}
            name="image"
            render={({ field }) => (
              <Dragger
                name={field.name}
                maxCount={1}
                showUploadList={false}
                height={150}
                disabled={isProcessing}
                accept="image/*"
                style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                onChange={(img) => onImageChange(img)}
              >
                {imagePreview ? (
                  <img
                    style={{
                      maxWidth: 'calc(100% - 32px)',
                      maxHeight: 100,
                      display: 'block',
                      margin: '0 auto',
                    }}
                    src={imagePreview}
                    alt=""
                  />
                ) : (
                  <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                )}
              </Dragger>
            )}
          />
        </Item>
        <Item
          validateStatus={errors?.title?.message && 'error'}
          help={errors?.title?.message}
          label="Intitulé"
          required
        >
          <Controller
            control={control}
            name="title"
            render={({ field }) => <Input {...field} disabled={isProcessing} />}
          />
        </Item>
        <Item
          validateStatus={errors?.goals?.message && 'error'}
          help={errors?.goals?.message}
          label="Objectifs"
          required
        >
          <Controller
            control={control}
            name="goals"
            render={({ field }) => <TextArea {...field} disabled={isProcessing} />}
          />
        </Item>
        <Item label="Difficulté" validateStatus={errors?.level?.message && 'error'} help={errors?.level?.message}>
          <Controller
            name="level"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                disabled={isProcessing}
                placeholder="Sélectionnez dans la liste"
                allowClear
                options={[
                  { label: 'Rookie (Débutant)', value: 'easy' },
                  { label: 'Aventurier (Avancé)', value: 'medium' },
                  { label: 'Explorateur (Expert)', value: 'hard' },
                ]}
              />
            )}
          />
        </Item>
        <Alert
          style={{ marginBottom: 20 }}
          showIcon
          type="info"
          message="Vous pourrez accéder à la création des cours et des chapitres une fois cette étape terminée."
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={isProcessing}>
            <Link to="/formao/cours">Annuler</Link>
          </Button>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default ModuleCreate;
