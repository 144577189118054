import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, Form, Typography, Steps } from 'antd';
import { Prompt } from 'react-router-dom';
import StudentImportUpload from './StudentImportUpload';
import StudentImportPreFormation from './StudentImportPreFormation';
import StudentImportEnd from './StudentImportEnd';
import { studentRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const { Step } = Steps;

const StudentCreate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [result, setResult] = useState({ status: null, data: null });
  const [currentStep, setCurrentStep] = useState(0);
  const [isBlocking, setIsBlocking] = useState(false);
  const methods = useForm({ defaultValues: { withFormation: false, agencyMode: 'global' } });
  const { post } = useFetch();

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(studentRoutes.import, JSON.stringify(form));

    setResult(results);
    setIsBlocking(false);

    setIsProcessing(false);
  };

  const handlePrev = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (currentStep !== 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleFinish = () => {
    setCurrentStep(2);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
                <li>Les noms d'utilisateur et les mots de passe seront générés</li>
                <li>
                  Vous pouvez télécharger le fichier{' '}
                  <a href="/download/stagiaires.xlsx" download>
                    ICI
                  </a>
                </li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Steps size="small" current={currentStep} style={{ marginBottom: 24 }}>
            <Step title="Importation" />
            <Step title="Formation" />
            <Step title="Finalisation" />
          </Steps>
          {currentStep === 0 && <StudentImportUpload onNext={handleNext} />}
          {currentStep === 1 && (
            <StudentImportPreFormation onPrev={handlePrev} onNext={handleNext} onFinish={handleFinish} />
          )}
          {currentStep === 2 && (
            <StudentImportEnd
              onMount={methods.handleSubmit(onSubmit)}
              status={isProcessing ? 'loading' : result.status === 201 ? 'success' : 'error'}
              result={result}
            />
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default StudentCreate;
