import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Tooltip, message, Popconfirm, Card } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { sort, checkAuthorization } from '../../../shared/utils';
import { useAuth } from '../../../authContext';
import types from '../../../datasets/project_types.json';
import tablePagination from '../../../lib/tablePagination';
import { projectRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const ProjectsList = () => {
  const { data: projects, isValidating, mutate } = useSWR(projectRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const { user } = useAuth();
  const { remove } = useFetch();

  useEffect(() => {
    if (projects && projects.data) {
      const extractedAgencies = projects.data.map((project) => ({
        text: project.agency.city,
        value: project.agency._id,
      }));

      const cleanAgencies = uniqBy(extractedAgencies, 'value');

      setAgencies(cleanAgencies);
    }
  }, [projects]);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const onDelete = async (id) => {
    setIsProcessing(true);

    const results = await remove(projectRoutes.delete + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      onFilter: (value, record) => record.type === value,
      sorter: (a, b) => sort(a, b, 'type'),
      sortDirections: ['ascend', 'descend'],
      filters: types.map((type) => ({ text: type.label, value: type.key })),
      onFilter: (value, record) => record.type === value,
      render: (value) => {
        const obj = types.filter((type) => type.key === value)[0];
        return obj.label;
      },
    },
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Auteur',
      dataIndex: 'author',
      key: 'author',
      width: 220,
      sorter: (a, b) => sort(a, b, 'author', 'Anonyme'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('author'),
      render: (value) => {
        if (!value) return 'Anonyme';
        return value;
      },
    },
    {
      title: 'Centre',
      dataIndex: 'agency',
      key: 'agency',
      width: 200,
      sorter: (a, b) => sort(a, b, 'agency.city'),
      sortDirections: ['ascend', 'descend'],
      filters: agencies,
      onFilter: (value, record) => record.agency._id === value,
      render: (value) => value.city,
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          {checkAuthorization(user, 'arinfo', 'projects', 'update-project') && (
            <Tooltip title="Modifier" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/arinfo/galerie/modifier-un-projet/${record._id}`}>
                <Button className="gray-text" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          )}
          {checkAuthorization(user, 'arinfo', 'projects', 'delete-project') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => onDelete(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'projects', 'create-project') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/arinfo/galerie/nouveau-projet">Nouveau projet</Link>
          </Button>
        </div>
      )}
      <Table
        loading={!projects || isValidating || isProcessing}
        dataSource={projects?.data || []}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 992 }}
        rowKey={(row) => row._id}
        pagination={tablePagination(projects?.data)}
      />
    </Card>
  );
};

export default ProjectsList;
