import { useState, useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Form, Col, Input, Button, message } from 'antd';
import { Prompt } from 'react-router-dom';
import { useAuth } from '../../../authContext';
import useFetch from '../../../hooks/useFetch';
import { userRoutes } from '../../../lib/routes';

const { Password } = Input;
const { Item } = Form;

const PasswordEdit = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const { user } = useAuth();
  const { handleSubmit, formState, control, setError } = useForm();
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);
    const results = await put(userRoutes.updatePassword, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications de mot de passe, voulez-vous vraiment quitter cette page ?"
      />
      <Form layout="vertical" noValidate onFinish={handleSubmit(onSubmit)}>
        <Col span={12}>
          <Item
            label="Mot de passe actuel"
            disabled={isProcessing}
            validateStatus={errors?.password?.message && 'error'}
            help={errors?.password?.message}
            required
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => <Password autoComplete="new-password" {...field} disabled={isProcessing} />}
            />
          </Item>
          <Item
            label="Nouveau mot de passe"
            disabled={isProcessing}
            validateStatus={errors?.new_password?.message && 'error'}
            help={errors?.new_password?.message}
            required
          >
            <Controller
              name="new_password"
              disabled={isProcessing}
              control={control}
              render={({ field }) => <Password autoComplete="new-password" {...field} disabled={isProcessing} />}
            />
          </Item>

          <Item
            label="Confirmer le mot de passe"
            validateStatus={errors?.password_confirm?.message && 'error'}
            help={errors?.password_confirm?.message}
            required
          >
            <Controller
              name="password_confirm"
              control={control}
              render={({ field }) => <Password autoComplete="new-password" {...field} disabled={isProcessing} />}
            />
          </Item>
        </Col>
        <div style={{ marginTop: 20 }}>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </>
  );
};
export default PasswordEdit;
