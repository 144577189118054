import { useState, memo } from 'react';
import { Card, Button, Table, Popconfirm, message, Menu, Dropdown, Tabs, Select } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { groupRoutes, userRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';
import { sort, checkAuthorization } from '../../../shared/utils';
import SatisfactionReportModal from './components/SatisfactionReportModal';

const { Item } = Menu;
const { TabPane } = Tabs;

const options = [
  { label: 'Mes sessions', value: 'own' },
  { label: 'Toutes les sessions', value: 'all' },
];

const GroupsList = () => {
  const { data: groups, isValidating, mutate } = useSWR(groupRoutes.default);
  const { data: users, isValidating: isUsersValidating } = useSWR(userRoutes.default);
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeView, setActiveView] = useState('own');
  const [satisfactionReports, setSatisfactionReports] = useState(null);
  const { put } = useFetch();
  const { user } = useAuth();
  const activeStudents = groups?.data.filter((group) => !group.disabled) || [];
  const ownActiveStudents = activeStudents.filter((group) => group.referent?._id === user._id);
  const disabledStudents = groups?.data.filter((group) => group.disabled) || [];
  const ownDisabledStudents = disabledStudents.filter((group) => group.referent?._id === user._id);

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const enable = async (id) => {
    setIsProcessing(true);

    const results = await put(groupRoutes.enable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disable = async (id) => {
    setIsProcessing(true);

    const results = await put(groupRoutes.disable + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const setNewReferent = async (id, newReferent) => {
    setIsProcessing(true);

    const results = await put(groupRoutes.referent + '/' + id, JSON.stringify({ referent: newReferent }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ record }) => (
    <Menu key={`menu-${record._id}`}>
      {checkAuthorization(user, 'formao', 'groups', 'update-group') && (
        <Item key="edit">
          <Link to={`/formao/sessions/modifier-une-session/${record._id}`}>Modifier</Link>
        </Item>
      )}
      {record.disabled
        ? checkAuthorization(user, 'formao', 'groups', 'enable-group') && (
            <Item key="enable">
              <Popconfirm {...popconfirmProps} onConfirm={() => enable(record._id)}>
                Activer
              </Popconfirm>
            </Item>
          )
        : checkAuthorization(user, 'formao', 'groups', 'disable-group') && (
            <Item key="disable">
              <Popconfirm {...popconfirmProps} onConfirm={() => disable(record._id)}>
                Désactiver
              </Popconfirm>
            </Item>
          )}
      {checkAuthorization(user, 'formao', 'satisfactionReports', 'search') && (
        <Item key="export" onClick={() => setSatisfactionReports(record)}>
          Consulter tous les bilans de satisfaction
        </Item>
      )}
    </Menu>
  ));

  const columns = [
    { title: 'Intitulé', dataIndex: 'name', key: 'name' },
    {
      title: 'Centre',
      dataIndex: 'agency',
      key: 'agency',
      width: 250,
      render: (value, record) => (!!record.remote ? 'Distanciel' : value.city),
    },
    {
      title: 'Référent',
      dataIndex: 'referent',
      key: 'referent',
      width: 250,
      render: (value, record) => (
        <Select
          style={{ width: '100%' }}
          disabled={!users || isProcessing}
          placeholder="Sélectionnez dans la liste"
          value={value._id}
          onChange={(newValue) => setNewReferent(record._id, newValue)}
          options={
            users?.data
              .sort((a, b) => sort(a, b, 'last_name'))
              .map((user) => ({
                label: `${user.last_name.toUpperCase()} ${
                  user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                }`,
                value: user._id,
              })) || []
          }
        />
      ),
    },
    {
      title: 'Apprenants',
      dataIndex: 'students',
      key: 'students',
      width: 100,
      align: 'center',
      render: (value) => value.length,
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'formao', 'groups', 'create-group') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button className="gray-text">
            <Link to="/formao/sessions/nouvelle-session">Nouvelle session</Link>
          </Button>
        </div>
      )}
      <SatisfactionReportModal data={satisfactionReports} onClose={() => setSatisfactionReports(null)} />
      <Tabs
        tabBarExtraContent={{
          right: <Select options={options} value={activeView} onChange={setActiveView}></Select>,
        }}
      >
        {activeView === 'own' && (
          <>
            <TabPane tab={`Actives (${ownActiveStudents.length})`} key="own-enabled">
              <Table
                loading={!groups || isValidating || isProcessing}
                dataSource={ownActiveStudents}
                columns={columns}
                bordered
                size="small"
                scroll={{ x: 992 }}
                rowKey={(row) => row._id}
                pagination={tablePagination(ownActiveStudents)}
              />
            </TabPane>
            <TabPane tab={`Inactives (${ownDisabledStudents.length})`} key="own-disabled">
              <Table
                loading={!groups || isValidating || isProcessing}
                dataSource={ownDisabledStudents}
                columns={columns}
                bordered
                size="small"
                scroll={{ x: 992 }}
                rowKey={(row) => row._id}
                pagination={tablePagination(ownDisabledStudents)}
              />
            </TabPane>
          </>
        )}
        {activeView === 'all' && (
          <>
            <TabPane tab={`Actifs (${activeStudents.length})`} key="all-enabled">
              <Table
                loading={!groups || isValidating || isProcessing}
                dataSource={activeStudents}
                columns={columns}
                bordered
                size="small"
                scroll={{ x: 992 }}
                rowKey={(row) => row._id}
                pagination={tablePagination(activeStudents)}
              />
            </TabPane>
            <TabPane tab={`Inactifs (${disabledStudents.length})`} key="all-disabled">
              <Table
                loading={!groups || isValidating || isProcessing}
                dataSource={disabledStudents}
                columns={columns}
                bordered
                size="small"
                scroll={{ x: 992 }}
                rowKey={(row) => row._id}
                pagination={tablePagination(disabledStudents)}
              />
            </TabPane>
          </>
        )}
      </Tabs>
    </Card>
  );
};

export default GroupsList;
