import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core';

export const inputRegex = /^\s*>\s$/;

export const Important = Node.create({
  name: 'important',
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'important',
      },
    };
  },
  content: 'block+',
  group: 'block',
  defining: true,
  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (el) => el.className === 'important',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      setImportant:
        () =>
        ({ commands }) => {
          return commands.wrapIn(this.name);
        },
      toggleImportant:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      unsetImportant:
        () =>
        ({ commands }) => {
          return commands.lift(this.name);
        },
    };
  },
  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },
});
