import { useFormContext, useWatch } from 'react-hook-form';

const CompletionPreview = () => {
  const { control } = useFormContext();
  const values = useWatch({ control });

  return <></>;
};

export default CompletionPreview;
