import { CodeOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button, Modal, Space, Spin, Typography, Input, Row, Col, Select, Form, Alert, Tag } from 'antd';
import { memo, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { aiRoutes } from '../../../../lib/routes';
import { extractVariablesFromJSON } from '../../../../lib/variables';
import useFetch from '../../../../hooks/useFetch';

const TestModal = memo(({ ai, item, versions }) => {
  const [viewerKey, setViewerKey] = useState(0);
  const [visible, setVisible] = useState(false);
  const [variables, setVariables] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState({});
  const { post } = useFetch();

  useEffect(() => {
    if (visible) {
      const variablesFromJSON = extractVariablesFromJSON(ai.test);
      const defaultVariables = variablesFromJSON.reduce((acc, curr) => {
        if (curr === 'VERSION') {
          return { ...acc, [curr]: null };
        }

        if (curr === 'API_KEY') {
          return { ...acc, [curr]: '*'.repeat(50) };
        }

        return { ...acc, [curr]: '' };
      }, {});

      setVariables(defaultVariables);
    }
  }, [visible]);

  const handleVariableChange = (variable, value) => {
    setVariables((rest) => ({ ...rest, [variable]: value }));
  };

  const handleTest = async () => {
    setIsProcessing(true);
    const url = `${aiRoutes.keys}/${item._id}/test`;
    const results = await post(url, JSON.stringify(variables));

    if (Object.keys(results?.errors).length !== 0) {
      setResponse(results.errors);
    } else if (results?.message) {
      setResponse({ error: results.message });
    } else {
      setResponse(results.data);
    }

    setViewerKey(viewerKey + 1);
    setIsProcessing(false);
  };

  return (
    <>
      <Tooltip title={!ai?.test ? 'Test non configuré' : 'Tester la clé'} destroyTooltipOnHide>
        <Button size="small" icon={<CodeOutlined />} onClick={() => setVisible(true)} disabled={!ai?.test} />
      </Tooltip>
      <Modal
        title="Test de clé API"
        width={1000}
        visible={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose
        footer={false}
      >
        <Typography.Title level={4}>Configuration</Typography.Title>
        <Space direction="vertical" style={{ display: 'flex' }}>
          {item?.restrictions && item?.restrictions?.length !== 0 && (
            <Alert
              showIcon
              type="warning"
              message="Cette clé est limitée à l'utilisation des versions suivantes :"
              description={item.restrictions.map((item) => (
                <Tag>{item?.name || item}</Tag>
              ))}
            />
          )}
          <Form layout="vertical">
            <Row gutter={[24, 24]}>
              {Object.keys(variables).map((variable, index) => {
                if (variable === 'VERSION') {
                  return (
                    <Col span={12}>
                      <Form.Item label={variable}>
                        <Select
                          key={index}
                          value={variables[variable]}
                          onChange={(value) => handleVariableChange(variable, value)}
                          placeholder="Sélectionnez dans la liste"
                          options={versions?.data
                            .filter((version) => {
                              if (!item.restrictions || item.restrictions?.length === 0) {
                                return true;
                              }

                              return !!item.restrictions.find((item) => item._id === version._id);
                            })
                            .map((item) => ({ label: item.name, value: item._id }))}
                        />
                      </Form.Item>
                    </Col>
                  );
                }

                if (variable === 'API_KEY') {
                  return (
                    <Col span={12}>
                      <Form.Item label={variable}>
                        <Input key={index} value={variables[variable]} disabled />
                      </Form.Item>
                    </Col>
                  );
                }

                return (
                  <Col span={12}>
                    <Form.Item label={variable}>
                      <Input
                        key={index}
                        value={variables[variable]}
                        onChange={(event) => handleVariableChange(variable, event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Button block type="primary" icon={<PlayCircleOutlined />} loading={isProcessing} onClick={handleTest}>
                  Lancer le test
                </Button>
              </Col>
            </Row>
          </Form>
        </Space>
        <Typography.Title level={4}>Réponse du serveur</Typography.Title>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Spin size="small" spinning={isProcessing}>
            <div style={{ maxHeight: 400, overflow: 'scroll', padding: 8, background: '#272822', borderRadius: 4 }}>
              <ReactJson
                key={viewerKey}
                src={response}
                theme="monokai"
                iconStyle="square"
                displayDataTypes={false}
                displayObjectSize={false}
                displayArrayKey={false}
                enableClipboard={false}
                collapsed={false}
                indentWidth={2}
              />
            </div>
          </Spin>
        </Space>
      </Modal>
    </>
  );
});

TestModal.displayName = 'TestModal';
export default TestModal;
