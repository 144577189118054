import { useEffect, useMemo, useState } from 'react';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { Button, Form, Card, message, Steps, Typography } from 'antd';
import useSWR from 'swr';
import { reportRoutes, reportFormRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';
import StudentStep from './components/StudentStep';
import CompanyStep from './components/CompanyStep';
import ReportsStep from './components/ReportsStep';

const studentErrorFields = [
  'studentEmail',
  'studentPhone',
  'studentFirstName',
  'studentLastName',
  'studentFormation',
  'studentFormationId',
  'studentId',
  'studentFormationDates',
  'studentAgency',
  'studentFormer',
  'referent',
];

const companyErrorFields = [
  'companyName',
  'companyTutorPhone',
  'companyTutorFirstName',
  'companyTutorLastName',
  'companyTutorFunction',
  'companyTutorEmail',
];

const ReportCreate = ({ reportsType, needCompanyStep }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [step, setStep] = useState(0);
  const [formsOptions, setFormsOptions] = useState([]);
  const [showAllForms, toggleShowAllForms] = useState(true);
  const {
    data: forms,
    isValidating: isFormsValidating,
    mutate: reloadForms,
  } = useSWR(reportFormRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const formattedForms = useMemo(
    () =>
      forms?.data.map((form) => ({
        label: form.name,
        value: form._id,
        formations: form.formations,
        formType: form.formType,
        reportType: form.reportType,
      })),
    [forms],
  );
  const methods = useForm({
    defaultValues: {
      reports: [],
    },
  });
  const { errors } = useFormState({ control: methods.control });
  const history = useHistory();
  const { post } = useFetch();
  const studentId = methods.watch('studentId');
  const studentEmail = methods.watch('studentEmail');
  const studentFormationId = methods.watch('studentFormationId');
  const studentFormation = methods.watch('studentFormation');
  const studentFormationDates = methods.watch('studentFormationDates');

  useEffect(() => {
    methods.setValue('reportsType', reportsType);

    switch (reportsType) {
      case 'FORMATION':
        methods.setValue('reports', [
          { form: null, sendAt: null, ignored: false },
          { form: null, sendAt: null, ignored: false },
        ]);
        break;
      case 'COMPANY':
        methods.setValue('reports', [
          { form: null, sendAt: null, ignored: false },
          { form: null, sendAt: null, ignored: false },
          { form: null, sendAt: null, ignored: false },
        ]);
        break;
      case 'STAGE':
        methods.setValue('reports', [{ form: null, sendAt: null }]);
        break;
      case 'MENSUEL':
        methods.setValue('reports', []);
        break;
    }
  }, [reportsType]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  const studentProps = {
    disabled: isProcessing,
    formattedForms,
    isEditing: false,
    setFormsOptions,
    toggleShowAllForms,
  };

  const reportsProps = {
    disabled: isProcessing,
    reportsType,
    showAllForms,
    formattedForms,
    forms,
    isFormsValidating,
    formsOptions,
    isEditing: false,
    setFormsOptions,
    toggleShowAllForms,
    reloadForms,
  };

  const getStatus = (errorFields) => {
    const stepErrors = Object.keys(errors).filter((key) => errorFields.includes(key));

    if (stepErrors.length !== 0) {
      return 'error';
    } else {
      return;
    }
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    let [studentStartAt, studentEndAt] = [,];
    let createPath = '';

    switch (reportsType) {
      case 'FORMATION':
        createPath = '/formation';
        break;
      case 'COMPANY':
        createPath = '/company';
        break;
      case 'MENSUEL':
        createPath = '/monthly';
        break;
      case 'STAGE':
        createPath = '/training';
        break;
      default:
        break;
    }

    if (form?.studentFormationDates) {
      [studentStartAt, studentEndAt] = form?.studentFormationDates;
    }

    const results = await post(
      reportRoutes.default + createPath,
      JSON.stringify({
        ...form,
        studentStartAt,
        studentEndAt,
        referent: form?.referent?.value,
        studentFormer: form?.studentFormer?.value,
        studentFormation: form?.studentFormation?.value || form?.studentFormation,
        reports: form?.reports?.map((item) => {
          return {
            sendAt: item.sendAt,
            form: item.form?.value ?? item.form,
            ignored: item.ignored ? item.ignored : false,
          };
        }),
        ...(reportsType === 'MENSUEL' && { courseType: needCompanyStep ? 'ALTERNANCE' : 'CONTINU' }),
      }),
    );

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/formao/bilans');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          if (key === 'studentStartAt' || key === 'studentEndAt') {
            methods.setError('studentFormationDates', { type: 'manual', message: value });
          } else if (key === 'studentId') {
            methods.setError('studentEmail', { type: 'manual', message: value });
          } else if (key === 'studentFormationId') {
            methods.setError('studentFormation', { type: 'manual', message: value });
          } else {
            methods.setError(key, { type: 'manual', message: value });
          }
        });
      }
    }

    setIsProcessing(false);
  };

  const onStepChange = (value) => {
    switch (value) {
      case 0:
        setStep(value);
        break;
      case 1:
        if (typeof studentEmail === 'string') {
          if (['COMPANY', 'STAGE'].includes(reportsType)) {
            setStep(value);
          } else {
            if ((studentFormationDates && studentFormation) || (studentId && studentFormationId)) {
              setStep(value);
            } else {
              message.error("Veuillez d'abord saisir une formation ainsi que les dates de cette formation");
            }
          }
        } else {
          message.error("Veuillez d'abord saisir un stagiaire");
        }
        break;
      case 2:
        if (typeof studentEmail === 'string') {
          if ((studentFormationDates && studentFormation) || (studentId && studentFormationId)) {
            setStep(value);
          } else {
            message.error("Veuillez d'abord saisir une formation ainsi que les dates de cette formation");
          }
        } else {
          message.error("Veuillez d'abord saisir un stagiaire");
        }
        break;
      default:
        break;
    }
  };

  const getTitle = () => {
    switch (reportsType) {
      case 'FORMATION':
        return "Création d'un bilan de formation en continu";
      case 'COMPANY':
        return "Création d'un bilan de formation en alternance";
      case 'MENSUEL':
        return "Création d'un suivi mensuel";
      case 'STAGE':
        return "Création d'un bilan de stage";
      default:
        return '';
    }
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Typography.Title level={2}>{getTitle()}</Typography.Title>
      <Steps style={{ marginBottom: 20 }} type="navigation" current={step} onChange={onStepChange}>
        <Steps.Step status={getStatus(studentErrorFields)} title="Stagiaire et formation" />
        {needCompanyStep && <Steps.Step status={getStatus(companyErrorFields)} title="Entreprise et tuteur" />}
        <Steps.Step status={getStatus(['reports'])} title="Envoi des bilans" />
      </Steps>
      <FormProvider {...methods}>
        <Form layout="vertical" noValidate onFinish={methods.handleSubmit(onSubmit)}>
          {step === 0 && <StudentStep {...studentProps} />}
          {step === 1 &&
            (needCompanyStep ? <CompanyStep disabled={isProcessing} /> : <ReportsStep {...reportsProps} />)}
          {needCompanyStep && step === 2 && <ReportsStep {...reportsProps} />}
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/formao/bilans">Annuler</Link>
            </Button>
            {((needCompanyStep && [0, 1].includes(step)) || (!needCompanyStep && step === 0)) && (
              <Button type="primary" onClick={() => onStepChange(step + 1)} disabled={isProcessing}>
                Continuer
              </Button>
            )}
            {((needCompanyStep && step === 2) || (!needCompanyStep && step === 1)) && (
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ReportCreate;
