import { useState, useEffect } from 'react';
import { Link, useParams, Prompt, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, Form, Tabs, Space, Button, message } from 'antd';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { questionRoutes } from '../../../../lib/routes';
import Meta from '../components/Meta';
import Organize from './Organize';
import { OrganizePreview, Preview } from '../components/Preview/index';
import { isValid } from 'date-fns';

const { TabPane } = Tabs;

const UpdateOrganizeQuestion = () => {
  const [tab, setTab] = useState('form');
  const [isBlocking, setIsBlocking] = useState(false);
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: question, isValidating } = useSWR(questionRoutes.findOne + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const methods = useForm({
    defaultValues: {
      duration: 30,
      labels: [],
      lists: [],
    },
  });
  const { put } = useFetch();
  const history = useHistory();

  useEffect(() => {
    methods.register('labels');
  }, []);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsBlocking(true);
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (question && question.data) {
      methods.setValue('trainings', question.data.trainings);
      methods.setValue('duration', question.data.duration);
      methods.setValue('difficulty', question.data.difficulty);
      methods.setValue('question', question.data.question);
      methods.setValue('labels', question.data.organize.labels);
      methods.setValue('lists', question.data.organize.lists);
    }
  }, [question]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(questionRoutes.update + '/organize' + `/${question.data._id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(questionRoutes.update + '/organize' + `/${question.data._id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/posiao/banque-de-question');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Tabs defaultActiveKey="form" activeKey={tab} onChange={setTab}>
            <TabPane tab="Formulaire" key="form">
              <Meta
                loading={isProcessing || !question || isValidating}
                withImage={false}
                instructions={[
                  'Saisir une question',
                  'Ajouter le nombre de colonnes souhaitées et les nommer',
                  'Ajouter des éléments et les nommer',
                  'Placer les éléments dans les bonnes colonnes',
                  'Certains éléments peuvent être piège et donc dans aucune colonne',
                ]}
              />
              <Organize loading={isProcessing || !question || isValidating} />
            </TabPane>
            <TabPane tab="Prévisualisation" key="preview" activeKey={tab} onChange={setTab}>
              <Preview type="organize">
                <OrganizePreview />
              </Preview>
            </TabPane>
          </Tabs>
          {tab === 'form' && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
              <Button className="gray-text" disabled={isProcessing}>
                <Link to="/posiao/banque-de-question">Annuler</Link>
              </Button>
              <Space>
                <Button loading={isProcessing} onClick={methods.handleSubmit(onSubmit)}>
                  Enregistrer
                </Button>
                <Button type="primary" loading={isProcessing} onClick={methods.handleSubmit(onSubmitAndLeave)}>
                  Enregistrer et quitter
                </Button>
              </Space>
            </div>
          )}
        </Form>
      </FormProvider>
    </Card>
  );
};

export default UpdateOrganizeQuestion;
